import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
const REACT_APP_SESSION_TIMEOUT = process.env.REACT_APP_SESSION_TIMEOUT

const InactivityInSeconds = REACT_APP_SESSION_TIMEOUT || 60

const ActivityChecker = (props) => {
    let history = useHistory();
    const [timer, setTimer] = useState(0)
    const [timerStart, setTimerStart] = useState(true)
    let timeout;

    const logout = () => {
        history.push(props.timoutRedirection)
        window.location.reload();
    };

    useEffect(() => {
        if (timerStart) {
            timeout = setTimeout(() => {
                setTimer(timer + 1)
            }, 1000)
            return () => clearInterval(timeout);
        }
    })

    useEffect(() => {
        if (timer >= +InactivityInSeconds) {
            clearInterval(timeout)
            setTimerStart(false)
            localStorage.removeItem(props.sessionName);
            Modal.info({
                title: 'Inactivity',
                icon: <ExclamationCircleOutlined />,
                content: 'System detected of inactivity. You will now logout and redirected to login page. Thank you',
                onOk() { logout() }
            });
        }
    }, [timer])

    useEffect(() => {
        setTimer(0)
    }, [props.state])

    useEffect(() => {
        onInactive()
    }, [])

    function onInactive(ms, cb) {
        var wait = setTimeout(cb, ms);
        document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = function () {
            clearTimeout(wait);
            wait = setTimeout(cb, ms);
            setTimer(0)
        };
    }

    return (null)
}


function mapStateToProps(state) {
    return { state }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityChecker)
