
import React from 'react'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadTenant } from '../actions'
import Bill from './bill'
import PaymentTransaction from './paymentTransaction'
import './index.scss'
class Billings extends React.Component {
    constructor() {
        super()
        this.state = {
            isEditModalOpen: false,
            selectedRow: {}
        }
        this.editModalToggle = this.editModalToggle.bind(this)
    }

    editModalToggle() {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
    }

    render() {

        return (
            <Layout>
                <Bill />
                <PaymentTransaction />
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        tenantData: state.client.billings.tenantData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenant: (data) => dispatch(loadTenant(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Billings))
