import Axios from 'axios'

const POST_LOGIN_DATA = 'POST_LOGIN_DATA'
const POST_LOGIN_DATA_FULFILLED = 'POST_LOGIN_DATA_FULFILLED'
const POST_LOGIN_DATA_REJECTED = 'POST_LOGIN_DATA_REJECTED'

export const GET_TERMS_AND_CONDITIONS = 'GET_TERMS_AND_CONDITIONS'
export const GET_TERMS_AND_CONDITIONS_FULFILLED = 'GET_TERMS_AND_CONDITIONS_FULFILLED'
export const GET_TERMS_AND_CONDITIONS_REJECTED = 'GET_TERMS_AND_CONDITIONS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const submitLogin = (data) => {
  return dispatch => {
    dispatch({
      type: POST_LOGIN_DATA,
      payload: {}
    })
    return Axios.post(`${API_URL}/login`, { ...data })
      .then(function (response) {
        if (response.data.token) {
          localStorage.setItem('CLIENT_SESSION_TOKEN', response.data.token)
          dispatch({
            type: POST_LOGIN_DATA_FULFILLED,
            payload: response.data
          })
        } else {
          dispatch({
            type: POST_LOGIN_DATA_REJECTED
            // payload: error
          })
        }
      })
      .catch(function (error) {
        dispatch({
          type: POST_LOGIN_DATA_REJECTED,
          payload: error
        })
      })
  }
}

export const loadTermsAndCondition = () => {
  return dispatch => {
    dispatch({
      type: GET_TERMS_AND_CONDITIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/public-terms-and-conditions`)
      .then(function (response) {
        dispatch({
          type: GET_TERMS_AND_CONDITIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_TERMS_AND_CONDITIONS_REJECTED,
          payload: error
        })
      })
  }
}

