import {
  POST_POLICY,
  POST_POLICY_FULFILLED,
  POST_POLICY_REJECTED,
  GET_POLICIES,
  GET_POLICIES_FULFILLED,
  GET_POLICIES_REJECTED,
  PUT_POLICY,
  PUT_POLICY_FULFILLED,
  PUT_POLICY_REJECTED,
  DELETE_POLICY,
  DELETE_POLICY_FULFILLED,
  DELETE_POLICY_REJECTED,
  APPROVE_POLICY,
  APPROVE_POLICY_FULFILLED,
  APPROVE_POLICY_REJECTED,

  //Header
  GET_POLICIES_HEADER,
  GET_POLICIES_HEADER_FULFILLED,
  GET_POLICIES_HEADER_REJECTED,
  PUT_POLICY_HEADER,
  PUT_POLICY_HEADER_FULFILLED,
  PUT_POLICY_HEADER_REJECTED
} from '../actions'

export default function policies(state = {
  policyLoading: false,
  policySuccess: false,
  policyRejected: false,
  policyData: [],
  policiesLoading: false,
  policiesSuccess: false,
  policiesRejected: false,
  policiesData: [],
  updatePolicyLoading: false,
  updatePolicySuccess: false,
  updatePolicyRejected: false,
  updatePolicyData: [],
  deletePolicyLoading: false,
  deletePolicySuccess: false,
  deletePolicyRejected: false,
  deletePolicyData: [],
  approvePolicyLoading: false,
  approvePolicySuccess: false,
  approvePolicyRejected: false,
  policiesHeaderLoading: false,
  policiesHeaderSuccess: false,
  policiesHeaderRejected: false,
  policiesHeaderData: []
}, action) {
  switch (action.type) {
    case GET_POLICIES_HEADER:
      return {
        ...state,
        policiesHeaderLoading: true,
        policiesHeaderSuccess: false,
        policiesHeaderRejected: false
      }
    case GET_POLICIES_HEADER_FULFILLED:
      return {
        ...state,
        policiesHeaderLoading: false,
        policiesHeaderSuccess: true,
        policiesHeaderData: action.payload
      }
    case GET_POLICIES_HEADER_REJECTED:
      return {
        ...state,
        policiesHeaderLoading: false,
        policiesHeaderRejected: true
      }
    case PUT_POLICY_HEADER:
      return {
        ...state,
        updatePolicyHeaderLoading: true,
        updatePolicyHeaderSuccess: false,
        updatePolicyHeaderRejected: false
      }
    case PUT_POLICY_HEADER_FULFILLED:
      return {
        ...state,
        updatePolicyHeaderLoading: false,
        updatePolicyHeaderSuccess: true,
        updatePolicyHeaderData: action.payload
      }
    case PUT_POLICY_HEADER_REJECTED:
      return {
        ...state,
        updatePolicyHeaderLoading: false,
        updatePolicyHeaderRejected: true
      }
    case POST_POLICY:
      return {
        ...state,
        policyLoading: true,
        policySuccess: false,
        policyRejected: false
      }
    case POST_POLICY_FULFILLED:
      return {
        ...state,
        policyLoading: false,
        policySuccess: true,
        policyData: action.payload
      }
    case POST_POLICY_REJECTED:
      return {
        ...state,
        policyLoading: false,
        policyRejected: true
      }
    case GET_POLICIES:
      return {
        ...state,
        policiesLoading: true,
        policiesSuccess: false,
        policiesRejected: false
      }
    case GET_POLICIES_FULFILLED:
      return {
        ...state,
        policiesLoading: false,
        policiesSuccess: true,
        policiesData: action.payload
      }
    case GET_POLICIES_REJECTED:
      return {
        ...state,
        policiesLoading: false,
        policiesRejected: true
      }
    case PUT_POLICY:
      return {
        ...state,
        updatePolicyLoading: true,
        updatePolicySuccess: false,
        updatePolicyRejected: false
      }
    case PUT_POLICY_FULFILLED:
      return {
        ...state,
        updatePolicyLoading: false,
        updatePolicySuccess: true,
        updatePolicyData: action.payload
      }
    case PUT_POLICY_REJECTED:
      return {
        ...state,
        updatePolicyLoading: false,
        updatePolicyRejected: true
      }
    case DELETE_POLICY:
      return {
        ...state,
        deletePolicyLoading: true,
        deletePolicySuccess: false,
        deletePolicyRejected: false
      }
    case DELETE_POLICY_FULFILLED:
      return {
        ...state,
        deletePolicyLoading: false,
        deletePolicySuccess: true,
        deletePolicyData: action.payload
      }
    case DELETE_POLICY_REJECTED:
      return {
        ...state,
        deletePolicyLoading: false,
        deletePolicyRejected: true
      }
    case APPROVE_POLICY:
      return {
        ...state,
        approvePolicyLoading: true,
        approvePolicySuccess: false,
        approvePolicyRejected: false
      }
    case APPROVE_POLICY_FULFILLED:
      return {
        ...state,
        approvePolicyLoading: false,
        approvePolicySuccess: true,
        approvePolicyData: action.payload
      }
    case APPROVE_POLICY_REJECTED:
      return {
        ...state,
        approvePolicyLoading: false,
        approvePolicyRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
