import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker } from 'antd'
import { connect } from 'react-redux'
import { submitInquiry } from '../../actions'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
import moment from 'moment'
const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false
    }
  }

  handleSubmit(values) {
    this.props.onSubmitServinceRequest(values)
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    return (
      <Modal
        title="ADD LEASING ASSISTANCE"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Date Inquiry"
                  name="dateInquiry"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date from!',
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Lessor’s Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Lessor’s Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Lessor’s Contact Number"
                  name="contactNumber"
                  rules={[{ required: true, message: 'Please input your contact number!' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    inquiryLoading: state.admin.inquiries.inquiryLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitServinceRequest: (data) => dispatch(submitInquiry(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant())
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

