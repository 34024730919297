import React, { useState, useEffect } from 'react'
import ImageUploader from './imageBulkUploader'
import { connect } from 'react-redux'
import { Modal, Button, Form, Row, Col, Input, Card, DatePicker } from 'antd'
import { getUser } from '../../../../utilities/token'
import { loadTenant, submitPayment, POST_PAYMENT_FULFILLED } from '../actions'
import { DIRECT_DEPOSIT } from '../../../../utilities/paymentsTypeEnum'
import { FOR_VERIFICATION } from '../../../../utilities/paymentsStatusEnum'
import { upload } from '../../../../utilities/filesController'

import { useHistory } from 'react-router-dom'

import moment from 'moment'

const Deposit = (props) => {
    let history = useHistory();
    const [form] = Form.useForm()
    const [fileImageList, setFileImageList] = useState([])
    const [fileImage, setFileImage] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        props.onLoadTenant(getUser().id).then(res => {
            let { clientCode, soaDate } = res.payload
            soaDate = moment(soaDate).format('MMDDYYYY')
            const invoiceNumber = `(${clientCode})${soaDate}`
            form.setFieldsValue({ ...res.payload, invoiceNumber })
        })
    }, [])

    function getFileImage(e) {
        const list = e.map(data => {
            return { name: data.name, url: `depositslip/${data.name}` }
        })
        setFileImageList(list)
        setFileImage(e)
    }

    function uploadFileImage() {
        return new Promise((resolve, reject) => {
            if (fileImage) {
                fileImage && fileImage.map((items, index) => {
                    upload(items, items.name, 'depositslip').then(data => {
                        return resolve(true)
                    }).catch(err => {
                        return Modal.error({
                            title: 'This is an error message for uploading images',
                            content: err,
                        });
                    })
                })
            } else {
                return resolve(true)
            }
        })
    }

    function handleSubmit(values) {
        setLoading(true)
        let { clientCode } = props.tenantData

        const submitData = {
            tenantId: getUser().id,
            clientCode,
            transactionDate: values.transactionDate,
            invoiceDate: values.invoiceDate,
            soaAmount: values.soaAmount,
            amountPaid: values.amountPaid,
            invoiceNumber: values.invoiceNumber,
            images: fileImageList,
            type: DIRECT_DEPOSIT,
            status: FOR_VERIFICATION
        }
        uploadFileImage().then((res) => {
            props.onSubmitPayment(submitData).then((res) => {
                Modal.success({
                    title: 'DEPOSIT SLIP UPLOADED',
                    content: 'You have successfully uploaded your deposit slip. Our officer in charge will review the file also we will redirect you to Billing Page. Thank you.',
                    onOk: () => history.push('/')
                });
            })
            setLoading(false)
        }).catch((err) => {
            Modal.error({
                title: 'Unable to submit deposit.',
                content: 'ERROR',
            });
            setLoading(false)
        })
    }

    return (
        <Card>
            <h1>Deposit</h1>
            <Form
                form={form}
                name="basic"
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Invoice Number (Read Only)"
                            name="invoiceNumber"
                        >
                            <Input className='primary-input' readOnly />
                        </Form.Item>

                        <Form.Item
                            label="SOA Amount (Read Only)"
                            name="soaAmount"
                        >
                            <Input className='primary-input' readOnly />
                        </Form.Item>

                        <Form.Item
                            label="Paid Amount"
                            name="amountPaid"
                            rules={[{ required: true, message: 'Please input your Paid Amount!' }]}
                        >
                            <Input className='primary-input' type='number' />
                        </Form.Item>

                        <Form.Item
                            label="Transaction Date"
                            name="transactionDate"
                            rules={[{ required: true, message: 'Please input your end time!' }]}
                        >
                            <DatePicker
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>

                    </Col>

                    <Col md={12} lg={12} sm={24} xs={24}>


                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} xs={24} sm={24}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h1>Upload at least 1 (one) image of deposit slip</h1>
                        </div>
                        <ImageUploader
                            getFile={e => getFileImage(e)}
                        />
                        <span style={{ fontSize: 10, color: 'red' }}>Single file (at least 1 image)</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={props.paymentLoading || loading} disabled={fileImageList.length === 0}>
                                UPLOAD DEPOSIT SLIP
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}


function mapStateToProps(state) {
    return {
        tenantData: state.client.billings.tenantData,
        paymentLoading: state.client.billings.paymentLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenant: (data) => dispatch(loadTenant(data)),
        onSubmitPayment: (data) => dispatch(submitPayment(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Deposit)
