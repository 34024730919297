import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker, Card, Space } from 'antd'
import moment from 'moment'
import { getUser } from '../../../../../utilities/token'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { updateTicket, loadTicketById } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import { getParamFromUrl } from '../../../../../utilities/helper'

const { Option } = Select;

class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      isLoading: false,
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadTicketById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload,
          dateOfApplication: res.payload.dateOfApplication && moment(res.payload.dateOfApplication),
          dateOfUse: res.payload.dateOfUse && moment(res.payload.dateOfUse),
          completedDate: res.payload.completedDate && moment(res.payload.completedDate).format('LLL') || '',
          approvalDate: res.payload.approvalDate && moment(res.payload.approvalDate).format('LLL') || '',
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }

  componentDidMount() {
    // this.props.onLoadTenant()
  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  getTypeEnum(type) {
    switch (type) {
      case 'Delivery':
        return 1
      case 'MoveIn':
        return 2
      case 'MoveOut':
        return 3
      case 'PullOut':
        return 4
      default:
        return 1
    }
  }

  handleUpdate(values) {
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id
    const submitData = {
      "tenantId": values.tenant,
      "bearerName": values.bearerName,
      "dateOfApplication": values.dateOfApplication,
      "dateOfUse": values.dateOfUse,
      "type": this.getTypeEnum(values.type),
      "items": values.items,
      "remarks": values.remarks,
      "status": this.getStatusEnum(values.status),

      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,

      // COMPLETED
      "completedDate": (values.status === 'COMPLETED' && this.props.selectedRow.status !== this.getStatusEnum(values.status)) && moment(new Date()),

      // ONGOING
      "dateApplied": (values.status === 'ONGOING' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date())

    }

    this.props.onUpdateTicket({ ...submitData, id: selectedRowId }, this.props.ticketType)

  }
  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  getTypeLabel(status) {
    switch (status) {
      case 1:
        return 'Delivery'
      case 2:
        return 'MoveIn'
      case 3:
        return 'MoveOut'
      case 4:
        return 'PullOut'
      default:
        break;
    }
  }


  render() {
    const { selectedRow } = this.props
    // const disabled = (!this.access('gatepass', 'edit') || !this.access('gatepass', 'approve'))
    const disabled = (!this.access('gatepass', 'edit') || this.props.selectedRow.status !== 1)
    return (
      <Modal
        title="EDIT GATEPASS"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            ref={this.formRef}
            name="basic"
            initialValues={{
              ...this.props.selectedRow,
              dateOfApplication: moment(this.props.selectedRow.dateOfApplication),
              dateOfUse: moment(this.props.selectedRow.dateOfUse),
              completedDate: moment(selectedRow.completedDate).format('LLL') || '',
              approvalDate: selectedRow.approvalDate && moment(selectedRow.approvalDate).format('LLL') || '',
              items: this.props.selectedRow.items || [],
              status: this.getStatusLabel(this.props.selectedRow.status),
              type: this.getTypeLabel(this.props.selectedRow.type),
              approvedBy: selectedRow.approver && selectedRow.approver.name || '',
              verifiedBy: selectedRow.verifier && selectedRow.verifier.name || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Bearer Name"
                  name="bearerName"
                  rules={[{ required: true, message: 'Please input your bearer name!' }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>

                <Form.Item
                  label="Date Of Application"
                  name="dateOfApplication"
                  rules={[{ required: true, message: 'Please input your date of application!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                    disabled={disabled}
                  />
                </Form.Item>

                <Form.Item
                  label="Date Of Use"
                  name="dateOfUse"
                  rules={[{ required: true, message: 'Please input your date of date of use!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                    disabled={disabled}
                  />
                </Form.Item>

                <Form.Item
                  label="Type"
                  name="type"
                // rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select defaultValue='Delivery' disabled={disabled}>
                    <Select.Option value={'Delivery'}>Delivery</Select.Option>
                    <Select.Option value={'MoveIn'}>Move In</Select.Option>
                    <Select.Option value={'MoveOut'}>Move Out</Select.Option>
                    <Select.Option value={'PullOut'}>Pull Out</Select.Option>
                  </Select>
                </Form.Item>

                <Card title="List of Items">
                  <Form.List name="items">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields && fields.map(field => (
                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                rules={[{ required: true, message: 'Missing Name' }]}
                              >
                                <Input placeholder="Name" disabled={disabled} />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'quantity']}
                                fieldKey={[field.fieldKey, 'quantity']}
                                rules={[{ required: true, message: 'Missing Quantity' },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject();
                                    }
                                    if (isNaN(value)) {
                                      return Promise.reject("Quantity to be a number.");
                                    }
                                    if (value.includes('.')) {
                                      return Promise.reject("Quantity is not a whole number");
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                                ]}
                              >
                                <Input type="number" placeholder="Quantity" disabled={disabled} />
                              </Form.Item>
                              {
                                disabled &&
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              }
                            </Space>
                          ))}
                          <Form.Item>
                            <Button disabled={disabled} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add Item
                            </Button>
                          </Form.Item>
                        </>
                      )
                    }}
                  </Form.List>
                </Card>

              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>

                {/* <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                >
                  <Input readOnly disabled />
                </Form.Item> */}

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Completed Date"
                  name="completedDate"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} disabled={!this.access('gatepass', 'edit')} >
                    {/* <Option value="">None</Option> */}

                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    {
                      this.access('gatepass', 'approve') &&
                      <Option value={'APPROVED'}>APPROVED</Option>
                    }
                    {
                      this.access('gatepass', 'ongoing') &&
                      <Option value={'ONGOING'}>ONGOING</Option>
                    }
                    {
                      this.access('gatepass', 'complete') &&
                      <Option value={'COMPLETED'}>COMPLETED</Option>
                    }
                    {
                      this.access('gatepass', 'cancel') &&
                      <Option value={'CANCELLED'}>CANCELLED</Option>
                    }
                    {
                      this.access('gatepass', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>
              </Col>

            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('gatepass', 'edit') &&
                    <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.updateGatePassLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateTicketLoading: state.admin.ticket.updateTicketLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateTicket: (data, ticketType) => dispatch(updateTicket(data, ticketType)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onLoadTicketById: (id) => dispatch(loadTicketById(id)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))
