import {
  GET_ADVERTISEMENTS,
  GET_ADVERTISEMENTS_FULFILLED,
  GET_ADVERTISEMENTS_REJECTED,
  GET_ADVERTISEMENT,
  GET_ADVERTISEMENT_FULFILLED,
  GET_ADVERTISEMENT_REJECTED,
} from '../actions'

export default function advertisements(state = {
  advertisementsLoading: false,
  advertisementsSuccess: false,
  advertisementsRejected: false,
  advertisementsData: [],
  advertisementLoading: true,
  advertisementSuccess: false,
  advertisementRejected: false,
  advertisementData: {}
}, action) {
  switch (action.type) {
    case GET_ADVERTISEMENTS:
      return {
        ...state,
        advertisementsLoading: true,
        advertisementsSuccess: false,
        advertisementsRejected: false
      }
    case GET_ADVERTISEMENTS_FULFILLED:
      return {
        ...state,
        advertisementsLoading: false,
        advertisementsSuccess: true,
        advertisementsData: action.payload
      }
    case GET_ADVERTISEMENTS_REJECTED:
      return {
        ...state,
        advertisementsLoading: false,
        advertisementsRejected: true
      }
    case GET_ADVERTISEMENT:
      return {
        ...state,
        advertisementLoading: true,
        advertisementSuccess: false,
        advertisementRejected: false
      }
    case GET_ADVERTISEMENT_FULFILLED:
      return {
        ...state,
        advertisementLoading: false,
        advertisementSuccess: true,
        advertisementData: action.payload
      }
    case GET_ADVERTISEMENT_REJECTED:
      return {
        ...state,
        advertisementLoading: false,
        advertisementRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
