import React from 'react'
import { Row, Col, Form, Radio, Button, Input, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { updateRequest } from '../../../actions'
import { getUser } from '../../../../../../utilities/token'
import moment from 'moment'
import SlidingPane from "react-sliding-pane";
const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleUpdate(values) {
    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "description": values.description,
      "status": values.status === 'Active' ? 1 : 0,
      "date": moment(values.date).format('LLL'),
      "estimatedAmount": values.estimatedAmount,
      "billOption": values.billOption,
      "approvalDate": moment(values.approvalDate).format('LLL'),
      "approvedById": values.approvedById,
      "remarks": values.remarks,
      "verifiedById": values.verifiedById,
    }
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id
    this.props.onUpdateRequest({ ...submitData, id: selectedRowId })
  }


  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT JOB ORDER REQUEST"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={false}>

          <Form
            name="basic"
            initialValues={{
              ...selectedRow,
              approvedById: selectedRow && selectedRow.approver && selectedRow.approver.name,
              status: this.getStatusLabel(selectedRow && selectedRow.status),
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Bill Option (Tag as Billable/Non-Billable)"
                  name="billOption"
                >
                  <Radio.Group defaultValue='Billable' disabled>
                    <Radio value={'Billable'}>Billable</Radio>
                    <Radio value={'Non-Billable'}>Non-Billable</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Estimated Amount"
                  name="estimatedAmount"
                  rules={[{ required: false, message: 'Please input your estimated amount!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date"
                  name="date"
                  rules={[{ required: false, message: 'Please input your date!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select readOnly disabled style={{ width: '100%' }} className='primary-input-select'>
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    <Option value={'APPROVED'}>APPROVED</Option>
                    <Option value={'ONGOING'}>ONGOING</Option>
                    <Option value={'COMPLETED'}>COMPLETED</Option>
                    <Option value={'CANCELLED'}>CANCELLED</Option>
                    <Option value={'REJECTED'}>REJECTED</Option>
                  </Select>
                </Form.Item>


              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Work Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your work approval date!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Work Approved By"
                  name="approvedById"
                  rules={[{ required: false, message: 'Please input your work approved by!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={5} disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date Completed"
                  name="completedDate"
                  rules={[{ required: false, message: 'Please input your date completed!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedById"
                  rules={[{ required: false, message: 'Please input your verified!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={this.props.updateRequestLoading || this.state.isLoading} disabled={disabled} >
                    Update Job Order Request
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateRequestLoading: state.client.services.updateRequestLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateRequest: (data) => dispatch(updateRequest(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}