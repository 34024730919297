import React from 'react'
import { Row, Modal, Spin, Card, Avatar, Divider, Collapse } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitComments, deleteComment } from '../../actions'
import moment from 'moment'
import CommentBox from './commentBox'
const { Panel } = Collapse;

class Thread extends React.Component {
  constructor() {
    super()
    this.state = {
      fileImage: '',
      isLoading: false,
      selectedRow: {}
    }
  }

  componentDidMount() {
    this.setState({ fileImage: '', selectedRow: this.props.selectedRow })
  }


  handleSubmitComment(data) {
    this.props.onSubmitComments(data)
  }

  handleDelete(commentId) {
    const self = this
    Modal.confirm({
      title: 'Do you Want to delete this comment?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this',
      onOk() {
        self.props.onDeleteComment(commentId).then(() => {
          const { selectedRow } = self.state
          const commentList = selectedRow.comments.filter((data => data.id !== commentId))
          selectedRow.comments = commentList
          self.setState({ selectedRow: selectedRow })
        })
      },
      onCancel() { },
    });
  }

  handleAddCommentProps(data) {
    const { selectedRow } = this.state
    selectedRow.comments.unshift(data)
    this.setState({ selectedRow: selectedRow })
  }

  render() {
    const { selectedRow } = this.state
    return (
      <Modal
        title="Thread"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large"
          spinning={false}
        >
          <Row style={{ width: '100%' }} >
            <Card className='thread-card-container' title={selectedRow.title} >
              <div className='card-content'>

                <div className='card-description'>
                  <p>{selectedRow.description}</p>
                  <span className='date'>{moment(selectedRow.created_at).format('LLL')}</span>
                </div>

                <Collapse ghost>
                  <Panel header={selectedRow.image} key="1">
                    <img className='post-image' src={`${selectedRow.image}`} />
                  </Panel>
                </Collapse>

                <div className='card-comment-section'>
                  <div className='comment-input'>
                    <Avatar className='image-tenant' src={getUser().image && `${getUser().image}`} > {getUser() && getUser().name && getUser().name.substring(0, 1).toUpperCase()}</Avatar>
                    <CommentBox className='comment-tb commentInput' postId={selectedRow.id} enableMessaging={selectedRow.enableMessaging} placeholder='White a comment' onEnterData={(data) => {
                      this.handleAddCommentProps(data)
                      this.handleSubmitComment(data)
                    }} />
                  </div>
                  <Divider />
                  {
                    selectedRow.comments && selectedRow.comments.map((comment, index) => {
                      return (
                        <div className='comments-list' key={index}>
                          <Avatar className='image-user' src={comment && comment.tenant && `${comment && comment.tenant && comment.tenant.image}`} > {comment && comment.tenant && comment.tenant.name && comment.tenant.name.substring(0, 1).toUpperCase()}</Avatar>
                          <Card style={{ borderRadius: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <h1>{`${comment.tenant && comment.tenant.firstName || ''} ${comment.tenant && comment.tenant.middleName || ''} ${comment.tenant && comment.tenant.lastName || ''}`} <span style={{ color: 'gray', fontSize: 9, fontStyle: 'italic' }}>{moment(comment.created_at).format('LLL')}</span></h1>
                                <span>{comment.comment}</span>
                              </div>
                              <div>
                                {/* <FontAwesomeIcon icon={faCog} /> */}
                                <a style={{ fontSize: 11, textDecoration: 'underline' }} onClick={() => {
                                  this.handleDelete(comment.id)
                                }}>Delete</a>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </Card>
          </Row>
        </Spin>
      </Modal>
    )
  }
}
function mapStateToProps(state) {
  return {
    postsData: state.client.post.postsData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitComments: (data) => dispatch(submitComments(data)),
    onDeleteComment: (commentId) => dispatch(deleteComment(commentId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Thread)
