import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_TENANT = 'POST_TENANT'
export const POST_TENANT_FULFILLED = 'POST_TENANT_FULFILLED'
export const POST_TENANT_REJECTED = 'POST_TENANT_REJECTED'
export const GET_TENANTS = 'GET_TENANTS'
export const GET_TENANTS_FULFILLED = 'GET_TENANTS_FULFILLED'
export const GET_TENANTS_REJECTED = 'GET_TENANTS_REJECTED'
export const PUT_TENANT = 'PUT_TENANT'
export const PUT_TENANT_FULFILLED = 'PUT_TENANT_FULFILLED'
export const PUT_TENANT_REJECTED = 'PUT_TENANT_REJECTED'
export const DELETE_TENANT = 'DELETE_TENANT'
export const DELETE_TENANT_FULFILLED = 'DELETE_TENANT_FULFILLED'
export const DELETE_TENANT_REJECTED = 'DELETE_TENANT_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadTenant = (pagination) => {
  pagination = {
    page: (pagination && pagination.page) || 1,
    limit: (pagination.limit !== undefined && pagination.limit) || 0,
    searchTerm: (pagination && pagination.searchTerm) || '',
    id: (pagination && pagination.id) || ''
  }
  return dispatch => {
    dispatch({
      type: GET_TENANTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/tenant-billings?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&id=${pagination.id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_TENANTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_TENANTS_REJECTED,
          payload: error
        })
      })
  }
}

export const submitTenant = (data) => {
  return dispatch => {
    dispatch({
      type: POST_TENANT,
      payload: {}
    })
    return Axios.post(`${API_URL}/tenant`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_TENANT_FULFILLED,
          payload: response.data
        })
        dispatch(loadTenant())
      })
      .catch(function (error) {
        dispatch({
          type: POST_TENANT_REJECTED,
          payload: error
        })
      })
  }
}

export const updateTenant = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_TENANT,
      payload: {}
    })
    return Axios.put(`${API_URL}/tenant/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_TENANT_FULFILLED,
          payload: response.data
        })
        dispatch(loadTenant())
      })
      .catch(function (error) {
        dispatch({
          type: PUT_TENANT_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteTenant = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_TENANT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/tenant/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_TENANT_FULFILLED,
          payload: response.data
        })
        dispatch(loadTenant())
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_TENANT_REJECTED,
          payload: error
        })
      })
  }
}





