import React from 'react'
import './index.scss'
import { connect } from 'react-redux'
import { Layout, Input, Card, Tabs } from 'antd'
import ImportDataFromPromis from './importDataFromPromis'
import SystemParameterSettings from './systemParameterSettings'
import ExportDataFromCsv from './exportDataFromCsv'
import UserAccessDefinition from './userAccessDefinition'
const { TabPane } = Tabs;
class Utilities extends React.Component {

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    return (
      <Layout>
        <Tabs defaultActiveKey="1" size='large' style={{ marginBottom: 32 }}>
          {
            this.access('utilities', 'importDataFromPromis') &&
            <TabPane tab="Import Data from PROMIS" key="1">
              <ImportDataFromPromis />
            </TabPane>
          }
          {/* {
            this.access('utilities', 'exportDataToCsv') &&
            <TabPane tab="Export Data to CSV/Excel" key="2">
              <ExportDataFromCsv />
            </TabPane>
          } */}
          {
            this.access('utilities', 'userAccessDefinition') &&
            <TabPane tab="User's Access Definition" key="3">
              <UserAccessDefinition />
            </TabPane>
          }
          {
            this.access('utilities', 'systemParameterSettings') &&
            <TabPane tab="System Parameter Settings" key="4">
              <SystemParameterSettings />
            </TabPane>
          }


        </Tabs>
      </Layout >
    )
  }
}


function mapStateToProps(state) {
  return {
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Utilities)


