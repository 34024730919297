import {
  POST_TERMS_AND_CONDITIONS,
  POST_TERMS_AND_CONDITIONS_FULFILLED,
  POST_TERMS_AND_CONDITIONS_REJECTED,
  GET_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_FULFILLED,
  GET_TERMS_AND_CONDITIONS_REJECTED,
} from '../actions'

export default function termsAndConditions(state = {
  termsAndConditionLoading: false,
  termsAndConditionSuccess: false,
  termsAndConditionRejected: false,
  termsAndConditionsLoading: false,
  termsAndConditionsSuccess: false,
  termsAndConditionsRejected: false,
  termsAndConditionsData: {},
}, action) {
  switch (action.type) {
    case POST_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditionLoading: true,
        termsAndConditionSuccess: false,
        termsAndConditionRejected: false
      }
    case POST_TERMS_AND_CONDITIONS_FULFILLED:
      return {
        ...state,
        termsAndConditionLoading: false,
        termsAndConditionSuccess: true,
        termsAndConditionData: action.payload
      }
    case POST_TERMS_AND_CONDITIONS_REJECTED:
      return {
        ...state,
        termsAndConditionLoading: false,
        termsAndConditionRejected: true
      }
    case GET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditionsLoading: true,
        termsAndConditionsSuccess: false,
        termsAndConditionsRejected: false
      }
    case GET_TERMS_AND_CONDITIONS_FULFILLED:
      return {
        ...state,
        termsAndConditionsLoading: false,
        termsAndConditionsSuccess: true,
        termsAndConditionsData: action.payload
      }
    case GET_TERMS_AND_CONDITIONS_REJECTED:
      return {
        ...state,
        termsAndConditionsLoading: false,
        termsAndConditionsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
