import {
  POST_PERMIT,
  POST_PERMIT_FULFILLED,
  POST_PERMIT_REJECTED,
  GET_PERMITS,
  GET_PERMITS_FULFILLED,
  GET_PERMITS_REJECTED,
  PUT_PERMIT,
  PUT_PERMIT_FULFILLED,
  PUT_PERMIT_REJECTED,
  DELETE_PERMIT,
  DELETE_PERMIT_FULFILLED,
  DELETE_PERMIT_REJECTED
} from '../actions'

export default function permits(state = {
  permitLoading: false,
  permitSuccess: false,
  permitRejected: false,
  permitData: [],
  permitsLoading: false,
  permitsSuccess: false,
  permitsRejected: false,
  permitsData: [],
  updatePermitLoading: false,
  updatePermitSuccess: false,
  updatePermitRejected: false,
  updatePermitData: [],
  deletePermitLoading: false,
  deletePermitSuccess: false,
  deletePermitRejected: false,
  deletePermitData: []
}, action) {
  switch (action.type) {
    case POST_PERMIT:
      return {
        ...state,
        permitLoading: true,
        permitSuccess: false,
        permitRejected: false
      }
    case POST_PERMIT_FULFILLED:
      return {
        ...state,
        permitLoading: false,
        permitSuccess: true,
        permitData: action.payload
      }
    case POST_PERMIT_REJECTED:
      return {
        ...state,
        permitLoading: false,
        permitRejected: true
      }
    case GET_PERMITS:
      return {
        ...state,
        permitsLoading: true,
        permitsSuccess: false,
        permitsRejected: false
      }
    case GET_PERMITS_FULFILLED:
      return {
        ...state,
        permitsLoading: false,
        permitsSuccess: true,
        permitsData: action.payload
      }
    case GET_PERMITS_REJECTED:
      return {
        ...state,
        permitsLoading: false,
        permitsRejected: true
      }
    case PUT_PERMIT:
      return {
        ...state,
        updatePermitLoading: true,
        updatePermitSuccess: false,
        updatePermitRejected: false
      }
    case PUT_PERMIT_FULFILLED:
      return {
        ...state,
        updatePermitLoading: false,
        updatePermitSuccess: true,
        updatePermitData: action.payload
      }
    case PUT_PERMIT_REJECTED:
      return {
        ...state,
        updatePermitLoading: false,
        updatePermitRejected: true
      }
    case DELETE_PERMIT:
      return {
        ...state,
        deletePermitLoading: true,
        deletePermitSuccess: false,
        deletePermitRejected: false
      }
    case DELETE_PERMIT_FULFILLED:
      return {
        ...state,
        deletePermitLoading: false,
        deletePermitSuccess: true,
        deletePermitData: action.payload
      }
    case DELETE_PERMIT_REJECTED:
      return {
        ...state,
        deletePermitLoading: false,
        deletePermitRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
