import React, { useState } from 'react'
import { Card } from 'antd'
import { Link } from 'react-router-dom'
const Services = (props) => {
    const [limit, setLimit] = useState(6)

    function handleSeeList() {
        setLimit(8)
    }

    const filterPath = ['orders', 'gatepass', 'reservations', 'reports', 'permits', 'visitors', 'inquiries', 'commendations']
    let newPath = []
    filterPath.map(path => {
        props.paths && newPath.push(props.paths.find(data => data.title === path))
    })

    return (
        <Card className='services-card'>
            <div className='pmo-title'>
                <h1>PMO Services <span onClick={() => handleSeeList()}>See list</span></h1>
            </div>
            <div className='service-icon-container'>
                {
                    newPath.slice(0, limit).map((data, index) => {
                        return (
                            data.sidebarMenu && <Link to={data.route} key={index} >
                                <div className='service-icon-content'>
                                    <div className='service-icon-wrapper'>
                                        {data.icon}
                                    </div>
                                    <span>{data.slug}</span>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </Card>
    )
}

export default Services