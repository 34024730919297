
import React from 'react'
import { Row, Col, Layout, Card, Pagination, Modal, Button } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../utilities/token'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './index.scss'
import moment from 'moment'
import { loadPosts, submitComments, deleteComment, submitPostClickAnalytics } from '../actions'
import draftToHtml from 'draftjs-to-html';
import { get } from '../../../../utilities/filesController'

let htmlContent = ''
class Announcements extends React.Component {
    constructor() {
        super()
        this.state = {
            isViewMode: false,
            selectedItem: {},
            activeId: 0,
            //pagination
            page: 1,
            limit: 10,
            searchTerm: '',
        }
    }

    componentDidMount() {
        const { limit, page, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadPosts(pagination)
    }

    onChangePagination(page) {
        const { limit, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadPosts(pagination)
        this.setState({ page: page })
    }


    handleSubmitComment(data) {
        this.props.onSubmitComments(data)
    }

    handleDelete(commentId) {
        const self = this
        Modal.confirm({
            title: 'Do you Want to delete this comment?',
            icon: <ExclamationCircleOutlined />,
            content: 'You cannot undo this',
            onOk() {
                self.props.onDeleteComment(commentId)
            },
            onCancel() { },
        });
    }

    handleAnalyticClicks(postId, redirectTo) {
        const submitData = {
            "postId": postId,
            "tenantId": getUser().id,
            "status": 1
        }
        this.props.onSubmitPostClickAnalytics(submitData)

        // redirectTo && window.open(redirectTo);
    }

    handleViewMail(selectedItem) {
        // Scroll to top when viewing email
        window.scrollTo(0, 0)
        this.setState({ selectedItem, isViewMode: true, activeId: selectedItem.id })
        this.handleAnalyticClicks(selectedItem.id)
    }

    componentDidUpdate(prevProps, prevState) {
        let self = this
        if (this.state.selectedItem.id !== prevState.selectedItem.id) {
            if (this.state.selectedItem.content) {
                const content = JSON.parse(this.state.selectedItem.content)
                const entityObject = content.entityMap
                Object.keys(entityObject).map(function (key, index) {
                    const source = entityObject[key].data.src
                    if (source) {
                        const urlSplitted = source.split('/')
                        const folderName = urlSplitted[urlSplitted.length - 2]
                        const fileName = urlSplitted[urlSplitted.length - 1].split('?')[0]
                        const fileFolderName = `${folderName}/${fileName}`
                        get(fileFolderName).then((res) => {
                            entityObject[key].data.src = res
                            content.entityMap = entityObject
                            self.setState({ selectedItem: { ...self.state.selectedItem, content: content && JSON.stringify(content) } })
                        })
                    }
                });
            }
        }
    }

    render() {
        console.log("thissssssss", this.state)
        const { selectedItem, activeId } = this.state
        return (
            <Layout className='announcements-container'>
                {
                    this.state.isViewMode ?
                        <Row gutter={32}>
                            <Col md={18} lg={18} xs={24} sm={24}>
                                <Card style={{ padding: 24, borderRadius: 20 }} >
                                    <div className='card-description'>
                                        <h1>{selectedItem.title}</h1>
                                        <p>{selectedItem.description}</p>
                                        <span className='date'>{moment(selectedItem.created_at).format('LLL')}</span>
                                    </div>

                                    <div className='html-content' >
                                        <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(selectedItem.content)) }} />
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button className='primary-btn' onClick={() => this.setState({ selectedItem: {}, isViewMode: false })}>BACK TO MAIL</Button>
                                    </div>

                                </Card>
                            </Col>

                            <Col md={6} lg={6} xs={24} sm={24}>
                                <Card style={{ borderRadius: 20 }}>
                                    <div className='card-view-header'>
                                        <h1 >Mail</h1>
                                    </div>
                                    {
                                        this.props.postsData.data.map((item, index) => {
                                            index = index + this.props.postsData.from
                                            return (
                                                <div className={`${activeId === item.id && 'card-container-in-view-active'} card-container-in-view`} onClick={() => this.handleViewMail(item)}>
                                                    <div className='card-wrapper'>
                                                        <div className='index'>
                                                            <h1>{index}.</h1>
                                                        </div>
                                                        <div className='content'>
                                                            <h1>{item.title && item.title.substring(0, 120)}{item.title && item.title.length >= 120 && '...'}</h1>
                                                            <p>{item.type || 'ANNOUNCEMENTS'} &nbsp;|&nbsp; {`${item.posted_by && item.posted_by.firstName} ${item.posted_by && item.posted_by.lastName}`} &nbsp;|&nbsp; {moment(item.created_at).format('LLLL')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div style={{ margin: 10 }} >
                                        <Button className='primary-btn-block' onClick={() => { this.setState({ selectedItem: {}, isViewMode: false }) }}>BACK TO MAIL</Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        :
                        <Row gutter={12}>
                            <div className='header'>
                                <h1>Mail: Announcements and Events</h1>
                            </div>
                            <Col md={24} lg={24} xs={24} sm={24}>
                                <Layout.Content >
                                    {
                                        this.props.postsData.data.map((item, index) => {
                                            index = index + this.props.postsData.from
                                            return (
                                                <Card className='card-container' onClick={() => this.handleViewMail(item)}>
                                                    <div className='card-wrapper'>
                                                        <div className='index'>
                                                            <h1>{index}.</h1>
                                                        </div>
                                                        <div className='content'>
                                                            <h1>{item.title && item.title.substring(0, 120)}{item.title && item.title.length >= 120 && '...'}</h1>
                                                            <p>{item.type || 'ANNOUNCEMENTS'} &nbsp;|&nbsp; {`${item.posted_by && item.posted_by.firstName} ${item.posted_by && item.posted_by.lastName}`} &nbsp;|&nbsp; {moment(item.created_at).format('LLLL')}</p>
                                                        </div>
                                                    </div>
                                                </Card>
                                            )
                                        })
                                    }
                                    <Row style={{ marginTop: 30, width: '100%' }}>
                                        <Col md={24} lg={24} sm={24} xs={24} align="right">
                                            <Pagination defaultCurrent={this.state.page} onChange={e => this.onChangePagination(e)} total={this.props.postsData.total} />
                                        </Col>
                                    </Row>
                                </Layout.Content>
                            </Col>
                        </Row>
                }
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        postsData: state.client.post.postsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPosts: (pagination) => dispatch(loadPosts(pagination)),
        onSubmitComments: (data) => dispatch(submitComments(data)),
        onDeleteComment: (commentId) => dispatch(deleteComment(commentId)),
        onSubmitPostClickAnalytics: (data) => dispatch(submitPostClickAnalytics(data))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Announcements)

