import React, { Fragment } from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, Modal, Card, Select, Tabs, Divider } from 'antd'
import { connect } from 'react-redux'
import { loadServices, updateServices } from '../actions'
const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;
class SystemParameterSettings extends React.Component {
  constructor() {
    super()
    this.state = {
      servicesData: [
        {
          type: 'jobOrderRequest',
          amount: 0,
          status: 0
        },
        {
          type: 'workPermit',
          amount: 0,
          status: 0
        },
        {
          type: 'gatePass',
          amount: 0,
          status: 0
        },
        {
          type: 'incident',
          amount: 0,
          status: 0
        },
        {
          type: 'visitorPass',
          amount: 0,
          status: 0
        },
        {
          type: 'reservation',
          amount: 0,
          status: 0
        },
        {
          type: 'leasingAssistance',
          amount: 0,
          status: 0
        },
        {
          type: 'commendation',
          amount: 0,
          status: 0
        }
      ]
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.servicesData !== prevProps.servicesData) {
      if (this.props.servicesData.length !== 0) {
        this.setState({ servicesData: Object.assign(this.state.servicesData, this.props.servicesData) })
      }
    }

    if ((this.props.updateServicesLoading !== prevProps.updateServicesLoading) && this.props.updateServicesLoading) {
      Modal.success({
        title: 'Success',
        content: 'You have successfully updated the services',
      });
    }

  }
  componentDidMount() {
    this.props.onLoadServices()
  }

  getAmountFromArrayServices(field) {
    const isFound = this.state.servicesData.find(data => data.type === field)
    return isFound ? isFound.amount : 0
  }

  getStatusFromArrayServices(field) {
    const isFound = this.state.servicesData.find(data => data.type === field)
    return isFound ? isFound.status === 1 ? 'active' : 'inactive' : 'active'
  }

  onChangeValueAmountInServices(field, newData) {
    const isFound = this.state.servicesData.findIndex(data => data.type === field)
    this.state.servicesData[isFound].amount = newData
    this.setState({ servicesData: this.state.servicesData })
  }

  onChangeValueStatusInServices(field, newData) {
    const isFound = this.state.servicesData.findIndex(data => data.type === field)
    this.state.servicesData[isFound].status = (newData === 'active' ? 1 : 0)
    this.setState({ servicesData: this.state.servicesData })
  }

  handleUpdateServices() {
    const submitData = {
      services: this.state.servicesData
    }
    this.props.onUpdateServices({ ...submitData })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    return (
      <Card loading={this.props.servicesLoading}>
        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Job Order Request</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('jobOrderRequest')} onChange={(e) => this.onChangeValueAmountInServices('jobOrderRequest', e.target.value)} style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('jobOrderRequest')} onChange={(e) => this.onChangeValueStatusInServices('jobOrderRequest', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Work Permit</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('workPermit')} onChange={(e) => this.onChangeValueAmountInServices('workPermit', e.target.value)} type="number" style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('workPermit')} onChange={(e) => this.onChangeValueStatusInServices('workPermit', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>GatePass</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('gatePass')} onChange={(e) => this.onChangeValueAmountInServices('gatePass', e.target.value)} type="number" style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('gatePass')} onChange={(e) => this.onChangeValueStatusInServices('gatePass', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Incident</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('incident')} onChange={(e) => this.onChangeValueAmountInServices('incident', e.target.value)} type="number" style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('incident')} onChange={(e) => this.onChangeValueStatusInServices('incident', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Visitors Pass</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('visitorPass')} onChange={(e) => this.onChangeValueAmountInServices('visitorPass', e.target.value)} type="number" style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('visitorPass')} onChange={(e) => this.onChangeValueStatusInServices('visitorPass', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Reservation</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('reservation')} onChange={(e) => this.onChangeValueAmountInServices('reservation', e.target.value)} type="number" style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('reservation')} onChange={(e) => this.onChangeValueStatusInServices('reservation', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Leasing Assistance</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('leasingAssistance')} onChange={(e) => this.onChangeValueAmountInServices('leasingAssistance', e.target.value)} type="number" style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('leasingAssistance')} onChange={(e) => this.onChangeValueStatusInServices('leasingAssistance', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row gutter={32}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Commendation And Testimonial</h1>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Minimum SOA Amount</h1>
            <Input value={this.getAmountFromArrayServices('commendation')} onChange={(e) => this.onChangeValueAmountInServices('commendation', e.target.value)} type="number" style={{ width: 200 }} />
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <h1>Service Status</h1>
            <Select value={this.getStatusFromArrayServices('commendation')} onChange={(e) => this.onChangeValueStatusInServices('commendation', e)} style={{ width: 120 }}>
              <Option value="active">Active</Option>
              <Option value="inactive">Inactive</Option>
            </Select>
          </Col>
        </Row>
        <Divider />

        <Row>
          <Col lg={24} md={24} sm={24} sm={24} align="right">
            {
              this.access('utilities', 'edit') &&
              <Button type="primary" loading={this.state.updateServicesLoading} onClick={() => this.handleUpdateServices()}>Update Services</Button>
            }
          </Col>
        </Row>
      </Card>
    )
  }
}


function mapStateToProps(state) {
  return {
    servicesLoading: state.admin.utilities.servicesLoading,
    updateServicesLoading: state.admin.utilities.updateServicesLoading,
    servicesData: state.admin.utilities.servicesData,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadServices: (data) => dispatch(loadServices(data)),
    onUpdateServices: (data) => dispatch(updateServices(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(SystemParameterSettings))
