import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Client from './client'
import Admin from './admin'
import SuperAdmin from './superAdmin'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
import TimeAgo from 'javascript-time-ago'
import Echo from "laravel-echo";
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'
const REACT_APP_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
class App extends Component {
  constructor() {
    super()
    this.state = {
      componentType: undefined
    }
  }

  componentWillMount() {
    const { location: { pathname } } = this.props
    if (pathname.includes('/admin')) {
      this.setState({ componentType: 'admin' })
    } else if (pathname.includes('/super-admin')) {
      this.setState({ componentType: 'super-admin' })
    } else {
      this.setState({ componentType: 'client' })
    }

    console.log("`${REACT_APP_WEBSOCKET_URL}`", `${REACT_APP_WEBSOCKET_URL}`)
    window.Pusher = require('pusher-js')
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: 'residential_app@123',
      wsHost: `${REACT_APP_WEBSOCKET_URL}`,
      wsPort: 6001,
      wssPort: 6001,
      disableStats: true,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'],
    })

  }

  render() {
    const { componentType } = this.state
    switch (componentType) {
      case 'client':
        return <Client />
      case 'admin':
        return <Admin />
      case 'super-admin':
        return <SuperAdmin />
    }
  }
}

export default withRouter(App)
