import React from 'react'
import { Layout, Row, Col, Dropdown, Menu, Modal } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { MenuOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
import './Header.scss'

const MobileHeader = (props) => {

  function logout() {
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will redirected to login page',
      onOk() {
        localStorage.removeItem('CLIENT_SESSION_TOKEN')
        props.history.push('/')
        window.location.reload()
      },
      onCancel() {
      },
    });
  }

  function getValueInWebsiteSettings(name) {
    const found = props.websiteSettingsData.find(data => data.name === name)
    if (found) return found.value
    return
  }

  const menu = (
    <Menu onClick={(e) => props.onChangeMenu(e)}>
      {
        props.paths.map((data, i) => {
          if (!data.sidebarMenu) return
          return (
            <Menu.Item key={(i + 1)} icon={data.icon}>
              <Link to={data.route} style={{ marginLeft: 10, marginRight: 10, color: 'gray' }} >
                {data.slug.toUpperCase()}
              </Link>
            </Menu.Item>
          )
        })
      }
      <Menu.Item icon={<FontAwesomeIcon icon={faSignOutAlt} />}>
        <a style={{ marginLeft: 10, marginRight: 10, color: 'gray' }} onClick={() => logout()}> LOGOUT   </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout className='mobile-header'>
      <Row>
        <Col xs={8} sm={8} align='left'>
          <div className='mobile-logo'>
            <ImagePresignedUrl src={`${getValueInWebsiteSettings('websiteLogo')}`} />
          </div>
        </Col>
        <Col xs={8} sm={8}>
          <div className='mobile-title' >
            <h1>{getValueInWebsiteSettings('websiteName')}</h1>
          </div>
        </Col>

        <Col xs={8} sm={8} align='right'>
          <div className='icon-actions'>
            <Dropdown overlay={menu} trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <MenuOutlined style={{ fontSize: 24, color: 'white' }} />
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

function mapStateToProps(state) {
  return {
    websiteSettingsData: state.client.websiteSettings.websiteSettingsData,
    websiteSettingsLoading: state.client.websiteSettings.websiteSettingsLoading,
  }
}

export default withRouter(connect(mapStateToProps, [])(MobileHeader))
