import React from 'react'
import { connect } from 'react-redux'
import './index.scss'
import draftToHtml from 'draftjs-to-html';

const TermsAndConditions = (props) => {
    return (
        <div className='terms-and-conditions'>
            <div className='html-content' >
                {
                    (props.termsAndConditionsData && props.termsAndConditionsData.value) &&
                    <div dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(props.termsAndConditionsData.value)) }} />
                }
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        termsAndConditionsData: state.client.login.termsAndConditionsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default (connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions))
