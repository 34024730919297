
import React from 'react'
import { Row, Col, Layout, Table, Form, Collapse, Pagination, Modal, Tag, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUser } from '../../../../utilities/token'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { submitService, loadService, deleteService } from '../actions'
import './index.scss'
import moment from 'moment'
import ServiceNavLink from '../../serviceNavLink'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
class Service extends React.Component {
    constructor() {
        super()
        this.state = {
            isAddModalOpen: false,
            isEditModalOpen: false,

            //pagination
            page: 1,
            limit: 10,
            searchTerm: '',

            tableData: []
        }
        this.onReset = this.onReset.bind(this)
        this.addModalToggle = this.addModalToggle.bind(this)
        this.editModalToggle = this.editModalToggle.bind(this)
        this.handleDeleteService = this.handleDeleteService.bind(this)
    }
    formRef = React.createRef();

    handleSubmit(data) {
        const submitData = {
            "tenantId": getUser().id,
            "title": data.title,
            "description": data.description,
            "comment": "TEST",
        }
        this.props.onSubmitService(submitData)
        this.onReset()

    }

    handleDeleteService(id) {
        const self = this
        Modal.confirm({
            title: 'Do you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'You cannot undo this.',
            onOk() {
                self.props.onDeleteService(id)
            },
            onCancel() { },
        });
    }

    onReset = () => {
        this.formRef.current.resetFields();
    };

    componentDidMount() {
        const { limit, searchTerm, page } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadService(pagination)
    }

    onChangePagination(page, limit) {
        const { searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadService(pagination)
        this.setState({ page, limit })
    }

    componentWillReceiveProps(nextProps) {
        const { limit, page, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        if ((this.props.addServiceSuccess !== nextProps.addServiceSuccess) && nextProps.addServiceSuccess) {
            this.setState({ isAddModalOpen: false })
            message.success("Service added successfully.")
            this.props.onLoadService(pagination)
        }
        if ((this.props.updateServiceSuccess !== nextProps.updateServiceSuccess) && nextProps.updateServiceSuccess) {
            this.setState({ isEditModalOpen: false })
            message.success("Service edited successfully.")
            this.props.onLoadService(pagination)
        }
        if ((this.props.deleteServiceSuccess !== nextProps.deleteServiceSuccess) && nextProps.deleteServiceSuccess) {
            this.setState({ isEditModalOpen: false })
            message.success("Service deleted successfully.")
            this.props.onLoadService(pagination)
        }
        if ((this.props.serviceSuccess !== nextProps.serviceSuccess) && nextProps.serviceSuccess) {
            this.setState({ tableData: nextProps.serviceData.data })
        }
    }

    addModalToggle() {
        this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
    }

    editModalToggle() {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
    }

    getType(status) {
        switch (status) {
            case 1:
                return <Tag color="gold">DELIVERY</Tag>
            case 2:
                return <Tag color="lime">MOVEIN</Tag>
            case 3:
                return <Tag color="geekblue">MOVEOUT</Tag>
            default:
                break;
        }
    }


    getStatus(status) {
        switch (status) {
            case 0:
                return <Tag color="red">DELETED</Tag>
            case 1:
                return <Tag color="gold">FOR APPROVAL</Tag>
            case 2:
                return <Tag color="lime">APPROVED</Tag>
            case 3:
                return <Tag color="geekblue">ONGOING</Tag>
            case 4:
                return <Tag color="green">COMPLETED</Tag>
            case 5:
                return <Tag color="magenta">CANCELLED</Tag>
            case 6:
                return <Tag color="red">REJECTED</Tag>
            default:
                break;
        }
    }

    render() {
        const dataSource = this.state.tableData

        const columns = [
            {
                title: 'Service Ticket #',
                dataIndex: 'ticketNumber',
                key: 'ticketNumber',
                sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
            },
            {
                title: 'Client Code',
                dataIndex: 'tenant',
                key: 'clientCode',
                sorter: (a, b) => a.tenant.clientCode && a.tenant.clientCode.localeCompare(b.tenant && b.tenant.clientCode),
                render: (info, data) => {
                    return (
                        <span>{data.tenant && `${data.tenant.clientCode}`}</span>
                    )
                }
            },
            {
                title: 'Client Name',
                dataIndex: 'tenant',
                key: 'tenant',
                sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
                render: (info, data) => {
                    return (
                        <span>{data.tenant && `${data.tenant.uoccname}`}</span>
                    )
                }
            },
            {
                title: 'Created Date',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
                render: (item, data) => {
                    return (
                        <span>{item && `${moment(item).format('LLL')}`}</span>
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (item) => {
                    return (<span>{this.getStatus(item)}</span>)
                }
            },
            // {
            //     title: 'Actions',
            //     dataIndex: 'actions',
            //     key: '',
            //     render: (info, data) => {
            //         return (
            //             <div className='action-btn'>
            //                 <Button type="primary" icon={<EditOutlined />} size={'medium'} title='Edit' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} disabled >View</Button>
            //                 {/* <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteService(data.id)}  >Delete</Button> */}
            //             </div>
            //         )
            //     }
            // },
        ];
        return (
            <Layout>
                <Row>
                    <Col md={12} lg={12} xs={24} sm={24}>
                        <h1 style={{ fontSize: 24, marginLeft: 10 }}>SERVICES</h1>
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col md={18} lg={18} xs={24} sm={24}>
                        <Table columns={columns} dataSource={dataSource} rowKey="id" pagination={false} loading={this.props.serviceLoading} />
                        <Row style={{ marginTop: 30, width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} align="right" >
                                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.serviceData.total} />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} lg={6} xs={24} sm={24}>
                        {this.props.advertisementData && this.props.advertisementData.images &&
                            <div className='banner-image'>
                                <ImagePresignedUrl src={this.props.advertisementData.images[0].url} />
                            </div>
                        }
                        <ServiceNavLink {...this.props} />
                    </Col>
                </Row>
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        serviceSuccess: state.client.ticketList.serviceSuccess,
        addServiceSuccess: state.client.ticketList.addServiceSuccess,
        updateServiceSuccess: state.client.ticketList.updateServiceSuccess,
        serviceData: state.client.ticketList.serviceData,
        serviceLoading: state.client.ticketList.serviceLoading,
        deleteServiceSuccess: state.client.ticketList.deleteServiceSuccess,
        advertisementData: state.client.advertisements.advertisementData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitService: (data) => dispatch(submitService(data)),
        onLoadService: (data) => dispatch(loadService(data)),
        onDeleteService: (id) => dispatch(deleteService(id)),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Service)
)