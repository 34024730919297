import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Divider } from 'antd'
import { loadMemorandums } from '../../memorandums/actions'
import { Link } from 'react-router-dom'
import moment from 'moment'
const Memorandums = (props) => {
    useEffect(() => {
        props.onLoadMemorandums()
    }, [])
    return (
        <Card className='memorandum-card'>
            <div className='memorandum-title'>
                <h1>Memorandums</h1>
                {
                    props.memorandumsData.splice(0, 3).map((item, index) => {
                        return (
                            <div className='row-item' key={index}>
                                <h1 className='description'>{item.description && item.description.substring(0, 160)} {item.description && +item.description.length >= 160 && '...'}</h1>
                                <h1 className='title'>{item.title && item.title.substring(0, 100)} {item.title && +item.title.length >= 100 && '...'} | {moment(item.created_at).format('LLLL')}</h1>
                                <Divider />
                            </div>
                        )
                    })
                }
                <div>
                    <Link to='/memorandums'>
                        <Button className='primary-btn-block'>SEE ALL MEMORANDUMS</Button>
                    </Link>
                </div>
            </div>
        </Card>
    )
}

function mapStateToProps(state) {
    return {
        memorandumsData: state.client.memorandums.memorandumsData,
        memorandumsLoading: state.client.memorandums.memorandumsLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMemorandums: () => dispatch(loadMemorandums()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Memorandums)
