import {
  POST_RESERVATION,
  POST_RESERVATION_FULFILLED,
  POST_RESERVATION_REJECTED,
  GET_RESERVATIONS,
  GET_RESERVATIONS_FULFILLED,
  GET_RESERVATIONS_REJECTED,
  PUT_RESERVATION,
  PUT_RESERVATION_FULFILLED,
  PUT_RESERVATION_REJECTED,
  DELETE_RESERVATION,
  DELETE_RESERVATION_FULFILLED,
  DELETE_RESERVATION_REJECTED,
  APPROVE_RESERVATION,
  APPROVE_RESERVATION_FULFILLED,
  APPROVE_RESERVATION_REJECTED

} from '../actions'

export default function reservations(state = {
  reservationLoading: false,
  reservationSuccess: false,
  reservationRejected: false,
  reservationData: [],
  reservationsLoading: false,
  reservationsSuccess: false,
  reservationsRejected: false,
  reservationsData: [],
  updateReservationLoading: false,
  updateReservationSuccess: false,
  updateReservationRejected: false,
  updateReservationData: [],
  deleteReservationLoading: false,
  deleteReservationSuccess: false,
  deleteReservationRejected: false,
  deleteReservationData: [],
  approveReservationLoading: false,
  approveReservationSuccess: false,
  approveReservationRejected: false
}, action) {
  switch (action.type) {
    case POST_RESERVATION:
      return {
        ...state,
        reservationLoading: true,
        reservationSuccess: false,
        reservationRejected: false
      }
    case POST_RESERVATION_FULFILLED:
      return {
        ...state,
        reservationLoading: false,
        reservationSuccess: true,
        reservationData: action.payload
      }
    case POST_RESERVATION_REJECTED:
      return {
        ...state,
        reservationLoading: false,
        reservationRejected: true
      }
    case GET_RESERVATIONS:
      return {
        ...state,
        reservationsLoading: true,
        reservationsSuccess: false,
        reservationsRejected: false
      }
    case GET_RESERVATIONS_FULFILLED:
      return {
        ...state,
        reservationsLoading: false,
        reservationsSuccess: true,
        reservationsData: action.payload
      }
    case GET_RESERVATIONS_REJECTED:
      return {
        ...state,
        reservationsLoading: false,
        reservationsRejected: true
      }
    case PUT_RESERVATION:
      return {
        ...state,
        updateReservationLoading: true,
        updateReservationSuccess: false,
        updateReservationRejected: false
      }
    case PUT_RESERVATION_FULFILLED:
      return {
        ...state,
        updateReservationLoading: false,
        updateReservationSuccess: true,
        updateReservationData: action.payload
      }
    case PUT_RESERVATION_REJECTED:
      return {
        ...state,
        updateReservationLoading: false,
        updateReservationRejected: true
      }
    case DELETE_RESERVATION:
      return {
        ...state,
        deleteReservationLoading: true,
        deleteReservationSuccess: false,
        deleteReservationRejected: false
      }
    case DELETE_RESERVATION_FULFILLED:
      return {
        ...state,
        deleteReservationLoading: false,
        deleteReservationSuccess: true,
        deleteReservationData: action.payload
      }
    case DELETE_RESERVATION_REJECTED:
      return {
        ...state,
        deleteReservationLoading: false,
        deleteReservationRejected: true
      }
    case APPROVE_RESERVATION:
      return {
        ...state,
        approveReservationLoading: true,
        approveReservationSuccess: false,
        approveReservationRejected: false
      }
    case APPROVE_RESERVATION_FULFILLED:
      return {
        ...state,
        approveReservationLoading: false,
        approveReservationSuccess: true,
        approveReservationData: action.payload
      }
    case APPROVE_RESERVATION_REJECTED:
      return {
        ...state,
        approveReservationLoading: false,
        approveReservationRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
