
import React from 'react'
import { Row, Col, Layout, Form, Button, Input, notification, Modal, Card } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { changePassword, PUT_UPDATE_PASSWORD_FULFILLED } from '../actions'
import './index.scss'

class ChangePassword extends React.Component {

    handleOnchangePassword(values) {
        if (values.newPassword !== values.reNewPassword) {
            return notification.warning({
                message: 'Password Unmatched',
                description:
                    'The new password and re-type password does not matched.',
            });
        }

        this.props.onChangePassword({ ...values }).then(res => {
            if (res.type === PUT_UPDATE_PASSWORD_FULFILLED) {
                Modal.success({
                    title: 'Password Changed',
                    content: 'Your Password has been changed succesfully.',
                    onOk: () => {
                        window.location.href = '/'
                    }
                });
            } else {
                return notification.error({
                    message: 'Change Password Failed',
                    description:
                        'Unable to change your password.',
                });
            }
        })

    }

    render() {
        return (
            <Card>
                <Layout.Content>
                    <Row gutter={32}>
                        <Col md={6} lg={6} sm={24} xs={24} />
                        <Col md={18} lg={18} sm={24} xs={24} >
                            <Form
                                name="basic"
                                ref={this.formRef}
                                onFinish={(value) => this.handleOnchangePassword(value)}
                            // onFinishFailed={onFinishFailed}
                            >
                                <Row gutter={32}>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Old Password"
                                            name="oldPassword"
                                            rules={[{ required: true, message: 'Please input your old password!' }]}
                                        >
                                            <Input.Password className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="New Password"
                                            name="newPassword"
                                            rules={[{ required: true, message: 'Please input your new password!' }]}
                                        >
                                            <Input.Password className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8} md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label="Re-Type New Password"
                                            name="reNewPassword"
                                            rules={[{ required: true, message: 'Please input your re-type new password!' }]}
                                        >
                                            <Input.Password className='primary-input' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%' }} >
                                    <Col md={24} lg={24} sm={24} xs={24} align="right">
                                        <Form.Item>
                                            <Button type="primary" loading={this.props.changePasswordLoading} className='primary-btn' htmlType="submit">
                                                Change Password
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Layout.Content>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        changePasswordLoading: state.client.settings.changePasswordLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onChangePassword: (data) => dispatch(changePassword(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))
