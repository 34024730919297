import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Modal } from 'antd'
import { formatMoney } from '../../../../utilities/helper'
import { loadTenant } from '../../billings/actions'
import { getUser } from '../../../../utilities/token'
import { Fragment } from 'react'
import EditModal from '../../billings/components/modals/edit'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const Billings = (props) => {
    let history = useHistory();

    const [isEditModalOpen, setIsEditModalOpen] = useState(false)

    useEffect(() => {
        const tenantId = getUser() && getUser().id
        props.onLoadTenant(tenantId)
    }, [])

    function payNow() {
        Modal.confirm({
            title: 'PAYMENT GATEWAY',
            content: 'You will redirect to our payment gateway page once you click OK.',
            onOk: () => history.push('/billings/payments')
        });
    }

    return (
        <Fragment>
            <Card className='billing-card'>
                <h1 className='billing-title'>Billing</h1>
                <div className='pb4 pt4'>
                    <div className='row-space-between'>
                        <h1 className='label'>Amount to pay <br /><span>HOA Dues</span></h1>
                        <h1 className='value'>₱ {formatMoney(props.tenantData.soaAmount)}</h1>
                    </div>
                    <div className='row-space-between'>
                        <h1 className='label'>Due Date</h1>
                        <h1 className='value'>{(props.tenantData && props.tenantData.soaDate) && moment(props.tenantData.soaDate).format('LL')}</h1>
                    </div>
                </div>
                <div className='row-center'>
                    <Button className='primary-btn' onClick={payNow}>PAY NOW</Button>
                    <Button className='primary-btn-outline' onClick={() => setIsEditModalOpen(true)}>VIEW MY BILL</Button>
                </div>
            </Card>
            {
                isEditModalOpen &&
                <EditModal
                    visible={isEditModalOpen}
                    selectedRow={props.tenantData}
                    onCancel={() => setIsEditModalOpen(!isEditModalOpen)}
                />
            }
        </Fragment>

    )
}

function mapStateToProps(state) {
    return {
        tenantData: state.client.billings.tenantData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenant: (data) => dispatch(loadTenant(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Billings)
