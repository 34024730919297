import React from 'react'
import Dropzone from 'react-dropzone'
import './index.scss'
import { v4 as uuidv4 } from 'uuid';

class UploadComponent extends React.Component {
    constructor(props) {
        super()
        this.state = {
            loading: false,
            base64Image: '',
            value: props.value,
            imageArray: []
        };
        this.getBase64 = this.getBase64.bind(this)
        this.handleDeleteImage = this.handleDeleteImage.bind(this)
    }

    getBase64(file) {
        file.map(data => {
            const id = uuidv4()
            const name = `deposit_slip_${id}.${data.type.split('/')[1]}`
            data.id = id
            Object.defineProperty(data, 'name', {
                writable: true,
                value: name
            });
            data.url = name
        })
        this.setState({ imageArray: file })
        this.props.getFile && this.props.getFile(file)
    }

    fileToBase64(file) {
        let fileUrl = ''
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            fileUrl = reader.result
        };
        reader.onerror = function (error) { };
        return fileUrl
    }

    handleDeleteImage() {
        this.setState({ base64Image: '' })
        this.props.getImageBase64('')
    }

    render() {
        return (
            <>
                <Dropzone onDrop={acceptedFiles => this.getBase64(acceptedFiles)} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {this.state.base64Image === '' ?
                                    (this.state.imageArray.length ?
                                        <div className='dropzone-container'>
                                            {
                                                this.state.imageArray.map((data, index) => {
                                                    return (
                                                        <div className='dropzone-list' key={index}>
                                                            <img src={URL.createObjectURL(data)} style={{ objectFit: 'cover' }} />
                                                            <span>{data.name}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='dropzone-empty'>Select your 1 (one) image file or drag here.</div>) :
                                    <img src={this.state.base64Image} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
                {this.state.base64Image !== '' && <a onClick={() => this.handleDeleteImage()}>Delete</a>}
            </>
        );
    }
}

export default UploadComponent;
