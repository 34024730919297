import {
  GET_RENTAL_INQUIRIES,
  GET_RENTAL_INQUIRIES_FULFILLED,
  GET_RENTAL_INQUIRIES_REJECTED,
  PUT_RENTAL_INQUIRIES,
  PUT_RENTAL_INQUIRIES_FULFILLED,
  PUT_RENTAL_INQUIRIES_REJECTED,
  POST_RENTAL_INQUIRIES,
  POST_RENTAL_INQUIRIES_FULFILLED,
  POST_RENTAL_INQUIRIES_REJECTED,
  DELETE_RENTAL_INQUIRIES,
  DELETE_RENTAL_INQUIRIES_FULFILLED,
  DELETE_RENTAL_INQUIRIES_REJECTED
} from '../actions'

export default function permit(state = {
  permitLoading: false,
  permitSuccess: false,
  permitRejected: false,
  permitData: {
    data: []
  },
  updateRentalInquiriesLoading: false,
  updateRentalInquiriesSuccess: false,
  updateRentalInquiriesRejected: false,
  addRentalInquiriesLoading: false,
  addRentalInquiriesSuccess: false,
  addRentalInquiriesRejected: false,
  deleteRentalInquiriesLoading: false,
  deleteRentalInquiriesSuccess: false,
  deleteRentalInquiriesRejected: false
}, action) {
  switch (action.type) {
    case GET_RENTAL_INQUIRIES:
      return {
        ...state,
        permitLoading: true,
        permitSuccess: false,
        permitRejected: false
      }
    case GET_RENTAL_INQUIRIES_FULFILLED:
      return {
        ...state,
        permitLoading: false,
        permitSuccess: true,
        permitData: action.payload
      }
    case GET_RENTAL_INQUIRIES_REJECTED:
      return {
        ...state,
        permitLoading: false,
        permitRejected: true
      }
    case POST_RENTAL_INQUIRIES:
      return {
        ...state,
        addRentalInquiriesLoading: true,
        addRentalInquiriesSuccess: false,
        addRentalInquiriesRejected: false
      }
    case POST_RENTAL_INQUIRIES_FULFILLED:
      return {
        ...state,
        addRentalInquiriesLoading: false,
        addRentalInquiriesSuccess: true,
        addRentalInquiriesData: action.payload
      }
    case POST_RENTAL_INQUIRIES_REJECTED:
      return {
        ...state,
        addRentalInquiriesLoading: false,
        addRentalInquiriesRejected: true
      }
    case PUT_RENTAL_INQUIRIES:
      return {
        ...state,
        updateRentalInquiriesLoading: true,
        updateRentalInquiriesSuccess: false,
        updateRentalInquiriesRejected: false
      }
    case PUT_RENTAL_INQUIRIES_FULFILLED:
      return {
        ...state,
        updateRentalInquiriesLoading: false,
        updateRentalInquiriesSuccess: true,
        updateRentalInquiriesData: action.payload
      }
    case PUT_RENTAL_INQUIRIES_REJECTED:
      return {
        ...state,
        updateRentalInquiriesLoading: false,
        updateRentalInquiriesRejected: true
      }

    case DELETE_RENTAL_INQUIRIES:
      return {
        ...state,
        deleteRentalInquiriesLoading: true,
        deleteRentalInquiriesSuccess: false,
        deleteRentalInquiriesRejected: false
      }
    case DELETE_RENTAL_INQUIRIES_FULFILLED:
      return {
        ...state,
        deleteRentalInquiriesLoading: false,
        deleteRentalInquiriesSuccess: true,
        deleteRentalInquiriesData: action.payload
      }
    case DELETE_RENTAL_INQUIRIES_REJECTED:
      return {
        ...state,
        deleteRentalInquiriesLoading: false,
        deleteRentalInquiriesRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
