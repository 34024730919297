import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_SERVICE = 'POST_SERVICE'
export const POST_SERVICE_FULFILLED = 'POST_SERVICE_FULFILLED'
export const POST_SERVICE_REJECTED = 'POST_SERVICE_REJECTED'
export const GET_SERVICES = 'GET_SERVICES'
export const GET_SERVICES_FULFILLED = 'GET_SERVICES_FULFILLED'
export const GET_SERVICES_REJECTED = 'GET_SERVICES_REJECTED'
export const PUT_SERVICE = 'PUT_SERVICE'
export const PUT_SERVICE_FULFILLED = 'PUT_SERVICE_FULFILLED'
export const PUT_SERVICE_REJECTED = 'PUT_SERVICE_REJECTED'
export const DELETE_SERVICE = 'DELETE_SERVICE'
export const DELETE_SERVICE_FULFILLED = 'DELETE_SERVICE_FULFILLED'
export const DELETE_SERVICE_REJECTED = 'DELETE_SERVICE_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadService = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_SERVICES,
      payload: {}
    })
    return Axios.get(`${API_URL}/services?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_SERVICES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_SERVICES_REJECTED,
          payload: error
        })
      })
  }
}

export const submitService = (data) => {
  return dispatch => {
    dispatch({
      type: POST_SERVICE,
      payload: {}
    })
    return Axios.post(`${API_URL}/service`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_SERVICE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_SERVICE_REJECTED,
          payload: error
        })
      })
  }
}

export const updateService = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_SERVICE,
      payload: {}
    })
    return Axios.put(`${API_URL}/service/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_SERVICE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_SERVICE_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteService = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_SERVICE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/service/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_SERVICE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_SERVICE_REJECTED,
          payload: error
        })
      })
  }
}





