import {
  POST_ADVERTISEMENT,
  POST_ADVERTISEMENT_FULFILLED,
  POST_ADVERTISEMENT_REJECTED,
  GET_ADVERTISEMENTS,
  GET_ADVERTISEMENTS_FULFILLED,
  GET_ADVERTISEMENTS_REJECTED,
  PUT_ADVERTISEMENT,
  PUT_ADVERTISEMENT_FULFILLED,
  PUT_ADVERTISEMENT_REJECTED,
  DELETE_ADVERTISEMENT,
  DELETE_ADVERTISEMENT_FULFILLED,
  DELETE_ADVERTISEMENT_REJECTED
} from '../actions'

export default function advertisements(state = {
  advertisementLoading: false,
  advertisementSuccess: false,
  advertisementRejected: false,
  advertisementData: {},
  advertisementsLoading: false,
  advertisementsSuccess: false,
  advertisementsRejected: false,
  advertisementsData: {
    data: []
  },
  updateAdvertisementLoading: false,
  updateAdvertisementSuccess: false,
  updateAdvertisementRejected: false,
  updateAdvertisementData: [],
  deleteAdvertisementLoading: false,
  deleteAdvertisementSuccess: false,
  deleteAdvertisementRejected: false,
  deleteAdvertisementData: []
}, action) {
  switch (action.type) {
    case POST_ADVERTISEMENT:
      return {
        ...state,
        advertisementLoading: true,
        advertisementSuccess: false,
        advertisementRejected: false
      }
    case POST_ADVERTISEMENT_FULFILLED:
      return {
        ...state,
        advertisementLoading: false,
        advertisementSuccess: true,
        advertisementData: action.payload
      }
    case POST_ADVERTISEMENT_REJECTED:
      return {
        ...state,
        advertisementLoading: false,
        advertisementRejected: true
      }
    case GET_ADVERTISEMENTS:
      return {
        ...state,
        advertisementsLoading: true,
        advertisementsSuccess: false,
        advertisementsRejected: false
      }
    case GET_ADVERTISEMENTS_FULFILLED:
      return {
        ...state,
        advertisementsLoading: false,
        advertisementsSuccess: true,
        advertisementsData: action.payload
      }
    case GET_ADVERTISEMENTS_REJECTED:
      return {
        ...state,
        advertisementsLoading: false,
        advertisementsRejected: true
      }
    case PUT_ADVERTISEMENT:
      return {
        ...state,
        updateAdvertisementLoading: true,
        updateAdvertisementSuccess: false,
        updateAdvertisementRejected: false
      }
    case PUT_ADVERTISEMENT_FULFILLED:
      return {
        ...state,
        updateAdvertisementLoading: false,
        updateAdvertisementSuccess: true,
        updateAdvertisementData: action.payload
      }
    case PUT_ADVERTISEMENT_REJECTED:
      return {
        ...state,
        updateAdvertisementLoading: false,
        updateAdvertisementRejected: true
      }
    case DELETE_ADVERTISEMENT:
      return {
        ...state,
        deleteAdvertisementLoading: true,
        deleteAdvertisementSuccess: false,
        deleteAdvertisementRejected: false
      }
    case DELETE_ADVERTISEMENT_FULFILLED:
      return {
        ...state,
        deleteAdvertisementLoading: false,
        deleteAdvertisementSuccess: true,
        deleteAdvertisementData: action.payload
      }
    case DELETE_ADVERTISEMENT_REJECTED:
      return {
        ...state,
        deleteAdvertisementLoading: false,
        deleteAdvertisementRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
