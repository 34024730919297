import {
  POST_MEMORANDUM,
  POST_MEMORANDUM_FULFILLED,
  POST_MEMORANDUM_REJECTED,
  GET_MEMORANDUMS,
  GET_MEMORANDUMS_FULFILLED,
  GET_MEMORANDUMS_REJECTED,
  PUT_MEMORANDUM,
  PUT_MEMORANDUM_FULFILLED,
  PUT_MEMORANDUM_REJECTED,
  DELETE_MEMORANDUM,
  DELETE_MEMORANDUM_FULFILLED,
  DELETE_MEMORANDUM_REJECTED,
  APPROVE_MEMORANDUM,
  APPROVE_MEMORANDUM_FULFILLED,
  APPROVE_MEMORANDUM_REJECTED

} from '../actions'

export default function memorandums(state = {
  memorandumLoading: false,
  memorandumSuccess: false,
  memorandumRejected: false,
  memorandumData: [],
  memorandumsLoading: false,
  memorandumsSuccess: false,
  memorandumsRejected: false,
  memorandumsData: [],
  updateMemorandumLoading: false,
  updateMemorandumSuccess: false,
  updateMemorandumRejected: false,
  updateMemorandumData: [],
  deleteMemorandumLoading: false,
  deleteMemorandumSuccess: false,
  deleteMemorandumRejected: false,
  deleteMemorandumData: [],
  approveMemorandumLoading: false,
  approveMemorandumSuccess: false,
  approveMemorandumRejected: false
}, action) {
  switch (action.type) {
    case POST_MEMORANDUM:
      return {
        ...state,
        memorandumLoading: true,
        memorandumSuccess: false,
        memorandumRejected: false
      }
    case POST_MEMORANDUM_FULFILLED:
      return {
        ...state,
        memorandumLoading: false,
        memorandumSuccess: true,
        memorandumData: action.payload
      }
    case POST_MEMORANDUM_REJECTED:
      return {
        ...state,
        memorandumLoading: false,
        memorandumRejected: true
      }
    case GET_MEMORANDUMS:
      return {
        ...state,
        memorandumsLoading: true,
        memorandumsSuccess: false,
        memorandumsRejected: false
      }
    case GET_MEMORANDUMS_FULFILLED:
      return {
        ...state,
        memorandumsLoading: false,
        memorandumsSuccess: true,
        memorandumsData: action.payload
      }
    case GET_MEMORANDUMS_REJECTED:
      return {
        ...state,
        memorandumsLoading: false,
        memorandumsRejected: true
      }
    case PUT_MEMORANDUM:
      return {
        ...state,
        updateMemorandumLoading: true,
        updateMemorandumSuccess: false,
        updateMemorandumRejected: false
      }
    case PUT_MEMORANDUM_FULFILLED:
      return {
        ...state,
        updateMemorandumLoading: false,
        updateMemorandumSuccess: true,
        updateMemorandumData: action.payload
      }
    case PUT_MEMORANDUM_REJECTED:
      return {
        ...state,
        updateMemorandumLoading: false,
        updateMemorandumRejected: true
      }
    case DELETE_MEMORANDUM:
      return {
        ...state,
        deleteMemorandumLoading: true,
        deleteMemorandumSuccess: false,
        deleteMemorandumRejected: false
      }
    case DELETE_MEMORANDUM_FULFILLED:
      return {
        ...state,
        deleteMemorandumLoading: false,
        deleteMemorandumSuccess: true,
        deleteMemorandumData: action.payload
      }
    case DELETE_MEMORANDUM_REJECTED:
      return {
        ...state,
        deleteMemorandumLoading: false,
        deleteMemorandumRejected: true
      }
    case APPROVE_MEMORANDUM:
      return {
        ...state,
        approveMemorandumLoading: true,
        approveMemorandumSuccess: false,
        approveMemorandumRejected: false
      }
    case APPROVE_MEMORANDUM_FULFILLED:
      return {
        ...state,
        approveMemorandumLoading: false,
        approveMemorandumSuccess: true,
        approveMemorandumData: action.payload
      }
    case APPROVE_MEMORANDUM_REJECTED:
      return {
        ...state,
        approveMemorandumLoading: false,
        approveMemorandumRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
