import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_RESERVATION = 'POST_RESERVATION'
export const POST_RESERVATION_FULFILLED = 'POST_RESERVATION_FULFILLED'
export const POST_RESERVATION_REJECTED = 'POST_RESERVATION_REJECTED'

export const GET_RESERVATIONS = 'GET_RESERVATIONS'
export const GET_RESERVATIONS_FULFILLED = 'GET_RESERVATIONS_FULFILLED'
export const GET_RESERVATIONS_REJECTED = 'GET_RESERVATIONS_REJECTED'

export const GET_RESERVATION_SCHEDULES = 'GET_RESERVATION_SCHEDULES'
export const GET_RESERVATION_SCHEDULES_FULFILLED = 'GET_RESERVATION_SCHEDULES_FULFILLED'
export const GET_RESERVATION_SCHEDULES_REJECTED = 'GET_RESERVATION_SCHEDULES_REJECTED'

export const GET_RESERVATION = 'GET_RESERVATION'
export const GET_RESERVATION_FULFILLED = 'GET_RESERVATION_FULFILLED'
export const GET_RESERVATION_REJECTED = 'GET_RESERVATION_REJECTED'

export const PUT_RESERVATION = 'PUT_RESERVATION'
export const PUT_RESERVATION_FULFILLED = 'PUT_RESERVATION_FULFILLED'
export const PUT_RESERVATION_REJECTED = 'PUT_RESERVATION_REJECTED'

export const DELETE_RESERVATION = 'DELETE_RESERVATION'
export const DELETE_RESERVATION_FULFILLED = 'DELETE_RESERVATION_FULFILLED'
export const DELETE_RESERVATION_REJECTED = 'DELETE_RESERVATION_REJECTED'

export const APPROVE_RESERVATION = 'APPROVE_RESERVATION'
export const APPROVE_RESERVATION_FULFILLED = 'APPROVE_RESERVATION_FULFILLED'
export const APPROVE_RESERVATION_REJECTED = 'APPROVE_RESERVATION_REJECTED'

export const GET_SELECTED_KEYS_RESERVATION = 'GET_SELECTED_KEYS_RESERVATION'
export const GET_SELECTED_KEYS_RESERVATION_FULFILLED = 'GET_SELECTED_KEYS_RESERVATION_FULFILLED'
export const GET_SELECTED_KEYS_RESERVATION_REJECTED = 'GET_SELECTED_KEYS_RESERVATION_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadReservation = (status, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_RESERVATIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/reservations?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&status=${status}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_RESERVATIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_RESERVATIONS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadReservationById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_RESERVATION,
      payload: {}
    })
    return Axios.get(`${API_URL}/reservation/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const submitReservation = (data, status) => {
  return dispatch => {
    dispatch({
      type: POST_RESERVATION,
      payload: {}
    })
    return Axios.post(`${API_URL}/reservation`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const updateReservation = (data, status) => {
  return dispatch => {
    dispatch({
      type: PUT_RESERVATION,
      payload: {}
    })
    return Axios.put(`${API_URL}/reservation/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteReservation = (id, status) => {
  return dispatch => {
    dispatch({
      type: DELETE_RESERVATION,
      payload: {}
    })
    return Axios.delete(`${API_URL}/reservation/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const approveReservation = (id, status) => {
  return dispatch => {
    dispatch({
      type: APPROVE_RESERVATION,
      payload: {}
    })
    return Axios.put(`${API_URL}/reservation/reserved/${id}`, {}, { headers: headers })
      .then(function (response) {
        dispatch({
          type: APPROVE_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: APPROVE_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const loadReservationSchedules = (id) => {
  return dispatch => {
    dispatch({
      type: GET_RESERVATION_SCHEDULES,
      payload: {}
    })
    return Axios.get(`${API_URL}/get-reservation-schedules`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_RESERVATION_SCHEDULES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_RESERVATION_SCHEDULES_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSelectedRowReservation = (selectedKeys) => {
  return dispatch => {
    dispatch({
      type: GET_SELECTED_KEYS_RESERVATION,
      payload: {}
    })
    return Axios.get(`${API_URL}/reservationIds?selectedIds=${selectedKeys.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SELECTED_KEYS_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SELECTED_KEYS_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}










