import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_RESERVATION = 'GET_RESERVATION'
export const GET_RESERVATION_FULFILLED = 'GET_RESERVATION_FULFILLED'
export const GET_RESERVATION_REJECTED = 'GET_RESERVATION_REJECTED'

export const POST_RESERVATION = 'POST_RESERVATION'
export const POST_RESERVATION_FULFILLED = 'POST_RESERVATION_FULFILLED'
export const POST_RESERVATION_REJECTED = 'POST_RESERVATION_REJECTED'

export const PUT_RESERVATION = 'PUT_RESERVATION'
export const PUT_RESERVATION_FULFILLED = 'PUT_RESERVATION_FULFILLED'
export const PUT_RESERVATION_REJECTED = 'PUT_RESERVATION_REJECTED'

export const DELETE_RESERVATION = 'DELETE_RESERVATION'
export const DELETE_RESERVATION_FULFILLED = 'DELETE_RESERVATION_FULFILLED'
export const DELETE_RESERVATION_REJECTED = 'DELETE_RESERVATION_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadReservation = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_RESERVATION,
      payload: {}
    })
    return Axios.get(`${API_URL}/reservations?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const submitReservation = (data) => {
  return dispatch => {
    dispatch({
      type: POST_RESERVATION,
      payload: {}
    })
    return Axios.post(`${API_URL}/reservation`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const updateReservation = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_RESERVATION,
      payload: {}
    })
    return Axios.put(`${API_URL}/reservation/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}


export const deleteReservation = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_RESERVATION,
      payload: {}
    })
    return Axios.delete(`${API_URL}/reservation/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}











