import {
  GET_INCIDENT,
  GET_INCIDENT_FULFILLED,
  GET_INCIDENT_REJECTED,
  PUT_INCIDENT,
  PUT_INCIDENT_FULFILLED,
  PUT_INCIDENT_REJECTED,
  POST_INCIDENT,
  POST_INCIDENT_FULFILLED,
  POST_INCIDENT_REJECTED
} from '../actions'

export default function incident(state = {
  incidentLoading: false,
  incidentSuccess: false,
  incidentRejected: false,
  incidentData: [],
  updateIncidentLoading: false,
  updateIncidentSuccess: false,
  updateIncidentRejected: false,
  addIncidentLoading: false,
  addIncidentSuccess: false,
  addIncidentRejected: false
}, action) {
  switch (action.type) {
    case GET_INCIDENT:
      return {
        ...state,
        incidentLoading: true,
        incidentSuccess: false,
        incidentRejected: false
      }
    case GET_INCIDENT_FULFILLED:
      return {
        ...state,
        incidentLoading: false,
        incidentSuccess: true,
        incidentData: action.payload
      }
    case GET_INCIDENT_REJECTED:
      return {
        ...state,
        incidentLoading: false,
        incidentRejected: true
      }
    case POST_INCIDENT:
      return {
        ...state,
        addIncidentLoading: true,
        addIncidentSuccess: false,
        addIncidentRejected: false
      }
    case POST_INCIDENT_FULFILLED:
      return {
        ...state,
        addIncidentLoading: false,
        addIncidentSuccess: true,
        addIncidentData: action.payload
      }
    case POST_INCIDENT_REJECTED:
      return {
        ...state,
        addIncidentLoading: false,
        addIncidentRejected: true
      }
    case PUT_INCIDENT:
      return {
        ...state,
        updateIncidentLoading: true,
        updateIncidentSuccess: false,
        updateIncidentRejected: false
      }
    case PUT_INCIDENT_FULFILLED:
      return {
        ...state,
        updateIncidentLoading: false,
        updateIncidentSuccess: true,
        updateIncidentData: action.payload
      }
    case PUT_INCIDENT_REJECTED:
      return {
        ...state,
        updateIncidentLoading: false,
        updateIncidentRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
