import {
  POST_PAYMENT,
  POST_PAYMENT_FULFILLED,
  POST_PAYMENT_REJECTED,
  GET_PAYMENTS,
  GET_PAYMENTS_FULFILLED,
  GET_PAYMENTS_REJECTED,
  PUT_PAYMENT,
  PUT_PAYMENT_FULFILLED,
  PUT_PAYMENT_REJECTED,
  DELETE_PAYMENT,
  DELETE_PAYMENT_FULFILLED,
  DELETE_PAYMENT_REJECTED,
  APPROVE_PAYMENT,
  APPROVE_PAYMENT_FULFILLED,
  APPROVE_PAYMENT_REJECTED

} from '../actions'

export default function payments(state = {
  paymentLoading: false,
  paymentSuccess: false,
  paymentRejected: false,
  paymentData: [],
  paymentsLoading: false,
  paymentsSuccess: false,
  paymentsRejected: false,
  paymentsData: [],
  updatePaymentLoading: false,
  updatePaymentSuccess: false,
  updatePaymentRejected: false,
  updatePaymentData: [],
  deletePaymentLoading: false,
  deletePaymentSuccess: false,
  deletePaymentRejected: false,
  deletePaymentData: [],
  approvePaymentLoading: false,
  approvePaymentSuccess: false,
  approvePaymentRejected: false
}, action) {
  switch (action.type) {
    case POST_PAYMENT:
      return {
        ...state,
        paymentLoading: true,
        paymentSuccess: false,
        paymentRejected: false
      }
    case POST_PAYMENT_FULFILLED:
      return {
        ...state,
        paymentLoading: false,
        paymentSuccess: true,
        paymentData: action.payload
      }
    case POST_PAYMENT_REJECTED:
      return {
        ...state,
        paymentLoading: false,
        paymentRejected: true
      }
    case GET_PAYMENTS:
      return {
        ...state,
        paymentsLoading: true,
        paymentsSuccess: false,
        paymentsRejected: false
      }
    case GET_PAYMENTS_FULFILLED:
      return {
        ...state,
        paymentsLoading: false,
        paymentsSuccess: true,
        paymentsData: action.payload
      }
    case GET_PAYMENTS_REJECTED:
      return {
        ...state,
        paymentsLoading: false,
        paymentsRejected: true
      }
    case PUT_PAYMENT:
      return {
        ...state,
        updatePaymentLoading: true,
        updatePaymentSuccess: false,
        updatePaymentRejected: false
      }
    case PUT_PAYMENT_FULFILLED:
      return {
        ...state,
        updatePaymentLoading: false,
        updatePaymentSuccess: true,
        updatePaymentData: action.payload
      }
    case PUT_PAYMENT_REJECTED:
      return {
        ...state,
        updatePaymentLoading: false,
        updatePaymentRejected: true
      }
    case DELETE_PAYMENT:
      return {
        ...state,
        deletePaymentLoading: true,
        deletePaymentSuccess: false,
        deletePaymentRejected: false
      }
    case DELETE_PAYMENT_FULFILLED:
      return {
        ...state,
        deletePaymentLoading: false,
        deletePaymentSuccess: true,
        deletePaymentData: action.payload
      }
    case DELETE_PAYMENT_REJECTED:
      return {
        ...state,
        deletePaymentLoading: false,
        deletePaymentRejected: true
      }
    case APPROVE_PAYMENT:
      return {
        ...state,
        approvePaymentLoading: true,
        approvePaymentSuccess: false,
        approvePaymentRejected: false
      }
    case APPROVE_PAYMENT_FULFILLED:
      return {
        ...state,
        approvePaymentLoading: false,
        approvePaymentSuccess: true,
        approvePaymentData: action.payload
      }
    case APPROVE_PAYMENT_REJECTED:
      return {
        ...state,
        approvePaymentLoading: false,
        approvePaymentRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
