import {
  GET_WEBSITE_SETTINGS,
  GET_WEBSITE_SETTINGS_FULFILLED,
  GET_WEBSITE_SETTINGS_REJECTED
} from '../actions'

import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

// Client
import login from '../login/reducers'
import post from '../announcements/reducers'
import services from '../services/reducers'
import settings from '../settings/reducers'
import gatePass from '../gatePass/reducers'
import incidents from '../incidents/reducers'
import reservations from '../reservations/reducers'
import permits from '../permits/reducers'
import visitors from '../visitors/reducers'
import memorandums from '../memorandums/reducers'
import policies from '../policies/reducers'
import billings from '../billings/reducers'
import rentalInquiries from '../inquiries/reducers'
import ticketList from '../ticketList/reducers'
import commendations from '../commendations/reducers'
import comments from '../comments/reducers'
import advertisements from '../advertisements/reducers'
import notifications from '../notifications/reducers'

const rootReducer = combineReducers({
  form,
  login,
  post,
  services,
  settings,
  gatePass,
  incidents,
  reservations,
  permits,
  visitors,
  memorandums,
  policies,
  websiteSettings,
  billings,
  ticketList,
  rentalInquiries,
  commendations,
  comments,
  advertisements,
  notifications
})

// Website settings
function websiteSettings(state = {
  websiteSettingsLoading: false,
  websiteSettingsSuccess: false,
  websiteSettingsRejected: false,
  websiteSettingsData: []
}, action) {
  switch (action.type) {
    case GET_WEBSITE_SETTINGS:
      return {
        ...state,
        websiteSettingsLoading: true,
        websiteSettingsSuccess: false,
        websiteSettingsRejected: false
      }
    case GET_WEBSITE_SETTINGS_FULFILLED:
      return {
        ...state,
        websiteSettingsLoading: false,
        websiteSettingsSuccess: true,
        websiteSettingsData: action.payload
      }
    case GET_WEBSITE_SETTINGS_REJECTED:
      return {
        ...state,
        websiteSettingsLoading: false,
        websiteSettingsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}

export default rootReducer
