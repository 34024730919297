import React from 'react'
import { Link } from 'react-router-dom'
// import _ from lodash

const ServiceNavLink = (props) => {
    const filterPath = ['orders', 'gatepass', 'reservations', 'reports', 'permits', 'visitors', 'inquiries', 'commendations']
    let newPath = []
    filterPath.map(path => {
        props.paths && newPath.push(props.paths.find(data => data.title === path))
    })
    return (
        <div className='service-nav-link'>
            <h1>PMO Services</h1>
            {
                newPath.map((data, index) => {
                    return (
                        data.sidebarMenu && <Link className={props.location.pathname === data.route && 'service-nav-link-active'} to={data.route} key={index}>{data.slug}</Link>
                    )
                })
            }
        </div>
    )
}

export default ServiceNavLink