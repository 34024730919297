import {
  POST_VISITOR,
  POST_VISITOR_FULFILLED,
  POST_VISITOR_REJECTED,
  GET_VISITORS,
  GET_VISITORS_FULFILLED,
  GET_VISITORS_REJECTED,
  PUT_VISITOR,
  PUT_VISITOR_FULFILLED,
  PUT_VISITOR_REJECTED,
  DELETE_VISITOR,
  DELETE_VISITOR_FULFILLED,
  DELETE_VISITOR_REJECTED
} from '../actions'

export default function visitors(state = {
  visitorLoading: false,
  visitorSuccess: false,
  visitorRejected: false,
  visitorData: [],
  visitorsLoading: false,
  visitorsSuccess: false,
  visitorsRejected: false,
  visitorsData: [],
  updateVisitorLoading: false,
  updateVisitorSuccess: false,
  updateVisitorRejected: false,
  updateVisitorData: [],
  deleteVisitorLoading: false,
  deleteVisitorSuccess: false,
  deleteVisitorRejected: false,
  deleteVisitorData: []
}, action) {
  switch (action.type) {
    case POST_VISITOR:
      return {
        ...state,
        visitorLoading: true,
        visitorSuccess: false,
        visitorRejected: false
      }
    case POST_VISITOR_FULFILLED:
      return {
        ...state,
        visitorLoading: false,
        visitorSuccess: true,
        visitorData: action.payload
      }
    case POST_VISITOR_REJECTED:
      return {
        ...state,
        visitorLoading: false,
        visitorRejected: true
      }
    case GET_VISITORS:
      return {
        ...state,
        visitorsLoading: true,
        visitorsSuccess: false,
        visitorsRejected: false
      }
    case GET_VISITORS_FULFILLED:
      return {
        ...state,
        visitorsLoading: false,
        visitorsSuccess: true,
        visitorsData: action.payload
      }
    case GET_VISITORS_REJECTED:
      return {
        ...state,
        visitorsLoading: false,
        visitorsRejected: true
      }
    case PUT_VISITOR:
      return {
        ...state,
        updateVisitorLoading: true,
        updateVisitorSuccess: false,
        updateVisitorRejected: false
      }
    case PUT_VISITOR_FULFILLED:
      return {
        ...state,
        updateVisitorLoading: false,
        updateVisitorSuccess: true,
        updateVisitorData: action.payload
      }
    case PUT_VISITOR_REJECTED:
      return {
        ...state,
        updateVisitorLoading: false,
        updateVisitorRejected: true
      }
    case DELETE_VISITOR:
      return {
        ...state,
        deleteVisitorLoading: true,
        deleteVisitorSuccess: false,
        deleteVisitorRejected: false
      }
    case DELETE_VISITOR_FULFILLED:
      return {
        ...state,
        deleteVisitorLoading: false,
        deleteVisitorSuccess: true,
        deleteVisitorData: action.payload
      }
    case DELETE_VISITOR_REJECTED:
      return {
        ...state,
        deleteVisitorLoading: false,
        deleteVisitorRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
