
import React, { Suspense } from 'react'
import { Row, Col, Layout, Table, Button, Modal, Tag, message, Pagination, Dropdown, Menu, } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUser } from '../../../../utilities/token'
import { ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { submitGatePass, loadGatePass, deleteGatePass } from '../actions'
import AddSlide from './slides/add'
import EditSlide from './slides/edit'
import './index.scss'
import moment from 'moment'
import ServiceNavLink from '../../serviceNavLink'
import { ImagePresignedUrl } from '../../../../utilities/renderer'

class Gatepass extends React.Component {
    constructor() {
        super()
        this.state = {
            isAddSlideOpen: false,
            isEditSlideOpen: false,
            //pagination
            page: 1,
            limit: 10,
            searchTerm: '',
            tableData: []
        }
        this.onReset = this.onReset.bind(this)
        this.addSlideToggle = this.addSlideToggle.bind(this)
        this.editSlideToggle = this.editSlideToggle.bind(this)
        this.handleDeleteGatePass = this.handleDeleteGatePass.bind(this)
    }
    formRef = React.createRef();

    handleSubmit(data) {
        const submitData = {
            "tenantId": getUser().id,
            "title": data.title,
            "description": data.description,
            "comment": "TEST",
        }
        this.props.onSubmitGatePass(submitData)
        this.onReset()

    }

    handleDeleteGatePass(id) {
        const self = this
        Modal.confirm({
            title: 'Do you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'You cannot undo this.',
            onOk() {
                self.props.onDeleteGatePass(id)
            },
            onCancel() { },
        });
    }

    onReset = () => {
        this.formRef.current.resetFields();
    };

    componentDidMount() {
        const { limit, page, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadGatePass(pagination)

        window.Echo.channel(`client-tickets-crud.${getUser().id}`)
            .listen('.client-tickets-crud-channel', (response) => {
                const { data } = response
                const index = this.state.tableData.findIndex(item => item.id === data.id)
                if (data.status === 0) {
                    this.state.tableData.splice(index, 1) // delete
                } else {
                    if (index !== -1) this.state.tableData[index] = data // update
                    else {
                        this.state.tableData.unshift({ ...data }) // create
                        this.state.tableData = this.state.tableData.slice(0, this.state.limit) // re-assigning and limit to 10
                    }
                }
                // this.setState({tableData: this.state.tableData})
                // this.setState are not working inside echo channel, 
                // regarding to this. <Table key/> key must be unique to know if there was a changed.
                this.forceUpdate()
            })
    }

    componentWillReceiveProps(nextProps) {
        const { limit, page, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        if ((this.props.addGatePassSuccess !== nextProps.addGatePassSuccess) && nextProps.addGatePassSuccess) {
            this.setState({ isAddSlideOpen: false })
            message.success("Gate Pass added successfully.")
            this.props.onLoadGatePass(pagination)
        }
        if ((this.props.updateGatePassSuccess !== nextProps.updateGatePassSuccess) && nextProps.updateGatePassSuccess) {
            this.setState({ isEditSlideOpen: false })
            message.success("Gate Pass edited successfully.")
            this.props.onLoadGatePass(pagination)
        }
        if ((this.props.deleteGatePassSuccess !== nextProps.deleteGatePassSuccess) && nextProps.deleteGatePassSuccess) {
            this.setState({ isEditSlideOpen: false })
            message.success("Gate Pass deleted successfully.")
            this.props.onLoadGatePass(pagination)
        }
        if ((this.props.gatePassSuccess !== nextProps.gatePassSuccess) && nextProps.gatePassSuccess) {
            this.setState({ tableData: nextProps.gatePassData.data })
        }
    }

    addSlideToggle() {
        this.setState({ isAddSlideOpen: !this.state.isAddSlideOpen })
    }

    editSlideToggle() {
        this.setState({ isEditSlideOpen: !this.state.isEditSlideOpen })
    }

    getType(status) {
        switch (status) {
            case 1:
                return <Tag color="gold">DELIVERY</Tag>
            case 2:
                return <Tag color="lime">MOVEIN</Tag>
            case 3:
                return <Tag color="geekblue">MOVEOUT</Tag>
            case 4:
                return <Tag color="magenta">PULLOUT</Tag>
            default:
                break;
        }
    }

    getStatus(status) {
        switch (status) {
            case 1:
                return <Tag color="gold">FOR APPROVAL</Tag>
            case 2:
                return <Tag color="lime">APPROVED</Tag>
            case 3:
                return <Tag color="geekblue">ONGOING</Tag>
            case 4:
                return <Tag color="green">COMPLETED</Tag>
            case 5:
                return <Tag color="magenta">CANCELLED</Tag>
            case 6:
                return <Tag color="red">REJECTED</Tag>
            default:
                break;
        }
    }

    onChangePagination(page, limit) {
        const { searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadGatePass(pagination)
        this.setState({ page, limit })
    }

    render() {
        const dataSource = this.state.tableData
        const columns = [
            {
                title: 'Service Ticket #',
                dataIndex: 'ticketNumber',
                key: 'ticketNumber',
                sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: (item) => {
                    return (<span>{this.getType(item)}</span>)
                }
            },
            {
                title: 'Bearer Name',
                dataIndex: 'bearerName',
                key: 'bearerName',
                sorter: (a, b) => a.bearerName && a.bearerName.localeCompare(b.bearerName)
            },
            {
                title: 'Date of Application',
                dataIndex: 'dateOfApplication',
                key: 'dateOfApplication',
                sorter: (a, b) => new Date(b.dateOfApplication) - new Date(a.dateOfApplication),
                render: (item) => {
                    return (<span>{moment(item).format('LLL')}</span>)
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (item) => {
                    return (<span>{this.getStatus(item)}</span>)
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: '',
                render: (info, data) => {
                    return (
                        <div className='action-dropdown-btn'>
                            <Dropdown overlay={<Menu>
                                <Menu.Item onClick={() => this.setState({ selectedRow: data, isEditSlideOpen: true })}>
                                    <span>View</span>
                                </Menu.Item>
                                {
                                    data.status === 1 ?
                                        <Menu.Item onClick={() => this.handleDeleteGatePass(data.id)}  >
                                            <span>Delete</span>
                                        </Menu.Item>
                                        :
                                        <Menu.Item disabled  >
                                            <span style={{ color: 'red' }}>Unable to delete</span>
                                        </Menu.Item>
                                }
                            </Menu>} trigger={['click']}>
                                <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <EllipsisOutlined />
                                </span>
                            </Dropdown>
                        </div>
                    )
                }
            },
        ];
        return (
            <Layout>
                <Row>
                    <Col md={12} lg={12} xs={24} sm={24}>
                        <h1 style={{ fontSize: 24, marginLeft: 10 }}>GATE PASS</h1>
                        <Button className='primary-btn' type='primary' onClick={() => this.setState({ isAddSlideOpen: true })} style={{ marginBottom: 10 }}> + ADD GATE PASS</Button>
                    </Col>
                </Row>

                <Row gutter={32}>
                    <Col md={18} lg={18} xs={24} sm={24}>
                        <Table columns={columns} dataSource={dataSource} rowKey="id" key={(JSON.stringify(this.state.tableData))} loading={this.props.gatePassLoading} pagination={false} />
                        <Row style={{ marginTop: 30, width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} align="right">
                                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.gatePassData.total} />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} lg={6} xs={24} sm={24}>
                        {this.props.advertisementData && this.props.advertisementData.images &&
                            <div className='banner-image'>
                                <ImagePresignedUrl src={this.props.advertisementData.images[0].url} />
                            </div>
                        }
                        <ServiceNavLink {...this.props} />
                    </Col>
                </Row>

                <AddSlide
                    visible={this.state.isAddSlideOpen}
                    onCancel={this.addSlideToggle}
                />
                <EditSlide
                    visible={this.state.isEditSlideOpen}
                    selectedRow={this.state.selectedRow}
                    onCancel={this.editSlideToggle}
                />
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        gatePassSuccess: state.client.gatePass.gatePassSuccess,
        addGatePassSuccess: state.client.gatePass.addGatePassSuccess,
        deleteGatePassSuccess: state.client.gatePass.deleteGatePassSuccess,
        updateGatePassSuccess: state.client.gatePass.updateGatePassSuccess,
        gatePassData: state.client.gatePass.gatePassData,
        gatePassLoading: state.client.gatePass.gatePassLoading,
        advertisementData: state.client.advertisements.advertisementData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitGatePass: (data) => dispatch(submitGatePass(data)),
        onLoadGatePass: (pagination) => dispatch(loadGatePass(pagination)),
        onDeleteGatePass: (id) => dispatch(deleteGatePass(id)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Gatepass)
)