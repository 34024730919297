import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import Upload from './upload'
import { submitTenant } from '../../actions'

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleSubmit(values) {
    const submitData = {
      clientCode: values.clientCode,
      uoccname: values.uoccname,
      uoccpers: values.uoccpers,
      uocctel1: values.uocctel1,
      uocctel2: values.uocctel2,
      ubtname: values.ubtname,
      ubaddln1: values.ubaddln1,
      ubaddln2: values.ubaddln2,
      ubaddln3: values.ubaddln3,
      ubaddln4: values.ubaddln4,
      lastName: values.lastName,
      firstName: values.firstName,
      middleName: values.middleName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      type: 3, // Client
      password: values.password,
      postById: getUser().id,
      status: 1
    }
    if (this.state.base64ImageUrl === '') {
      this.props.onSubmitTenant(submitData)
    }
  }

  render() {
    return (
      <Modal
        title="ADD TENANT INFORMATION"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={false}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Client Code"
                  name="clientCode"
                  rules={[{ required: false, message: 'Please input your client code!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: false, message: 'Please input your first name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: false, message: 'Please input your last name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Middle Name"
                  name="middleName"
                  rules={[{ required: false, message: 'Please input your middle name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: false, message: 'Please input your email!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[{ required: false, message: 'Please input your phone number!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: false, message: 'Please input your password!' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>

                {/* <span style={{ fontWeight: 550 }}>Tenant Image</span>
                <Upload getImageBase64={e => this.setState({ base64ImageUrl: e })} /> */}

                <Form.Item
                  label="UOCC NAME"
                  name="uoccname"
                  rules={[{ required: true, message: 'Please input your Uocc name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UOCC PERS"
                  name="uoccpers"
                  rules={[{ required: true, message: 'Please input your Uocc pers!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UOCC Tel 1"
                  name="uocctel1"
                  rules={[{ required: true, message: 'Please input your UOCC tel 1!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UOCC Tel 2"
                  name="uocctel2"
                  rules={[{ required: true, message: 'Please input your UOCC tel 2!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBT Name"
                  name="ubtname"
                  rules={[{ required: true, message: 'Please input your Ubt name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln1"
                  name="ubaddln1"
                  rules={[{ required: true, message: 'Please input your Ubadd ln1!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln2"
                  name="ubaddln2"
                  rules={[{ required: true, message: 'Please input your Ubadd ln2!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln3"
                  name="ubaddln3"
                  rules={[{ required: true, message: 'Please input your Ubadd ln3!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln4"
                  name="ubaddln4"
                  rules={[{ required: true, message: 'Please input your Ubadd ln4!' }]}
                >
                  <Input />
                </Form.Item>


              </Col>
            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button type="primary" htmlType="submit" loading={this.props.tenantLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    tenantLoading: state.admin.tenant.tenantLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitTenant: (data) => dispatch(submitTenant(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}