import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Collapse, Tag, Pagination } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadPermit, deletePermit, updatePermit, loadSelectedRowPermit } from '../actions'
import moment from 'moment'
import { getParamFromUrl } from '../../../../utilities/helper'
import Papa from 'papaparse'
import { getUser } from '../../../../utilities/token'
import {
  APPROVAL,
  APPROVED,
  CANCELLED,
  REJECTED
} from '../../../../utilities/serviceStatusEnum'
const { Search } = Input;
const { Panel } = Collapse;
const { TabPane } = Tabs;

class Permits extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      permitStatus: '0',
      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      selectedRowKeys: [],
      downloadLoading: false,
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
    this.timeout = 0;
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    if ((this.props.permitSuccess !== nextProps.permitSuccess) && nextProps.permitSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Permit added successfully.")
      this.props.onLoadPermit(this.getKeyConvertToStatus(this.state.permitStatus), pagination)

    }
    if ((this.props.updatePermitSuccess !== nextProps.updatePermitSuccess) && nextProps.updatePermitSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Permit edited successfully.")
      this.props.onLoadPermit(this.getKeyConvertToStatus(this.state.permitStatus), pagination)
    }
    if ((this.props.deletePermitSuccess !== nextProps.deletePermitSuccess) && nextProps.deletePermitSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Permit deleted successfully.")
      this.props.onLoadPermit(this.getKeyConvertToStatus(this.state.permitStatus), pagination)
    }
    if ((this.props.permitsSuccess !== nextProps.permitsSuccess) && nextProps.permitsSuccess) {
      this.setState({ tableData: nextProps.permitsData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    this.props.onLoadPermit(this.getKeyConvertToStatus(this.state.permitStatus), pagination)

    if (getParamFromUrl('modal') === 'true') {
      this.setState({ isEditModalOpen: true })
    }

    window.Echo.channel(`admin-permits-crud.${getUser().id}`)
      .listen('.admin-permits-crud-channel', (response) => {
        const { data } = response
        const index = this.state.tableData.findIndex(item => item.id === data.id)
        if (data.status === 0) {
          this.state.tableData.splice(index, 1) // delete
        } else {
          if (index !== -1) this.state.tableData[index] = data // update
          else {
            this.state.tableData.unshift({ ...data }) // create
            this.state.tableData = this.state.tableData.slice(0, this.state.limit) // re-assigning and limit to 10
          }
        }
        // this.setState({tableData: this.state.tableData})
        // this.setState are not working inside echo channel, 
        // regarding to this. <Table key/> key must be unique to know if there was a changed.
        this.forceUpdate()
      })
  }

  getKeyConvertToStatus(key) {
    let status
    switch (key) {
      case '0':
        status = ''
        break;
      case '1':
        status = APPROVAL
        break;
      case '2':
        status = APPROVED
        break;
      case '3':
        status = CANCELLED
        break;
      case '4':
        status = REJECTED
        break;
      default:
        break;
    }
    return status
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeletePermit(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeletePermit(id)
      },
      onCancel() { },
    });
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadPermit(this.getKeyConvertToStatus(this.state.permitStatus), pagination)

    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadPermit(this.getKeyConvertToStatus(this.state.permitStatus), pagination)

    this.setState({ page, limit })
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return <Tag color="gold">FOR APPROVAL</Tag>
      case 2:
        return <Tag color="lime">APPROVED</Tag>
      case 3:
        return <Tag color="geekblue">ONGOING</Tag>
      case 4:
        return <Tag color="green">COMPLETED</Tag>
      case 5:
        return <Tag color="magenta">CANCELLED</Tag>
      case 6:
        return <Tag color="red">REJECTED</Tag>
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }


  handleDownloadSelectedRowToCSV() {
    this.setState({ downloadLoading: true })
    this.props.onLoadSelectedRowPermit(this.state.selectedRowKeys).then(res => {

      function returnContractors(data) {
        let itemArray = []
        data && data.map(item => {
          item && itemArray.push(`${item && item.name}-${item && item.information}`)
        })
        return itemArray.toString()
      }
      const newData = res.payload.map(data => {
        return {
          id: data.id,
          ticketNumber: data.ticketNumber,
          clientCode: data.tenant && data.tenant.clientCode,
          clientName: data.tenant && data.tenant.uoccname,
          workPermitFor: data.workPermitFor,
          workArea: data.workArea,
          purpose: data.purpose,
          contractors: returnContractors(data.contractors),
          bondAmount: data.bondAmount,
          status: this.getStatusForCsv(data.status),
          remarks: data.remarks,
          dateApplied: moment(data.dateApplied).format('DD/MM/YYYY'),
          completedDate: moment(data.dateCompleted).format('DD/MM/YYYY'),
          dateFrom: moment(data.dateFrom).format('DD/MM/YYYY'),
          dateTo: moment(data.dateTo).format('DD/MM/YYYY'),
          createdAt: moment(data.created_at).format('DD/MM/YYYY'),
        }
      })
      var csv = Papa.unparse(newData);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, 'Permit Reports.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'Permit Reports.csv');
      tempLink.click();

    }).finally(() => {
      this.setState({ downloadLoading: false })
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  getStatusForCsv(status) {
    switch (status) {
      case 1:
        return 'FOR APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  handlePermitType(e) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: 1,
      limit: limit,
      searchTerm: searchTerm
    }

    this.setState({ permitStatus: e.toString(), page: 1 })
    this.props.onLoadPermit(this.getKeyConvertToStatus(e), pagination)

  }


  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      preserveSelectedRowKeys: true
    };
    const dataSource = this.state.tableData
    const columns = [
      {
        title: 'Service Ticket #',
        dataIndex: 'ticketNumber',
        key: 'ticketNumber',
        sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
      },
      {
        title: 'Client Code',
        dataIndex: 'tenant',
        key: 'clientCode',
        sorter: (a, b) => a.tenant.clientCode && a.tenant.clientCode.localeCompare(b.tenant && b.tenant.clientCode),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.clientCode}`}</span>
          )
        }
      },
      {
        title: 'Client Name',
        dataIndex: 'tenant',
        key: 'tenant',
        sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.uoccname}`}</span>
          )
        }
      },
      {
        title: 'Work Permit For',
        dataIndex: 'workPermitFor',
        key: 'workPermitFor',
        sorter: (a, b) => a.workPermitFor && a.workPermitFor.localeCompare(b.workPermitFor),
      },
      {
        title: 'Contractors',
        dataIndex: 'contractors',
        key: 'contractors',
        render: (item) => {
          return (
            <Collapse ghost>
              {
                item && item.map((data, index) => {
                  return (
                    <Panel header={data.name} key={index}>
                      <span>{data.information}</span>
                    </Panel>
                  )
                })
              }
            </Collapse>
          )
        }
      },
      {
        title: 'Date Applied',
        dataIndex: 'dateApplied',
        key: 'dateApplied',
        sorter: (a, b) => new Date(b.dateApplied) - new Date(a.dateApplied),
        render: (item, data) => {
          return (<span>{moment(data.created_at).format('LL')}</span>)
        }
      },
      {
        title: 'Date From',
        dataIndex: 'dateFrom',
        key: 'dateFrom',
        sorter: (a, b) => new Date(b.dateFrom) - new Date(a.dateFrom),
        render: (item) => {
          return (<span>{moment(item).format('LL')}</span>)
        }
      },
      {
        title: 'Date To',
        dataIndex: 'dateTo',
        key: 'dateTo',
        sorter: (a, b) => new Date(b.dateTo) - new Date(a.dateTo),
        render: (item) => {
          return (<span>{moment(item).format('LL')}</span>)
        }
      },
      {
        title: 'Date Completed',
        dataIndex: 'dateCompleted',
        key: 'dateCompleted',
        sorter: (a, b) => new Date(b.dateCompleted) - new Date(a.dateCompleted),
        render: (item) => {
          return (<span>{moment(item).format('LL')}</span>)
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (item) => {
          return (<span>{this.getStatus(item)}</span>)
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              {
                this.access('permits', 'view') &&
                <Button type="primary" icon={<EditOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} >View</Button>
              }
              {
                this.access('permits', 'delete') &&
                <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeletePermit(data.id)}  >Delete</Button>
              }
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='permits-container'>

        <Layout.Content>
          <div className='permit-title'>
            <h1>Permits</h1>
          </div>
          <Tabs defaultActiveKey="0" type="card" size='small' onChange={e => this.handlePermitType(e)}>
            <TabPane tab="All" key="0" />
            <TabPane tab="Approval" key="1" />
            <TabPane tab="Approved" key="2" />
            <TabPane tab="Cancelled" key="3" />
            <TabPane tab="Rejected" key="4" />
          </Tabs>
          <Row>
            <Col md={14} lg={14} sm={24} xs={24}>
              <div className='mt3 mb3'>
                <Button type='primary' onClick={() => this.handleDownloadSelectedRowToCSV()} loading={this.state.downloadLoading}>Download CSV</Button>
              </div>
            </Col>
          </Row>
          <Row className='permit-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('permits', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Work Area, Permits For, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value, ['tenant.firstName', 'tenant.lastName', 'workArea', 'workPermitFor', 'purpose', 'bondAmount', 'status'])} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table style={{ width: '100%' }} rowKey='id'
              rowSelection={rowSelection}
              loading={this.props.permitsLoading || this.props.deletePermitLoading || this.props.updatePermitLoading}
              dataSource={dataSource} columns={columns} pagination={false}
              key={(JSON.stringify(this.state.tableData))}
            />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.permitsData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    permitLoading: state.admin.permits.permitLoading,
    permitSuccess: state.admin.permits.permitSuccess,
    updatePermitSuccess: state.admin.permits.updatePermitSuccess,
    deletePermitSuccess: state.admin.permits.deletePermitSuccess,
    permitsSuccess: state.admin.permits.permitsSuccess,
    permitsData: state.admin.permits.permitsData,
    permitsLoading: state.admin.permits.permitsLoading,
    deletePermitLoading: state.admin.permits.deletePermitLoading,
    updatePermitLoading: state.admin.permits.updatePermitLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadPermit: (status, pagination) => dispatch(loadPermit(status, pagination)),
    onDeletePermit: (id) => dispatch(deletePermit(id)),
    onUpdateStatus: (data) => dispatch(updatePermit(data)),
    onLoadSelectedRowPermit: (selectedKeys) => dispatch(loadSelectedRowPermit(selectedKeys))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Permits))
