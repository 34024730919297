import React from 'react'
import './index.scss'
import { Row, Col, Spin, Space } from 'antd'

const Maintenance = (props) => {
    return (
        <Row className='maintenance'>
            <Col className='content' md={24} lg={24} sm={24} xs={24} align='center' >
                {
                    props.loading ?
                        <Space size="middle">
                            <Spin size="large" />
                            <h1>Loading ...</h1>
                        </Space>
                        :
                        <div className='message'>
                            <h1>We'll be back soon!</h1>
                            <p>{props.message}</p>
                        </div>

                }

            </Col>


        </Row>
    )
}

export default Maintenance