import React, { useEffect } from 'react'
import { Card, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { loadAdvertisements } from '../../advertisements/actions'
import config from '../../../../config'
import { ImagePresignedUrl } from '../../../../utilities/renderer'

const Discover = (props) => {

    useEffect(() => {
        const limit = 4
        props.onLoadAdvertisements(limit)
    }, [])

    function handleRedirect(url) {
        window.open(url, '_blank');
    }

    return (
        <Card className='advertisement-card mt5'>
            <div className='advertisement-title'>
                <h1>Discover</h1>
            </div>

            <Row gutter={[16, 16]}>
                {
                    props.advertisementsData.map((data, index) => {
                        console.log("data", data)
                        return (
                            <Col md={12} key={index}>
                                <div className='discover-container' onClick={() => handleRedirect(data.url)}>
                                    <ImagePresignedUrl src={data.images[0].url} />
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </Card>

    )
}

function mapStateToProps(state) {
    return {
        advertisementsData: state.client.advertisements.advertisementsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadAdvertisements: (limit) => dispatch(loadAdvertisements(limit)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Discover)