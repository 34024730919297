import React, { useEffect } from 'react'
import { Divider, Card, Button } from 'antd'
import '../index.scss'
import { updateDragonPayStatus } from '../../actions'
import { connect } from 'react-redux'
import { FOR_VERIFICATION } from '../../../../../utilities/paymentsStatusEnum'

const ReturnUrlMessage = (props) => {

    function getStatus() {
        const status = url.searchParams.get("status")
        switch (status) {
            case 'S':
                return 'SUCCESSFUL'
            case 'P':
                return 'PENDING'
            case 'R':
                return 'REFUND'
            default:
                return 'PENDING'
        }
    }

    useEffect(() => {
        var url_string = window.location.href
        var url = new URL(url_string);
        const submitData = {
            txnId: url.searchParams.get("txnid"),
            refNo: url.searchParams.get("refno"),
            status: FOR_VERIFICATION,
            outsourceStatus: url.searchParams.get("status"),
            message: url.searchParams.get("message"),
            digest: url.searchParams.get("digest")
        }
        props.onUpdateDragonPayStatus(submitData)
    }, [])

    var url_string = window.location.href
    var url = new URL(url_string);
    return (
        <div className='dragonpay-transaction-complete'>
            <Card loading={props.updateDragonPayStatusLoading} style={{ width: 600 }}>
                <h1>Transaction Complete</h1>
                <p>Please check the email you have provided and follow the instructions sent by dragon pay.</p>
                <Divider />
                <p>Trans ID: {url.searchParams.get("txnid")}</p>
                <p>Reference Number: {url.searchParams.get("refno")}</p>
                <p>Message: {url.searchParams.get("message")}</p>
                <p>Status: <b>{getStatus()}</b></p>
                {/* <div className='mt5'>
                <u><Link to='/'>Back to dashboard</Link></u>
            </div> */}
            </Card>
        </div>

    )
}

function mapStateToProps(state) {
    return {
        updateDragonPayStatusLoading: state.client.billings.updateDragonPayStatusLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateDragonPayStatus: (data) => dispatch(updateDragonPayStatus(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnUrlMessage)