import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_TERMS_AND_CONDITIONS = 'POST_TERMS_AND_CONDITIONS'
export const POST_TERMS_AND_CONDITIONS_FULFILLED = 'POST_TERMS_AND_CONDITIONS_FULFILLED'
export const POST_TERMS_AND_CONDITIONS_REJECTED = 'POST_TERMS_AND_CONDITIONS_REJECTED'
export const GET_TERMS_AND_CONDITIONS = 'GET_TERMS_AND_CONDITIONS'
export const GET_TERMS_AND_CONDITIONS_FULFILLED = 'GET_TERMS_AND_CONDITIONS_FULFILLED'
export const GET_TERMS_AND_CONDITIONS_REJECTED = 'GET_TERMS_AND_CONDITIONS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadTermsAndCondition = () => {
  return dispatch => {
    dispatch({
      type: GET_TERMS_AND_CONDITIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/terms-and-conditions`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_TERMS_AND_CONDITIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_TERMS_AND_CONDITIONS_REJECTED,
          payload: error
        })
      })
  }
}

export const submitTermsAndConditions = (data) => {
  console.log("data", data)
  return dispatch => {
    dispatch({
      type: POST_TERMS_AND_CONDITIONS,
      payload: {}
    })
    return Axios.post(`${API_URL}/terms-and-conditions`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_TERMS_AND_CONDITIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_TERMS_AND_CONDITIONS_REJECTED,
          payload: error
        })
      })
  }
}







