import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { submitCommendation } from '../../actions'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false
    }
  }

  handleSubmit(values) {
    this.props.onSubmitServinceRequest(values)
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }

  render() {
    return (
      <Modal
        title="ADD COMMENDATION AND TESTIMONIAL"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[{ required: true, message: 'Please input your comment!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={1}>COMMENDATION</Option>
                    <Option value={2}>TESTIMONIAL</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    commendationLoading: state.admin.commendations.commendationLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitServinceRequest: (data) => dispatch(submitCommendation(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant())
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

