import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_SUMMARY_REPORTS = 'GET_SUMMARY_REPORTS'
export const GET_SUMMARY_REPORTS_FULFILLED = 'GET_SUMMARY_REPORTS_FULFILLED'
export const GET_SUMMARY_REPORTS_REJECTED = 'GET_SUMMARY_REPORTS_REJECTED'

export const GET_SUMMARY_REQUESTS = 'GET_SUMMARY_REQUESTS'
export const GET_SUMMARY_REQUESTS_FULFILLED = 'GET_SUMMARY_REQUESTS_FULFILLED'
export const GET_SUMMARY_REQUESTS_REJECTED = 'GET_SUMMARY_REQUESTS_REJECTED'

export const GET_SUMMARY_RESERVATIONS = 'GET_SUMMARY_RESERVATIONS'
export const GET_SUMMARY_RESERVATIONS_FULFILLED = 'GET_SUMMARY_RESERVATIONS_FULFILLED'
export const GET_SUMMARY_RESERVATIONS_REJECTED = 'GET_SUMMARY_RESERVATIONS_REJECTED'

export const GET_SUMMARY_POST_ANALYTICS = 'GET_SUMMARY_POST_ANALYTICS'
export const GET_SUMMARY_POST_ANALYTICS_FULFILLED = 'GET_SUMMARY_POST_ANALYTICS_FULFILLED'
export const GET_SUMMARY_POST_ANALYTICS_REJECTED = 'GET_SUMMARY_POST_ANALYTICS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadSummaryReports = () => {
  return dispatch => {
    dispatch({
      type: GET_SUMMARY_REPORTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/summary-reports`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_SUMMARY_REPORTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_SUMMARY_REPORTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSummaryRequests = () => {
  return dispatch => {
    dispatch({
      type: GET_SUMMARY_REQUESTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/summary-requests`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_SUMMARY_REQUESTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_SUMMARY_REQUESTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSummaryReservations = () => {
  return dispatch => {
    dispatch({
      type: GET_SUMMARY_RESERVATIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/summary-reservations`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_SUMMARY_RESERVATIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_SUMMARY_RESERVATIONS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSummaryPostsAnalytics = () => {
  return dispatch => {
    dispatch({
      type: GET_SUMMARY_POST_ANALYTICS,
      payload: {}
    })
    return Axios.get(`${API_URL}/post-analytic-reports?limit=10`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_SUMMARY_POST_ANALYTICS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_SUMMARY_POST_ANALYTICS_REJECTED,
          payload: error
        })
      })
  }
}





