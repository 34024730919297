import {
  POST_AMENITY,
  POST_AMENITY_FULFILLED,
  POST_AMENITY_REJECTED,
  GET_AMENITIES,
  GET_AMENITIES_FULFILLED,
  GET_AMENITIES_REJECTED,
  PUT_AMENITY,
  PUT_AMENITY_FULFILLED,
  PUT_AMENITY_REJECTED,
  DELETE_AMENITY,
  DELETE_AMENITY_FULFILLED,
  DELETE_AMENITY_REJECTED
} from '../actions'

export default function amenities(state = {
  amenityLoading: false,
  amenitySuccess: false,
  amenityRejected: false,
  amenityData: {},
  amenitiesLoading: false,
  amenitiesSuccess: false,
  amenitiesRejected: false,
  amenitiesData: {
    data: []
  },
  updateAmenityLoading: false,
  updateAmenitySuccess: false,
  updateAmenityRejected: false,
  updateAmenityData: [],
  deleteAmenityLoading: false,
  deleteAmenitySuccess: false,
  deleteAmenityRejected: false,
  deleteAmenityData: []
}, action) {
  switch (action.type) {
    case POST_AMENITY:
      return {
        ...state,
        amenityLoading: true,
        amenitySuccess: false,
        amenityRejected: false
      }
    case POST_AMENITY_FULFILLED:
      return {
        ...state,
        amenityLoading: false,
        amenitySuccess: true,
        amenityData: action.payload
      }
    case POST_AMENITY_REJECTED:
      return {
        ...state,
        amenityLoading: false,
        amenityRejected: true
      }
    case GET_AMENITIES:
      return {
        ...state,
        amenitiesLoading: true,
        amenitiesSuccess: false,
        amenitiesRejected: false
      }
    case GET_AMENITIES_FULFILLED:
      return {
        ...state,
        amenitiesLoading: false,
        amenitiesSuccess: true,
        amenitiesData: action.payload
      }
    case GET_AMENITIES_REJECTED:
      return {
        ...state,
        amenitiesLoading: false,
        amenitiesRejected: true
      }
    case PUT_AMENITY:
      return {
        ...state,
        updateAmenityLoading: true,
        updateAmenitySuccess: false,
        updateAmenityRejected: false
      }
    case PUT_AMENITY_FULFILLED:
      return {
        ...state,
        updateAmenityLoading: false,
        updateAmenitySuccess: true,
        updateAmenityData: action.payload
      }
    case PUT_AMENITY_REJECTED:
      return {
        ...state,
        updateAmenityLoading: false,
        updateAmenityRejected: true
      }
    case DELETE_AMENITY:
      return {
        ...state,
        deleteAmenityLoading: true,
        deleteAmenitySuccess: false,
        deleteAmenityRejected: false
      }
    case DELETE_AMENITY_FULFILLED:
      return {
        ...state,
        deleteAmenityLoading: false,
        deleteAmenitySuccess: true,
        deleteAmenityData: action.payload
      }
    case DELETE_AMENITY_REJECTED:
      return {
        ...state,
        deleteAmenityLoading: false,
        deleteAmenityRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
