import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Divider, Tag } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import '../index.scss'
import { updatePayment, loadPaymentById, loadPaymentSchedules } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import { loadTenant } from '../../../tenants/actions'
import { formatMoney } from '../../../../../utilities/helper'
import { loadAmenity } from '../../../amenities/actions'
import { ImagePresignedUrl } from '../../../../../utilities/renderer'
import { DIRECT_DEPOSIT, DRAGON_PAY, QUICK_WIRE } from '../../../../../utilities/paymentsTypeEnum'
const { Option } = Select;
class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      isLoading: false,
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {},
      showSelectedImageModal: false,
      selectedImageUrl: ''
    }
  }

  handleUpdate(values) {
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id
    const submitData = {
      "remarks": values.remarks,
      "status": this.getStatusEnum(values.status),
      // APPROVED
      "verifiedDate": (values.status === 'VERIFIED' && this.props.selectedRow.status !== this.getStatusEnum(values.status)) && moment(new Date()),
      "verifiedById": (values.status === 'VERIFIED' && this.props.selectedRow.status !== this.getStatusEnum(values.status)) && getUser().id,
    }

    this.props.onUpdatePayment({ ...submitData, id: selectedRowId }, this.props.paymentStatus)
  }

  getStatusEnum(status) {
    switch (status) {
      case 'CREATED':
        return 1
      case 'FOR_VERIFICATION':
        return 2
      case 'VERIFIED':
        return 3
      case 'POSTED':
        return 4
      case 'REJECTED':
        return 5
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'FOR_VERIFICATION'
      case 2:
        return 'FOR_VERIFICATION'
      case 3:
        return 'VERIFIED'
      case 4:
        return 'POSTED'
      case 5:
        return 'REJECTED'
      default:
        break;
    }
  }

  getType(status) {
    switch (status) {
      case DIRECT_DEPOSIT:
        return <Tag color="#2db7f5">DIRECT DEPOSIT</Tag>
      case DRAGON_PAY:
        return <Tag color="#87d068">DRAGON PAY</Tag>
      case QUICK_WIRE:
        return <Tag color="#108ee9">QUICK WIRE</Tag>
      default:
        break;
    }
  }


  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  disabledDateTime() {
    return {
      disabledHours: () => [0, 1, 2, 3],
      disabledMinutes: () => [50, 51],
      disabledSeconds: () => [55, 56],
    };
  }

  onChangePaymentSchedules() {
    this.props.onLoadPaymentSchedules().then((res) => { })
  }

  render() {
    const { selectedRow } = this.props
    const initialValues = {
      remarks: selectedRow.remarks,
      status: this.getStatusLabel(this.props.selectedRow.status),
      verifiedBy: selectedRow.verifier && selectedRow.verifier.name || '',
      verifiedDate: selectedRow.verifiedDate && moment(selectedRow.verifiedDate).format('LLL') || '',
    }

    console.log("selectedRow", selectedRow)
    return (
      <Modal
        title="PAYMENT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={false}>
          <Form
            ref={this.formRef}
            name="basic"
            initialValues={initialValues}
            onFinish={(e) => this.handleUpdate(e)}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>

                <label>Reference Number : {selectedRow.referenceNumber}</label><br />
                <label>Transaction Date : {moment(selectedRow.transactionDate).format('LLL')}</label><br />
                <Divider />
                <label>Client Name : {selectedRow.tenant && selectedRow.tenant.uoccname}</label><br />
                <label>Client Code : {selectedRow.tenant && selectedRow.tenant.clientCode}</label><br />
                <Divider />
                <label>SOA Amount : <span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}>₱ {formatMoney(selectedRow.soaAmount)}</span> </label><br />
                <label>Paid Amount : <span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}>₱ {formatMoney(selectedRow.amountPaid)}</span> </label><br />
                <label>Type : {this.getType(selectedRow.type)}</label><br />
                <Divider />

                <Row>
                  <Col md={24} lg={24} sm={24} xs={24}>
                    {
                      selectedRow.images &&
                      <>
                        <span>Deposit Slip Images</span>
                        <div>
                          <Row gutter={16} className='payment-deposit-slip-container'>
                            {
                              selectedRow.images && selectedRow.images.map((item, index) => {
                                return (
                                  <Col span={4} className='image-col' key={index} onClick={() => {
                                    this.setState({ showSelectedImageModal: true, selectedImageUrl: item.url })
                                  }}>
                                    <div className='image-block'>
                                      {/* <div className='wrapper-delete'>
                                    <Button size='small' onClick={() => this.handleRemoveImage(item.id)}  ><span>Delete</span></Button>
                                  </div> */}
                                      <ImagePresignedUrl src={`${item.url}`} />
                                    </div>
                                  </Col>
                                )
                              })
                            }
                          </Row>
                        </div>
                      </>
                    }

                  </Col>
                </Row>
              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>

                <Form.Item
                  label="Date Verified"
                  name="verifiedDate"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={5} placeholder='Type something here so that the tenant can read your remarks.' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'FOR_VERIFICATION'}>FOR VERIFICATION</Option>
                    {
                      this.access('payments', 'verify') &&
                      <Option value={'VERIFIED'}>VERIFIED</Option>
                    }
                    {
                      this.access('payments', 'posted') &&
                      <Option value={'POSTED'}>POSTED</Option>
                    }
                    {
                      this.access('payments', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>
              </Col>

            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('payments', 'edit') &&
                    <Button type="primary" htmlType="submit" loading={this.props.updatePaymentLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
        <Modal className='deposit-slip-view' width={'100%'} height={'100%'} title={null} visible={this.state.showSelectedImageModal} footer={null} onCancel={() => this.setState({ showSelectedImageModal: false })}>
          <Row>
            <Col sm={24} xs={24} lg={24} md={24} align='center'>
              <ImagePresignedUrl src={`${this.state.selectedImageUrl}`} />
            </Col>
          </Row>
        </Modal>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updatePaymentLoading: state.admin.payment.updatePaymentLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData,
    amenitiesData: state.admin.amenity.amenitiesData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdatePayment: (data, status) => dispatch(updatePayment(data, status)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onLoadPaymentById: (id) => dispatch(loadPaymentById(id)),
    onLoadAmenity: (pagination) => dispatch(loadAmenity(pagination)),
    onLoadPaymentSchedules: (pagination) => dispatch(loadPaymentSchedules(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))
