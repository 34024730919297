
import React from 'react'
import { Row, Col, Layout, Card, Button } from 'antd'
import { connect } from 'react-redux'
import OfficeWoman from '../../../../assets/images/officeWoman.png'
import './index.scss'
import { Link } from 'react-router-dom'
import Memorandums from './memorandums'
import Discover from './discover'
import Services from './services'
import Billings from './billings'
import { loadPostLatest } from '../../announcements/actions'
class Dashboard extends React.Component {

    componentDidMount() {
        this.props.onLoadPostLatest()
    }

    render() {
        const { postLatestData } = this.props
        return (
            <Layout className='client-dashboard-container'>
                <Row className='mb5' gutter={[16, 16]}>
                    <Col lg={17} md={17} sm={24} xs={24}>
                        <Card className='mail-container'>
                            <div className='mail-card'>
                                <div className='mail-image'>
                                    <img src={OfficeWoman} />
                                </div>
                                <div className='mail-content'>
                                    <h2>From the PMO <Link to='/mail'><span>See list</span></Link></h2>
                                    <h1>{postLatestData.title && postLatestData.title.substring(0, 120)}{postLatestData.title && postLatestData.title.length >= 120 && '...'}</h1>
                                    <p>{postLatestData.description && postLatestData.description.substring(0, 250)}{postLatestData.description && postLatestData.description.length >= 250 && '...'}</p>
                                    <div>
                                        <Link to='/mail'>
                                            <Button className='primary-btn'>
                                                READ MORE
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col lg={7} md={7} sm={24} xs={24}>
                        <Billings />
                    </Col>
                </Row>

                <Row className='mb5' gutter={[16, 16]}>
                    <Col lg={17} md={17} sm={24} xs={24}>
                        <Services {...this.props} />
                        <Discover />
                    </Col>
                    <Col lg={7} md={7} sm={24} xs={24} >
                        <Memorandums />
                    </Col>
                </Row>
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        postLatestData: state.client.post.postLatestData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPostLatest: () => dispatch(loadPostLatest())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
