import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker, Space, Card } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitGatePass } from '../../actions'
import SlidingPane from "react-sliding-pane";
import moment from 'moment'
import '../index.scss'
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleSubmit(values) {
    const submitData = {
      "tenantId": getUser().id,
      "bearerName": values.bearerName,
      "dateOfApplication": values.dateOfApplication,
      "dateOfUse": values.dateOfUse,
      "type": this.getTypeEnum(values.type),
      "items": values.items
    }
    this.props.onSubmitGatePass(submitData)
  }

  getTypeEnum(type) {
    switch (type) {
      case 'Delivery':
        return 1
      case 'MoveIn':
        return 2
      case 'MoveOut':
        return 3
      case 'PullOut':
        return 4
      default:
        return 1
    }
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="ADD GATE PASS"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            onFinish={(e) => this.handleSubmit(e)}
            initialValues={{
              items: [{ name: '', quantity: null }]
            }}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Bearer Name"
                  name="bearerName"
                  rules={[{ required: true, message: 'Please input your bearer name!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date Of Application"
                  name="dateOfApplication"
                  rules={[{ required: true, message: 'Please input your date of application!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    className='primary-input'
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  label="Date Of Use"
                  name="dateOfUse"
                  rules={[{ required: true, message: 'Please input your date of date of use!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    className='primary-input'
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  label="Type"
                  name="type"
                // rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select defaultValue='Delivery' className='primary-input-select'>
                    <Select.Option value={'Delivery'}>Delivery</Select.Option>
                    <Select.Option value={'MoveIn'}>Move In</Select.Option>
                    <Select.Option value={'MoveOut'}>Move Out</Select.Option>
                    <Select.Option value={'PullOut'}>Pull Out</Select.Option>
                  </Select>
                </Form.Item>

                <Card title="List of Items">
                  <Form.List name="items">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Missing Name' }]}
                            >
                              <Input placeholder="Name" className='primary-input' />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'quantity']}
                              fieldKey={[field.fieldKey, 'quantity']}
                              rules={[{ required: true, message: 'Missing Quantity' }]}
                            >
                              <Input type="number" placeholder="Quantity" className='primary-input' />
                            </Form.Item>
                            {
                              index !== 0 &&
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            }
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Item
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.addGatePassLoading || this.state.isLoading}
                  >
                    Submit Gate Pass
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.client.services.postLoading,
    addGatePassLoading: state.client.gatePass.addGatePassLoading,

  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitGatePass: (data) => dispatch(submitGatePass(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}