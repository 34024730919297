import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'
import { Layout, Menu, Modal, Input, Row, Col } from 'antd'
import 'antd/dist/antd.css'
import { NotFound } from './notFound'
import Login from './login/component'
import Utilities from './utilities/components'
import WebsiteSettings from './websiteSettings/components'
import TermsAndConditions from './termsAndConditions/components'
import './index.scss'
import {
  SettingOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
const { Footer, Sider, Content, Header } = Layout;
const REACT_APP_NAME = process.env.REACT_APP_NAME

moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })

const paths = [
  {
    default: true,
    exact: true,
    slug: 'Website Settings',
    route: '/super-admin',
    component: WebsiteSettings,
    icon: <SettingOutlined />
  },
  {
    default: true,
    exact: true,
    slug: 'Utilities',
    route: '/super-admin/utilities',
    component: Utilities,
    icon: <SettingOutlined />
  },
  {
    default: true,
    exact: true,
    slug: 'Terms And Conditions',
    route: '/super-admin/terms-and-conditions',
    component: TermsAndConditions,
    icon: <SettingOutlined />
  }
]

const MainLayout = (props) => {
  return (
    <Layout {...props} className='app-layout'>
      {props.children}
    </Layout>
  )
}

const hasLocalStorage = () => {
  let strResult
  if (localStorage.getItem('SUPER_ADMIN_SESSION_TOKEN') !== null) {
    strResult = true
  } else {
    strResult = false
  }
  return strResult
}

class App extends Component {
  constructor() {
    super()
    this.state = {
      isUnauthenticated: false,
      activeTab: 0
    }
  }

  logout() {
    const self = this
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will redirected to login page',
      onOk() {
        localStorage.removeItem('SUPER_ADMIN_SESSION_TOKEN')
        self.props.history.push('/super-admin')
        window.location.reload()
      },
      onCancel() { },
    });
  }

  componentWillMount() {
    window.document.title = ''
    window.document.title = REACT_APP_NAME && `${this.capitalizedFirstChar(REACT_APP_NAME)} Super Admin`

    const currentPathIndex = paths.findIndex(data => data.route === this.props.location.pathname)
    this.setState({ activeTab: currentPathIndex + 1 })

    if (hasLocalStorage()) this.setState({ isUnauthenticated: false })
    else this.setState({ isUnauthenticated: true })
  }

  capitalizedFirstChar(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
      window.location.reload()
    }
  }

  render() {
    switch (this.state.isUnauthenticated) {
      case true:
        return (
          <MainLayout>
            <Login />
          </MainLayout>
        )

      case false:
        return (
          <Layout className='admin-container'>
            <Sider
              style={{
                // overflow: 'auto',
                // height: '100vh',
                // position: 'fixed',
                left: 0,
                background: 'rgb(41,77,240)'
              }}
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={broken => { }}
              onCollapse={(collapsed, type) => { }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px 15px' }}>
                <h1 style={{ color: 'white', textAlign: 'center' }}>Super Admin</h1>
              </div>
              <Menu theme="dark" style={{ background: 'transparent' }} onClick={(e) => {
                this.setState({ activeTab: e.key })
              }} mode="inline" defaultSelectedKeys={[this.state.activeTab.toString()]}>
                {
                  paths.map((data, i) => {
                    return (
                      <Menu.Item key={(i + 1)} icon={data.icon}>
                        <Link to={data.route}>
                          {data.slug}
                        </Link>
                      </Menu.Item>
                    )
                  })
                }
                <Menu.Item icon={<LogoutOutlined />} onClick={() => this.logout()}>
                  Logout
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout>
              {/* <Header className='admin-main-header-container'>
                <Row style={{ width: '100%' }}>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <Input className='search' placeholder='[Search] Ex: Tenants, Tickets, Reservations' />
                  </Col>
                </Row>
              </Header> */}
              <Content className='main-layout-content' style={{ padding: '5vh 2vw' }} >
                <Switch>
                  {/* // this will scroll to top when switch changes. */}
                  {window.scrollTo(0, 0)}
                  {_.map(paths, (path) => (
                    <Route
                      exact={path.exact}
                      path={path.route}
                      component={path.component}
                      key={path.route}
                    />
                  ))}
                  <Route component={NotFound} />
                  <Redirect to='/404' />
                </Switch>
                <div style={{ paddingBottom: '50vh' }} />
              </Content>

              <Footer style={{ background: '#0095c9' }}>
                <div style={{ textAlign: 'center' }}>
                  <span style={{ color: 'white' }}>
                    © 2021 All Rights Reserved.
                  </span>
                </div>
              </Footer>
            </Layout>
          </Layout>

        )
      default:
        break
    }
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.admin.login.loggedIn
  }
}

export default withRouter(
  connect(mapStateToProps, {})(App)
)
