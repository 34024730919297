import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Spin, Card } from 'antd'
import { connect } from 'react-redux'
import { updateAdvertisement, loadAdvertisementById } from '../../actions'
import ImageUploader from './imageBulkUploader'
import { v4 as uuidv4 } from 'uuid';
import '../index.scss'
import { ImagePresignedUrl } from '../../../../../utilities/renderer'
import { upload } from '../../../../../utilities/filesController'

class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileImageUploaded: false,
      fileImageList: [],
      images: []
    }
    this.handleRemoveImage = this.handleRemoveImage.bind(this)
    this.getFileImage = this.getFileImage.bind(this)
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { id: uuidv4(), name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'advertisements').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  componentDidMount() {
    this.setState({ images: this.props.selectedRow && this.props.selectedRow.images || [] })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id)
    const submitData = {
      "title": values.title,
      "description": values.description,
      "url": values.url,
      "images": this.state.fileImageList.concat(this.state.images),
      "status": 1
    }

    Promise.all([
      this.uploadFileImage(),
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onUpdateAdvertisement({ ...submitData, id: selectedRowId })
        this.setState({ isLoading: false })
      } else {
        Modal.error({
          title: 'Unable to submit advertisement',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  handleRemoveImage(id) {
    const imagesArr = this.state.images.filter(data => data.id !== id)
    this.setState({ images: imagesArr })
  }

  render() {
    const { selectedRow } = this.props
    const initialValues = {
      "title": selectedRow.title,
      "url": selectedRow.url,
    }
    return (
      <Modal
        title="EDIT ADVERTISEMENT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            ref={this.formRef}
            name="basic"
            initialValues={initialValues}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Card>
              <Row gutter={12}>
                <Col md={24} lg={24} sm={24} xs={24}>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input your title!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Url"
                    name="url"
                    rules={[{ required: true, message: 'Please input your url!' }]}
                  >
                    <Input />
                  </Form.Item>

                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col md={24} lg={24} sm={24} xs={24}>
                  <span>Advertisement Images</span>
                  <div>
                    <Row gutter={16} className='image-container'>
                      {
                        this.state.images && this.state.images.map((item, index) => {
                          return (
                            <Col span={4} className='image-col' key={index}>
                              <div className='image-block'>
                                <div className='wrapper-delete'>
                                  <Button size='small' onClick={() => this.handleRemoveImage(item.id)}  ><span>Delete</span></Button>
                                </div>
                                <ImagePresignedUrl src={`${item.url}`} />
                              </div>
                            </Col>
                          )
                        })
                      }
                    </Row>
                  </div>
                </Col>
              </Row>
              <h1>Upload</h1>
              <Row gutter={12}>
                <Col lg={12} md={12} xs={24} sm={24}>
                  <ImageUploader
                    value={this.props.selectedRow}
                    getFile={e => this.getFileImage(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Multiple file</span>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('advertisements', 'edit') &&
                    <Button type="primary" htmlType="submit" style={{ marginTop: 8 }} loading={this.props.updateAdvertisementLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateAdvertisementLoading: state.admin.advertisement.updateAdvertisementLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateAdvertisement: (data) => dispatch(updateAdvertisement(data)),
    onLoadAdvertisementById: (id) => dispatch(loadAdvertisementById(id))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))
