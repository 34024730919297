import {
  GET_WEBSITE_SETTINGS,
  GET_WEBSITE_SETTINGS_FULFILLED,
  GET_WEBSITE_SETTINGS_REJECTED
} from '../actions'

import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

// Admin
import login from '../login/reducers'
import dashboard from '../dashboard/reducers'
import tenant from '../tenants/reducers'
import ticket from '../tickets/reducers'
import reservation from '../reservations/reducers'
import serviceRequest from '../serviceRequests/reducers'
import incidentReport from '../incidentReports/reducers'
import post from '../announcements/reducers'
import billing from '../billings/reducers'
import utilities from '../utilities/reducers'
import memorandums from '../memorandums/reducers'
import policies from '../policies/reducers'
import permits from '../permits/reducers'
import visitors from '../visitors/reducers'
import services from '../services/reducers'
import inquiries from '../inquiries/reducers'
import commendations from '../commendations/reducers'
import amenity from '../amenities/reducers'
import advertisement from '../advertisements/reducers'
import payment from '../payments/reducers'
import feedbacks from '../feedbacks/reducers'

const rootReducer = combineReducers({
  form,
  amenity,
  login,
  dashboard,
  tenant,
  ticket,
  reservation,
  serviceRequest,
  incidentReport,
  post,
  billing,
  utilities,
  memorandums,
  policies,
  websiteSettings,
  permits,
  visitors,
  services,
  inquiries,
  commendations,
  advertisement,
  payment,
  feedbacks
})

// Website settings
function websiteSettings(state = {
  websiteSettingsLoading: false,
  websiteSettingsSuccess: false,
  websiteSettingsRejected: false,
  websiteSettingsData: []
}, action) {
  switch (action.type) {
    case GET_WEBSITE_SETTINGS:
      return {
        ...state,
        websiteSettingsLoading: true,
        websiteSettingsSuccess: false,
        websiteSettingsRejected: false
      }
    case GET_WEBSITE_SETTINGS_FULFILLED:
      return {
        ...state,
        websiteSettingsLoading: false,
        websiteSettingsSuccess: true,
        websiteSettingsData: action.payload
      }
    case GET_WEBSITE_SETTINGS_REJECTED:
      return {
        ...state,
        websiteSettingsLoading: false,
        websiteSettingsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}

export default rootReducer
