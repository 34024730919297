import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Pagination, Tag } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadTicket, deleteTicket, loadSelectedRowGatePass } from '../actions'
import moment from 'moment'
import { getParamFromUrl } from '../../../../utilities/helper'
import Papa from 'papaparse'
import { getUser } from '../../../../utilities/token'
import {
  DELETED,
  APPROVAL,
  APPROVED,
  ONGOING,
  COMPLETED,
  CANCELLED,
  REJECTED
} from '../../../../utilities/serviceStatusEnum'
const { Search } = Input;
const { TabPane } = Tabs;
class Tickets extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      ticketType: '0',

      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      selectedRowKeys: [],
      downloadLoading: false,
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { limit, page, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if ((this.props.ticketSuccess !== nextProps.ticketSuccess) && nextProps.ticketSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Gate Pass added successfully.")
      this.props.onLoadTicket(this.getKeyConvertToStatus(this.state.ticketType), pagination)

    }
    if ((this.props.updateTicketSuccess !== nextProps.updateTicketSuccess) && nextProps.updateTicketSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Gate Pass edited successfully.")
      this.props.onLoadTicket(this.getKeyConvertToStatus(this.state.ticketType), pagination)

    }
    if ((this.props.deleteTicketSuccess !== nextProps.deleteTicketSuccess) && nextProps.deleteTicketSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Gate Pass deleted successfully.")
      this.props.onLoadTicket(this.getKeyConvertToStatus(this.state.ticketType), pagination)

    }
    if ((this.props.ticketsSuccess !== nextProps.ticketsSuccess) && nextProps.ticketsSuccess) {
      this.setState({ tableData: nextProps.ticketsData.data })
    }
  }

  getKeyConvertToStatus(key) {
    let status
    switch (key) {
      case '0':
        status = ''
        break;
      case '1':
        status = APPROVAL
        break;
      case '2':
        status = APPROVED
        break;
      case '3':
        status = CANCELLED
        break;
      case '4':
        status = REJECTED
        break;
      default:
        break;
    }
    return status
  }
  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    this.props.onLoadTicket(this.getKeyConvertToStatus(this.state.ticketType), pagination)

    if (getParamFromUrl('modal') === 'true') {
      this.setState({ isEditModalOpen: true })
    }

    window.Echo.channel(`admin-tickets-crud.${getUser().id}`)
      .listen('.admin-tickets-crud-channel', (response) => {
        const { data } = response
        const index = this.state.tableData.findIndex(item => item.id === data.id)
        if (data.status === 0) {
          this.state.tableData.splice(index, 1) // delete
        } else {
          if (index !== -1) this.state.tableData[index] = data // update
          else {
            this.state.tableData.unshift({ ...data }) // create
            this.state.tableData = this.state.tableData.slice(0, this.state.limit) // re-assigning and limit to 10
          }
        }
        // this.setState({tableData: this.state.tableData})
        // this.setState are not working inside echo channel, 
        // regarding to this. <Table key/> key must be unique to know if there was a changed.
        this.forceUpdate()
      })
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteTicket(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteTicket(id, self.getTicketType(self.state.ticketType))
      },
      onCancel() { },
    });
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadTicket(this.getKeyConvertToStatus(this.state.ticketType), pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  countdownRenderer(props) {
    const { days, hours, minutes, seconds, completed } = props
    if (completed) {
      // Render a completed state
      return <div >
        <span style={{ fontWeight: 'bold', color: 'gray' }} >{moment(props.props.originalValue).format("LLLL")}</span><br />
        <span style={{ color: '#ff695e', fontWeight: 'bold', }}>(Expired)</span>
      </div>;
    } else {
      // Render a countdown
      return <span style={{ fontWeight: 'bold', color: '#1890FF' }}>{days}Days {hours}:{minutes}:{seconds}</span>;
    }
  };

  getTicketType(e) {
    let type
    switch (e) {
      case '0':
        type = ''
        break;
      case '1':
        type = 'approval'
        break;
      case '2':
        type = 'approved'
        break;
      case '3':
        type = 'cancelled'
        break;
      case '4':
        type = 'rejected'
        break;
      default:
        break;
    }
    return type
  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadTicket(this.getKeyConvertToStatus(this.state.ticketType), pagination)
    this.setState({ page: page })
  }

  handleTicketType(e) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: 1,
      limit: limit,
      searchTerm: searchTerm
    }

    this.setState({ ticketType: e.toString(), page: 1 })
    this.props.onLoadTicket(this.getKeyConvertToStatus(e), pagination)
  }


  getStatus(status) {
    switch (status) {
      case 1:
        return <Tag color="gold">FOR APPROVAL</Tag>
      case 2:
        return <Tag color="lime">APPROVED</Tag>
      case 3:
        return <Tag color="geekblue">ONGOING</Tag>
      case 4:
        return <Tag color="green">COMPLETED</Tag>
      case 5:
        return <Tag color="magenta">CANCELLED</Tag>
      case 6:
        return <Tag color="red">REJECTED</Tag>
      default:
        break;
    }
  }

  handleDownloadSelectedRowToCSV() {
    this.setState({ downloadLoading: true })
    this.props.onLoadSelectedRowGatePass(this.state.selectedRowKeys).then(res => {

      function returnItems(data) {
        let itemArray = []
        data && data.map(item => {
          item && itemArray.push(`${item && item.name}-${item && item.quantity}`)
        })
        return itemArray.toString()
      }

      const newData = res.payload.map(data => {
        console.log("returnItems(data.items)", returnItems(data.items))
        return {
          id: data.id,
          ticketNumber: data.ticketNumber,
          clientCode: data.tenant && data.tenant.clientCode,
          clientName: data.tenant && data.tenant.uoccname,
          bearerName: data.bearerName,
          completedDate: moment(data.completedDate).format('DD/MM/YYYY'),
          dateOfApplication: moment(data.dateOfApplication).format('DD/MM/YYYY'),
          dateOfUse: moment(data.dateOfUse).format('DD/MM/YYYY'),
          createdAt: moment(data.created_at).format('DD/MM/YYYY'),
          items: returnItems(data.items),
          remarks: data.remarks,
          status: this.getStatusForCsv(data.status),
          type: this.getTypeForCsv(data.type),
        }
      })
      var csv = Papa.unparse(newData);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, 'Gatepass Reports.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'Gatepass Reports.csv');
      tempLink.click();

    }).finally(() => {
      this.setState({ downloadLoading: false })
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  getStatusForCsv(status) {
    switch (status) {
      case 1:
        return 'FOR APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  getTypeForCsv(status) {
    switch (status) {
      case 1:
        return 'DELIVERY'
      case 2:
        return 'MOVE IN'
      case 3:
        return 'MOVE OUT'
      case 4:
        return 'PULL OUT'
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }


  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      preserveSelectedRowKeys: true
    };
    const dataSource = this.state.tableData
    const columns = [

      {
        title: 'Service Ticket #',
        dataIndex: 'ticketNumber',
        key: 'ticketNumber',
        sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
      },
      {
        title: 'Client Code',
        dataIndex: 'tenant',
        key: 'clientCode',
        sorter: (a, b) => a.tenant.clientCode && a.tenant.clientCode.localeCompare(b.tenant && b.tenant.clientCode),
        render: (item, data) => {
          return (
            <span>{data.tenant && `${data.tenant.clientCode || ''}`}</span>
          )
        }
      },
      {
        title: 'Client Name',
        dataIndex: 'tenant',
        key: 'tenant',
        sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
        render: (item, data) => {
          return (
            <span>{data.tenant && `${data.tenant.uoccname || ''}`}</span>
          )
        }
      },
      {
        title: 'Bearer Name',
        dataIndex: 'bearerName',
        key: 'bearerName',
        sorter: (a, b) => a.bearerName && a.bearerName.localeCompare(b.bearerName),
      },
      {
        title: 'Date Of Use',
        dataIndex: 'dateOfUse',
        key: 'dateOfUse',
        sorter: (a, b) => new Date(b.dateOfUse) - new Date(a.dateOfUse),
        render: (item, data) => {
          return (
            <span>{moment(item).format('LLLL')}</span>
          )
        }
      },
      {
        title: 'Date Of Application',
        dataIndex: 'dateOfApplication',
        key: 'dateOfApplication',
        sorter: (a, b) => new Date(b.dateOfApplication) - new Date(a.dateOfApplication),
        render: (item, data) => {
          return (
            <span>{moment(item).format('LLLL')}</span>
          )
        }
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (item) => {
          switch (item) {
            case 1:
              return <Tag color="#474aff">DELIVERY</Tag>
            case 2:
              return <Tag color="#12b86a">MOVE IN</Tag>
            case 3:
              return <Tag color="red">MOVE OUT</Tag>
            case 4:
              return <Tag color="magenta">PULL OUT</Tag>
            // case 4:
            //   return <div style={{ background: '#baae00', textAlign: 'center', borderRadius: 8 }}><span style={{ color: 'white' }}>Turn Over</span></div>
            default:
              break;
          }
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (item) => {
          return (<span>{this.getStatus(item)}</span>)
        }
      },
      {
        title: 'Actions',
        dataIndex: 'status',
        key: 'status',
        render: (info, data) => {
          switch (info) {
            case 1:
              return (
                <div className='action-btn'>
                  {
                    this.access('gatepass', 'view') &&
                    <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} >View</Button>
                  }
                  {
                    this.access('gatepass', 'delete') &&
                    <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteTicket(data.id)} >Delete</Button>
                  }
                </div>
              )
            case 2:
            case 3:
            case 4:
            case 6:
              return <div className='action-btn'>
                {
                  this.access('gatepass', 'view') &&
                  <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} >View</Button>
                }
              </div>
            default:
              break;
          }
        }
      },
    ];
    return (
      <Layout className='tickets-container'>

        <Layout.Content>
          <div className='ticket-title'>
            <h1>Gate Pass</h1>
          </div>
          <Tabs defaultActiveKey="0" type="card" size='small' onChange={e => this.handleTicketType(e)}>
            <TabPane tab="All" key="0" />
            <TabPane tab="Approval" key="1" />
            <TabPane tab="Approved" key="2" />
            <TabPane tab="Cancelled" key="3" />
            <TabPane tab="Rejected" key="4" />
          </Tabs>
          <Row>
            <Col md={14} lg={14} sm={24} xs={24}>
              <div className='mt3 mb3'>
                <Button type='primary' onClick={() => this.handleDownloadSelectedRowToCSV()} loading={this.state.downloadLoading}>Download CSV</Button>
              </div>
            </Col>
          </Row>
          <Row className='ticket-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('gatepass', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Ticket Number, Bearer Name, Remarks, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table
              rowSelection={rowSelection}
              key={(JSON.stringify(this.state.tableData))}
              style={{ width: '100%' }} rowKey='id' loading={this.props.ticketsLoading || this.props.ticketLoading || this.props.deleteTicketLoading} dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.ticketsData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            ticketType={this.getTicketType(this.state.ticketType)}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            ticketType={this.getTicketType(this.state.ticketType)}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    ticketLoading: state.admin.ticket.ticketLoading,
    ticketSuccess: state.admin.ticket.ticketSuccess,
    updateTicketSuccess: state.admin.ticket.updateTicketSuccess,
    deleteTicketSuccess: state.admin.ticket.deleteTicketSuccess,
    ticketsSuccess: state.admin.ticket.ticketsSuccess,
    ticketsData: state.admin.ticket.ticketsData,
    ticketsLoading: state.admin.ticket.ticketsLoading,
    deleteTicketLoading: state.admin.ticket.deleteTicketLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadTicket: (status, pagination) => dispatch(loadTicket(status, pagination)),
    onDeleteTicket: (id, type) => dispatch(deleteTicket(id, type)),
    onLoadSelectedRowGatePass: (selectedKeys) => dispatch(loadSelectedRowGatePass(selectedKeys))

  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Tickets))
