import {
  GET_FEEDBACKS,
  GET_FEEDBACKS_FULFILLED,
  GET_FEEDBACKS_REJECTED,
} from '../actions'

export default function feedbacks(state = {
  feedbacksLoading: false,
  feedbacksSuccess: false,
  feedbacksRejected: false,
  feedbacksData: [],
}, action) {
  switch (action.type) {
    case GET_FEEDBACKS:
      return {
        ...state,
        feedbacksLoading: true,
        feedbacksSuccess: false,
        feedbacksRejected: false
      }
    case GET_FEEDBACKS_FULFILLED:
      return {
        ...state,
        feedbacksLoading: false,
        feedbacksSuccess: true,
        feedbacksData: action.payload
      }
    case GET_FEEDBACKS_REJECTED:
      return {
        ...state,
        feedbacksLoading: false,
        feedbacksRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
