import {
  POST_POST,
  POST_POST_FULFILLED,
  POST_POST_REJECTED,
  GET_POSTS,
  GET_POSTS_FULFILLED,
  GET_POSTS_REJECTED,
  PUT_POST,
  PUT_POST_FULFILLED,
  PUT_POST_REJECTED,
  DELETE_POST,
  DELETE_POST_FULFILLED,
  DELETE_POST_REJECTED
} from '../actions'

export default function posts(state = {
  postLoading: false,
  postSuccess: false,
  postRejected: false,
  postData: [],
  postsLoading: false,
  postsSuccess: false,
  postsRejected: false,
  postsData: [],
  updatePostLoading: false,
  updatePostSuccess: false,
  updatePostRejected: false,
  updatePostData: [],
  deletePostLoading: false,
  deletePostSuccess: false,
  deletePostRejected: false,
  deletePostData: []
}, action) {
  switch (action.type) {
    case POST_POST:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
        postRejected: false
      }
    case POST_POST_FULFILLED:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
        postData: action.payload
      }
    case POST_POST_REJECTED:
      return {
        ...state,
        postLoading: false,
        postRejected: true
      }
    case GET_POSTS:
      return {
        ...state,
        postsLoading: true,
        postsSuccess: false,
        postsRejected: false
      }
    case GET_POSTS_FULFILLED:
      action.payload.data.map(data => {
        data.status = data.status === 1 ? 'Active' : 'Inactive'
      })
      return {
        ...state,
        postsLoading: false,
        postsSuccess: true,
        postsData: action.payload
      }
    case GET_POSTS_REJECTED:
      return {
        ...state,
        postsLoading: false,
        postsRejected: true
      }
    case PUT_POST:
      return {
        ...state,
        updatePostLoading: true,
        updatePostSuccess: false,
        updatePostRejected: false
      }
    case PUT_POST_FULFILLED:
      return {
        ...state,
        updatePostLoading: false,
        updatePostSuccess: true,
        updatePostData: action.payload
      }
    case PUT_POST_REJECTED:
      return {
        ...state,
        updatePostLoading: false,
        updatePostRejected: true
      }
    case DELETE_POST:
      return {
        ...state,
        deletePostLoading: true,
        deletePostSuccess: false,
        deletePostRejected: false
      }
    case DELETE_POST_FULFILLED:
      return {
        ...state,
        deletePostLoading: false,
        deletePostSuccess: true,
        deletePostData: action.payload
      }
    case DELETE_POST_REJECTED:
      return {
        ...state,
        deletePostLoading: false,
        deletePostRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
