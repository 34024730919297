import {
  POST_REQUEST,
  POST_REQUEST_FULFILLED,
  POST_REQUEST_REJECTED,
  POST_GATEPASS,
  POST_GATEPASS_FULFILLED,
  POST_GATEPASS_REJECTED,
  POST_RESERVATION,
  POST_RESERVATION_FULFILLED,
  POST_RESERVATION_REJECTED,
  POST_REPORT,
  POST_REPORT_FULFILLED,
  POST_REPORT_REJECTED,
  GET_UTILITY_SERVICES,
  GET_UTILITY_SERVICES_FULFILLED,
  GET_UTILITY_SERVICES_REJECTED,
  GET_REQUEST,
  GET_REQUEST_FULFILLED,
  GET_REQUEST_REJECTED,
  PUT_REQUESTS,
  PUT_REQUESTS_FULFILLED,
  PUT_REQUESTS_REJECTED,
  DELETE_REQUEST,
  DELETE_REQUEST_FULFILLED,
  DELETE_REQUEST_REJECTED
} from '../actions'

export default function services(state = {
  scheduledBusesLoading: false,
  scheduledBusesSuccess: false,
  scheduledBusesRejected: false,
  ticketsLoading: false,
  ticketsSuccess: false,
  ticketsRejected: false,
  ticketsData: [],
  reservationLoading: false,
  reservationSuccess: false,
  reservationRejected: false,
  reservationDate: [],
  reportLoading: false,
  reportSuccess: false,
  reportRejected: false,
  reportData: [],
  utilityServicesLoading: false,
  utilityServicesSuccess: false,
  utilityServicesRejected: false,
  utilityServicesData: [],
  updateRequestLoading: false,
  updateRequestSuccess: false,
  updateRequestRejected: false,
  updateRequestData: [],
  deleteRequestLoading: false,
  deleteRequestSuccess: false,
  deleteRequestRejected: false,
  requestsLoading: false,
  requestsSuccess: false,
  requestsData: {
    data: []
  },
  updateRequestLoading: false,
  updateRequestSuccess: false,
  updateRequestRejected: false
}, action) {
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        requestsLoading: true,
        requestsSuccess: false,
        requestsRejected: false
      }
    case GET_REQUEST_FULFILLED:
      return {
        ...state,
        requestsLoading: false,
        requestsSuccess: true,
        requestsData: action.payload
      }
    case GET_REQUEST_REJECTED:
      return {
        ...state,
        requestsLoading: false,
        requestsRejected: true
      }
    case PUT_REQUESTS:
      return {
        ...state,
        updateRequestLoading: true,
        updateRequestSuccess: false,
        updateRequestRejected: false
      }
    case PUT_REQUESTS_FULFILLED:
      return {
        ...state,
        updateRequestLoading: false,
        updateRequestSuccess: true,
        updateRequestData: action.payload
      }
    case PUT_REQUESTS_REJECTED:
      return {
        ...state,
        updateRequestLoading: false,
        updateRequestRejected: true
      }
    case POST_REQUEST:
      return {
        ...state,
        requestLoading: true,
        requestSuccess: false,
        requestRejected: false
      }
    case POST_REQUEST_FULFILLED:
      return {
        ...state,
        requestLoading: false,
        requestSuccess: true,
        requestData: action.payload
      }
    case POST_REQUEST_REJECTED:
      return {
        ...state,
        requestLoading: false,
        requestRejected: true
      }
    case POST_GATEPASS:
      return {
        ...state,
        ticketsLoading: true,
        ticketsSuccess: false,
        ticketsRejected: false
      }
    case POST_GATEPASS_FULFILLED:
      return {
        ...state,
        ticketsLoading: false,
        ticketsSuccess: true,
        ticketsData: action.payload
      }
    case POST_GATEPASS_REJECTED:
      return {
        ...state,
        ticketsLoading: false,
        ticketsRejected: true
      }
    case POST_RESERVATION:
      return {
        ...state,
        reservationLoading: true,
        reservationSuccess: false,
        reservationRejected: false
      }
    case POST_RESERVATION_FULFILLED:
      return {
        ...state,
        reservationLoading: false,
        reservationSuccess: true,
        reservationData: action.payload
      }
    case POST_RESERVATION_REJECTED:
      return {
        ...state,
        reservationLoading: false,
        reservationRejected: true
      }
    case POST_REPORT:
      return {
        ...state,
        reportLoading: true,
        reportSuccess: false,
        reportRejected: false
      }
    case POST_REPORT_FULFILLED:
      return {
        ...state,
        reportLoading: false,
        reportSuccess: true,
        reportData: action.payload
      }
    case POST_REPORT_REJECTED:
      return {
        ...state,
        reportLoading: false,
        reportRejected: true
      }
    case GET_UTILITY_SERVICES:
      return {
        ...state,
        utilityServicesLoading: true,
        utilityServicesSuccess: false,
        utilityServicesRejected: false
      }
    case GET_UTILITY_SERVICES_FULFILLED:
      return {
        ...state,
        utilityServicesLoading: false,
        utilityServicesSuccess: true,
        utilityServicesData: action.payload
      }
    case GET_UTILITY_SERVICES_REJECTED:
      return {
        ...state,
        utilityServicesLoading: false,
        utilityServicesRejected: true
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleteRequestLoading: true,
        deleteRequestSuccess: false,
        deleteRequestRejected: false
      }
    case DELETE_REQUEST_FULFILLED:
      return {
        ...state,
        deleteRequestLoading: false,
        deleteRequestSuccess: true,
        deleteRequestData: action.payload
      }
    case DELETE_REQUEST_REJECTED:
      return {
        ...state,
        deleteRequestLoading: false,
        deleteRequestRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
