export const defaultRoles = [
    {
        title: 'dashboard',
        visible: false,
        roles: {
            view: false
        }
    },
    {
        title: 'amenities',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'advertisements',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'announcements',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            verify: false,
            approve: false,
            thread: false
        }
    },
    {
        title: 'tenants',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'memorandums',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'policies',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
        }
    },
    {
        title: 'services',
        visible: false,
        roles: {
            view: false
        }
    },
    {
        title: 'orders',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'gatepass',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,

        }
    },
    {
        title: 'reservations',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'reports',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'permits',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'visitors',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'commendations',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'inquiries',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            ongoing: false,
            complete: false,
            cancel: false,
            reject: false,
            verify: false,
            approve: false,
        }
    },
    {
        title: 'billings',
        visible: false,
        roles: {
            view: false
        }
    },
    {
        title: 'payments',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            verify: false,
            posted: false,
            reject: false,
        }
    },
    {
        title: 'utilities',
        visible: false,
        roles: {
            view: false,
            add: false,
            edit: false,
            delete: false,
            importDataFromPromis: false,
            exportDataToCsv: false,
            userAccessDefinition: false,
            systemParameterSettings: false,
            manageAccess: false
        }
    },
    {
        title: 'loggedInLogs',
        visible: true,
        roles: {
            view: true,
        }
    },
    {
        title: 'feedbacks',
        visible: true,
        roles: {
            view: true,
        }
    },

]