import {
  GET_WEBSITE_SETTINGS,
  GET_WEBSITE_SETTINGS_FULFILLED,
  GET_WEBSITE_SETTINGS_REJECTED,
  POST_WEBSITE_SETTINGS,
  POST_WEBSITE_SETTINGS_FULFILLED,
  POST_WEBSITE_SETTINGS_REJECTED,
  GET_MOBILE_SETTINGS,
  GET_MOBILE_SETTINGS_FULFILLED,
  GET_MOBILE_SETTINGS_REJECTED
} from '../actions'

export default function websiteSettings(state = {
  websiteSettingsLoading: false,
  websiteSettingsSuccess: false,
  websiteSettingsRejected: false,
  websiteSettingsData: [],
  updateWebsiteSettingsLoading: false,
  updateWebsiteSettingsSuccess: false,
  updateWebsiteSettingsData: [],
  mobileSettingsLoading: false,
  mobileSettingsSuccess: false,
  mobileSettingsRejected: false,
  mobileSettingsData: {}
}, action) {
  switch (action.type) {
    case GET_WEBSITE_SETTINGS:
      return {
        ...state,
        websiteSettingsLoading: true,
        websiteSettingsSuccess: false,
        websiteSettingsRejected: false
      }
    case GET_WEBSITE_SETTINGS_FULFILLED:
      return {
        ...state,
        websiteSettingsLoading: false,
        websiteSettingsSuccess: true,
        websiteSettingsData: action.payload
      }
    case GET_WEBSITE_SETTINGS_REJECTED:
      return {
        ...state,
        websiteSettingsLoading: false,
        websiteSettingsRejected: true
      }
    case GET_MOBILE_SETTINGS:
      return {
        ...state,
        mobileSettingsLoading: true,
        mobileSettingsSuccess: false,
        mobileSettingsRejected: false
      }
    case GET_MOBILE_SETTINGS_FULFILLED:
      return {
        ...state,
        mobileSettingsLoading: false,
        mobileSettingsSuccess: true,
        mobileSettingsData: action.payload
      }
    case GET_MOBILE_SETTINGS_REJECTED:
      return {
        ...state,
        mobileSettingsLoading: false,
        mobileSettingsRejected: true
      }
    case POST_WEBSITE_SETTINGS:
      return {
        ...state,
        updateWebsiteSettingsLoading: true,
        updateWebsiteSettingsSuccess: false,
        updateWebsiteSettingsRejected: false
      }
    case POST_WEBSITE_SETTINGS_FULFILLED:
      return {
        ...state,
        updateWebsiteSettingsLoading: false,
        updateWebsiteSettingsSuccess: true,
        updateWebsiteSettingsData: action.payload
      }
    case POST_WEBSITE_SETTINGS_REJECTED:
      return {
        ...state,
        updateWebsiteSettingsLoading: false,
        updateWebsiteSettingsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
