import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, DatePicker } from 'antd'
import { connect } from 'react-redux'
import { submitMemorandum } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import moment from 'moment'
import PdfBulkUploader from './pdfBulkUploader'
import { upload } from '../../../../../utilities/filesController'
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      pdfFilesArray: []
    }
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "memoNumber": values.memoNumber,
      "title": values.title,
      "description": values.description,
      "date": values.date,
    }

    if (this.state.pdfFilesArray.length === 0) {
      this.setState({ isLoading: false })
      this.props.onSubmitMemorandum({ ...submitData })
    } else {
      this.setState({ isLoading: false })
      this.uploadFilePdf().then(res => {
        this.props.onSubmitMemorandum({ ...submitData, files: res })
      })
    }
  }


  uploadFilePdf() {
    this.setState({ isLoading: true })
    return new Promise((resolve, reject) => {
      const returnArray = []
      this.state.pdfFilesArray.map((items, index) => {
        upload(items, items.name, 'pdf').then(data => {
          returnArray.push({ name: items.name, url: `pdf/${items.name}` })
        }).then(() => {
          if (this.state.pdfFilesArray.length === index + 1) {
            this.setState({ isLoading: false })
            return resolve(returnArray)
          }
        }).catch(err => {
          this.setState({ isLoading: false })
          return Modal.error({
            title: 'This is an error message for uploading images',
            content: err,
          });
        })
      })
    })
  }

  getPdfFileArray(e) {
    this.setState({ pdfFilesArray: e })
  }


  render() {
    return (
      <Modal
        title="ADD MEMORANDUM"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={(e) => this.handleSubmit(e)}
        // onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            <Col md={24} lg={24} sm={24} xs={24}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please input your title!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: 'Please input your description!' }]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>

              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Please input your date!' }]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <PdfBulkUploader
                getFile={e => this.getPdfFileArray(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={24} lg={24} sm={24} align={'right'}>
              <Form.Item >
                <Button type="primary" style={{ marginTop: 10 }} htmlType="submit" loading={this.props.memorandumLoading || this.state.isLoading}>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    memorandumLoading: state.admin.memorandums.memorandumLoading,
    tenantsData: state.admin.memorandums.tenantsData,
    tenantsLoading: state.admin.memorandums.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitMemorandum: (data, status) => dispatch(submitMemorandum(data, status)),
    onLoadTenant: (data) => dispatch(loadTenant(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}