import React, { useEffect, useState } from 'react'
import { get } from '../filesController'

const ImagePresignedUrl = (props) => {
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    useEffect(() => {
        setLoading(true)
        async function getData() {
            const fetchedUrl = await get(props.src)
            setUrl(fetchedUrl)
            setLoading(false)
        }
        getData()
    }, [props.src])

    return (
        (!loading) && <img
            src={url}
            id={props.id}
            style={{ ...props.style, display: show ? '' : 'none' }}
            className={props.className}
            onLoad={() => setShow(true)}
            onError={() => setShow(false)}
        />
    )

}

export default ImagePresignedUrl