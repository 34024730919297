
import React from 'react'
import { Layout, List, Divider, Row, Col, Collapse, Card } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.scss'
import _ from 'lodash'
import { loadPolicies } from '../actions'
import { IframePresignedUrl } from '../../../../utilities/renderer'

const { Panel } = Collapse;
class Policies extends React.Component {
    constructor() {
        super()
        this.getSubHeaderList = this.getSubHeaderList.bind(this)
    }
    componentDidMount() {
        this.props.onLoadPolicies()
    }

    getSubHeaderList() {
        let policies = []
        this.props.policiesData.map(item => {
            const combinedArray = [...policies, ...item.policies]
            policies = combinedArray
        })
        return policies
    }

    handleScrollView(id) {
        var elmnt = document.getElementById(`policies-${id}`);
        elmnt.scrollIntoView(true);
        window.scrollBy(0, -140);
    }

    render() {
        return (
            <Layout className='policies-container'>
                <Row gutter={12}>
                    <Col md={24} lg={18} sm={24} xs={24}>
                        <List
                            className='policies-list'
                            itemLayout="horizontal"
                            dataSource={this.props.policiesData}
                            loading={this.props.policiesLoading}
                            style={{ marginBottom: 10 }}
                            renderItem={data => {
                                var a = _.groupBy(data.policies, function (n) {
                                    return n.subHeader;
                                })
                                return (
                                    <List.Item>
                                        <Card title={data.header} style={{ width: '100%' }}>
                                            {
                                                Object.keys(a).map(function (key, index) {
                                                    return (
                                                        <div key={index}>
                                                            {
                                                                a[key].map((item, index) => {
                                                                    return (
                                                                        <div id={`policies-${item.id}`} key={index}>
                                                                            {
                                                                                index === 0 &&
                                                                                <Row>
                                                                                    <Col md={24} lg={24} xs={24} sm={24}>
                                                                                        <p style={{ color: 'gray', fontWeight: 'bold' }}>{item.subHeader}</p>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                            <Row>
                                                                                <Col md={24} lg={24} xs={24} sm={24}>
                                                                                    <p style={{ color: 'gray', fontStyle: 'italic' }}>{item.title}</p>
                                                                                    <Collapse bordered={false} ghost>
                                                                                        {
                                                                                            item.files && item.files.map((data, index) => {
                                                                                                return (
                                                                                                    <Panel header={data.name} key={index} style={{ width: '100%' }}>
                                                                                                        <Row style={{ width: '100%' }}>
                                                                                                            <Col md={24} lg={24} xs={24} sm={24} align='center'>
                                                                                                                <IframePresignedUrl src={`${data.url}#view=FitH`} style={{ width: '100%' }}
                                                                                                                    scrolling="yes" height={600} frameBorder="0" />
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </Panel>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Collapse>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <Divider />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Card>
                                    </List.Item>
                                )
                            }}
                        />
                    </Col>
                    <Col className='summary' md={24} lg={6} xs={24} sm={24}>
                        <Card title="Policies" >
                            <Collapse defaultActiveKey={'1'} bordered={false} ghost>
                                <Panel header="Lists" key="1" >
                                    {
                                        this.getSubHeaderList().map((item, index) => {
                                            return (
                                                <div className='item-list' key={index}>
                                                    <h1 onClick={() => this.handleScrollView(item.id)}>{item.title}</h1>
                                                    <Divider />
                                                </div>
                                            )
                                        })
                                    }
                                </Panel>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        policiesData: state.client.policies.policiesData,
        policiesLoading: state.client.policies.policiesLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPolicies: () => dispatch(loadPolicies()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Policies))
