import {
  GET_COMMENTS,
  GET_COMMENTS_FULFILLED,
  GET_COMMENTS_REJECTED,
} from '../actions'

export default function comments(state = {
  commentsLoading: false,
  commentsSuccess: false,
  commentsRejected: false,
  commentsData: {
    data: []
  },

}, action) {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        commentsLoading: true,
        commentsSuccess: false,
        commentsRejected: false
      }
    case GET_COMMENTS_FULFILLED:
      return {
        ...state,
        commentsLoading: false,
        commentsSuccess: true,
        commentsData: action.payload
      }
    case GET_COMMENTS_REJECTED:
      return {
        ...state,
        commentsLoading: false,
        commentsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
