import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Tag, Pagination } from 'antd'
import { ExclamationCircleOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatMoney } from '../../../../utilities/helper'
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadPayment, deletePayment, approvePayment, loadSelectedRowTransactions } from '../actions'
import Papa from 'papaparse'
import moment from 'moment'
import { DIRECT_DEPOSIT, DRAGON_PAY, QUICK_WIRE } from '../../../../utilities/paymentsTypeEnum'
import { CREATED, FOR_VERIFICATION, VERIFIED, POSTED, REJECTED } from '../../../../utilities/paymentsStatusEnum'
const { Search } = Input;
const { TabPane } = Tabs;
class Payments extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      downloadLoading: false,
      paymentStatus: '0',

      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      selectedRowKeys: [],
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    if ((this.props.paymentSuccess !== nextProps.paymentSuccess) && nextProps.paymentSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Payment added successfully.")
      this.props.onLoadPayment(this.getKeyConvertToStatus(this.state.paymentStatus), pagination)
    }
    if ((this.props.updatePaymentSuccess !== nextProps.updatePaymentSuccess) && nextProps.updatePaymentSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Payment edited successfully.")
      this.props.onLoadPayment(this.getKeyConvertToStatus(this.state.paymentStatus), pagination)
    }
    if ((this.props.deletePaymentSuccess !== nextProps.deletePaymentSuccess) && nextProps.deletePaymentSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Payment deleted successfully.")
      this.props.onLoadPayment(this.getKeyConvertToStatus(this.state.paymentStatus), pagination)
    }
    if ((this.props.approvePaymentSuccess !== nextProps.approvePaymentSuccess) && nextProps.approvePaymentSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Payment approved successfully.")
      this.props.onLoadPayment(this.getKeyConvertToStatus(this.state.paymentStatus), pagination)
    }
    if ((this.props.paymentsSuccess !== nextProps.paymentsSuccess) && nextProps.paymentsSuccess) {
      this.setState({ tableData: nextProps.paymentsData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadPayment(this.getKeyConvertToStatus(this.state.paymentStatus), pagination)
  }

  getKeyConvertToStatus(key) {
    let status
    switch (key) {
      case '0':
        status = ''
        break;
      case '1':
        status = CREATED
        break;
      case '2':
        status = FOR_VERIFICATION
        break;
      case '3':
        status = VERIFIED
        break;
      case '4':
        status = POSTED
        break;
      case '5':
        status = REJECTED
        break;
      default:
        break;
    }
    return status
  }
  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadPayment(this.getKeyConvertToStatus(this.state.paymentStatus), pagination)

    this.setState({ page, limit })
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeletePayment(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeletePayment(id, self.getPaymentStatus(self.state.paymentStatus))
      },
      onCancel() { },
    });
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadPayment(this.getPaymentStatus(this.state.paymentStatus), pagination)

    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  getPaymentStatus(e) {
    let type
    switch (e) {
      case '0':
        type = ''
        break;
      case '1':
        type = 'created'
      case '2':
        type = 'for_verification'
        break;
      case '3':
        type = 'verified'
        break;
      case '4':
        type = 'posted'
        break;
      case '5':
        type = 'rejected'
        break;
      default:
        break;
    }
    return type
  }

  getStatus(status) {
    switch (status) {
      case FOR_VERIFICATION:
        return <Tag color="gold">FOR VERIFICATION</Tag>
      case VERIFIED:
        return <Tag color="lime">VERIFIED</Tag>
      case POSTED:
        return <Tag color="geekblue">POSTED</Tag>
      case REJECTED:
        return <Tag color="red">REJECTED</Tag>
      default:
        break;
    }
  }

  getType(status) {
    switch (status) {
      case DIRECT_DEPOSIT:
        return <Tag color="#2db7f5">DIRECT DEPOSIT</Tag>
      case DRAGON_PAY:
        return <Tag color="#87d068">DRAGON PAY</Tag>
      case QUICK_WIRE:
        return <Tag color="#108ee9">QUICK WIRE</Tag>
      default:
        break;
    }
  }

  getTransactionTypeCSV(status) {
    switch (status) {
      case DIRECT_DEPOSIT:
        return 'DD'
      case DRAGON_PAY:
        return 'DP'
      case QUICK_WIRE:
        return 'QW'
      default:
        break;
    }
  }

  handlePaymentStatus(e) {
    const { limit, page, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm,
      selectedRowKeys: [],
    }
    this.setState({ paymentStatus: e.toString() })
    this.props.onLoadPayment(this.getKeyConvertToStatus(e), pagination)
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  handleDownloadSelectedRowToCSV() {
    this.setState({ downloadLoading: true })
    this.props.onLoadSelectedRowTransactions(this.state.selectedRowKeys).then(res => {

      const newData = res.payload.map(data => {
        return {
          trnno: data.referenceNumber,
          trndate: moment(data.transactionDate).format('DD/MM/YYYY'),
          urefno: data.tenant.clientCode,
          clientname: data.tenant.uoccname,
          payamt: data.amountPaid,
          trantype: this.getTransactionTypeCSV(data.type)
        }
      })
      var csv = Papa.unparse(newData);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, 'Payfile.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'Payfile.csv');
      tempLink.click();

    }).finally(() => {
      this.setState({ downloadLoading: false })
    })
  }

  getOutsourceStatus(status) {
    switch (status) {
      case 'S':
        return <Tag color="lime">SUCCESSFUL</Tag>
      case 'P':
        return <Tag color="gold">PENDING</Tag>
      case 'R':
        return <Tag color="red">REFUND</Tag>
      default:
        return ''
    }
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      preserveSelectedRowKeys: true
    };
    const dataSource = this.state.tableData
    const columns = [
      {
        title: 'Reference #',
        dataIndex: 'referenceNumber',
        key: 'referenceNumber',
        sorter: (a, b) => a.referenceNumber && a.referenceNumber.localeCompare(b.referenceNumber)
      },
      {
        title: 'Client Code',
        dataIndex: 'tenant',
        key: 'clientCode',
        sorter: (a, b) => a.tenant.clientCode && a.tenant.clientCode.localeCompare(b.tenant && b.tenant.clientCode),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.clientCode}`}</span>
          )
        }
      },
      {
        title: 'Client Name',
        dataIndex: 'tenant',
        key: 'tenant',
        sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.uoccname}`}</span>
          )
        }
      },
      {
        title: 'Transaction Date',
        dataIndex: 'transactionDate',
        key: 'transactionDate',
        sorter: (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate),
        render: (item) => {
          return (
            <span>{moment(item).format('LL')}</span>
          )
        }
      },
      {
        title: 'Soa Amount',
        dataIndex: 'soaAmount',
        key: 'soaAmount',
        sorter: (a, b) => a.soaAmount - b.soaAmount,
        render: (info) => {
          return (<span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}>₱{formatMoney(info)}</span>)
        }
      },
      {
        title: 'Paid Amount',
        dataIndex: 'amountPaid',
        key: 'amountPaid',
        sorter: (a, b) => a.amountPaid - b.amountPaid,
        render: (info) => {
          return (<span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}>₱{formatMoney(info)}</span>)
        }
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (item) => {
          return (<span>{this.getType(item)}</span>)
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (item) => {
          return (<span>{this.getStatus(item)}</span>)
        }
      },
      {
        title: 'Outsource Status (DragonPay & AQWire)',
        dataIndex: 'outsourceStatus',
        key: 'outsourceStatus',
        render: (item) => {
          return (<span>{this.getOutsourceStatus(item)}</span>)
        }
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        render: (item) => {
          return (<span style={{ color: 'red' }}>{item}</span>)
        }
      },
      {
        title: 'Actions',
        dataIndex: 'status',
        key: 'status',
        render: (item, data) => {
          switch (item) {
            case FOR_VERIFICATION:
              return (
                <div className='action-btn'>
                  {
                    this.access('payments', 'view') &&
                    <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true, isModalEditEditable: false })} >View</Button>
                  }
                  {
                    this.access('payments', 'reject') &&
                    <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Reject' danger onClick={() => this.handleDeletePayment(data.id)} >Delete</Button>
                  }
                </div>
              )
            case VERIFIED:
              return <div className='action-btn'>
                {
                  this.access('payments', 'view') &&
                  <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true, isModalEditEditable: false })} >View</Button>
                }
              </div>
            case POSTED:
              return <div className='action-btn'>
                {
                  this.access('payments', 'view') &&
                  <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true, isModalEditEditable: false })} >View</Button>
                }
              </div>
            case REJECTED:
              return <div className='action-btn'>
                {
                  this.access('payments', 'view') &&
                  <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true, isModalEditEditable: false })} >View</Button>
                }
              </div>
            default:
              break;
          }
        }
      },
    ];
    return (
      <Layout className='payments-container'>
        <Layout.Content>
          <div className='payment-title'>
            <h1>Payments</h1>
          </div>
          <Tabs defaultActiveKey="0" type="card" size='small' onChange={e => this.handlePaymentStatus(e)}>
            <TabPane tab="All" key="0" />
            <TabPane tab="For Verification" key="2" />
            <TabPane tab="Verified" key="3" />
            <TabPane tab="Posted" key="4" />
            <TabPane tab="Rejected" key="5" />
          </Tabs>
          <Row>
            <Col md={14} lg={14} sm={24} xs={24}>
              <div className='mt3 mb3'>
                <Button type='primary' onClick={() => this.handleDownloadSelectedRowToCSV()} loading={this.state.downloadLoading}>Download CSV</Button>
              </div>
            </Col>
          </Row>
          <Row className='payment-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {/* {
                  this.access('payments', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                } */}
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Reference Number" className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table
              rowSelection={rowSelection}
              style={{ width: '100%' }} rowKey='id' loading={this.props.paymentsLoading || this.props.deletePaymentLoading} dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.paymentsData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            paymentStatus={this.getPaymentStatus(this.state.paymentStatus)}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            paymentStatus={this.getPaymentStatus(this.state.paymentStatus)}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    paymentLoading: state.admin.payment.paymentLoading,
    paymentSuccess: state.admin.payment.paymentSuccess,
    updatePaymentSuccess: state.admin.payment.updatePaymentSuccess,
    deletePaymentSuccess: state.admin.payment.deletePaymentSuccess,
    paymentsSuccess: state.admin.payment.paymentsSuccess,
    paymentsData: state.admin.payment.paymentsData,
    paymentsLoading: state.admin.payment.paymentsLoading,
    deletePaymentLoading: state.admin.payment.deletePaymentLoading,
    approvePaymentLoading: state.admin.payment.approvePaymentLoading,
    approvePaymentSuccess: state.admin.payment.approvePaymentSuccess,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadPayment: (status, pagination) => dispatch(loadPayment(status, pagination)),
    onDeletePayment: (id, status) => dispatch(deletePayment(id, status)),
    onApprovePayment: (id, status) => dispatch(approvePayment(id, status)),
    onLoadSelectedRowTransactions: (selectedKeys) => dispatch(loadSelectedRowTransactions(selectedKeys))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Payments))
