import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Pagination, Tag } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, CheckOutlined, EyeOutlined, DeleteOutlined, UnorderedListOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import ThreadModal from './modals/thread'
import { connect } from 'react-redux'
import { loadPost, deletePost, approvedPost } from '../actions'
import moment from 'moment'
import { getUser } from '../../../../utilities/token'
import draftToHtml from 'draftjs-to-html';
// import rasterizeHTML from 'rasterizehtml/dist/rasterizeHTML.allinone.js'
import rasterizeHTML from 'rasterizehtml'
const { Search } = Input;
class Posts extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      isThreadModalOpen: false,
      selectedRow: {},
      tableData: [],

      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
    this.threadModalToggle = this.threadModalToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { limit, page, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if ((this.props.postSuccess !== nextProps.postSuccess) && nextProps.postSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Post added successfully.")
      this.props.onLoadPost(pagination)
    }
    if ((this.props.updatePostSuccess !== nextProps.updatePostSuccess) && nextProps.updatePostSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Post edited successfully.")
      this.props.onLoadPost(pagination)
    }
    if ((this.props.deletePostSuccess !== nextProps.deletePostSuccess) && nextProps.deletePostSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Post deleted successfully.")
      this.props.onLoadPost(pagination)
    }
    if ((this.props.postsSuccess !== nextProps.postsSuccess) && nextProps.postsSuccess) {
      this.setState({ tableData: nextProps.postsData.data })
    }
  }

  componentDidMount() {
    const { limit, page, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadPost(pagination)
  }

  onChangePagination(page) {
    const { limit, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadPost(pagination)
    this.setState({ page: page })
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadPost(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }


  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  threadModalToggle() {
    this.setState({ isThreadModalOpen: !this.state.isThreadModalOpen })
  }

  handleDeletePost(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeletePost(id)
      },
      onCancel() { },
    });
  }

  handleApprovedPost(rowId) {
    const self = this
    Modal.confirm({
      title: 'Do you want to approve this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        const submitData = {
          approvedById: getUser().id,
          approvalDate: moment(new Date),
          id: rowId
        }
        self.props.onApprovedPost({ ...submitData })
      },
      onCancel() { },
    });
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  rasterizedHtmlContent(id, content) {
    const html = (draftToHtml(JSON.parse(content)))
    var canvas = document.getElementById(`canvas_${id}`)
    rasterizeHTML.drawHTML(html, canvas);
  }
  render() {
    const dataSource = this.state.tableData
    const columns = [
      {
        title: 'Posted By',
        dataIndex: 'posted_by',
        key: 'posted_by',
        render: (item, data) => {
          return (
            <div>
              <span>{item.firstName} {item.lastName}</span>
            </div>
          )
        }
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title && a.title.localeCompare(b.title),
        render: (item, data) => {
          return (
            <div>
              <span>{item}</span>
            </div>
          )
        }
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (item, data) => {
          return (
            item === 'EVENTS' ?
              <Tag color="geekblue">EVENTS</Tag> :
              <Tag color="blue">ANNOUNCEMENTS</Tag>
          )
        }
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   key: 'status',
      //   sorter: (a, b) => a.status.length - b.status.length,
      //   render: (item, data) => {
      //     return (
      //       <div style={{ background: item === 'Active' ? "#009c34" : "red", textAlign: 'center', borderRadius: 8, width: 80 }}>
      //         <span style={{ color: 'white', fontWeight: 500 }}>{item}</span>
      //       </div>
      //     )
      //   }
      // },
      {
        title: 'Date Posted',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
        render: (item) => {
          return (
            <span style={{ whiteSpace: 'nowrap' }}>{moment(item).format('LL')}</span>
          )
        }
      },
      // {
      //   title: 'Date Approved',
      //   dataIndex: 'approvalDate',
      //   key: 'approvalDate',
      //   sorter: (a, b) => a.approvalDate.length - b.approvalDate.length,
      //   render: (item, data) => {
      //     return (
      //       <div>
      //         <span style={{ whiteSpace: 'nowrap' }}>{item && moment(item).format('LL')}</span>
      //       </div>
      //     )
      //   }
      // },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              {/* {
                this.access('announcements', 'approve') && <Button type="primary" icon={<CheckOutlined />} size={'medium'} title='View' style={{ backgroundColor: '#009c34', border: 0 }} onClick={() => this.handleApprovedPost(data.id)} disabled={data.approvedById}> Approved</Button>
              } */}
              {
                this.access('announcements', 'view') && <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })}>View</Button>
              }
              {/* {
                this.access('announcements', 'thread') && <Button type="primary" icon={<UnorderedListOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isThreadModalOpen: true })}>Thread</Button>
              } */}
              {
                this.access('announcements', 'delete') && <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeletePost(data.id)} >Delete</Button>
              }
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='posts-container'>

        <Layout.Content>
          <div className='post-title'>
            <h1>Announcements And Events</h1>
          </div>
          <Row className='post-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('announcements', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Title" className='search' onChange={(e) => this.handleSearch(e.target.value, ['name', 'description', 'title'])} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table style={{ width: '100%' }} rowKey='id' loading={this.props.postsLoading || this.props.deletePostLoading} dataSource={dataSource} columns={columns} pagination={false} />
          </Row>
          <Row style={{ marginTop: 30, width: '100%' }}>
            <Col md={24} lg={24} sm={24} xs={24} align="right">
              <Pagination defaultCurrent={this.state.page} onChange={e => this.onChangePagination(e)} total={this.props.postsData.total} />
            </Col>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
        {
          this.state.isThreadModalOpen &&
          <ThreadModal
            visible={this.state.isThreadModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.threadModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.admin.post.postLoading,
    postSuccess: state.admin.post.postSuccess,
    updatePostSuccess: state.admin.post.updatePostSuccess,
    deletePostSuccess: state.admin.post.deletePostSuccess,
    postsSuccess: state.admin.post.postsSuccess,
    postsData: state.admin.post.postsData,
    postsLoading: state.admin.post.postsLoading,
    deletePostLoading: state.admin.post.deletePostLoading,
    adminData: state.admin.utilities.adminData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadPost: (onLoadPost) => dispatch(loadPost(onLoadPost)),
    onDeletePost: (id) => dispatch(deletePost(id)),
    onApprovedPost: (data) => dispatch(approvedPost(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Posts))
