import {
  GET_PERMIT,
  GET_PERMIT_FULFILLED,
  GET_PERMIT_REJECTED,
  PUT_PERMIT,
  PUT_PERMIT_FULFILLED,
  PUT_PERMIT_REJECTED,
  POST_PERMIT,
  POST_PERMIT_FULFILLED,
  POST_PERMIT_REJECTED,
  DELETE_PERMIT,
  DELETE_PERMIT_FULFILLED,
  DELETE_PERMIT_REJECTED
} from '../actions'

export default function permit(state = {
  permitLoading: false,
  permitSuccess: false,
  permitRejected: false,
  permitData: {
    data: []
  },
  updatePermitLoading: false,
  updatePermitSuccess: false,
  updatePermitRejected: false,
  addPermitLoading: false,
  addPermitSuccess: false,
  addPermitRejected: false,
  deletePermitLoading: false,
  deletePermitSuccess: false,
  deletePermitRejected: false
}, action) {
  switch (action.type) {
    case GET_PERMIT:
      return {
        ...state,
        permitLoading: true,
        permitSuccess: false,
        permitRejected: false
      }
    case GET_PERMIT_FULFILLED:
      return {
        ...state,
        permitLoading: false,
        permitSuccess: true,
        permitData: action.payload
      }
    case GET_PERMIT_REJECTED:
      return {
        ...state,
        permitLoading: false,
        permitRejected: true
      }
    case POST_PERMIT:
      return {
        ...state,
        addPermitLoading: true,
        addPermitSuccess: false,
        addPermitRejected: false
      }
    case POST_PERMIT_FULFILLED:
      return {
        ...state,
        addPermitLoading: false,
        addPermitSuccess: true,
        addPermitData: action.payload
      }
    case POST_PERMIT_REJECTED:
      return {
        ...state,
        addPermitLoading: false,
        addPermitRejected: true
      }
    case PUT_PERMIT:
      return {
        ...state,
        updatePermitLoading: true,
        updatePermitSuccess: false,
        updatePermitRejected: false
      }
    case PUT_PERMIT_FULFILLED:
      return {
        ...state,
        updatePermitLoading: false,
        updatePermitSuccess: true,
        updatePermitData: action.payload
      }
    case PUT_PERMIT_REJECTED:
      return {
        ...state,
        updatePermitLoading: false,
        updatePermitRejected: true
      }

    case DELETE_PERMIT:
      return {
        ...state,
        deletePermitLoading: true,
        deletePermitSuccess: false,
        deletePermitRejected: false
      }
    case DELETE_PERMIT_FULFILLED:
      return {
        ...state,
        deletePermitLoading: false,
        deletePermitSuccess: true,
        deletePermitData: action.payload
      }
    case DELETE_PERMIT_REJECTED:
      return {
        ...state,
        deletePermitLoading: false,
        deletePermitRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
