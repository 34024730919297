import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, DatePicker, Spin, Card, Space } from 'antd'
import { connect } from 'react-redux'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { updatePermit } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import moment from 'moment'
import SlidingPane from "react-sliding-pane";

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id

    const submitData = {
      "tenantId": getUser().id,
      "dateApplied": values.dateApplied,
      "workPermitFor": values.workPermitFor,
      "workArea": values.workArea,
      "dateFrom": values.dateFrom,
      "dateTo": values.dateTo,
      "purpose": values.purpose,
      "contractors": values.contractors,
      "items": values.items,
    }

    this.props.onUpdatePermit({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }


  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT PERMIT"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={false}>
          <Form
            name="basic"
            initialValues={{
              ...selectedRow,
              dateFrom: moment(selectedRow && selectedRow.dateFrom),
              dateTo: moment(selectedRow && selectedRow.dateTo),
              dateApplied: moment(selectedRow && selectedRow.dateApplied).format('LLL'),
              dateCompleted: moment(selectedRow && selectedRow.dateCompleted).format('LLL'),
              approvalDate: moment(selectedRow && selectedRow.approvalDate).format('LLL'),
              approvedById: selectedRow && selectedRow.approver && selectedRow.approver.name,
              status: this.getStatusLabel(selectedRow && selectedRow.status),
              contractors: selectedRow && selectedRow.contractors || [],
              items: selectedRow && selectedRow.items || []
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Work Permit For"
                  name="workPermitFor"
                  rules={[{ required: true, message: 'Please input your work permit for!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Work Area"
                  name="workArea"
                  rules={[{ required: true, message: 'Please input your work area!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Purpose"
                  name="purpose"
                  rules={[{ required: true, message: 'Please input your purpose!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Covered From"
                  name="dateFrom"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date from!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Covered To"
                  name="dateTo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date to!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Card title="List of Workers">
                  <Form.List name="contractors">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Missing Name' }]}
                            >
                              <Input placeholder="Worker Name" disabled={disabled} className='primary-input' />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'information']}
                              fieldKey={[field.fieldKey, 'information']}
                              rules={[{ required: true, message: 'Missing Information' }]}
                            >
                              <Input placeholder="Worker Information" disabled={disabled} className='primary-input' />
                            </Form.Item>
                            {
                              (!disabled && index !== 0) &&
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            }
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" disabled={disabled} className='primary-btn' onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Worker
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>

                <Card title="List of Items" style={{ marginTop: 30 }}>
                  <Form.List name="items">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields && fields.map((field, index) => (
                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                rules={[{ required: true, message: 'Missing Name' }]}
                              >
                                <Input placeholder="Name" className='primary-input' disabled={disabled} />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'quantity']}
                                fieldKey={[field.fieldKey, 'quantity']}
                                rules={[{ required: true, message: 'Missing Quantity' }]}
                              >
                                <Input type="number" placeholder="Quantity" className='primary-input' disabled={disabled} />
                              </Form.Item>
                              {
                                (!disabled && index !== 0) &&
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              }
                            </Space>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={disabled}>
                              Add Item
                            </Button>
                          </Form.Item>
                        </>
                      )
                    }}
                  </Form.List>
                </Card>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>

                <Form.Item
                  label="Date Applied"
                  name="dateApplied"
                  rules={[{ required: false, message: 'Please input your date applied!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date Completed"
                  name="dateCompleted"
                  rules={[{ required: false, message: 'Please input your date completed!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedById"
                  rules={[{ required: false, message: 'Please input your approved by!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Bond Amount"
                  name="bondAmount"
                  rules={[{ required: false, message: 'Please input your bond amount!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={5} disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: false, message: 'Please input your status!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={this.props.updatePermitLoading || this.state.isLoading} disabled={disabled}>
                    Update Work Permit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    updatePermitLoading: state.client.permits.updatePermitLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdatePermit: (data) => dispatch(updatePermit(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

