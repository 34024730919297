import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Switch, DatePicker } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitPost } from '../../actions'
import moment from 'moment'
import { upload } from '../../../../../utilities/filesController'

import '../index.scss'

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      fileImage: '',
      isLoading: false,
      editorState: EditorState.createEmpty(),
    }
    this.onEditorStateChange = this.onEditorStateChange.bind(this)
  }

  componentDidMount() {
    this.setState({ fileImage: '' })
  }

  handleSubmit(values) {
    const submitData = {
      "postById": getUser().id,
      "title": values.title,
      "description": values.description,
      "startDate": values.startDate,
      "endDate": values.endDate,
      "attachedUrl": values.attachedUrl,
      "type": values.type,
      "content": JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
      "enableMessaging": (values.enableMessaging) ? 1 : 0,
      // "status": values.status === 'Active' ? 1 : 0
      "status": 1
    }
    if (this.state.fileImage.length === 0) {
      this.props.onSubmitPost(submitData)
    } else {
      this.setState({ isLoading: true })
      upload(this.state.fileImage[0], this.state.fileImage[0].name, 'images').then(data => {
        this.setState({ isLoading: false })
        this.props.onSubmitPost({ ...submitData, image: `images/${this.state.fileImage[0].name}` })
      }).catch(err => {
        this.setState({ isLoading: false })
      })
    }
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };

  uploadCallback(file) {
    return upload(file, file.name, 'images').then(data => {
      return new Promise(
        (resolve, reject) => { resolve({ data: { link: data.completeUrl } }); }
      );
    }).catch(err => { })
  }

  render() {
    const { editorState } = this.state;
    return (
      <Modal
        title="ADD ANNOUNCEMENT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >

        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >

            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>



                {/* <Form.Item
                  label="Enable Messaging?"
                  name="enableMessaging"
                >
                  <Switch />
                </Form.Item> */}
              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Attached Url"
                  name="attachedUrl"
                  rules={[{ required: true, message: 'Please input your attached url!' }]}
                >
                  <Input />
                </Form.Item>
                {/* <Form.Item
                  label="Start Time"
                  name="startDate"
                  rules={[{ required: true, message: 'Please input your start time!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  label="End Time"
                  name="endDate"
                  rules={[{ required: true, message: 'Please input your end time!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item> */}

                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'ANNOUNCEMENTS'}>ANNOUNCEMENTS</Option>
                    <Option value={'EVENTS'}>EVENTS</Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'Active'}>Active</Option>
                    <Option value={'Inactive'}>Inactive</Option>
                  </Select>
                </Form.Item> */}

                {/* <span style={{ fontWeight: 550 }}>Image</span>
                <Upload getFile={e => this.setState({ fileImage: e })} /> */}
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Editor
                  editorState={editorState}
                  // wrapperClassName="demo-wrapper"
                  // editorClassName="demo-editor"
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{ image: { uploadCallback: this.uploadCallback, alt: { present: true, mandatory: false }, } }}
                /></Col>
            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} style={{ marginTop: 5 }} align={'right'}>
                <Form.Item >
                  <Button type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.admin.post.postLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitPost: (data) => dispatch(submitPost(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
