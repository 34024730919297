import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FULFILLED,
  GET_NOTIFICATIONS_REJECTED
} from '../actions'

export default function notifications(state = {
  notificationsLoading: false,
  notificationsSuccess: false,
  notificationsRejected: false,
  notificationsData: {
    data: []
  }
}, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notificationsLoading: true,
        notificationsSuccess: false,
        notificationsRejected: false
      }
    case GET_NOTIFICATIONS_FULFILLED:
      return {
        ...state,
        notificationsLoading: false,
        notificationsSuccess: true,
        notificationsData: action.payload
      }
    case GET_NOTIFICATIONS_REJECTED:
      return {
        ...state,
        notificationsLoading: false,
        notificationsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
