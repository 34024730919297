import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_WEBSITE_SETTINGS = 'GET_WEBSITE_SETTINGS'
export const GET_WEBSITE_SETTINGS_FULFILLED = 'GET_WEBSITE_SETTINGS_FULFILLED'
export const GET_WEBSITE_SETTINGS_REJECTED = 'GET_WEBSITE_SETTINGS_REJECTED'

export const GET_MOBILE_SETTINGS = 'GET_MOBILE_SETTINGS'
export const GET_MOBILE_SETTINGS_FULFILLED = 'GET_MOBILE_SETTINGS_FULFILLED'
export const GET_MOBILE_SETTINGS_REJECTED = 'GET_MOBILE_SETTINGS_REJECTED'

export const POST_WEBSITE_SETTINGS = 'POST_WEBSITE_SETTINGS'
export const POST_WEBSITE_SETTINGS_FULFILLED = 'POST_WEBSITE_SETTINGS_FULFILLED'
export const POST_WEBSITE_SETTINGS_REJECTED = 'POST_WEBSITE_SETTINGS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadWebsiteSettings = (data) => {
  return dispatch => {
    dispatch({
      type: GET_WEBSITE_SETTINGS,
      payload: {}
    })
    return Axios.get(`${API_URL}/website-settings`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_WEBSITE_SETTINGS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_WEBSITE_SETTINGS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadMobileSettings = (data) => {
  return dispatch => {
    dispatch({
      type: GET_MOBILE_SETTINGS,
      payload: {}
    })
    return Axios.get(`${API_URL}/mobile-settings`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_MOBILE_SETTINGS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_MOBILE_SETTINGS_REJECTED,
          payload: error
        })
      })
  }
}

export const submitWebsiteSettings = (data) => {
  return dispatch => {
    dispatch({
      type: POST_WEBSITE_SETTINGS,
      payload: {}
    })
    return Axios.post(`${API_URL}/website-setting`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_WEBSITE_SETTINGS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_WEBSITE_SETTINGS_REJECTED,
          payload: error
        })
      })
  }
}



