import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_COMMENDATION = 'GET_COMMENDATION'
export const GET_COMMENDATION_FULFILLED = 'GET_COMMENDATION_FULFILLED'
export const GET_COMMENDATION_REJECTED = 'GET_COMMENDATION_REJECTED'

export const POST_COMMENDATION = 'POST_COMMENDATION'
export const POST_COMMENDATION_FULFILLED = 'POST_COMMENDATION_FULFILLED'
export const POST_COMMENDATION_REJECTED = 'POST_COMMENDATION_REJECTED'

export const PUT_COMMENDATION = 'PUT_COMMENDATION'
export const PUT_COMMENDATION_FULFILLED = 'PUT_COMMENDATION_FULFILLED'
export const PUT_COMMENDATION_REJECTED = 'PUT_COMMENDATION_REJECTED'

export const DELETE_COMMENDATION = 'DELETE_COMMENDATION'
export const DELETE_COMMENDATION_FULFILLED = 'DELETE_COMMENDATION_FULFILLED'
export const DELETE_COMMENDATION_REJECTED = 'DELETE_COMMENDATION_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadCommendation = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_COMMENDATION,
      payload: {}
    })
    return Axios.get(`${API_URL}/commendations?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_COMMENDATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_COMMENDATION_REJECTED,
          payload: error
        })
      })
  }
}

export const submitCommendation = (data) => {
  return dispatch => {
    dispatch({
      type: POST_COMMENDATION,
      payload: {}
    })
    return Axios.post(`${API_URL}/commendation`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_COMMENDATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_COMMENDATION_REJECTED,
          payload: error
        })
      })
  }
}

export const updateCommendation = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_COMMENDATION,
      payload: {}
    })
    return Axios.put(`${API_URL}/commendation/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_COMMENDATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_COMMENDATION_REJECTED,
          payload: error
        })
      })
  }
}


export const deleteCommendation = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_COMMENDATION,
      payload: {}
    })
    return Axios.delete(`${API_URL}/commendation/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_COMMENDATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_COMMENDATION_REJECTED,
          payload: error
        })
      })
  }
}











