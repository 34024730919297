import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form, Row, Col, Input, Card, DatePicker } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { loadTenant, submitPaymentDragonPay, POST_PAYMENT_FULFILLED } from '../../actions'
import { DRAGON_PAY } from '../../../../../utilities/paymentsTypeEnum'
import { DELETE, CREATED } from '../../../../../utilities/paymentsStatusEnum'
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

const Dragonpay = (props) => {
    let history = useHistory();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        props.onLoadTenant(getUser().id).then(res => {
            let { clientCode, soaDate } = res.payload
            soaDate = moment(soaDate).format('MMDDYYYY')
            const invoiceNumber = `(${clientCode})${soaDate}`
            const transactionDate = moment(new Date())
            form.setFieldsValue({ ...res.payload, invoiceNumber, transactionDate })
        })
    }, [])

    function handleSubmit(values) {
        let { soaAmount, soaDate, clientCode, uoccname } = props.tenantData
        soaDate = moment(soaDate).format('MMDDYYYY')
        const remarks = `Payment for a monthly bill with amount of ${values.amountPaid}`

        const submitData = {
            tenantId: getUser().id,
            clientCode,
            email: values.email,
            transactionDate: values.transactionDate,
            invoiceDate: values.invoiceDate,
            soaAmount: values.soaAmount,
            amountPaid: values.amountPaid,
            invoiceNumber: values.invoiceNumber,
            type: DRAGON_PAY,
            status: CREATED
        }
        Modal.confirm({
            title: 'DragonPay Payment Gateway',
            icon: <ExclamationCircleOutlined />,
            content: `You chose dragon pay as your payment gateway, Continuing this will create a record to our payments data.`,
            onOk() {
                props.onSubmitPayment(submitData).then(res => {
                    if (res.type === POST_PAYMENT_FULFILLED) {
                        history.push('/billings')
                        window.open(res.payload && res.payload.returnUrl, "_blank");
                    }
                })
            },
        });
    }

    return (
        <Card>
            <h1>Dragon Pay</h1>
            <Form
                form={form}
                name="basic"
                onFinish={(e) => handleSubmit(e)}
            >
                <Row gutter={12}>
                    <Col md={12} lg={12} sm={24} xs={24}>
                        <Form.Item
                            label="Invoice Number (Read Only)"
                            name="invoiceNumber"
                        >
                            <Input className='primary-input' readOnly />
                        </Form.Item>

                        <Form.Item
                            label="SOA Amount (Read Only)"
                            name="soaAmount"
                        >
                            <Input className='primary-input' readOnly />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input className='primary-input' />
                        </Form.Item>

                        <Form.Item
                            label="Paid Amount"
                            name="amountPaid"
                            rules={[{ required: true, message: 'Please input your Paid Amount!' }]}
                        >
                            <Input className='primary-input' type='number' />
                        </Form.Item>

                        <Form.Item
                            label="Transaction Date"
                            name="transactionDate"
                            rules={[{ required: true, message: 'Please input your end time!' }]}
                        >
                            <DatePicker
                                disabled
                                className='primary-input'
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} lg={24} sm={24} align={'left'}>
                        <Form.Item >
                            <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={props.paymentLoading || loading}>
                                CONTINUE WITH DRAGONPAY
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}


function mapStateToProps(state) {
    return {
        tenantData: state.client.billings.tenantData,
        paymentLoading: state.client.billings.paymentLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenant: (data) => dispatch(loadTenant(data)),
        onSubmitPayment: (data) => dispatch(submitPaymentDragonPay(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dragonpay)
