import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Input, message, Pagination } from 'antd'
import { connect } from 'react-redux'
import { loadTenant } from '../actions'
import { formatMoney } from '../../../../utilities/helper'
import { get } from '../../../../utilities/filesController'
const { Search } = Input;

class Billings extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: {},
      tableData: [],
      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.tenantSuccess !== nextProps.tenantSuccess) && nextProps.tenantSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Tenant added successfully.")
    }
    if ((this.props.updateTenantSuccess !== nextProps.updateTenantSuccess) && nextProps.updateTenantSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Tenant edited successfully.")
    }
    if ((this.props.deleteTenantSuccess !== nextProps.deleteTenantSuccess) && nextProps.deleteTenantSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Tenant deleted successfully.")
    }
    if ((this.props.tenantsSuccess !== nextProps.tenantsSuccess) && nextProps.tenantsSuccess) {
      this.setState({ tableData: nextProps.tenantsData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadTenant(pagination)
  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadTenant(pagination)
    this.setState({ page, limit })
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadTenant(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  async urlOpen(pathname) {
    const url = await get(pathname)
    window.open(url);
  }

  render() {
    const dataSource = this.state.tableData

    const columns = [
      {
        title: 'Client Code',
        dataIndex: 'clientCode',
        key: 'clientCode',
        sorter: (a, b) => a.clientCode && a.clientCode.localeCompare(b.clientCode),
      },

      {
        title: 'Uoccname',
        dataIndex: 'uoccname',
        key: 'uoccname',
        sorter: (a, b) => a.uoccname && a.uoccname.localeCompare(b.uoccname),
      },
      {
        title: 'Soa Amount',
        dataIndex: 'soaAmount',
        key: 'soaAmount',
        sorter: (a, b) => a.soaAmount - b.soaAmount,
        render: (info) => {
          return (<span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}>₱{formatMoney(info)}</span>)
        }
      },
      {
        title: 'Attachment',
        dataIndex: 'soaName',
        key: 'soaName',
        sorter: (a, b) => a.soaName && a.soaName.localeCompare(b.soaName),
        render: (info) => {
          return (<span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}><a onClick={() => this.urlOpen(`soa/pdf/${info}`)} ><u>{info}</u></a></span>)
        }
      },
    ];
    return (
      <Layout className='tenants-container'>
        <Layout.Content>
          <div className='tenant-title'>
            <h1>Billings</h1>
          </div>
          <Row className='tenant-header'>
            <Col md={14} lg={14} sm={24} className='input-search'>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Name, Email, Phone Number, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value, ['name', 'firstName', 'lastName', 'middleName', 'phoneNumber', 'email'])} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Table style={{ width: '100%' }} rowKey='id' loading={this.props.tenantsLoading} dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.tenantsData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    tenantLoading: state.admin.tenant.tenantLoading,
    tenantSuccess: state.admin.tenant.tenantSuccess,
    updateTenantSuccess: state.admin.tenant.updateTenantSuccess,
    deleteTenantSuccess: state.admin.tenant.deleteTenantSuccess,
    tenantsSuccess: state.admin.tenant.tenantsSuccess,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    deleteTenantLoading: state.admin.tenant.deleteTenantLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadTenant: (pagination) => dispatch(loadTenant(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Billings))
