import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { updateCommendation, loadCommendationById } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import moment from 'moment'
import { getParamFromUrl } from '../../../../../utilities/helper'
const { Option } = Select;
class Edit extends React.Component {
  formRef = React.createRef();

  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: [],
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadCommendationById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload,
          approvalDate: moment(res.payload.approvalDate).format('LLL'),
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id

    const submitData = {
      "tenantId": this.props.selectedRow.tenantId,
      "title": values.title,
      "type": values.type,
      "comment": values.comment,
      "remarks": values.remarks,
      "status": this.getStatusEnum(values.status),

      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,

    }
    this.props.onUpdateCommendation({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })

  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found && found.roles[role]) ? true : false
    else return false
  }


  render() {
    const isReadOnly = false// Cant edit if the status is for approval = 1
    return (
      <Modal
        title="EDIT COMMENDATION AND TESTIMONIAL"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            ref={this.formRef}
            name="basic"
            initialValues={{
              ...this.props.selectedRow,
              approvalDate: moment(this.props.selectedRow.approvalDate).format('LLL'),
              approvedBy: this.props.selectedRow && this.props.selectedRow.approver && this.props.selectedRow && this.props.selectedRow.approver.name || '',
              status: this.getStatusLabel(this.props.selectedRow.status),
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={1}>COMMENDATION</Option>
                    <Option value={2}>TESTIMONIAL</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[{ required: true, message: 'Please input your comment!' }]}
                >
                  <Input.TextArea rows={5} readOnly={isReadOnly} />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                  rules={[{ required: false, message: 'Please input your approved by!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    {
                      this.access('commendations', 'approve') &&
                      <Option value={'APPROVED'}>APPROVED</Option>
                    }
                    {/* {
                      this.access('commendations', 'ongoing') &&
                      <Option value={'ONGOING'}>ONGOING</Option>
                    }
                    {
                      this.access('commendations', 'complete') &&
                      <Option value={'COMPLETED'}>COMPLETED</Option>
                    }
                    {
                      this.access('commendations', 'cancel') &&
                      <Option value={'CANCELLED'}>CANCELLED</Option>
                    } */}
                    {
                      this.access('commendations', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('commendations', 'edit') &&
                    <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.updateCommendationLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }

                </Form.Item>
              </Col>
            </Row>

          </Form>

        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateCommendationLoading: state.admin.commendations.updateCommendationLoading,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateCommendation: (data) => dispatch(updateCommendation(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onLoadCommendationById: (id) => dispatch(loadCommendationById(id)),

  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))

