import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_ADVERTISEMENTS = 'GET_ADVERTISEMENTS'
export const GET_ADVERTISEMENTS_FULFILLED = 'GET_ADVERTISEMENTS_FULFILLED'
export const GET_ADVERTISEMENTS_REJECTED = 'GET_ADVERTISEMENTS_REJECTED'

export const GET_ADVERTISEMENT = 'GET_ADVERTISEMENT'
export const GET_ADVERTISEMENT_FULFILLED = 'GET_ADVERTISEMENT_FULFILLED'
export const GET_ADVERTISEMENT_REJECTED = 'GET_ADVERTISEMENT_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadAdvertisements = (limit) => {
  return dispatch => {
    dispatch({
      type: GET_ADVERTISEMENTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/ads?&limit=${limit}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ADVERTISEMENTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ADVERTISEMENTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadAdvertisement = () => {
  return dispatch => {
    dispatch({
      type: GET_ADVERTISEMENT,
      payload: {}
    })
    return Axios.get(`${API_URL}/ad`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ADVERTISEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ADVERTISEMENT_REJECTED,
          payload: error
        })
      })
  }
}
