import jwt_decode from "jwt-decode";


export const getToken = () => {
  try {
    // return window.localStorage.getItem('SESSION_TOKEN')
    let sessionName
    if (window.location.pathname.includes('/admin')) sessionName = 'ADMIN_SESSION_TOKEN'
    else if (window.location.pathname.includes('/super-admin')) sessionName = 'SUPER_ADMIN_SESSION_TOKEN'
    else sessionName = 'CLIENT_SESSION_TOKEN'
    return window.localStorage.getItem(sessionName)
  } catch (error) { }
}

export const getUser = () => {
  try {
    let sessionName
    if (window.location.pathname.includes('/admin')) sessionName = 'ADMIN_SESSION_TOKEN'
    else if (window.location.pathname.includes('/super-admin')) sessionName = 'SUPER_ADMIN_SESSION_TOKEN'
    else sessionName = 'CLIENT_SESSION_TOKEN'
    return jwt_decode(window.localStorage.getItem(sessionName))
  } catch (error) { }
}

export const headers = {
  "Authorization": `Bearer ${getToken()}`,
  "Accept": "application/json",
  "Content-Type": "application/json"
}
