import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_POLICY = 'POST_POLICY'
export const POST_POLICY_FULFILLED = 'POST_POLICY_FULFILLED'
export const POST_POLICY_REJECTED = 'POST_POLICY_REJECTED'
export const GET_POLICIES = 'GET_POLICIES'
export const GET_POLICIES_FULFILLED = 'GET_POLICIES_FULFILLED'
export const GET_POLICIES_REJECTED = 'GET_POLICIES_REJECTED'
export const PUT_POLICY = 'PUT_POLICY'
export const PUT_POLICY_FULFILLED = 'PUT_POLICY_FULFILLED'
export const PUT_POLICY_REJECTED = 'PUT_POLICY_REJECTED'
export const DELETE_POLICY = 'DELETE_POLICY'
export const DELETE_POLICY_FULFILLED = 'DELETE_POLICY_FULFILLED'
export const DELETE_POLICY_REJECTED = 'DELETE_POLICY_REJECTED'

//Header
export const POST_POLICY_HEADER = 'POST_POLICY_HEADER'
export const POST_POLICY_HEADER_FULFILLED = 'POST_POLICY_HEADER_FULFILLED'
export const POST_POLICY_HEADER_REJECTED = 'POST_POLICY_HEADER_REJECTED'
export const GET_POLICIES_HEADER = 'GET_POLICIES_HEADER'
export const GET_POLICIES_HEADER_FULFILLED = 'GET_POLICIES_HEADER_FULFILLED'
export const GET_POLICIES_HEADER_REJECTED = 'GET_POLICIES_HEADER_REJECTED'
export const PUT_POLICY_HEADER = 'PUT_POLICY_HEADER'
export const PUT_POLICY_HEADER_FULFILLED = 'PUT_POLICY_HEADER_FULFILLED'
export const PUT_POLICY_HEADER_REJECTED = 'PUT_POLICY_HEADER_REJECTED'
export const DELETE_POLICY_HEADER = 'DELETE_POLICY_HEADER'
export const DELETE_POLICY_HEADER_FULFILLED = 'DELETE_POLICY_HEADER_FULFILLED'
export const DELETE_POLICY_HEADER_REJECTED = 'DELETE_POLICY_HEADER_REJECTED'

export const APPROVE_POLICY = 'APPROVE_POLICY'
export const APPROVE_POLICY_FULFILLED = 'APPROVE_POLICY_FULFILLED'
export const APPROVE_POLICY_REJECTED = 'APPROVE_POLICY_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadPolicy = () => {
  return dispatch => {
    dispatch({
      type: GET_POLICIES,
      payload: {}
    })
    return Axios.get(`${API_URL}/policies`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_POLICIES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_POLICIES_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPolicy = (data) => {
  return dispatch => {
    dispatch({
      type: POST_POLICY,
      payload: {}
    })
    return Axios.post(`${API_URL}/policy`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_POLICY_FULFILLED,
          payload: response.data
        })
        dispatch(loadPolicyHeader())
      })
      .catch(function (error) {
        dispatch({
          type: POST_POLICY_REJECTED,
          payload: error
        })
      })
  }
}

export const updatePolicy = (data, status) => {
  return dispatch => {
    dispatch({
      type: PUT_POLICY,
      payload: {}
    })
    return Axios.put(`${API_URL}/policy/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_POLICY_FULFILLED,
          payload: response.data
        })
        dispatch(loadPolicyHeader(status))
      })
      .catch(function (error) {
        dispatch({
          type: PUT_POLICY_REJECTED,
          payload: error
        })
      })
  }
}

export const deletePolicy = (id, status) => {
  return dispatch => {
    dispatch({
      type: DELETE_POLICY,
      payload: {}
    })
    return Axios.delete(`${API_URL}/policy/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_POLICY_FULFILLED,
          payload: response.data
        })
        dispatch(loadPolicyHeader(status))
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_POLICY_REJECTED,
          payload: error
        })
      })
  }
}

export const approvePolicy = (id, status) => {
  return dispatch => {
    dispatch({
      type: APPROVE_POLICY,
      payload: {}
    })
    return Axios.put(`${API_URL}/policy`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: APPROVE_POLICY_FULFILLED,
          payload: response.data
        })
        dispatch(loadPolicy(status))
      })
      .catch(function (error) {
        dispatch({
          type: APPROVE_POLICY_REJECTED,
          payload: error
        })
      })
  }
}


export const loadPolicyHeader = () => {
  return dispatch => {
    dispatch({
      type: GET_POLICIES_HEADER,
      payload: {}
    })
    return Axios.get(`${API_URL}/policies-header`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_POLICIES_HEADER_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_POLICIES_HEADER_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPolicyHeader = (data) => {
  return dispatch => {
    dispatch({
      type: POST_POLICY_HEADER,
      payload: {}
    })
    return Axios.post(`${API_URL}/policy-header`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_POLICY_HEADER_FULFILLED,
          payload: response.data
        })
        dispatch(loadPolicyHeader())
      })
      .catch(function (error) {
        dispatch({
          type: POST_POLICY_HEADER_REJECTED,
          payload: error
        })
      })
  }
}

export const updatePolicyHeader = (data, status) => {
  return dispatch => {
    dispatch({
      type: PUT_POLICY_HEADER,
      payload: {}
    })
    return Axios.put(`${API_URL}/policy-header/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_POLICY_HEADER_FULFILLED,
          payload: response.data
        })
        dispatch(loadPolicyHeader(status))
      })
      .catch(function (error) {
        dispatch({
          type: PUT_POLICY_HEADER_REJECTED,
          payload: error
        })
      })
  }
}

export const deletePolicyHeader = (id, status) => {
  return dispatch => {
    dispatch({
      type: DELETE_POLICY_HEADER,
      payload: {}
    })
    return Axios.delete(`${API_URL}/policy-header/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_POLICY_HEADER_FULFILLED,
          payload: response.data
        })
        dispatch(loadPolicyHeader(status))
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_POLICY_HEADER_REJECTED,
          payload: error
        })
      })
  }
}

export const updatePolicyHeaderSequence = (data) => {
  return dispatch => {
    dispatch({
      type: DELETE_POLICY_HEADER,
      payload: {}
    })
    return Axios.put(`${API_URL}/policy-header-sequence`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_POLICY_HEADER_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_POLICY_HEADER_REJECTED,
          payload: error
        })
      })
  }
}








