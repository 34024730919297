import React from 'react'
import { Row, Col, Form, Modal, Button, Radio, Input, Select, Spin } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { updateServiceRequest, approveServiceRequest, verifiedServiceRequest, loadServiceById } from '../../actions'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
import { getParamFromUrl } from '../../../../../utilities/helper'
import moment from 'moment'
const { Option } = Select;
class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      isLoading: false,
      isApprovedDisabled: false,
      isVerifiedDisabled: false,
      remarks: '',
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
    this.timeout = 0;
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadServiceById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }

  componentDidMount() {
    this.props.onLoadTenant({ id: this.props.selectedRow.tenantId })
    this.setState({
      isApprovedDisabled: this.props.selectedRow.approvedById ? true : false,
      isVerifiedDisabled: this.props.selectedRow.verifiedById ? true : false
    })
  }

  handleUpdate(values) {
    const submitData = {
      "tenantId": values.tenantId,
      // "postById": getUser().id,
      "title": values.title,
      "description": values.description,
      "comment": values.comment,
      "status": this.getStatusEnum(values.status),
      "estimatedAmount": values.estimatedAmount,
      "billOption": values.billOption,
      "remarks": values.remarks,
      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,

      // COMPLETED
      "completedDate": (values.status === 'COMPLETED' && this.props.selectedRow.status !== this.getStatusEnum(values.status)) && moment(new Date()),
    }
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id
    this.props.onUpdateServiceRequest({ ...submitData, id: selectedRowId }, this.props.requestStatus)
  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  getRequestStatus(e) {
    let type
    switch (e) {
      case '1':
        type = 'approval'
        break;
      case '2':
        type = 'approved'
        break;
      case '3':
        type = 'cancelled'
        break;
      case '4':
        type = 'rejected'
        break;
      default:
        break;
    }
    return type
  }

  handleApproveServiceRequest(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to Close this item?',
      icon: <InfoCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        // self.props.onDeleteServiceRequest(id)
        const submitData = {
          approvalDate: moment(new Date()),
          approvedById: getUser().id
        }
        self.props.onApproveServiceRequest(id, self.getRequestStatus(self.state.requestStatus), submitData).then(() => {
          self.setState({ isApprovedDisabled: true })
        })
      },
      onCancel() { },
    });
  }

  handleVerifiedRequest(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to verify this item?',
      icon: <InfoCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        // self.props.onDeleteServiceRequest(id)
        const submitData = {
          verifiedById: getUser().id
        }
        self.props.onVerifiedServiceRequest(id, self.getRequestStatus(self.state.requestStatus), submitData).then(() => {
          self.setState({ isVerifiedDisabled: true })
        })
      },
      onCancel() { },
    });
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }


  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const { editable, selectedRow } = this.props
    const initialValues = {
      "tenantId": selectedRow.tenantId,
      "postById": getUser().id,
      "title": selectedRow.title,
      "description": selectedRow.description,
      "comment": selectedRow.comment,
      "approvedById": selectedRow.approver && selectedRow.approver.name,
      "approvalDate": moment(selectedRow.approvalDate).format('LLL'),
      "completedDate": moment(selectedRow.completedDate).format('LLL'),
      "estimatedAmount": selectedRow.estimatedAmount,
      "billOption": selectedRow.billOption,
      "remarks": selectedRow.remarks,
      "status": this.getStatusLabel(selectedRow.status),
    }

    const disabled = (!this.access('orders', 'edit') || this.props.selectedRow.status !== 1) // (1 !=) not for approval 

    return (
      <Modal
        title="EDIT JOB ORDER"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            ref={this.formRef}
            name="basic"
            initialValues={initialValues}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }} disabled={disabled}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>


                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>

                <Form.Item
                  label="Bill Option (Tag as Billable/Non-Billable)"
                  name="billOption"
                >
                  <Radio.Group defaultValue='Billable' disabled={disabled}>
                    <Radio value={'Billable'}>Billable</Radio>
                    <Radio value={'Non-Billable'}>Non-Billable</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    {
                      this.access('orders', 'approve') &&
                      <Option value={'APPROVED'}>APPROVED</Option>
                    }
                    {
                      this.access('orders', 'ongoing') &&
                      <Option value={'ONGOING'}>ONGOING</Option>
                    }
                    {
                      this.access('orders', 'complete') &&
                      <Option value={'COMPLETED'}>COMPLETED</Option>
                    }
                    {
                      this.access('orders', 'cancel') &&
                      <Option value={'CANCELLED'}>CANCELLED</Option>
                    }
                    {
                      this.access('orders', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>


              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Approve By"
                  name="approvedById"
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label="Work Approval Date"
                  name="approvalDate"
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label="Completed Date"
                  name="completedDate"
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label="Estimated Amount"
                  name="estimatedAmount"
                  rules={[{ required: true, message: 'Please input your estimated amount!' }]}
                >
                  <Input type="number" disabled={disabled} />
                </Form.Item>

                <Form.Item
                  label={`Remarks`}
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea onChange={(e) => this.setState({ remarks: e.target.value })} rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                {/* {
                  this.access('orders', 'approve') &&
                  <Button icon={<CheckOutlined />} type="primary" htmlType="button" disabled={this.state.isApprovedDisabled} style={{ backgroundColor: '#12B86A', margin: 4, border: 0 }} onClick={() => {
                    this.handleApproveServiceRequest(selectedRow.id)
                  }} >
                    Approved
                </Button>
                }
                {
                  this.access('orders', 'verify') &&
                  <Button icon={<CheckOutlined />} type="primary" htmlType="button" disabled={this.state.isVerifiedDisabled} style={{ backgroundColor: '#12B86A', margin: 4, border: 0 }} onClick={() => {
                    // this.handleApproveServiceRequest(this.props.selectedRow.id)
                    this.handleVerifiedRequest(selectedRow.id)
                  }}>
                    Verified
                  </Button>
                } */}
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('orders', 'edit') &&
                    <Button type="primary" htmlType="submit" loading={this.props.updateServiceRequestLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }

                </Form.Item>
              </Col>
            </Row>
          </Form>

        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateServiceRequestLoading: state.admin.serviceRequest.updateServiceRequestLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateServiceRequest: (data, status) => dispatch(updateServiceRequest(data, status)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onApproveServiceRequest: (id, status, data) => dispatch(approveServiceRequest(id, status, data)),
    onVerifiedServiceRequest: (id, status, data) => dispatch(verifiedServiceRequest(id, status, data)),
    onLoadServiceById: (id) => dispatch(loadServiceById(id)),
    onUnloadTenant: () => dispatch(unLoadTenant())
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))
