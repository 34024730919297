import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { updatePost } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { upload } from '../../../../../utilities/filesController'
import { get } from '../../../../../utilities/filesController'

const { Option } = Select;

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileImage: '',
      isLoading: false,
      editorState: props.selectedRow.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.selectedRow.content))) : EditorState.createEmpty(),
    }
    this.onEditorStateChange = this.onEditorStateChange.bind(this)
  }


  componentDidMount() {
    this.setState({ fileImage: '' })
    this.loadSelectedContent()
  }

  handleUpdate(values) {
    const submitData = {
      "postById": getUser().id,
      "title": values.title,
      "description": values.description,
      "attachedUrl": values.attachedUrl,
      "type": values.type,
      "content": JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
      // "status": values.status === 'Active' ? 1 : 0
      "status": 1
    }
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id
    if (this.state.fileImage.length === 0) {
      this.props.onUpdatePost({ ...submitData, id: selectedRowId })
    } else {
      this.setState({ isLoading: true })
      upload(this.state.fileImage[0], this.state.fileImage[0].name, 'images').then(data => {
        this.setState({ isLoading: false })
        this.props.onUpdatePost({ ...submitData, image: `images/${this.state.fileImage[0].name}`, id: selectedRowId })
      }).catch(err => {
        this.setState({ isLoading: false })
      })
    }
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };

  loadSelectedContent() {
    let self = this
    if (this.props.selectedRow.content) {
      const content = JSON.parse(self.props.selectedRow.content)
      const entityObject = content.entityMap
      Object.keys(entityObject).map(function (key, index) {
        const source = entityObject[key].data.src
        if (source) {
          const urlSplitted = source.split('/')
          const folderName = urlSplitted[urlSplitted.length - 2]
          const fileName = urlSplitted[urlSplitted.length - 1].split('?')[0]
          const fileFolderName = `${folderName}/${fileName}`
          get(fileFolderName).then((res) => {
            entityObject[key].data.src = res
            content.entityMap = entityObject
            self.setState({ editorState: EditorState.createWithContent(convertFromRaw(content)) })
          })
        }
      });
    }
  }



  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  uploadCallback(file) {
    return upload(file, file.name, 'images').then(data => {
      return new Promise(
        (resolve, reject) => { resolve({ data: { link: data.completeUrl } }); }
      );
    }).catch(err => { })
  }


  render() {
    const { editorState } = this.state;
    return (
      <Modal
        title="EDIT ANNOUNCEMENT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={false}>
          <Form
            name="basic"
            initialValues={{ ...this.props.selectedRow }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Attached Url"
                  name="attachedUrl"
                  rules={[{ required: true, message: 'Please input your attached url!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'ANNOUNCEMENTS'}>ANNOUNCEMENTS</Option>
                    <Option value={'EVENTS'}>EVENTS</Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ message: 'Please input your status!' }]}
                >
                  <Select defaultValue="" style={{ width: '100%' }} >
                    <Option value={'Active'}>Active</Option>
                    <Option value={'Inactive'}>Inactive</Option>
                  </Select>
                </Form.Item> */}
              </Col>
            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Editor
                  editorState={editorState}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{ image: { uploadCallback: this.uploadCallback, alignmentEnabled: true, previewImage: true, urlEnabled: false, alt: { present: true, mandatory: false }, } }}
                /></Col>
            </Row>


            {
              this.access('announcements', 'edit') &&
              <Row>
                <Col md={24} lg={24} sm={24} style={{ marginTop: 5 }} align={'right'}>
                  <Form.Item >
                    <Button type="primary" htmlType="submit" loading={this.props.updatePostLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            }

          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updatePostLoading: state.admin.post.updatePostLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdatePost: (data) => dispatch(updatePost(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))
