import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Pagination, Tag } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadAmenity, deleteAmenity, updateAmenity } from '../actions'
import { getParamFromUrl } from '../../../../utilities/helper'
import noAvailableImage from '../../../../assets/images/noavailableimage.png'
import { ImagePresignedUrl } from '../../../../utilities/renderer'

const { Search } = Input;
const { TabPane } = Tabs;

class Amenities extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      reportType: '0',

      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if ((this.props.amenitySuccess !== nextProps.amenitySuccess) && nextProps.amenitySuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Amenity added successfully.")
      this.props.onLoadAmenity(pagination)
    }
    if ((this.props.updateAmenitySuccess !== nextProps.updateAmenitySuccess) && nextProps.updateAmenitySuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Amenity edited successfully.")
      this.props.onLoadAmenity(pagination)
    }
    if ((this.props.deleteAmenitySuccess !== nextProps.deleteAmenitySuccess) && nextProps.deleteAmenitySuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Amenity deleted successfully.")
      this.props.onLoadAmenity(pagination)
    }
    if ((this.props.amenitiesSuccess !== nextProps.amenitiesSuccess) && nextProps.amenitiesSuccess) {
      this.setState({ tableData: nextProps.amenitiesData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    this.props.onLoadAmenity(pagination)

    if (getParamFromUrl('modal') === 'true') {
      this.setState({ isEditModalOpen: true })
    }
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteAmenity(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteAmenity(id)
      },
      onCancel() { },
    });
  }

  onChangePagination(page) {
    const { limit, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadAmenity(pagination)
    this.setState({ page: page })
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadAmenity(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const dataSource = this.state.tableData

    const columns = [
      {
        title: 'Image',
        dataIndex: 'images',
        key: 'images',
        render: (images) => {
          return (
            <ImagePresignedUrl src={(images && images[0]) ? `${images[0].url}` : noAvailableImage} style={{ height: 100, width: 150, objectFit: 'cover' }} />
          )
        }
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => (a.title && a.title.length) - (b.title && b.title.length),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => (a.tenant && a.tenant.description && a.tenant.description.length) - (b.tenant && b.tenant.description && b.tenant.description.length),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              {
                this.access('amenities', 'view') &&
                <Button type="primary" icon={<EditOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} >View</Button>
              }
              {
                this.access('amenities', 'delete') &&
                <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteAmenity(data.id)}  >Delete</Button>
              }
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='amenities-container'>

        <Layout.Content>
          <div className='amenity-title'>
            <h1>Amenities</h1>
          </div>
          <Row className='amenity-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('amenities', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Name, Email, Phone Number, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value, ['tenant.firstName', 'tenant.lastName', 'title', 'description', 'comment', 'driver', 'number', 'description', 'plate', 'status'])} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table style={{ width: '100%' }} rowKey='id'
              loading={this.props.amenitiesLoading || this.props.deleteAmenityLoading || this.props.updateAmenityLoading}
              dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={e => this.onChangePagination(e)} total={this.props.amenitiesData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    amenityLoading: state.admin.amenity.amenityLoading,
    amenitySuccess: state.admin.amenity.amenitySuccess,
    updateAmenitySuccess: state.admin.amenity.updateAmenitySuccess,
    deleteAmenitySuccess: state.admin.amenity.deleteAmenitySuccess,
    amenitiesSuccess: state.admin.amenity.amenitiesSuccess,
    amenitiesData: state.admin.amenity.amenitiesData,
    amenitiesLoading: state.admin.amenity.amenitiesLoading,
    deleteAmenityLoading: state.admin.amenity.deleteAmenityLoading,
    updateAmenityLoading: state.admin.amenity.updateAmenityLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAmenity: (pagination) => dispatch(loadAmenity(pagination)),
    onDeleteAmenity: (id) => dispatch(deleteAmenity(id)),
    onUpdateStatus: (data) => dispatch(updateAmenity(data)),


  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Amenities))
