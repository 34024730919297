import {
  GET_RESERVATION,
  GET_RESERVATION_FULFILLED,
  GET_RESERVATION_REJECTED,
  PUT_RESERVATION,
  PUT_RESERVATION_FULFILLED,
  PUT_RESERVATION_REJECTED,
  POST_RESERVATION,
  POST_RESERVATION_FULFILLED,
  POST_RESERVATION_REJECTED,
  DELETE_RESERVATION,
  DELETE_RESERVATION_FULFILLED,
  DELETE_RESERVATION_REJECTED
} from '../actions'

export default function reservation(state = {
  reservationLoading: false,
  reservationSuccess: false,
  reservationRejected: false,
  reservationData: [],
  updateReservationLoading: false,
  updateReservationSuccess: false,
  updateReservationRejected: false,
  addReservationLoading: false,
  addReservationSuccess: false,
  addReservationRejected: false,
  deleteReservationLoading: false,
  deleteReservationSuccess: false,
  deleteReservationRejected: false
}, action) {
  switch (action.type) {
    case GET_RESERVATION:
      return {
        ...state,
        reservationLoading: true,
        reservationSuccess: false,
        reservationRejected: false
      }
    case GET_RESERVATION_FULFILLED:
      return {
        ...state,
        reservationLoading: false,
        reservationSuccess: true,
        reservationData: action.payload
      }
    case GET_RESERVATION_REJECTED:
      return {
        ...state,
        reservationLoading: false,
        reservationRejected: true
      }
    case POST_RESERVATION:
      return {
        ...state,
        addReservationLoading: true,
        addReservationSuccess: false,
        addReservationRejected: false
      }
    case POST_RESERVATION_FULFILLED:
      return {
        ...state,
        addReservationLoading: false,
        addReservationSuccess: true,
        addReservationData: action.payload
      }
    case POST_RESERVATION_REJECTED:
      return {
        ...state,
        addReservationLoading: false,
        addReservationRejected: true
      }
    case PUT_RESERVATION:
      return {
        ...state,
        updateReservationLoading: true,
        updateReservationSuccess: false,
        updateReservationRejected: false
      }
    case PUT_RESERVATION_FULFILLED:
      return {
        ...state,
        updateReservationLoading: false,
        updateReservationSuccess: true,
        updateReservationData: action.payload
      }
    case PUT_RESERVATION_REJECTED:
      return {
        ...state,
        updateReservationLoading: false,
        updateReservationRejected: true
      }



    case DELETE_RESERVATION:
      return {
        ...state,
        deleteReservationLoading: true,
        deleteReservationSuccess: false,
        deleteReservationRejected: false
      }
    case DELETE_RESERVATION_FULFILLED:
      return {
        ...state,
        deleteReservationLoading: false,
        deleteReservationSuccess: true,
        deleteReservationData: action.payload
      }
    case DELETE_RESERVATION_REJECTED:
      return {
        ...state,
        deleteReservationLoading: false,
        deleteReservationRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
