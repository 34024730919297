import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_COMMENTS = 'GET_COMMENTS'
export const GET_COMMENTS_FULFILLED = 'GET_COMMENTS_FULFILLED'
export const GET_COMMENTS_REJECTED = 'GET_COMMENTS_REJECTED'

export const COMMENT_COMMENT = 'COMMENT_COMMENT'
export const COMMENT_COMMENT_FULFILLED = 'COMMENT_COMMENT_FULFILLED'
export const COMMENT_COMMENT_REJECTED = 'COMMENT_COMMENT_REJECTED'

export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_COMMENT_FULFILLED = 'DELETE_COMMENT_FULFILLED'
export const DELETE_COMMENT_REJECTED = 'DELETE_COMMENT_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadComments = (data) => {
  return dispatch => {
    dispatch({
      type: GET_COMMENTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/approved-commendations`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_COMMENTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_COMMENTS_REJECTED,
          payload: error
        })
      })
  }
}

export const submitComments = (data) => {
  return dispatch => {
    dispatch({
      type: COMMENT_COMMENT,
      payload: {}
    })
    return Axios.comment(`${API_URL}/comment`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch(loadComments())
        return dispatch({
          type: COMMENT_COMMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: COMMENT_COMMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteComment = (commentId) => {
  return dispatch => {
    dispatch({
      type: DELETE_COMMENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/comment/${commentId} `, { headers: headers })
      .then(function (response) {
        dispatch(loadComments())
        return dispatch({
          type: DELETE_COMMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_COMMENT_REJECTED,
          payload: error
        })
      })
  }
}





