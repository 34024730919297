import React from 'react'
import { Modal, Spin } from 'antd'
import { v4 as uuidv4 } from 'uuid';
import { EmbedPresignedUrl } from '../../../../../utilities/renderer';
class Edit extends React.Component {

  render() {
    return (
      <Modal
        title="VIEW BILLING'S INFORMATION"
        centered
        visible={this.props.visible}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={false}>
          <EmbedPresignedUrl style={{ width: '100%', height: '70vh' }} src={'soa/pdf/' + this.props.selectedRow.soaName} />
        </Spin>
      </Modal>
    )
  }
}

export default Edit
