import {
  GET_POSTS,
  GET_POSTS_FULFILLED,
  GET_POSTS_REJECTED,
  GET_POST_LATEST,
  GET_POST_LATEST_FULFILLED,
  GET_POST_LATEST_REJECTED
} from '../actions'

export default function posts(state = {
  postsLoading: false,
  postsSuccess: false,
  postsRejected: false,
  postsData: {
    data: []
  },
  postLatestLoading: false,
  postLatestSuccess: false,
  postLatestRejected: false,
  postLatestData: {}

}, action) {
  switch (action.type) {
    case GET_POSTS:
      return {
        ...state,
        postsLoading: true,
        postsSuccess: false,
        postsRejected: false
      }
    case GET_POSTS_FULFILLED:
      return {
        ...state,
        postsLoading: false,
        postsSuccess: true,
        postsData: action.payload
      }
    case GET_POSTS_REJECTED:
      return {
        ...state,
        postsLoading: false,
        postsRejected: true
      }
    case GET_POST_LATEST:
      return {
        ...state,
        postLatestLoading: true,
        postLatestSuccess: false,
        postLatestRejected: false
      }
    case GET_POST_LATEST_FULFILLED:
      return {
        ...state,
        postLatestLoading: false,
        postLatestSuccess: true,
        postLatestData: action.payload
      }
    case GET_POST_LATEST_REJECTED:
      return {
        ...state,
        postLatestLoading: false,
        postLatestRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
