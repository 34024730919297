import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Collapse } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadMemorandum, deleteMemorandum } from '../actions'
import { get } from '../../../../utilities/filesController'

const { Panel } = Collapse;
const { Search } = Input;
const { TabPane } = Tabs;
class Memorandums extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: {},
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.memorandumSuccess !== nextProps.memorandumSuccess) && nextProps.memorandumSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Memorandum added successfully.")
    }
    if ((this.props.updateMemorandumSuccess !== nextProps.updateMemorandumSuccess) && nextProps.updateMemorandumSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Memorandum edited successfully.")
    }
    if ((this.props.deleteMemorandumSuccess !== nextProps.deleteMemorandumSuccess) && nextProps.deleteMemorandumSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Memorandum deleted successfully.")
    }
    if ((this.props.memorandumsSuccess !== nextProps.memorandumsSuccess) && nextProps.memorandumsSuccess) {
      this.setState({ tableData: nextProps.memorandumsData })
    }
  }

  componentDidMount() {
    this.props.onLoadMemorandum()
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteMemorandum(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteMemorandum(id)
      },
      onCancel() { },
    });
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch(searchTerm, searchKeys) {
    // function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split('.')
      while (arr.length && (obj = obj[arr.shift()]));
      return obj
    }

    const newArrayObj = this.props.memorandumsData
    // if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj })
    }
    const distributorArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        const rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        console.log("rowItemKeysrowItemKeys", rowItemKeys)
        const isFound = rowItemKeys && rowItemKeys.toLowerCase().includes(searchTerm && searchTerm.toLowerCase())
        if (isFound) distributorArray.push(rowItem)
      })
    })
    // Removed Duplicate Entry in Array.
    const newArrayElement = distributorArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem)
    })
    this.setState({ tableData: newArrayElement })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  async urlOpen(pathname) {
    const url = await get(pathname)
    window.open(url);
  }


  render() {
    const dataSource = this.state.tableData
    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title && a.title.localeCompare(b.title)
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description && a.description.localeCompare(b.description && b.description.length)
      },
      {
        title: 'Files',
        dataIndex: 'files',
        key: 'files',
        render: (item) => {
          return (
            <Collapse ghost>
              {
                item && item.map((data, index) => {
                  return (
                    <Panel header={data.name} key={index}>
                      <a onClick={() => this.urlOpen(data.url)} >{`${data.url}`}</a>
                    </Panel>
                  )
                })
              }
            </Collapse>
          )

        }
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   key: 'status',
      //   sorter: (a, b) => a.status.length - b.status.length,
      // },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (

            <div className='action-btn'>
              {
                this.access('memorandums', 'view') &&
                <Button type="primary" icon={<EditOutlined />} size={'medium'} title='Edit' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} >View</Button>
              }
              {
                this.access('memorandums', 'delete') &&
                <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteMemorandum(data.id)}  >Delete</Button>
              }
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='memorandums-container'>

        <Layout.Content>
          <div className='memorandum-title'>
            <h1>Building Circulars and Memorandums</h1>
          </div>
          <Row className='memorandum-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('memorandums', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Title, Description Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value, ['title', 'description'])} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table style={{ width: '100%' }} rowKey='id' loading={this.props.memorandumsLoading || this.props.deleteMemorandumLoading} dataSource={dataSource} columns={columns} />
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    memorandumLoading: state.admin.memorandums.memorandumLoading,
    memorandumSuccess: state.admin.memorandums.memorandumSuccess,
    updateMemorandumSuccess: state.admin.memorandums.updateMemorandumSuccess,
    deleteMemorandumSuccess: state.admin.memorandums.deleteMemorandumSuccess,
    memorandumsSuccess: state.admin.memorandums.memorandumsSuccess,
    memorandumsData: state.admin.memorandums.memorandumsData,
    memorandumsLoading: state.admin.memorandums.memorandumsLoading,
    deleteMemorandumLoading: state.admin.memorandums.deleteMemorandumLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadMemorandum: (data) => dispatch(loadMemorandum(data)),
    onDeleteMemorandum: (id) => dispatch(deleteMemorandum(id))

  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Memorandums))
