import {
  GET_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_FULFILLED,
  GET_TERMS_AND_CONDITIONS_REJECTED,
} from '../actions'

const initialData = {
  loggingIn: false,
  loggedIn: false,
  loginRejected: false,
  loggedData:
  {
    user: {
      username: '',
      email: ''
    }
  },
  termsAndConditionsLoading: false,
  termsAndConditionsSuccess: false,
  termsAndConditionsData: {
    value: ''
  }

}
export default function login(state = initialData, action) {
  switch (action.type) {
    case 'POST_LOGIN_DATA':
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        loginRejected: false
      }
    case 'POST_LOGIN_DATA_FULFILLED':
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loggedData: action.payload
      }
    case 'POST_LOGIN_DATA_REJECTED':
      return {
        ...state,
        loggingIn: false,
        loginRejected: true
      }
    case GET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditionsLoading: true,
        termsAndConditionsSuccess: false,
        termsAndConditionsRejected: false
      }
    case GET_TERMS_AND_CONDITIONS_FULFILLED:
      return {
        ...state,
        termsAndConditionsLoading: false,
        termsAndConditionsSuccess: true,
        termsAndConditionsData: action.payload
      }
    case GET_TERMS_AND_CONDITIONS_REJECTED:
      return {
        ...state,
        termsAndConditionsLoading: false,
        termsAndConditionsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
