import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_INCIDENTREPORT = 'POST_INCIDENTREPORT'
export const POST_INCIDENTREPORT_FULFILLED = 'POST_INCIDENTREPORT_FULFILLED'
export const POST_INCIDENTREPORT_REJECTED = 'POST_INCIDENTREPORT_REJECTED'

export const GET_INCIDENTREPORTS = 'GET_INCIDENTREPORTS'
export const GET_INCIDENTREPORTS_FULFILLED = 'GET_INCIDENTREPORTS_FULFILLED'
export const GET_INCIDENTREPORTS_REJECTED = 'GET_INCIDENTREPORTS_REJECTED'

export const GET_INCIDENTREPORT = 'GET_INCIDENTREPORT'
export const GET_INCIDENTREPORT_FULFILLED = 'GET_INCIDENTREPORT_FULFILLED'
export const GET_INCIDENTREPORT_REJECTED = 'GET_INCIDENTREPORT_REJECTED'

export const PUT_INCIDENTREPORT = 'PUT_INCIDENTREPORT'
export const PUT_INCIDENTREPORT_FULFILLED = 'PUT_INCIDENTREPORT_FULFILLED'
export const PUT_INCIDENTREPORT_REJECTED = 'PUT_INCIDENTREPORT_REJECTED'

export const DELETE_INCIDENTREPORT = 'DELETE_INCIDENTREPORT'
export const DELETE_INCIDENTREPORT_FULFILLED = 'DELETE_INCIDENTREPORT_FULFILLED'
export const DELETE_INCIDENTREPORT_REJECTED = 'DELETE_INCIDENTREPORT_REJECTED'

export const GET_SELECTED_KEYS_REPORT = 'GET_SELECTED_KEYS_REPORT'
export const GET_SELECTED_KEYS_REPORT_FULFILLED = 'GET_SELECTED_KEYS_REPORT_FULFILLED'
export const GET_SELECTED_KEYS_REPORT_REJECTED = 'GET_SELECTED_KEYS_REPORT_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadIncidentReport = (status, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_INCIDENTREPORTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/reports?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&status=${status}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_INCIDENTREPORTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_INCIDENTREPORTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadIncidentReportById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_INCIDENTREPORT,
      payload: {}
    })
    return Axios.get(`${API_URL}/report/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_INCIDENTREPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_INCIDENTREPORT_REJECTED,
          payload: error
        })
      })
  }
}




export const submitIncidentReport = (data) => {
  return dispatch => {
    dispatch({
      type: POST_INCIDENTREPORT,
      payload: {}
    })
    return Axios.post(`${API_URL}/report`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_INCIDENTREPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_INCIDENTREPORT_REJECTED,
          payload: error
        })
      })
  }
}

export const updateIncidentReport = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_INCIDENTREPORT,
      payload: {}
    })
    return Axios.put(`${API_URL}/report/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_INCIDENTREPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_INCIDENTREPORT_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteIncidentReport = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_INCIDENTREPORT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/report/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_INCIDENTREPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_INCIDENTREPORT_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSelectedRowReport = (selectedKeys) => {
  return dispatch => {
    dispatch({
      type: GET_SELECTED_KEYS_REPORT,
      payload: {}
    })
    return Axios.get(`${API_URL}/reportIds?selectedIds=${selectedKeys.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SELECTED_KEYS_REPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SELECTED_KEYS_REPORT_REJECTED,
          payload: error
        })
      })
  }
}




