import React from 'react'
import { Row, Col, Form, Button, Input, Spin, DatePicker, Modal } from 'antd'
import { getUser } from '../../../../../utilities/token'
import ImageUploader from './imageBulkUploader'
import { connect } from 'react-redux'
import { submitVisitor } from '../../actions'
import SlidingPane from "react-sliding-pane";
import moment from 'moment'
import { upload } from '../../../../../utilities/filesController'
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      fileImage: undefined,
      fileImageList: []
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }


  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": getUser().id,
      "approvalDate": values.approvalDate,
      "visitorsName": values.visitorsName,
      "address": values.address,
      "dateOfVisit": values.dateOfVisit,
      "purposeOfVisit": values.purposeOfVisit,
      "email": values.email,
      "phoneNumber": values.phoneNumber,
      "birthDate": values.birthDate,
      "gender": values.gender,
      "images": this.state.fileImageList
    }

    Promise.all([
      this.uploadFileImage(),
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onSubmitVisitor(submitData).then(res => {
          this.setState({ isLoading: false })
        })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'images').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="ADD VISITOR"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Name of Visitor"
                  name="visitorsName"
                  rules={[{ required: true, message: 'Please input the name of visitor!' }]}
                >
                  <Input className='primary-input' placeholder='John Doe' />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: 'Please input the address!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input the email!' }]}
                >
                  <Input className='primary-input' placeholder='johndoe@gmail.com' />
                </Form.Item>

                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the phone number!'
                    },
                    {
                      pattern: /^[\d]{11,11}$/,
                      message: "Phone number should be 11 digits",
                    },
                  ]}
                >
                  <Input maxLength={11} placeholder='Ex: 09854411254' className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date of Visit"
                  name="dateOfVisit"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date of visit!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Purpose of Visit"
                  name="purposeOfVisit"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the purpose of visit!',
                    },
                  ]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Col lg={24} md={24} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1>Upload scanned copy of one (1) valid government - issued id with photo</h1>
                  </div>
                  <ImageUploader
                    getFile={e => this.getFileImage(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Single file</span>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit Visitor
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}


function mapStateToProps(state) {
  return {
    postLoading: state.client.visitors.postLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitVisitor: (data) => dispatch(submitVisitor(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
