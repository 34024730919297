import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_FEEDBACKS = 'GET_FEEDBACKS'
export const GET_FEEDBACKS_FULFILLED = 'GET_FEEDBACKS_FULFILLED'
export const GET_FEEDBACKS_REJECTED = 'GET_FEEDBACKS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadFeedback = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_FEEDBACKS,
      payload: {}
    })
    return Axios.get(`${API_URL}/feedbacks?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_FEEDBACKS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_FEEDBACKS_REJECTED,
          payload: error
        })
      })
  }
}

