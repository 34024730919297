import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, DatePicker, Spin } from 'antd'
import { connect } from 'react-redux'
import { updateReservation } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import { loadAmenity } from '../../../../admin/amenities/actions'
import moment from 'moment'
import SlidingPane from "react-sliding-pane";

const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
    }
    this.range = this.range.bind(this)
    this.disabledDateTime = this.disabledDateTime.bind(this)
  }

  componentDidMount() {
    const pagination = {
      page: 1,
      limit: 0,
      searchTerm: ''
    }
    this.props.onLoadAmenity(pagination)
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id

    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "description": values.description,
      "amenityId": values.amenityId,
      "startTime": values.startTime,
      "endTime": values.endTime,
      "estimatedAmount": values.estimatedAmount
    }

    this.props.onUpdateReservation({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDateTime() {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, , 22, 23, 24],
      disabledMinutes: () => this.range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }

  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1

    const initialValues = {
      "tenantId": selectedRow && selectedRow.tenantId,
      "amenityId": selectedRow && selectedRow.amenityId,
      "title": selectedRow && selectedRow.title,
      "description": selectedRow && selectedRow.description,
      "comment": selectedRow && selectedRow.comment,
      "startTime": moment(selectedRow && selectedRow.startTime),
      "endTime": moment(selectedRow && selectedRow.endTime),
      "remarks": selectedRow && selectedRow.remarks,
      "estimatedAmount": selectedRow && selectedRow.estimatedAmount,
      "status": this.getStatusLabel(selectedRow && selectedRow.status),

      approvedBy: selectedRow && selectedRow.approver && selectedRow && selectedRow.approver.name || '',
      approvalDate: selectedRow && selectedRow.approvalDate && moment(selectedRow && selectedRow.approvalDate).format('LLL') || '',
      completedDate: moment(selectedRow && selectedRow.completedDate).format('LLL') || '',
      verifiedBy: selectedRow && selectedRow.verifier && selectedRow && selectedRow.verifier.name || '',
    }
    return (

      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT RESERVATION"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={this.props.amenitiesLoading}>

          <Form
            name="basic"
            initialValues={initialValues}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Amenity"
                  name="amenityId"
                  rules={[{ required: true, message: 'Please input your amenity!' }]}
                >
                  <Select style={{ width: '100%' }} disabled={disabled} className='primary-input-select'>
                    {
                      this.props.amenitiesData && this.props.amenitiesData.data.map((data, index) => {
                        return (
                          <Option key={data.id} >{data.title}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please choose title!' }]}
                >
                  <Select
                    className='primary-input-select'
                    placeholder="Select a Title"
                    disabled={disabled}
                  >
                    <Option value="Amenity Booking" >Amenity Booking</Option>
                    <Option value="Event Booking" >Event Booking</Option>
                  </Select>
                </Form.Item>


                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} disabled={disabled} className='primary-input' />
                </Form.Item>


                <Form.Item
                  label="Start Time (6AM – 9PM)"
                  name="startTime"
                  rules={[{ required: true, message: 'Please input your start time!' }]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    disabledDate={this.disabledDate}
                    disabledTime={this.disabledDateTime}
                  />
                </Form.Item>
                <Form.Item
                  label="End Time (6AM – 9PM)"
                  name="endTime"
                  rules={[{ required: true, message: 'Please input your end time!' }]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    disabledDate={this.disabledDate}
                    disabledTime={this.disabledDateTime}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Estimated Amount"
                  name="estimatedAmount"
                  rules={[{ required: true, message: 'Please input your estimated amount!' }]}
                >
                  <Input type="number" disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                >
                  <Input.TextArea rows={5} disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Completed Date"
                  name="completedDate"
                >
                  <Input readOnly disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select readOnly style={{ width: '100%' }} disabled className='primary-input'>
                    {/* <Option value="">None</Option> */}
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    <Option value={'APPROVED'}>APPROVED</Option>
                    <Option value={'ONGOING'}>ONGOING</Option>
                    <Option value={'COMPLETED'}>COMPLETED</Option>
                    <Option value={'CANCELLED'}>CANCELLED</Option>
                    <Option value={'REJECTED'}>REJECTED</Option>
                  </Select>
                </Form.Item>
              </Col>

            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={this.props.updateReservationLoading || this.state.isLoading} disabled={disabled}>
                    Update Reservation
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane >
    )
  }
}

function mapStateToProps(state) {
  return {
    updateReservationLoading: state.client.reservations.updateReservationLoading,
    amenitiesData: state.admin.amenity.amenitiesData,
    amenitiesLoading: state.admin.amenity.amenitiesLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateReservation: (data) => dispatch(updateReservation(data)),
    onLoadAmenity: (pagination) => dispatch(loadAmenity(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

