import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Collapse, Select, Tag, Pagination } from 'antd'
import { ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadService, deleteService, updateService } from '../actions'
import moment from 'moment'
const { Search } = Input;
class Services extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,

      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
    this.timeout = 0;
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    if ((this.props.serviceSuccess !== nextProps.serviceSuccess) && nextProps.serviceSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Service added successfully.")
      this.props.onLoadService(pagination)
    }
    if ((this.props.updateServiceSuccess !== nextProps.updateServiceSuccess) && nextProps.updateServiceSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Service edited successfully.")
      this.props.onLoadService(pagination)
    }
    if ((this.props.deleteServiceSuccess !== nextProps.deleteServiceSuccess) && nextProps.deleteServiceSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Service deleted successfully.")
      this.props.onLoadService(pagination)
    }
    if ((this.props.servicesSuccess !== nextProps.servicesSuccess) && nextProps.servicesSuccess) {
      this.setState({ tableData: nextProps.servicesData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    this.props.onLoadService(pagination)
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteService(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteService(id)
      },
      onCancel() { },
    });
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadService(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadService(pagination)
    this.setState({ page: page })
  }

  getStatus(status) {
    switch (status) {
      case 0:
        return <Tag color="red">DELETED</Tag>
      case 1:
        return <Tag color="gold">FOR APPROVAL</Tag>
      case 2:
        return <Tag color="lime">APPROVED</Tag>
      case 3:
        return <Tag color="geekblue">ONGOING</Tag>
      case 4:
        return <Tag color="green">COMPLETED</Tag>
      case 5:
        return <Tag color="magenta">CANCELLED</Tag>
      case 6:
        return <Tag color="red">REJECTED</Tag>
      default:
        break;
    }
  }

  getServiceOrigin(origin) {
    switch (origin) {
      case 'requests':
        return 'admin/service-requests'
      case 'tickets':
        return 'admin/tickets'
      case 'reservations':
        return 'admin/reservations'
      case 'reports':
        return 'admin/incident-reports'
      case 'permits':
        return 'admin/permits'
      case 'visitors':
        return 'admin/visitors'
      case 'rentalInquiries':
        return 'admin/inquiries'
      case 'commendations':
        return 'admin/commendations'
      default:
        break;
    }
  }

  redirect(origin, id) {
    const urlPath = `${window.location.origin}/${this.getServiceOrigin(origin)}?modal=true&serviceId=${id}`
    window.location.href = urlPath
  }

  render() {
    const dataSource = this.state.tableData

    const columns = [
      {
        title: 'Service Ticket #',
        dataIndex: 'ticketNumber',
        key: 'ticketNumber',
        sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
      },
      {
        title: 'Client Code',
        dataIndex: 'tenant',
        key: 'clientCode',
        sorter: (a, b) => a.tenant.clientCode && a.tenant.clientCode.localeCompare(b.tenant && b.tenant.clientCode),
        render: (item, data) => {
          return (
            <span>{item && `${item.clientCode || ''}`}</span>
          )
        }
      },
      {
        title: 'Client Name',
        dataIndex: 'tenant',
        key: 'uoccname',
        sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
        render: (item, data) => {
          return (
            <span>{item && `${item.uoccname || ''}`}</span>
          )
        }
      },
      {
        title: 'Created Date',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
        render: (item, data) => {
          return (
            <span>{item && `${moment(item).format('LLL')}`}</span>
          )
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (item) => {
          return (<span>{this.getStatus(item)}</span>)
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              <Button type="primary" icon={<EditOutlined />}
                onClick={() => this.redirect(data.origin, data.id)}
                size={'medium'} title='Edit'>View</Button>
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='services-container'>

        <Layout.Content>
          <div className='service-title'>
            <h1>Services</h1>
          </div>
          <Row className='service-header'>
            <Col md={14} lg={14} sm={24} >
              {/* <div className='action-btn'>
                <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
              </div> */}
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Service Ticket, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table style={{ width: '100%' }} rowKey='ticketNumber'
              loading={this.props.servicesLoading || this.props.deleteServiceLoading || this.props.updateServiceLoading}
              dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.servicesData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    serviceLoading: state.admin.services.serviceLoading,
    serviceSuccess: state.admin.services.serviceSuccess,
    updateServiceSuccess: state.admin.services.updateServiceSuccess,
    deleteServiceSuccess: state.admin.services.deleteServiceSuccess,
    servicesSuccess: state.admin.services.servicesSuccess,
    servicesData: state.admin.services.servicesData,
    servicesLoading: state.admin.services.servicesLoading,
    deleteServiceLoading: state.admin.services.deleteServiceLoading,
    updateServiceLoading: state.admin.services.updateServiceLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadService: (pagination) => dispatch(loadService(pagination)),
    onDeleteService: (id) => dispatch(deleteService(id)),
    onUpdateStatus: (data) => dispatch(updateService(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Services))
