
import React, { useEffect, useState, useRef } from 'react'
import { Badge, Modal, Button, Row, Col } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.scss'
import { loadNotifications, loadNotificationsCount, submitReadNotificationsIds, GET_NOTIFICATIONS_COUNT_FULFILLED, GET_NOTIFICATIONS_FULFILLED } from '../actions'
import { getUser } from '../../../../utilities/token'
import {
    BellOutlined,
} from '@ant-design/icons'
import ReactTimeAgo from 'react-time-ago'
import InfiniteScroll from "react-infinite-scroll-component";
import _ from 'lodash'

const Notifications = (props) => {
    const [showNotification, setShowNotification] = useState(false)
    const [notifCount, setNotifCount] = useState(0)
    const [firstOpen, setFirstOpen] = useState(false)
    const [displayData, setDisplayData] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [selectedNotif, setSelectedNotif] = useState({})
    const wrapperRef = useRef(null);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10
    })

    useEffect(() => {
        const tenantId = getUser().id
        const filter = props.notificationsData.data.filter(data => data.isReadAdmin === 0)
        const filterIds = _.map(filter, 'id')
        props.onSubmitReadNotificationsIds(filterIds.toString()).then(() => {
            props.onLoadNotificationsCount(tenantId).then(res => {
                if (res.type === GET_NOTIFICATIONS_COUNT_FULFILLED) {
                    setNotifCount(res.payload)
                }
            })
        })

        const setRead = props.notificationsData.data.map(data => {
            return { ...data, isReadAdmin: 1 }
        })
        setDisplayData([...displayData, ...setRead])

    }, [props.notificationsData])

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowNotification(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    function loadMore() {
        const tenantId = getUser().id
        const newPagination = { ...pagination, page: pagination.page + 1 }
        props.onLoadNotifications(tenantId, newPagination)
        setPagination(newPagination)
    }

    function toggleShowNotifications() {
        setFirstOpen(true)
        setShowNotification(!showNotification)
        const tenantId = getUser().id

        if (firstOpen) return

        props.onLoadNotifications(tenantId, pagination).then(res => {
            if (res.type === GET_NOTIFICATIONS_FULFILLED) {
                const filter = res.payload.data.filter(data => data.isReadAdmin === 0)
                const filterIds = _.map(filter, 'id')
                props.onSubmitReadNotificationsIds(filterIds.toString())
                props.onLoadNotificationsCount(tenantId).then(res => {
                    if (res.type === GET_NOTIFICATIONS_COUNT_FULFILLED) {
                        setNotifCount(res.payload)
                    }
                })
            }
        })
    }

    return (
        <div className='notifications-container' ref={wrapperRef}>
            <div>
                <Badge count={notifCount}>
                    <BellOutlined style={{ fontSize: 24, backgroundColor: showNotification ? 'lightgray' : 'transparent' }} onClick={() => {
                        toggleShowNotifications()
                    }} />
                </Badge>
                <div className='notification-card' id='notification-card' style={{ visibility: showNotification ? 'visible' : 'hidden', maxHeight: 550, minHeight: 100, overflow: "auto" }}>
                    {
                        displayData.length !== 0 ?
                            <InfiniteScroll
                                dataLength={displayData.length}
                                next={loadMore}
                                hasMore={true && (props.notificationsData.to !== props.notificationsData.total)}
                                loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
                                scrollableTarget='notification-card'
                            >
                                {
                                    displayData.map((data, index) => {
                                        console.log("data.isReadAdmin", data.isReadAdmin)
                                        return (
                                            <div className={data.isReadAdmin === 0 ? 'notification-row' : 'notification-row read'} key={`${index}_${data.id}`} onClick={() => {
                                                setModalShow(true)
                                                setShowNotification(false)
                                                setSelectedNotif(data)
                                            }} >
                                                <h1>{data.title}</h1>
                                                {
                                                    data.tenant &&
                                                    <p>{data.tenant.uoccname && data.tenant.uoccname.toUpperCase()} ({data.tenant && data.tenant.clientCode})</p>
                                                }
                                                <p>{data.message}</p>
                                                <div className='time-ago'>
                                                    <ReactTimeAgo date={data.created_at} locale="en-US" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteScroll> :
                            <div className='empty-message'>
                                <h1 style={{ textAlign: 'center' }}>You don't have any notifications yet.</h1>
                            </div>
                    }

                </div>
            </div>
            <Modal title={`${selectedNotif.title}`} visible={modalShow} footer={null} onCancel={() => setModalShow(false)}>
                <p>{selectedNotif.message}</p>
                {
                    selectedNotif.tenant &&
                    <p>{selectedNotif.tenant.uoccname && selectedNotif.tenant.uoccname.toUpperCase()} ({selectedNotif.tenant && selectedNotif.tenant.clientCode})</p>
                }
                <br />
                <ReactTimeAgo date={selectedNotif.created_at} locale="en-US" />
                <Row>
                    <Col span={24} align='right'>
                        <Button className='primary-btn' onClick={() => setModalShow(false)} >Close</Button>
                    </Col>
                </Row>
            </Modal>
        </div >
    )
}

function mapStateToProps(state) {
    return {
        notificationsData: state.client.notifications.notificationsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadNotifications: (tenantId, pagination) => dispatch(loadNotifications(tenantId, pagination)),
        onLoadNotificationsCount: (tenantId) => dispatch(loadNotificationsCount(tenantId)),
        onSubmitReadNotificationsIds: (tenantId) => dispatch(submitReadNotificationsIds(tenantId)),


    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications))
