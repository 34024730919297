import React, { Fragment } from 'react'
import '../index.scss'
import { Row, Col, Layout, Table, Button, Input, Pagination, Card, Select, Tabs, message, Modal } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, CheckOutlined, DeleteOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { loadAdmins, deleteAdmin, PUT_SOA_INFO_FULFILLED } from '../../actions'
import moment from 'moment'
import RolesModal from './modals/roles'
import AddModal from './modals/add'
import EditModal from './modals/edit'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;


class UserAccessDefinition extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      isRolesModalOpen: false,
      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
    this.rolesModalToggle = this.rolesModalToggle.bind(this)
    this.timeout = 0;
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadAdmins(pagination)
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if ((this.props.addAdminSuccess !== nextProps.addAdminSuccess) && nextProps.addAdminSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Admin added successfully.")
      this.props.onLoadAdmins(pagination)
    }
    if ((this.props.editAdminSuccess !== nextProps.editAdminSuccess) && nextProps.editAdminSuccess) {
      this.setState({ isEditModalOpen: false, isRolesModalOpen: false })
      message.success("Admin edited successfully.")
      this.props.onLoadAdmins(pagination)
    }

    if ((this.props.deleteAdminSuccess !== nextProps.deleteAdminSuccess) && nextProps.deleteAdminSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Admin deleted successfully.")
      this.props.onLoadAdmins(pagination)
    }

    if ((this.props.adminsSuccess !== nextProps.adminsSuccess) && nextProps.adminsSuccess) {
      this.setState({ tableData: nextProps.adminsData.data })
    }
  }

  onChangePagination(page) {
    const { limit, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadAdmins(pagination)

    this.setState({ page: page })
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  rolesModalToggle() {
    this.setState({ isRolesModalOpen: !this.state.isRolesModalOpen })
  }

  handleDeleteAdmin(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to reject this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteAdmin(id)
      },
      onCancel() { },
    });
  }


  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadAdmins(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {

    const dataSource = this.state.tableData

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name && a.name.localeCompare(b.name)
      },

      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: (a, b) => a.firstName && a.firstName.localeCompare(b.firstName)
      },

      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: (a, b) => a.lastName && a.lastName.localeCompare(b.lastName)
      },

      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email && a.email.localeCompare(b.email)
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
        render: (info, data) => {
          return (
            <span>{moment(info).format('LLL')}</span>
          )
        }
      },
      {
        title: 'Actions',
        dataIndex: 'status',
        key: 'status',
        render: (item, data) => {
          return (
            <div className='action-btn'>
              {
                this.access('utilities', 'manageAccess') &&
                <Button type="primary" size={'medium'} icon={<EyeOutlined />} title='View' onClick={() => this.setState({ selectedRow: data, isRolesModalOpen: true, isModalEditEditable: false })} >Manage Access</Button>
              }
              {
                this.access('utilities', 'view') &&
                <Button type="primary" size={'medium'} icon={<EyeOutlined />} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true, isModalEditEditable: false })} >View</Button>
              }
              {
                this.access('utilities', 'delete') &&
                <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Reject' danger onClick={() => this.handleDeleteAdmin(data.id)} >Delete</Button>
              }
            </div>
          )
        }
      },
    ];

    return (
      <Card loading={this.props.servicesLoading} className='admin-access-definition-container'>
        <Row gutter={32}>
          <Layout.Content>
            <div className='admin-access-definition-title'>
              <h1>Administrator List</h1>
            </div>

            <Row className='admin-access-definition-header'>
              <Col md={14} lg={14} sm={24} >
                <div className='action-btn'>
                  {
                    this.access('utilities', 'add') &&
                    <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                  }
                </div>
              </Col>
              <Col md={10} lg={10} sm={24} className='input-search'>
                <Search placeholder="Search Name, Email, First Name, LastName, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
              </Col>
            </Row>
            <Row>
              <Table style={{ width: '100%' }} rowKey='id' loading={this.props.adminsLoading || this.props.deleteServiceRequestLoading} dataSource={dataSource} columns={columns} pagination={false} />
              <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right">
                  <Pagination defaultCurrent={this.state.page} onChange={e => this.onChangePagination(e)} total={this.props.adminsData.total} />
                </Col>
              </Row>
            </Row>
          </Layout.Content>
        </Row>

        {
          this.state.isRolesModalOpen &&
          <RolesModal
            visible={this.state.isRolesModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.rolesModalToggle}
          />
        }
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Card>
    )
  }
}


function mapStateToProps(state) {
  return {
    servicesLoading: state.admin.utilities.servicesLoading,
    adminsData: state.admin.utilities.adminsData,
    adminsSuccess: state.admin.utilities.adminsSuccess,
    adminsLoading: state.admin.utilities.adminsLoading,
    addAdminSuccess: state.admin.utilities.addAdminSuccess,
    editAdminSuccess: state.admin.utilities.editAdminSuccess,
    deleteAdminSuccess: state.admin.utilities.deleteAdminSuccess,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAdmins: (pagination) => dispatch(loadAdmins(pagination)),
    onDeleteAdmin: (id) => dispatch(deleteAdmin(id))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(UserAccessDefinition))
