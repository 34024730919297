import React from 'react'
import { Row, Col, Form, Modal, Button, Input, DatePicker } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { updatePolicy } from '../../../actions'
import PdfBulkUploader from '../pdfBulkUploader'
import { upload } from '../../../../../../utilities/filesController'

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      pdfFilesArray: []
    }
  }

  handleUpdate(values) {
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id
    const submitData = {
      "subHeader": values.subHeader,
      "title": values.title,
      "date": values.date,
    }

    if (this.state.pdfFilesArray.length === 0) {
      this.setState({ isLoading: false })
      this.props.onUpdatePolicy({ ...submitData, id: selectedRowId })
    } else {
      this.setState({ isLoading: false })
      this.uploadFilePdf().then(res => {
        this.props.onUpdatePolicy({ ...submitData, files: res, id: selectedRowId })
      })
    }
  }

  uploadFilePdf() {
    this.setState({ isLoading: true })
    return new Promise((resolve, reject) => {
      const returnArray = []
      this.state.pdfFilesArray.map((items, index) => {
        upload(items, items.name, 'pdf').then(data => {
          returnArray.push({ name: items.name, url: `pdf/${items.name}` })
        }).then(() => {
          if (this.state.pdfFilesArray.length === index + 1) {
            this.setState({ isLoading: false })
            return resolve(returnArray)
          }
        }).catch(err => {
          this.setState({ isLoading: false })
          return Modal.error({
            title: 'This is an error message for uploading images',
            content: err,
          });
        })
      })
    })
  }

  getPdfFileArray(e) {
    this.setState({ pdfFilesArray: e })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const { selectedRow } = this.props
    const initialValues = {
      "subHeader": selectedRow.subHeader,
      "title": selectedRow.title,
      "date": moment(selectedRow.date)
    }

    return (
      <Modal
        title="EDIT HOUSE RULES AND POLICIES"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={initialValues}
          onFinish={(e) => this.handleUpdate(e)}
        // onFinishFailed={onFinishFailed}
        >
          <Row gutter={12}>
            <Col md={24} lg={24} sm={24} xs={24}>

              <Form.Item
                label="Sub Header"
                name="subHeader"
                rules={[{ required: true, message: 'Please input your sub header!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please input your title!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Please input your date!' }]}
              >
                <DatePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <PdfBulkUploader
                getFile={e => this.getPdfFileArray(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={24} lg={24} sm={24} align={'right'}>
              <Form.Item >
                {
                  this.access('policies', 'edit') &&
                  <Button type="primary" style={{ marginTop: 10 }} htmlType="submit" loading={this.props.updatePolicyLoading || this.state.isLoading}>
                    Submit
                  </Button>
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updatePolicyLoading: state.admin.policies.updatePolicyLoading,
    tenantsData: state.admin.policies.tenantsData,
    tenantsLoading: state.admin.policies.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdatePolicy: (data, status) => dispatch(updatePolicy(data, status))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}