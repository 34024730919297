import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { updateReservation, loadReservationById, loadReservationSchedules, PUT_RESERVATION_REJECTED } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
import { getParamFromUrl } from '../../../../../utilities/helper'
import { loadAmenity } from '../../../amenities/actions'


const { Option } = Select;
class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      isLoading: false,
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
    this.timeout = 0;
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadReservationById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload,
          approvalDate: res.payload.approvalDate && moment(res.payload.approvalDate).format('LLL') || '',
          completedDate: moment(res.payload.completedDate).format('LLL') || '',
          "startTime": moment(res.payload.startTime),
          "endTime": moment(res.payload.endTime),
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }

  componentDidMount() {
    const pagination = {
      page: 1,
      limit: 0,
      searchTerm: ''
    }
    this.props.onLoadTenant({ id: this.props.selectedRow.tenantId })
    this.props.onLoadAmenity(pagination)
  }

  handleUpdate(values) {
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id
    const submitData = {
      "tenantId": values.tenantId,
      "amenityId": values.amenityId,
      "title": values.title,
      "description": values.description,
      "comment": values.comment,
      "startTime": values.startTime,
      "endTime": values.endTime,
      "remarks": values.remarks,
      "estimatedAmount": +values.estimatedAmount,
      "status": this.getStatusEnum(values.status),
      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,
      // COMPLETED
      "completedDate": (values.status === 'COMPLETED' && this.props.selectedRow.status !== this.getStatusEnum(values.status)) && moment(new Date()),
      // ONGOING
      "dateApplied": (values.status === 'ONGOING' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date())
    }

    this.props.onUpdateReservation({ ...submitData, id: selectedRowId }, this.props.reservationStatus).then((res) => {
      console.log("RESSSS", res)
      if (res.type === PUT_RESERVATION_REJECTED) {
        if ((res.payload && res.payload.response && res.payload.response.status) === 409) {
          Modal.info({
            title: 'Unable to reserved',
            content: (
              <div>
                <p>These dates already reserved to this amenity.</p>
              </div>
            ),
            onOk() { },
          });
        }
      }
    })
  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  disabledDateTime() {
    return {
      disabledHours: () => [0, 1, 2, 3],
      disabledMinutes: () => [50, 51],
      disabledSeconds: () => [55, 56],
    };
  }

  onChangeReservationSchedules() {
    this.props.onLoadReservationSchedules().then((res) => { })
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    const { selectedRow } = this.props
    const initialValues = {
      "tenantId": selectedRow.tenantId,
      "amenityId": selectedRow.amenityId,
      "title": selectedRow.title,
      "description": selectedRow.description,
      "comment": selectedRow.comment,
      "startTime": moment(selectedRow.startTime),
      "endTime": moment(selectedRow.endTime),
      "remarks": selectedRow.remarks,
      "estimatedAmount": selectedRow.estimatedAmount,
      "status": this.getStatusLabel(this.props.selectedRow.status),

      approvedBy: selectedRow.approver && selectedRow.approver.name || '',
      approvalDate: selectedRow.approvalDate && moment(selectedRow.approvalDate).format('LLL') || '',
      completedDate: moment(selectedRow.completedDate).format('LLL') || '',
      verifiedBy: selectedRow.verifier && selectedRow.verifier.name || '',
    }

    return (
      <Modal
        title="EDIT RESERVATION"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            ref={this.formRef}
            name="basic"
            initialValues={initialValues}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Amenity"
                  name="amenityId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    {
                      this.props.amenitiesData && this.props.amenitiesData.data.map((data, index) => {
                        return (
                          <Option key={data.id} >{data.title}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please choose title!' }]}
                >
                  <Select
                    // className='primary-input-select'
                    placeholder="Select a Title"
                  >
                    <Option value="Amenity Booking" >Amenity Booking</Option>
                    <Option value="Event Booking" >Event Booking</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item
                  label="Start Time"
                  name="startTime"
                  rules={[{ required: true, message: 'Please input your start time!' }]}
                  onChange={() => this.onChangeReservationSchedules()}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="End Time"
                  name="endTime"
                  rules={[{ required: true, message: 'Please input your end time!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Estimated Amount"
                  name="estimatedAmount"
                  rules={[{ required: true, message: 'Please input your estimated amount!' }]}
                >
                  <Input type="number" />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedBy"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Completed Date"
                  name="completedDate"
                >
                  <Input readOnly disabled />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    {
                      this.access('reservations', 'approve') &&
                      <Option value={'APPROVED'}>APPROVED</Option>
                    }
                    {
                      this.access('reservations', 'ongoing') &&
                      <Option value={'ONGOING'}>ONGOING</Option>
                    }
                    {
                      this.access('reservations', 'complete') &&
                      <Option value={'COMPLETED'}>COMPLETED</Option>
                    }
                    {
                      this.access('reservations', 'cancel') &&
                      <Option value={'CANCELLED'}>CANCELLED</Option>
                    }
                    {
                      this.access('reservations', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>
              </Col>

            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('reservations', 'approve') &&
                    <Button type="primary" htmlType="submit" loading={this.props.updateReservationLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateReservationLoading: state.admin.reservation.updateReservationLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData,
    amenitiesData: state.admin.amenity.amenitiesData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateReservation: (data, status) => dispatch(updateReservation(data, status)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant()),
    onLoadReservationById: (id) => dispatch(loadReservationById(id)),
    onLoadAmenity: (pagination) => dispatch(loadAmenity(pagination)),
    onLoadReservationSchedules: (pagination) => dispatch(loadReservationSchedules(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))
