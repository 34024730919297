import React from 'react'
import { Row, Col, Form, Radio, Button, Input, Select, Spin } from 'antd'
import { getUser } from '../../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitRequest } from '../../../actions'
import SlidingPane from "react-sliding-pane";

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleSubmit(values) {
    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "description": values.description,
      // "billOption": values.billOption,
      "estimatedAmount": 0,
      "status": values.status === 'Active' ? 1 : 0
    }
    this.props.onSubmitRequest(submitData)
  }

  render() {
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="ADD JOB ORDER REQUEST"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} className='primary-input' />
                </Form.Item>

                {/* <Form.Item
                  label="Estimated Amount"
                  name="estimatedAmount"
                  rules={[{ required: true, message: 'Please input your estimated amount!' }]}
                >
                  <Input type='number' className='primary-input' />
                </Form.Item> */}

                {/* <Form.Item
                  label="Bill Option (Tag as Billable/Non-Billable)"
                  name="billOption"
                  rules={[{ required: true, message: 'Please select a bill option!' }]}
                >
                  <Radio.Group>
                    <Radio value={'Billable'}>Billable</Radio>
                    <Radio value={'Non-Billable'}>Non-Billable</Radio>
                  </Radio.Group>
                </Form.Item> */}

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit Job Order Request
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.client.services.postLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitRequest: (data) => dispatch(submitRequest(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

