
import React from 'react'
import { Layout, Divider } from 'antd'
import './index.scss'
import Profile from './profile'
import ChangePassword from './changePassword'

class Settings extends React.Component {
    render() {
        return (
            <Layout className='services-container'>
                <Layout.Content>
                    <h1>Profile Settings</h1>
                    <Divider />
                    <Profile />
                    <h1>Change Password</h1>
                    <Divider />
                    <ChangePassword />
                    <Divider />

                </Layout.Content>
            </Layout>
        )
    }
}

export default Settings