import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitReservation } from '../../actions'
import { loadAmenity } from '../../../../admin/amenities/actions'
import SlidingPane from "react-sliding-pane";
import moment from 'moment'
const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileImageUploaded: false,
      fileImageList: [],
    }
    this.range = this.range.bind(this)
    this.disabledDateTime = this.disabledDateTime.bind(this)
  }

  componentDidMount() {
    const pagination = {
      page: 1,
      limit: 0,
      searchTerm: ''
    }
    this.props.onLoadAmenity(pagination)
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "description": values.description,
      "amenityId": values.amenityId,
      "startTime": values.startTime,
      "endTime": values.endTime,
      estimatedAmount: 0,
      "status": 1
    }

    this.props.onSubmitReservation(submitData).then(res => {
      this.setState({ isLoading: false })
    })
  }

  range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDateTime() {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, , 22, 23, 24],
      disabledMinutes: () => this.range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }


  render() {
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="ADD RESERVATION"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={this.props.amenitiesLoading}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please choose title!' }]}
                >
                  <Select
                    className='primary-input-select'
                    placeholder="Select a Title"
                    onChange={this.onGenderChange}
                    allowClear
                  >
                    <Option value="Amenity Booking" >Amenity Booking</Option>
                    <Option value="Event Booking" >Event Booking</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Amenity"
                  name="amenityId"
                  rules={[
                    {
                      required: true,
                      message: 'Please choose amenity!',
                    },
                  ]}
                >
                  <Select
                    className='primary-input-select'
                    placeholder="Select a amenity"
                    allowClear
                  >
                    {
                      this.props.amenitiesData && this.props.amenitiesData.data.map((data, index) => {
                        return (
                          <Option key={data.id} >{data.title}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Start Time (6AM – 9PM)"
                  name="startTime"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the datetime!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                    disabledTime={this.disabledDateTime}
                  />
                </Form.Item>
                <Form.Item
                  label="End Time (6AM – 9PM)"
                  name="endTime"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the datetime!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                    disabledTime={this.disabledDateTime}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit Reservation
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.client.reservations.postLoading,
    amenitiesData: state.admin.amenity.amenitiesData,
    amenitiesLoading: state.admin.amenity.amenitiesLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitReservation: (data) => dispatch(submitReservation(data)),
    onLoadAmenity: (pagination) => dispatch(loadAmenity(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
