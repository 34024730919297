import {
  GET_POLICIES,
  GET_POLICIES_FULFILLED,
  GET_POLICIES_REJECTED
} from '../actions'

export default function policies(state = {
  policiesLoading: false,
  policiesSuccess: false,
  policiesRejected: false,
  policiesData: []
}, action) {
  switch (action.type) {
    case GET_POLICIES:
      return {
        ...state,
        policiesLoading: true,
        policiesSuccess: false,
        policiesRejected: false
      }
    case GET_POLICIES_FULFILLED:
      return {
        ...state,
        policiesLoading: false,
        policiesSuccess: true,
        policiesData: action.payload
      }
    case GET_POLICIES_REJECTED:
      return {
        ...state,
        policiesLoading: false,
        policiesRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
