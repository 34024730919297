import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, message } from 'antd'
import { connect } from 'react-redux'
import { submitAdmin, POST_ADMINS_REJECTED } from '../../../actions'
const { Search } = Input;
const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false
    }
  }

  handleSubmit(values) {
    const submitData = {
      "name": values.name,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "password": values.password,
      "password_confirmation": values.confirm,
      "status": 1,
    }

    this.props.onSubmitAdmin(submitData).then((res) => {
      if (res.type === POST_ADMINS_REJECTED) {
        if (res.payload && res.payload.response && res.payload.response.status === 400) {
          if (res.payload && res.payload.response && res.payload.response.data) {
            const errorArray = JSON.parse(res.payload.response.data)
            Object.keys(errorArray).map(function (key, index) {
              message.error(errorArray[key][0])
            });
          }
        }
      }
    })
  }

  render() {
    return (
      <Modal
        title="ADD ADMIN"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={false}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

              </Col>

            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button type="primary" htmlType="submit" >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitAdmin: (data) => dispatch(submitAdmin(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

