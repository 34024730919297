import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

// Admin
import login from '../login/reducers'
import websiteSettings from '../websiteSettings/reducers'
import termsAndConditions from '../termsAndConditions/reducers'
import utilities from '../utilities/reducers'

const rootReducer = combineReducers({
  form,
  login,
  utilities,
  websiteSettings,
  termsAndConditions
})

export default rootReducer
