import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, Pagination, Rate } from 'antd'
import { FileAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { loadFeedback } from '../actions'
import moment from 'moment';

const { Search } = Input;

class Feedbacks extends React.Component {
  constructor() {
    super()
    this.state = {
      feedbackStatus: '0',
      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      selectedRowKeys: [],
      downloadLoading: false,
      tableData: []
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.feedbacksSuccess !== nextProps.feedbacksSuccess) && nextProps.feedbacksSuccess) {
      this.setState({ tableData: nextProps.feedbacksData.data })
    }
  }

  componentDidMount() {
    const { page, limit, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadFeedback(pagination)
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: 1,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadFeedback(pagination)

    }, 1000);
    this.setState({ searchTerm: searchTerm, page: 1 })
  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadFeedback(pagination)

    this.setState({ page, limit })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const dataSource = this.state.tableData
    const columns = [

      {
        title: 'Client Name',
        dataIndex: 'tenant',
        key: 'tenant',
        sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
        render: (info, data) => {
          return (
            <span>{info && (info.uoccname || info.email)}</span>
          )
        }
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        sorter: (a, b) => a.rate - b.rate,
        render: (info) => {
          return (
            <Rate value={info} readOnly disabled />
          )
        }
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        sorter: (a, b) => a.message && a.message.localeCompare(b.message),
        render: (info) => {
          return (
            <span>{info}</span>
          )
        }
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
        render: (info) => {
          return (
            <span>{moment(info).format('LLLL')}</span>
          )
        }
      },

    ];
    return (
      <Layout className='feedbacks-container'>
        <Layout.Content>
          <div className='feedback-title'>
            <h1>Feedbacks</h1>
          </div>
          <Row className='feedback-header'>
            <Col md={14} lg={14} sm={24} >
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Message" className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table style={{ width: '100%' }} rowKey='id'
              loading={this.props.feedbacksLoading}
              dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.feedbacksData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    feedbacksSuccess: state.admin.feedbacks.feedbacksSuccess,
    feedbacksLoading: state.admin.feedbacks.feedbacksLoading,
    feedbacksData: state.admin.feedbacks.feedbacksData,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadFeedback: (pagination) => dispatch(loadFeedback(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Feedbacks))
