import {
  POST_INQUIRY,
  POST_INQUIRY_FULFILLED,
  POST_INQUIRY_REJECTED,
  GET_INQUIRIES,
  GET_INQUIRIES_FULFILLED,
  GET_INQUIRIES_REJECTED,
  PUT_INQUIRY,
  PUT_INQUIRY_FULFILLED,
  PUT_INQUIRY_REJECTED,
  DELETE_INQUIRY,
  DELETE_INQUIRY_FULFILLED,
  DELETE_INQUIRY_REJECTED
} from '../actions'

export default function inquiries(state = {
  inquiryLoading: false,
  inquirySuccess: false,
  inquiryRejected: false,
  inquiryData: [],
  inquiriesLoading: false,
  inquiriesSuccess: false,
  inquiriesRejected: false,
  inquiriesData: [],
  updateInquiryLoading: false,
  updateInquirySuccess: false,
  updateInquiryRejected: false,
  updateInquiryData: [],
  deleteInquiryLoading: false,
  deleteInquirySuccess: false,
  deleteInquiryRejected: false,
  deleteInquiryData: []
}, action) {
  switch (action.type) {
    case POST_INQUIRY:
      return {
        ...state,
        inquiryLoading: true,
        inquirySuccess: false,
        inquiryRejected: false
      }
    case POST_INQUIRY_FULFILLED:
      return {
        ...state,
        inquiryLoading: false,
        inquirySuccess: true,
        inquiryData: action.payload
      }
    case POST_INQUIRY_REJECTED:
      return {
        ...state,
        inquiryLoading: false,
        inquiryRejected: true
      }
    case GET_INQUIRIES:
      return {
        ...state,
        inquiriesLoading: true,
        inquiriesSuccess: false,
        inquiriesRejected: false
      }
    case GET_INQUIRIES_FULFILLED:
      return {
        ...state,
        inquiriesLoading: false,
        inquiriesSuccess: true,
        inquiriesData: action.payload
      }
    case GET_INQUIRIES_REJECTED:
      return {
        ...state,
        inquiriesLoading: false,
        inquiriesRejected: true
      }
    case PUT_INQUIRY:
      return {
        ...state,
        updateInquiryLoading: true,
        updateInquirySuccess: false,
        updateInquiryRejected: false
      }
    case PUT_INQUIRY_FULFILLED:
      return {
        ...state,
        updateInquiryLoading: false,
        updateInquirySuccess: true,
        updateInquiryData: action.payload
      }
    case PUT_INQUIRY_REJECTED:
      return {
        ...state,
        updateInquiryLoading: false,
        updateInquiryRejected: true
      }
    case DELETE_INQUIRY:
      return {
        ...state,
        deleteInquiryLoading: true,
        deleteInquirySuccess: false,
        deleteInquiryRejected: false
      }
    case DELETE_INQUIRY_FULFILLED:
      return {
        ...state,
        deleteInquiryLoading: false,
        deleteInquirySuccess: true,
        deleteInquiryData: action.payload
      }
    case DELETE_INQUIRY_REJECTED:
      return {
        ...state,
        deleteInquiryLoading: false,
        deleteInquiryRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
