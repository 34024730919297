import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker, Card, Space } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitTicket } from '../../actions'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
import moment from 'moment'
const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false
    }
    this.timeout = 0;
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleSubmit(values) {
    const submitData = {
      "tenantId": values.tenant,
      "postById": getUser().id,
      "bearerName": values.bearerName,
      "dateOfApplication": values.dateOfApplication,
      "dateOfUse": values.dateOfUse,
      "items": values.items,
      "type": this.getTypeEnum(values.type),
      "status": 1
    }
    this.props.onSubmitTicket(submitData, this.props.ticketType)
  }

  getTypeEnum(type) {
    switch (type) {
      case 'Delivery':
        return 1
      case 'MoveIn':
        return 2
      case 'MoveOut':
        return 3
      case 'PullOut':
        return 4
      default:
        return 1
    }
  }


  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    return (
      <Modal
        title={`ADD GATEPASS`}
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Tenants"
                  name="tenant"
                  rules={[{ required: true, message: 'Please choose your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id.toString()}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Bearer Name"
                  name="bearerName"
                  rules={[{ required: true, message: 'Please input your bearer name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Date Of Application"
                  name="dateOfApplication"
                  rules={[{ required: true, message: 'Please input your date of application!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  label="Date Of Use"
                  name="dateOfUse"
                  rules={[{ required: true, message: 'Please input your date of date of use!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  label="Type"
                  name="type"
                // rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select defaultValue='Delivery'>
                    <Select.Option value={'Delivery'}>Delivery</Select.Option>
                    <Select.Option value={'MoveIn'}>Move In</Select.Option>
                    <Select.Option value={'MoveOut'}>Move Out</Select.Option>
                    <Select.Option value={'PullOut'}>Pull Out</Select.Option>
                  </Select>
                </Form.Item>



                <Card title="List of Items">
                  <Form.List name="items">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(field => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Missing Name' }]}
                            >
                              <Input placeholder="Name" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'quantity']}
                              fieldKey={[field.fieldKey, 'quantity']}
                              rules={[{ required: true, message: 'Missing Quantity' }]}
                            >
                              <Input type="number" placeholder="Quantity" />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Item
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>
              </Col>

            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.ticketLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    ticketLoading: state.admin.ticket.ticketLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitTicket: (data, ticketType) => dispatch(submitTicket(data, ticketType)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant())
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
