import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'
import { Layout, Menu, Modal, Spin, Row, Col } from 'antd'
import 'antd/dist/antd.css'
import { NotFound } from './notFound'
import { loadAdmin } from './utilities/actions'
import Login from './login/component'
import Dashboard from './dashboard/components'
import Tenants from './tenants/components'
import LoggedInLogs from './tenants/components/loggedInLogs'
import Tickets from './tickets/components'
import Reservations from './reservations/components'
import Memorandums from './memorandums/components'
import Policies from './policies/components'
import ServiceRequests from './serviceRequests/components'
import IncidentReports from './incidentReports/components'
import Amenities from './amenities/components'
import Advertisements from './advertisements/components'
import Services from './services/components'
import Permits from './permits/components'
import Inquiries from './inquiries/components'
import Commendations from './commendations/components'
import Visitors from './visitors/components'
import Feedbacks from './feedbacks/components'
import Announcements from './announcements/components'
import Billings from './billings/components'
import Payments from './payments/components'
import Utilities from './utilities/components'
import Notifications from './notifications/components'
import { loadWebsiteSettings } from './actions'
import './index.scss'
import {
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarAlt, faCog, faCogs, faComment, faCreditCard, faBook, faExclamationCircle, faFlag, faHeartbeat, faHandPaper, faImage, faMoneyBill, faPaperclip, faPenAlt, faSignOutAlt, faTachometerAlt, faTasks, faTicketAlt, faUserAlt, faUsers } from '@fortawesome/free-solid-svg-icons'
import { getUser } from '../../utilities/token'
import ActivityChecker from '../../utilities/activityChecker'
import { ImagePresignedUrl } from '../../utilities/renderer'

const { Footer, Sider, Content } = Layout;
const REACT_APP_NAME = process.env.REACT_APP_NAME

moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })

const MainLayout = (props) => {
  return (
    <Layout {...props} className='app-layout'>
      {props.children}
    </Layout>
  )
}

const hasLocalStorage = () => {
  let strResult
  if (localStorage.getItem('ADMIN_SESSION_TOKEN') !== null) {
    strResult = true
  } else {
    strResult = false
  }
  return strResult
}

const paths = [
  {
    default: true,
    exact: true,
    slug: 'Dashboard',
    title: 'dashboard',
    route: '/admin',
    component: Dashboard,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faTachometerAlt} />
  },
  {
    default: true,
    exact: true,
    title: 'announcements',
    slug: 'Posts',
    route: '/admin/announcements',
    component: Announcements,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faPenAlt} />
  },
  {
    default: true,
    exact: true,
    title: 'amenities',
    slug: 'Amenities',
    route: '/admin/amenities',
    component: Amenities,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faBuilding} />
  },
  {
    default: true,
    exact: true,
    title: 'tenants',
    slug: 'Tenant List',
    route: '/admin/tenants',
    component: Tenants,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faUserAlt} />
  },
  {
    default: true,
    exact: true,
    title: 'memorandums',
    slug: 'Memorandums',
    route: '/admin/memorandums',
    component: Memorandums,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faHandPaper} />
  },
  {
    default: true,
    exact: true,
    title: 'policies',
    slug: 'Policies',
    route: '/admin/policies',
    component: Policies,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faFlag} />
  },
  {
    default: true,
    exact: true,
    title: 'services',
    slug: 'Services',
    route: '/admin/services',
    component: Services,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faTasks} />
  },
  {
    default: true,
    exact: true,
    title: 'orders',
    slug: 'Job Orders',
    route: '/admin/service-requests',
    component: ServiceRequests,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faBuilding} />
  },
  {
    default: true,
    exact: true,
    title: 'gatepass',
    slug: 'Gate Pass',
    route: '/admin/tickets',
    component: Tickets,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faTicketAlt} />
  },
  {
    default: true,
    exact: true,
    title: 'reservations',
    slug: 'Reservations',
    route: '/admin/reservations',
    component: Reservations,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faCalendarAlt} />
  },

  {
    default: true,
    exact: true,
    title: 'reports',
    slug: 'Incident Reports',
    route: '/admin/incident-reports',
    component: IncidentReports,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faExclamationCircle} />
  },

  {
    default: true,
    exact: true,
    title: 'permits',
    slug: 'Work Permits',
    route: '/admin/permits',
    component: Permits,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faCogs} />
  },
  {
    default: true,
    exact: true,
    title: 'visitors',
    slug: 'Visitors',
    route: '/admin/visitors',
    component: Visitors,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faUsers} />
  },
  {
    default: true,
    exact: true,
    title: 'inquiries',
    slug: 'Leasing Assistance',
    route: '/admin/inquiries',
    component: Inquiries,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faPaperclip} />
  },
  {
    default: true,
    exact: true,
    title: 'commendations',
    slug: 'Commendations',
    route: '/admin/commendations',
    component: Commendations,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faComment} />
  },
  {
    default: true,
    exact: true,
    title: 'billings',
    slug: 'Billings',
    route: '/admin/settings',
    component: Billings,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faCreditCard} />
  },
  {
    default: true,
    exact: true,
    title: 'payments',
    slug: 'Payments',
    route: '/admin/payments',
    component: Payments,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faMoneyBill} />
  },
  {
    default: true,
    exact: true,
    title: 'utilities',
    slug: 'Utilities',
    route: '/admin/utilities',
    component: Utilities,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faCog} />
  },
  {
    default: true,
    exact: true,
    title: 'advertisements',
    slug: 'Advertisements',
    route: '/admin/advertisements',
    component: Advertisements,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faImage} />
  },
  {
    default: true,
    exact: true,
    title: 'loggedInLogs',
    slug: 'Logged In Logs',
    route: '/admin/logged-in-logs',
    component: LoggedInLogs,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faBook} />
  },
  {
    default: true,
    exact: true,
    title: 'feedbacks',
    slug: 'Feedbacks',
    route: '/admin/feedbacks',
    component: Feedbacks,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faHeartbeat} />
  },
]

class App extends Component {
  constructor() {
    super()
    this.state = {
      isUnauthenticated: false,
      activeTab: 0
    }
    this.showMenu = this.showMenu.bind(this)
  }

  logout() {
    const self = this
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will redirected to login page',
      onOk() {
        localStorage.removeItem('ADMIN_SESSION_TOKEN')
        self.props.history.push('/admin')
        window.location.reload()
      },
      onCancel() { },
    });
  }

  componentDidMount() {
    this.props.onLoadWebsiteSettings().then(res => {
      // internal function, this will get the immediate result of public settings in the first call.
      function getValueInWebsiteSettings(name) {
        const found = res.payload.find(data => data.name === name)
        if (found) return found.value
        return
      }

      if (hasLocalStorage() && getValueInWebsiteSettings('isUnderMaintenance') === '1') {
        // if maintenance is true logged out client session
        localStorage.removeItem('ADMIN_SESSION_TOKEN')
        this.props.history.push('/admin')
        window.location.reload()
      }
    })
    if (getUser() && getUser().id) {
      this.props.onLoadAdmin(getUser().id)
    }
  }

  componentWillMount() {
    window.document.title = REACT_APP_NAME && `${this.capitalizedFirstChar(REACT_APP_NAME)} Admin`
    const currentPathIndex = paths.findIndex(data => data.route === this.props.location.pathname)
    this.setState({ activeTab: currentPathIndex + 1 })

    if (hasLocalStorage()) this.setState({ isUnauthenticated: false })
    else this.setState({ isUnauthenticated: true })
  }

  capitalizedFirstChar(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
      window.location.reload()
    }
  }

  getValueInWebsiteSettings(name) {
    const found = this.props.websiteSettingsData.find(data => data.name === name)
    if (found) return found.value
    return
  }

  showMenu(name) {
    const found = this.props.adminData.roles.find(data => data.title === name)
    if (found && found.visible) return true
    else return false
  }


  render() {
    switch (this.state.isUnauthenticated) {
      case true:
        return (
          <MainLayout>
            <Login />
          </MainLayout>
        )

      case false:
        return (
          <Layout className='admin-container'>
            <Sider
              style={{
                left: 0,
                background: '#294DF0'
              }}
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={broken => { }}
              onCollapse={(collapsed, type) => { }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '20px 15px' }}>
                {
                  this.getValueInWebsiteSettings('websiteLogo') &&
                  <Link to='/admin'>
                    <ImagePresignedUrl src={`${this.getValueInWebsiteSettings('websiteLogo')}`} style={{ width: 80, height: 70, borderRadius: 5 }} />
                  </Link>
                }
                <h1 style={{ color: 'white', textAlign: 'center' }}>{this.getValueInWebsiteSettings('websiteName')}</h1>
              </div>
              <Spin spinning={this.props.adminLoading}>
                <Menu theme="dark" style={{ background: 'transparent' }} onClick={(e) => {
                  this.setState({ activeTab: e.key })
                }} mode="inline" defaultSelectedKeys={[this.state.activeTab.toString()]}>
                  {
                    paths.map((data, i) => {
                      if (!this.showMenu(data.title)) return
                      return (
                        <Menu.Item key={`menu_${(i + 1)}`} icon={data.icon}>
                          <Link to={data.route} style={{ marginLeft: 10, marginRight: 10 }}>
                            {data.slug}
                          </Link>
                        </Menu.Item>
                      )
                    })
                  }
                  <Menu.Item icon={<FontAwesomeIcon icon={faSignOutAlt} />} onClick={() => this.logout()}>
                    <span style={{ marginLeft: 10, marginRight: 10 }}> Logout</span>
                  </Menu.Item>
                </Menu>
              </Spin>
            </Sider>
            <Layout>
              <Content className='main-layout-content' style={{ padding: '5vh 2vw' }} >
                <Row>
                  <Col md={24} lg={24} sm={24} xs={24} align='right' className='p3'>
                    <Notifications />
                  </Col>
                </Row>
                <Switch>
                  {/* // this will scroll to top when switch changes. */}
                  {window.scrollTo(0, 0)}
                  {_.map(paths, (path) => (
                    <Route
                      exact={path.exact}
                      path={path.route}
                      component={path.component}
                      key={path.route}
                    />
                  ))}
                  <Route component={NotFound} />
                  <Redirect to='/404' />
                </Switch>
                <div style={{ paddingBottom: '50vh' }} />
              </Content>

              <Footer style={{ background: '#0095c9' }}>
                <div style={{ textAlign: 'center' }}>
                  <span style={{ color: 'white' }}>
                    © 2021 All Rights Reserved.
                  </span>
                </div>
              </Footer>
            </Layout>
            <ActivityChecker timoutRedirection='/admin' sessionName='ADMIN_SESSION_TOKEN' />
          </Layout>
        )
      default:
        break
    }
  }
}

function mapStateToProps(state) {
  return {
    loggedIn: state.admin.login.loggedIn,
    websiteSettingsData: state.admin.websiteSettings.websiteSettingsData,
    websiteSettingsLoading: state.admin.websiteSettings.websiteSettingsLoading,
    adminData: state.admin.utilities.adminData,
    adminLoading: state.admin.utilities.adminLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadWebsiteSettings: (data) => dispatch(loadWebsiteSettings(data)),
    onLoadAdmin: (id) => dispatch(loadAdmin(id)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))

