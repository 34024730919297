import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tag, Pagination, Tabs } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadInquiry, deleteInquiry, updateInquiry, loadSelectedRowInquiry } from '../actions'
import { getParamFromUrl } from '../../../../utilities/helper'
import moment from 'moment'
import Papa from 'papaparse'
import { getUser } from '../../../../utilities/token'
import {
  APPROVAL,
  APPROVED,
  CANCELLED,
  REJECTED
} from '../../../../utilities/serviceStatusEnum'
const { Search } = Input;
const { TabPane } = Tabs;
class Inquiries extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      inquiryStatus: '0',
      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      selectedRowKeys: [],
      downloadLoading: false,
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
    this.timeout = 0;
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    if ((this.props.inquirySuccess !== nextProps.inquirySuccess) && nextProps.inquirySuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Inquiry added successfully.")
      this.props.onLoadInquiry(this.getKeyConvertToStatus(this.state.inquiryStatus), pagination)
    }
    if ((this.props.updateInquirySuccess !== nextProps.updateInquirySuccess) && nextProps.updateInquirySuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Inquiry edited successfully.")
      this.props.onLoadInquiry(this.getKeyConvertToStatus(this.state.inquiryStatus), pagination)
    }
    if ((this.props.deleteInquirySuccess !== nextProps.deleteInquirySuccess) && nextProps.deleteInquirySuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Inquiry deleted successfully.")
      this.props.onLoadInquiry(this.getKeyConvertToStatus(this.state.inquiryStatus), pagination)
    }
    if ((this.props.inquiriesSuccess !== nextProps.inquiriesSuccess) && nextProps.inquiriesSuccess) {
      this.setState({ tableData: nextProps.inquiriesData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    this.props.onLoadInquiry(this.getKeyConvertToStatus(this.state.inquiryStatus), pagination)

    if (getParamFromUrl('modal') === 'true') {
      this.setState({ isEditModalOpen: true })
    }

    window.Echo.channel(`admin-inquiries-crud.${getUser().id}`)
      .listen('.admin-inquiries-crud-channel', (response) => {
        const { data } = response
        const index = this.state.tableData.findIndex(item => item.id === data.id)
        if (data.status === 0) {
          this.state.tableData.splice(index, 1) // delete
        } else {
          if (index !== -1) this.state.tableData[index] = data // update
          else {
            this.state.tableData.unshift({ ...data }) // create
            this.state.tableData = this.state.tableData.slice(0, this.state.limit) // re-assigning and limit to 10
          }
        }
        // this.setState({tableData: this.state.tableData})
        // this.setState are not working inside echo channel, 
        // regarding to this. <Table key/> key must be unique to know if there was a changed.
        this.forceUpdate()
      })
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteInquiry(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteInquiry(id)
      },
      onCancel() { },
    });
  }

  getKeyConvertToStatus(key) {
    let status
    switch (key) {
      case '0':
        status = ''
        break;
      case '1':
        status = APPROVAL
        break;
      case '2':
        status = APPROVED
        break;
      case '3':
        status = CANCELLED
        break;
      case '4':
        status = REJECTED
        break;
      default:
        break;
    }
    return status
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadInquiry(this.getKeyConvertToStatus(this.state.inquiryStatus), pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadInquiry(this.getKeyConvertToStatus(this.state.inquiryStatus), pagination)
    this.setState({ page, limit })
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return <Tag color="gold">FOR APPROVAL</Tag>
      case 2:
        return <Tag color="lime">APPROVED</Tag>
      case 3:
        return <Tag color="geekblue">ONGOING</Tag>
      case 4:
        return <Tag color="green">COMPLETED</Tag>
      case 5:
        return <Tag color="magenta">CANCELLED</Tag>
      case 6:
        return <Tag color="red">REJECTED</Tag>
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  handleInquiryStatus(e) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: 1,
      limit: limit,
      searchTerm: searchTerm
    }

    this.setState({ inquiryStatus: e.toString(), page: 1 })
    this.props.onLoadInquiry(this.getKeyConvertToStatus(e), pagination)
  }

  handleDownloadSelectedRowToCSV() {
    this.setState({ downloadLoading: true })
    this.props.onLoadSelectedRowInquiry(this.state.selectedRowKeys).then(res => {

      const newData = res.payload.map(data => {
        return {
          id: data.id,
          ticketNumber: data.ticketNumber,
          clientCode: data.tenant && data.tenant.clientCode,
          clientName: data.tenant && data.tenant.uoccname,
          name: data.name,
          title: data.title,
          description: data.description,
          email: data.email,
          contactNumber: data.contactNumber,
          status: this.getStatusForCsv(data.status),
          remarks: data.remarks,
          dateInquiry: moment(data.dateInquiry).format('DD/MM/YYYY'),
          createdAt: moment(data.created_at).format('DD/MM/YYYY'),
        }
      })
      var csv = Papa.unparse(newData);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, 'Inquiry Reports.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'Inquiry Reports.csv');
      tempLink.click();

    }).finally(() => {
      this.setState({ downloadLoading: false })
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  getStatusForCsv(status) {
    switch (status) {
      case 1:
        return 'FOR APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      preserveSelectedRowKeys: true
    };
    const dataSource = this.state.tableData
    const columns = [
      {
        title: 'Service Ticket #',
        dataIndex: 'ticketNumber',
        key: 'ticketNumber',
        sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
      },
      {
        title: 'Client Code',
        dataIndex: 'tenant',
        key: 'clientCode',
        sorter: (a, b) => a.tenant.clientCode && a.tenant.clientCode.localeCompare(b.tenant && b.tenant.clientCode),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.clientCode}`}</span>
          )
        }
      },
      {
        title: 'Client Name',
        dataIndex: 'tenant',
        key: 'tenant',
        sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.uoccname}`}</span>
          )
        }
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title && a.title.localeCompare(b.title),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description && a.title.localeCompare(b.description),
      },
      {
        title: 'Date Inquiry',
        dataIndex: 'dateInquiry',
        key: 'dateInquiry',
        sorter: (a, b) => new Date(b.dateInquiry) - new Date(a.dateInquiry),
        render: (item) => {
          return (<span>{moment(item).format('LL')}</span>)
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (item) => {
          return (<span>{this.getStatus(item)}</span>)
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              {
                this.access('inquiries', 'view') &&
                <Button type="primary" icon={<EditOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} >View</Button>
              }
              {
                this.access('inquiries', 'delete') &&
                <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteInquiry(data.id)}  >Delete</Button>
              }
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='inquiries-container'>

        <Layout.Content>
          <div className='inquiry-title'>
            <h1>Leasing Assistance</h1>
          </div>
          <Tabs defaultActiveKey="0" type="card" size='small' onChange={e => this.handleInquiryStatus(e)}>
            <TabPane tab="All" key="0" />
            <TabPane tab="Approval" key="1" />
            <TabPane tab="Approved" key="2" />
            {/* <TabPane tab="Cancelled" key="3" /> */}
            <TabPane tab="Rejected" key="4" />
          </Tabs>
          <Row>
            <Col md={14} lg={14} sm={24} xs={24}>
              <div className='mt3 mb3'>
                <Button type='primary' onClick={() => this.handleDownloadSelectedRowToCSV()} loading={this.state.downloadLoading}>Download CSV</Button>
              </div>
            </Col>
          </Row>
          <Row className='inquiry-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('inquiries', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Title, Description, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table
              rowSelection={rowSelection}
              style={{ width: '100%' }} rowKey='id'
              loading={this.props.inquiriesLoading || this.props.deleteInquiryLoading || this.props.updateInquiryLoading}
              dataSource={dataSource} columns={columns} pagination={false}
              key={(JSON.stringify(this.state.tableData))}
            />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.inquiriesData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    inquiryLoading: state.admin.inquiries.inquiryLoading,
    inquirySuccess: state.admin.inquiries.inquirySuccess,
    updateInquirySuccess: state.admin.inquiries.updateInquirySuccess,
    deleteInquirySuccess: state.admin.inquiries.deleteInquirySuccess,
    inquiriesSuccess: state.admin.inquiries.inquiriesSuccess,
    inquiriesData: state.admin.inquiries.inquiriesData,
    inquiriesLoading: state.admin.inquiries.inquiriesLoading,
    deleteInquiryLoading: state.admin.inquiries.deleteInquiryLoading,
    updateInquiryLoading: state.admin.inquiries.updateInquiryLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadInquiry: (status, pagination) => dispatch(loadInquiry(status, pagination)),
    onDeleteInquiry: (id) => dispatch(deleteInquiry(id)),
    onUpdateStatus: (data) => dispatch(updateInquiry(data)),
    onLoadSelectedRowInquiry: (selectedKeys) => dispatch(loadSelectedRowInquiry(selectedKeys))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Inquiries))
