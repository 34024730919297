import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker } from 'antd'
import { connect } from 'react-redux'
import { submitVisitor } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
import moment from 'moment'
import ImageUploader from './imageBulkUploader'
import { upload } from '../../../../../utilities/filesController'

const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      fileImage: undefined,
      fileImageUploaded: false,
      fileImageList: [],
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": values.tenant,
      "postById": getUser().id,
      "approvalDate": values.approvalDate,
      "visitorsName": values.visitorsName,
      "address": values.address,
      "dateOfVisit": values.dateOfVisit,
      "purposeOfVisit": values.purposeOfVisit,
      "email": values.email,
      "phoneNumber": values.phoneNumber,
      "birthDate": values.birthDate,
      "gender": values.gender,
      "images": this.state.fileImageList
    }

    Promise.all([
      this.uploadFileImage(),
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onSubmitVisitor(submitData).then(res => {
          this.setState({ isLoading: false })
        })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'images').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    return (
      <Modal
        title="ADD VISITOR"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenant"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Name of Visitor"
                  name="visitorsName"
                  rules={[{ required: true, message: 'Please input the name of visitor!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                  rules={[{ required: true, message: 'Please input the address!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Gender"
                  name="gender"
                  rules={[{ required: true, message: 'Please select a gender!' }]}
                >
                  <Select>
                    <Select.Option value={'male'}>Male</Select.Option>
                    <Select.Option value={'female'}>Female</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the phone number!'
                    },
                    {
                      pattern: /^[\d]{11,11}$/,
                      message: "Phone number should be 11 digits",
                    },
                  ]}
                >
                  <Input maxLength={11} placeholder='Ex: 09854411254' />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input the email!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Date of Visit"
                  name="dateOfVisit"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date of visit!',
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Birthday"
                  name="birthDate"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the visitors birthday!',
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Purpose of Visit"
                  name="purposeOfVisit"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the purpose of visit!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Col lg={24} md={24} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1>Upload scanned copy of one (1) valid government - issued id with photo</h1>
                  </div>
                  <ImageUploader
                    getFile={e => this.getFileImage(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Single file</span>
                </Col>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit"
                    loading={this.props.visitorLoading || this.state.isLoading}
                  >
                    Submit
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    visitorLoading: state.admin.visitors.visitorLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitVisitor: (data) => dispatch(submitVisitor(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant())
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
