import React, { useEffect, useState } from 'react'
import { Layout, Button, Row, Col, Card, Input, message, Divider } from 'antd'
import ColorPicker from './colorPicker'
import MobileSimulator from './mobileSimulator'
import { connect } from 'react-redux'
import { loadMobileSettings, submitWebsiteSettings } from '../../../websiteSettings/actions'
import Upload from './upload'
import { upload } from '../../../../../utilities/filesController'

const APP_NAME = process.env.REACT_APP_NAME

const MobileSettings = (props) => {
    const [mobileSettings, setMobileSettings] = useState({
        appName: APP_NAME,
        appTitle: '',
        appConnectionString: '',
        headerBackgroundColor: '',
        loginBackgroundImage: '',
        headerLogo: '',
        footerNavigationBackgroundColor: '',
        drawerNavigationBackgroundColor: '',
        primaryButtonBackgroundColor: '',
        primaryButtonBorderColor: '',
        primaryButtonLabelColor: '',
        secondaryButtonBackgroundColor: '',
        secondaryButtonBorderColor: '',
        secondaryButtonLabelColor: ''
    })
    const [loading, setLoading] = useState(false)

    const [tempHeaderLogo, setTempHeaderLogo] = useState({
        file: {},
        base64: ''
    })

    const [tempLoginBackgroundImage, setTempLoginBackgroundImage] = useState({
        file: {},
        base64: ''
    })

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    async function handleHeaderImage(e) {
        await getBase64(e[0]).then(base64 => {
            setTempHeaderLogo({
                file: e,
                base64: base64
            })
        })
    }

    async function handleLoginBackgroundImage(e) {
        await getBase64(e[0]).then(base64 => {
            setTempLoginBackgroundImage({
                file: e,
                base64: base64
            })
        })
    }

    useEffect(() => {
        props.onLoadMobileSettings()
    }, [])

    useEffect(() => {
        if ((Object.keys(props.mobileSettingsData).length !== 0) && props.mobileSettingsData.value !== '') {
            const mobileSettings = JSON.parse(props.mobileSettingsData && props.mobileSettingsData.value)
            setMobileSettings(mobileSettings)
        }
    }, [props.mobileSettingsData])

    async function handleSubmit() {
        const folder = 'logo'
        let headerLogo = ''
        let loginBackgroundImage = ''
        if (Object.keys(tempHeaderLogo.file).length === 0) {
            headerLogo = mobileSettings.headerLogoPath
        } else {
            setLoading(true)
            headerLogo = await upload(tempHeaderLogo.file[0], tempHeaderLogo.file[0].name, 'logo').then(data => {
                return `${folder}/${tempHeaderLogo.file[0].name}`
            }).finally(() => {
                setLoading(false)
            })
        }

        if (Object.keys(tempLoginBackgroundImage.file).length === 0) {
            loginBackgroundImage = mobileSettings.loginBackgroundImagePath
        } else {
            setLoading(true)
            loginBackgroundImage = await upload(tempLoginBackgroundImage.file[0], tempLoginBackgroundImage.file[0].name, 'logo').then(data => {
                return `${folder}/${tempLoginBackgroundImage.file[0].name}`
            }).finally(() => {
                setLoading(false)
            })
        }

        const arrayValues = [{
            name: 'mobileSettings',
            value: JSON.stringify({ ...mobileSettings, headerLogo, loginBackgroundImage })
        }]
        props.onSubmitWebsiteSettings({ settings: arrayValues }).then(res => {
            message.success('Mobile Settings Successfuly Updated.')
        })
    }

    return (
        <Layout className='mobile-settings'>
            <Layout.Content>
                <Card>
                    <Row>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <div className='mt5'>
                                <label>App Name</label>
                                <Input value={APP_NAME} readOnly />
                            </div>

                            <div className='mt5'>
                                <label>App Title</label>
                                <Input value={mobileSettings.appTitle} onChange={(e) => setMobileSettings({ ...mobileSettings, appTitle: e.target.value })} />
                            </div>

                            <div className='mt5'>
                                <label>App Connection String</label>
                                <Input value={mobileSettings.appConnectionString} onChange={(e) => setMobileSettings({ ...mobileSettings, appConnectionString: e.target.value })} />
                            </div>


                            <div className='login-background-image mt5'>
                                <h1>Login Background Image (300x750)</h1>
                                <Upload value={mobileSettings.loginBackgroundImage} getFile={e => handleLoginBackgroundImage(e)} width={300} height={600} />
                            </div>

                            <div className='logo-container mt5'>
                                <h1>Header Logo (100x40)</h1>
                                <Upload value={mobileSettings.headerLogo} getFile={e => handleHeaderImage(e)} />
                            </div>

                            <div className='mt5'>
                                <h1>Header Background Color</h1>
                                <ColorPicker color={mobileSettings.headerBackgroundColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, headerBackgroundColor: hexColor })} />
                            </div>

                            <div className='mt5'>
                                <h1>Footer Navigation Background Color</h1>
                                <ColorPicker color={mobileSettings.footerNavigationBackgroundColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, footerNavigationBackgroundColor: hexColor })} />
                            </div>

                            <div className='mt5'>
                                <h1>Drawer (Sidebar) Navigation Background Color</h1>
                                <ColorPicker color={mobileSettings.drawerNavigationBackgroundColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, drawerNavigationBackgroundColor: hexColor })} />
                            </div>

                            <Divider />
                            <h2>Primary Button</h2>
                            <div className='mt3'>
                                <h1>Background Color (Primary Button)</h1>
                                <ColorPicker color={mobileSettings.primaryButtonBackgroundColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, primaryButtonBackgroundColor: hexColor })} />
                            </div>
                            <div className='mt3'>
                                <h1>Border Color (Primary Button)</h1>
                                <ColorPicker color={mobileSettings.primaryButtonBorderColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, primaryButtonBorderColor: hexColor })} />
                            </div>
                            <div className='mt3'>
                                <h1>Label Color (Primary Button)</h1>
                                <ColorPicker color={mobileSettings.primaryButtonLabelColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, primaryButtonLabelColor: hexColor })} />
                            </div>

                            <Divider />
                            <h2>Secondary Button</h2>
                            <div className='mt3'>
                                <h1>Background Color (Secondary Button)</h1>
                                <ColorPicker color={mobileSettings.secondaryButtonBackgroundColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, secondaryButtonBackgroundColor: hexColor })} />
                            </div>
                            <div className='mt3'>
                                <h1>Border Color (Secondary Button)</h1>
                                <ColorPicker color={mobileSettings.secondaryButtonBorderColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, secondaryButtonBorderColor: hexColor })} />
                            </div>
                            <div className='mt3'>
                                <h1>Label Color (Primary Button)</h1>
                                <ColorPicker color={mobileSettings.secondaryButtonLabelColor} onChange={(hexColor) => setMobileSettings({ ...mobileSettings, secondaryButtonLabelColor: hexColor })} />
                            </div>

                            <Row>
                                <Col span={24} align='right'>
                                    <Button loading={props.updateWebsiteSettingsLoading || loading} type="primary" onClick={() => handleSubmit()}> Submit</Button>
                                </Col>
                            </Row>

                            <div style={{ marginBottom: 300 }} />
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <MobileSimulator
                                    {...mobileSettings}
                                    headerLogo={tempHeaderLogo.base64 ? tempHeaderLogo.base64 : `${mobileSettings.headerLogo}`}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Layout.Content>
        </Layout>
    )
}


function mapStateToProps(state) {
    return {
        mobileSettingsLoading: state.superadmin.websiteSettings.mobileSettingsLoading,
        mobileSettingsData: state.superadmin.websiteSettings.mobileSettingsData,
        updateWebsiteSettingsLoading: state.superadmin.websiteSettings.updateWebsiteSettingsLoading,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMobileSettings: (data) => dispatch(loadMobileSettings(data)),
        onSubmitWebsiteSettings: (data) => dispatch(submitWebsiteSettings(data)),
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(MobileSettings))