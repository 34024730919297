import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker } from 'antd'
import { loadAmenity } from '../../../amenities/actions'
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { submitPayment } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import moment from 'moment'
const { Search } = Input;
const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    const pagination = {
      page: 1,
      limit: 0,
      searchTerm: ''
    }
    this.props.onLoadTenant()
    this.props.onLoadAmenity(pagination)
  }

  handleSubmit(values) {
    const submitData = {
      "tenantId": values.tenantId,
      "postById": getUser().id,
      "title": values.title,
      "amenityId": values.amenityId,
      "description": values.description,
      "comment": values.comment,
      "startTime": values.startTime,
      "endTime": values.endTime,
      "estimatedAmount": 0,
      "status": 1
    }

    this.props.onSubmitPayment(submitData, this.props.paymentStatus).then((res) => {
      if ((res.payload && res.payload.request && res.payload.request.status) === 409) {
        Modal.info({
          title: 'Unable to reserved',
          content: (
            <div>
              <p>This date already reserved to this amenity.</p>
            </div>
          ),
          onOk() { },
        });
      }
    })
  }

  render() {
    return (
      <Modal
        title="ADD PAYMENT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading || this.props.amenitiesLoading}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname}`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Amenity"
                  name="amenityId"
                  rules={[{ required: true, message: 'Please input your amenity!' }]}
                >
                  <Select style={{ width: '100%' }} >
                    {
                      this.props.amenitiesData && this.props.amenitiesData.data.map((data, index) => {
                        return (
                          <Option value={data.id} key={index}>{data.title}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
                <Form.Item
                  label="Start Time"
                  name="startTime"
                  rules={[{ required: true, message: 'Please input your start time!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  label="End Time"
                  name="endTime"
                  rules={[{ required: true, message: 'Please input your end time!' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button type="primary" htmlType="submit" loading={this.props.paymentLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    paymentLoading: state.admin.payment.paymentLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    amenitiesData: state.admin.amenity.amenitiesData,
    amenitiesLoading: state.admin.amenity.amenitiesLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitPayment: (data, status) => dispatch(submitPayment(data, status)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onLoadAmenity: (pagination) => dispatch(loadAmenity(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
