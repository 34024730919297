import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_REQUEST = 'GET_REQUEST'
export const GET_REQUEST_FULFILLED = 'GET_REQUEST_FULFILLED'
export const GET_REQUEST_REJECTED = 'GET_REQUEST_REJECTED'

export const PUT_REQUESTS = 'PUT_REQUESTS'
export const PUT_REQUESTS_FULFILLED = 'PUT_REQUESTS_FULFILLED'
export const PUT_REQUESTS_REJECTED = 'PUT_REQUESTS_REJECTED'

export const POST_REQUEST = 'POST_REQUEST'
export const POST_REQUEST_FULFILLED = 'POST_REQUEST_FULFILLED'
export const POST_REQUEST_REJECTED = 'POST_REQUEST_REJECTED'

export const POST_GATEPASS = 'POST_GATEPASS'
export const POST_GATEPASS_FULFILLED = 'POST_GATEPASS_FULFILLED'
export const POST_GATEPASS_REJECTED = 'POST_GATEPASS_REJECTED'

export const POST_RESERVATION = 'POST_RESERVATION'
export const POST_RESERVATION_FULFILLED = 'POST_RESERVATION_FULFILLED'
export const POST_RESERVATION_REJECTED = 'POST_RESERVATION_REJECTED'

export const POST_REPORT = 'POST_REPORT'
export const POST_REPORT_FULFILLED = 'POST_REPORT_FULFILLED'
export const POST_REPORT_REJECTED = 'POST_REPORT_REJECTED'

export const GET_UTILITY_SERVICES = 'GET_UTILITY_SERVICES'
export const GET_UTILITY_SERVICES_FULFILLED = 'GET_UTILITY_SERVICES_FULFILLED'
export const GET_UTILITY_SERVICES_REJECTED = 'GET_UTILITY_SERVICES_REJECTED'

export const DELETE_REQUEST = 'DELETE_REQUEST'
export const DELETE_REQUEST_FULFILLED = 'DELETE_REQUEST_FULFILLED'
export const DELETE_REQUEST_REJECTED = 'DELETE_REQUEST_REJECTED'

export const PUT_GATEPASS = 'PUT_GATEPASS'
export const PUT_GATEPASS_FULFILLED = 'PUT_GATEPASS_FULFILLED'
export const PUT_GATEPASS_REJECTED = 'PUT_GATEPASS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL


export const loadRequests = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_REQUEST,
      payload: {}
    })
    return Axios.get(`${API_URL}/requests?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_REQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_REQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const updateRequest = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_REQUESTS,
      payload: {}
    })
    return Axios.put(`${API_URL}/request/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_REQUESTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_REQUESTS_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteRequest = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_REQUEST,
      payload: {}
    })
    return Axios.delete(`${API_URL}/request/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_REQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_REQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const submitRequest = (data) => {
  return dispatch => {
    dispatch({
      type: POST_REQUEST,
      payload: {}
    })
    return Axios.post(`${API_URL}/request`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_REQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_REQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const submitGatePass = (data) => {
  return dispatch => {
    dispatch({
      type: POST_GATEPASS,
      payload: {}
    })
    return Axios.post(`${API_URL}/ticket`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_GATEPASS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_GATEPASS_REJECTED,
          payload: error
        })
      })
  }
}

export const updateGatePass = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_GATEPASS,
      payload: {}
    })
    return Axios.put(`${API_URL}/ticket/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_GATEPASS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_GATEPASS_REJECTED,
          payload: error
        })
      })
  }
}


export const submitReservation = (data) => {
  return dispatch => {
    dispatch({
      type: POST_RESERVATION,
      payload: {}
    })
    return Axios.post(`${API_URL}/reservation`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_RESERVATION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_RESERVATION_REJECTED,
          payload: error
        })
      })
  }
}

export const submitReport = (data) => {
  return dispatch => {
    dispatch({
      type: POST_REPORT,
      payload: {}
    })
    return Axios.post(`${API_URL}/report`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_REPORT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_REPORT_REJECTED,
          payload: error
        })
      })
  }
}

export const loadUtilityServices = (data) => {
  return dispatch => {
    dispatch({
      type: GET_UTILITY_SERVICES,
      payload: {}
    })
    return Axios.get(`${API_URL}/system-parameter-settings`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_UTILITY_SERVICES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_UTILITY_SERVICES_REJECTED,
          payload: error
        })
      })
  }
}









