import {
  GET_MEMORANDUMS,
  GET_MEMORANDUMS_FULFILLED,
  GET_MEMORANDUMS_REJECTED
} from '../actions'

export default function memorandums(state = {
  memorandumsLoading: false,
  memorandumsSuccess: false,
  memorandumsRejected: false,
  memorandumsData: []
}, action) {
  switch (action.type) {
    case GET_MEMORANDUMS:
      return {
        ...state,
        memorandumsLoading: true,
        memorandumsSuccess: false,
        memorandumsRejected: false
      }
    case GET_MEMORANDUMS_FULFILLED:
      return {
        ...state,
        memorandumsLoading: false,
        memorandumsSuccess: true,
        memorandumsData: action.payload
      }
    case GET_MEMORANDUMS_REJECTED:
      return {
        ...state,
        memorandumsLoading: false,
        memorandumsRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
