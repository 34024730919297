import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, DatePicker, Spin, Card, Space } from 'antd'
import { connect } from 'react-redux'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { updateRentalInquiries } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import SlidingPane from "react-sliding-pane";
import moment from 'moment'
const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id

    const submitData = {
      "tenantId": getUser().id,
      "name": values.name,
      "email": values.email,
      "contactNumber": values.contactNumber,
      "title": values.title,
      "description": values.description,
      "dateInquiry": values.dateInquiry,
    }

    this.props.onUpdateRentalInquiries({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (

      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT LEASING ASSISTANCE"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={false}>

          <Form
            name="basic"
            initialValues={{
              ...selectedRow,
              dateInquiry: moment(selectedRow && selectedRow.dateTo),
              status: this.getStatusLabel(selectedRow && selectedRow.status),
              approvedBy: selectedRow && selectedRow.approver && selectedRow && selectedRow.approver.name || '',
              approvalDate: selectedRow && selectedRow.approvalDate && moment(selectedRow && selectedRow.approvalDate).format('LLL') || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Contact Number"
                  name="contactNumber"
                  rules={[{ required: true, message: 'Please input your contact number!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date Inquiry"
                  name="dateInquiry"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date inquiry!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    disabled={disabled}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[
                    {
                      required: false,
                      message: 'Please input the remarks!',
                    },
                  ]}
                >
                  <Input.TextArea rows={5} disabled className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select readOnly disabled style={{ width: '100%' }} className='primary-input-select' >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    <Option value={'APPROVED'}>APPROVED</Option>
                    <Option value={'ONGOING'}>ONGOING</Option>
                    <Option value={'COMPLETED'}>COMPLETED</Option>
                    <Option value={'CANCELLED'}>CANCELLED</Option>
                    <Option value={'REJECTED'}>REJECTED</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approved by"
                  name="approvedBy"
                >
                  <Input disabled className='primary-input' />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Update Leasing Assistance
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateRentalInquiriesLoading: state.client.rentalInquiries.updateRentalInquiriesLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateRentalInquiries: (data) => dispatch(updateRentalInquiries(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

