import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_PERMIT = 'POST_PERMIT'
export const POST_PERMIT_FULFILLED = 'POST_PERMIT_FULFILLED'
export const POST_PERMIT_REJECTED = 'POST_PERMIT_REJECTED'

export const GET_PERMITS = 'GET_PERMITS'
export const GET_PERMITS_FULFILLED = 'GET_PERMITS_FULFILLED'
export const GET_PERMITS_REJECTED = 'GET_PERMITS_REJECTED'

export const GET_PERMIT = 'GET_PERMIT'
export const GET_PERMIT_FULFILLED = 'GET_PERMIT_FULFILLED'
export const GET_PERMIT_REJECTED = 'GET_PERMIT_REJECTED'

export const PUT_PERMIT = 'PUT_PERMIT'
export const PUT_PERMIT_FULFILLED = 'PUT_PERMIT_FULFILLED'
export const PUT_PERMIT_REJECTED = 'PUT_PERMIT_REJECTED'

export const DELETE_PERMIT = 'DELETE_PERMIT'
export const DELETE_PERMIT_FULFILLED = 'DELETE_PERMIT_FULFILLED'
export const DELETE_PERMIT_REJECTED = 'DELETE_PERMIT_REJECTED'

export const GET_SELECTED_KEYS_PERMIT = 'GET_SELECTED_KEYS_PERMIT'
export const GET_SELECTED_KEYS_PERMIT_FULFILLED = 'GET_SELECTED_KEYS_PERMIT_FULFILLED'
export const GET_SELECTED_KEYS_PERMIT_REJECTED = 'GET_SELECTED_KEYS_PERMIT_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadPermit = (status, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_PERMITS,
      payload: {}
    })
    return Axios.get(`${API_URL}/permits?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&status=${status}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_PERMITS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_PERMITS_REJECTED,
          payload: error
        })
      })
  }
}


export const loadPermitById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_PERMIT,
      payload: {}
    })
    return Axios.get(`${API_URL}/permit/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_PERMIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_PERMIT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPermit = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PERMIT,
      payload: {}
    })
    return Axios.post(`${API_URL}/permit`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_PERMIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_PERMIT_REJECTED,
          payload: error
        })
      })
  }
}

export const updatePermit = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_PERMIT,
      payload: {}
    })
    return Axios.put(`${API_URL}/permit/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_PERMIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_PERMIT_REJECTED,
          payload: error
        })
      })
  }
}

export const deletePermit = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_PERMIT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/permit/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_PERMIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_PERMIT_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSelectedRowPermit = (selectedKeys) => {
  return dispatch => {
    dispatch({
      type: GET_SELECTED_KEYS_PERMIT,
      payload: {}
    })
    return Axios.get(`${API_URL}/permitIds?selectedIds=${selectedKeys.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SELECTED_KEYS_PERMIT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SELECTED_KEYS_PERMIT_REJECTED,
          payload: error
        })
      })
  }
}





