import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Card, Collapse } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { updateIncidentReport, loadIncidentReportById } from '../../actions'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
import ImageUploader from './imageBulkUploader'
import VideoUploader from './videoUploader'
import { getParamFromUrl } from '../../../../../utilities/helper'
import { ImagePresignedUrl, VideoPresignedUrl } from '../../../../../utilities/renderer'
import { get, upload } from '../../../../../utilities/filesController'
import moment from 'moment'
const { Option } = Select;
const { Panel } = Collapse;
class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: [],
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
    this.timeout = 0;
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadIncidentReportById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload,
          "startTime": moment(res.payload.startTime),
          "endTime": moment(res.payload.endTime),
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `incidentreports/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'incidentreports').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  uploadFileVideo() {
    return new Promise((resolve, reject) => {
      if (this.state.fileVideo) {
        upload(this.state.fileVideo[0], this.state.fileVideo[0].name, 'videos').then(data => {
          return resolve(true)
        }).catch(err => {
          return Modal.error({
            title: 'This is an error message in Soa Web Client',
            content: err,
          });
        })
      } else {
        return resolve(true)
      }
    })
  }

  componentDidMount() {
    this.props.onLoadTenant({ id: this.props.selectedRow.tenantId })
  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }


  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id

    const submitData = {
      "tenantId": values.tenantId,
      "title": values.title,
      "description": values.description,
      "caseClosedImages": this.state.fileImageList,
      "caseClosedVideos": this.state.fileVideoList,

      "status": this.getStatusEnum(values.status),

      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,

      // COMPLETED
      "completedDate": (values.status === 'COMPLETED' && this.props.selectedRow.status !== this.getStatusEnum(values.status)) && moment(new Date()),
      "caseClosedDate": (values.status === 'COMPLETED' && this.props.selectedRow.status !== this.getStatusEnum(values.status)) && moment(new Date()),

    }
    Promise.all([
      this.uploadFileImage(),
      this.uploadFileVideo()
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onUpdateIncidentReport({ ...submitData, id: selectedRowId })
        this.setState({ isLoading: false })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  async urlOpen(pathname) {
    const url = await get(pathname)
    window.open(url);
  }

  render() {
    const { selectedRow } = this.props
    const initialValues = {
      "tenantId": selectedRow.tenantId,
      "postById": getUser().id,
      "amenity": selectedRow.amenity,
      "title": selectedRow.title,
      "description": selectedRow.description,
      "comment": selectedRow.comment,
      "startTime": moment(selectedRow.startTime),
      "endTime": moment(selectedRow.endTime),
      "status": this.getStatusLabel(this.props.selectedRow.status)
    }

    return (
      <Modal
        title="EDIT INCIDENT REPORT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            ref={this.formRef}
            name="basic"
            initialValues={{
              ...initialValues,
              approvedBy: selectedRow.approver && selectedRow.approver.name || '',
              approvalDate: selectedRow.approvalDate && moment(selectedRow.approvalDate).format('LLL') || '',
              completedDate: moment(selectedRow.completedDate).format('LLL') || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Card>
              <Row gutter={12}>
                <Col md={12} lg={12} sm={24} xs={24}>
                  <Form.Item
                    label="Tenant"
                    name="tenantId"
                    rules={[{ required: true, message: 'Please input your tenant!' }]}
                  >
                    <Select style={{ width: '100%' }}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                      onSearch={(value) => this.tenantSearch(value)}
                    >
                      {
                        this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                          return (
                            <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please input your title!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: true, message: 'Please input your description!' }]}
                  >
                    <Input.TextArea rows={5} />
                  </Form.Item>

                </Col>
                <Col md={12} lg={12} sm={24} xs={24}>
                  <Form.Item
                    label="Approval Date"
                    name="approvalDate"
                  >
                    <Input readOnly disabled />
                  </Form.Item>

                  <Form.Item
                    label="Approved By"
                    name="approvedBy"
                  >
                    <Input readOnly disabled />
                  </Form.Item>

                  <Form.Item
                    label="Verified By"
                    name="verifiedBy"
                  >
                    <Input readOnly disabled />
                  </Form.Item>

                  <Form.Item
                    label="Review Date"
                    name="reviewDate"
                  >
                    <Input readOnly disabled />
                  </Form.Item>

                  <Form.Item
                    label="Completed Date"
                    name="completedDate"
                  >
                    <Input readOnly disabled />
                  </Form.Item>

                  <Form.Item
                    label="Case Closed Date"
                    name="caseClosedDate"
                  >
                    <Input readOnly disabled />
                  </Form.Item>

                  <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: 'Please input your status!' }]}
                  >
                    <Select style={{ width: '100%' }} >
                      <Option value={'APPROVAL'}>APPROVAL</Option>
                      {
                        this.access('reports', 'approve') &&
                        <Option value={'APPROVED'}>APPROVED</Option>
                      }
                      {
                        this.access('reports', 'ongoing') &&
                        <Option value={'ONGOING'}>ONGOING</Option>
                      }
                      {
                        this.access('reports', 'complete') &&
                        <Option value={'COMPLETED'}>COMPLETED</Option>
                      }
                      {
                        this.access('reports', 'cancel') &&
                        <Option value={'CANCELLED'}>CANCELLED</Option>
                      }
                      {
                        this.access('reports', 'reject') &&
                        <Option value={'REJECTED'}>REJECTED</Option>
                      }
                    </Select>
                  </Form.Item>
                  <div>
                    <span>Incident Images</span>
                    <Collapse bordered={false} ghost>
                      {
                        selectedRow.images && selectedRow.images.map((data, index) => {
                          return (
                            <Panel header={data.name} key={index}>
                              <ImagePresignedUrl style={{ width: 400, height: "auto" }} src={`${data.url}`} />
                            </Panel>
                          )
                        })
                      }
                    </Collapse>

                  </div>
                  <div>
                    <span>Incident Video</span>
                    <Collapse bordered={false} ghost>
                      {
                        selectedRow.video && selectedRow.video.map((data, index) => {
                          return (
                            <Panel header={data.name} key={index}>
                              <a onClick={() => this.urlOpen(data.url)}  >{data.name}</a>
                              <VideoPresignedUrl url={data.url} width='100%' height='100%' />
                            </Panel>
                          )
                        })
                      }
                    </Collapse>
                  </div>

                </Col>

              </Row>
            </Card>
            <Card>
              <h1>Case Closed</h1>
              <Row>
                <Col md={24} lg={24} sm={24} xs={24}>
                  <div>
                    <span>Incident Images</span>
                    <Collapse bordered={false} ghost>
                      {
                        selectedRow.caseClosedImages && selectedRow.caseClosedImages.map((data, index) => {
                          return (
                            <Panel header={data.name} key={index}>
                              <ImagePresignedUrl style={{ width: 400, height: "auto" }} src={`${data.url}`} />
                            </Panel>
                          )
                        })
                      }
                    </Collapse>

                  </div>
                  <div>
                    <span>Incident Video</span>
                    <Collapse bordered={false} ghost>
                      {
                        selectedRow.caseClosedVideos && selectedRow.caseClosedVideos.map((data, index) => {
                          return (
                            <Panel header={data.name} key={index}>
                              <a onClick={() => this.urlOpen(data.url)}  >{data.name}</a>
                              <VideoPresignedUrl url={data.url} width='100%' height='100%' />
                            </Panel>
                          )
                        })
                      }
                    </Collapse>
                  </div>
                </Col>
              </Row>
              <h1>Upload</h1>
              <Row gutter={12}>
                <Col lg={12} md={12} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Upload at least 3 (three) images of case closed</span>
                  </div>
                  <ImageUploader
                    value={this.props.selectedRow}
                    getFile={e => this.getFileImage(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Multiple file (at least 3 images)</span>
                </Col>

                <Col lg={12} md={12} xs={24} sm={24}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Upload 1(one) video of case closed</span>
                  </div>
                  <VideoUploader
                    value={this.props.selectedRow}
                    getFile={e => this.getFileVideo(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Single file Only</span>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('reports', 'edit') &&
                    <Button type="primary" htmlType="submit" style={{ marginTop: 8 }} loading={this.props.updateIncidentReportLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateIncidentReportLoading: state.admin.incidentReport.updateIncidentReportLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateIncidentReport: (data) => dispatch(updateIncidentReport(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant()),
    onLoadIncidentReportById: (id) => dispatch(loadIncidentReportById(id))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))
