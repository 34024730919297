import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Spin, Card } from 'antd'
import { connect } from 'react-redux'
import { submitAmenity } from '../../actions'
import ImageUploader from './imageBulkUploader'
import { v4 as uuidv4 } from 'uuid';
import { upload } from '../../../../../utilities/filesController'

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileImageUploaded: false,
      fileImageList: [],
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { id: uuidv4(), name: data.name, url: `amenities/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'amenities').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  handleSubmit(values) {
    const submitData = {
      "title": values.title,
      "description": values.description,
      "images": this.state.fileImageList,
      "status": 1
    }
    this.setState({ isLoading: true })

    Promise.all([
      this.uploadFileImage(),
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onSubmitServinceRequest(submitData)
        this.setState({ isLoading: false })
      } else {
        Modal.error({
          title: 'Unable to submit amenity',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })


  }

  render() {
    const { selectedRow } = this.props
    return (
      <Modal
        title="ADD AMENITY"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            // initialValues={initialValues}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
              </Col>
            </Row>
            <Card>
              <h1>Upload</h1>
              <Row gutter={12}>
                <Col lg={12} md={12} xs={24} sm={24}>
                  <ImageUploader
                    value={this.props.selectedRow}
                    getFile={e => this.getFileImage(e)}
                  />
                  <span style={{ fontSize: 10, color: 'red' }}>Multiple file</span>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.amenityLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    amenityLoading: state.admin.amenity.amenityLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitServinceRequest: (data) => dispatch(submitAmenity(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
