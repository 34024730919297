import {
  POST_SERVICE,
  POST_SERVICE_FULFILLED,
  POST_SERVICE_REJECTED,
  GET_SERVICES,
  GET_SERVICES_FULFILLED,
  GET_SERVICES_REJECTED,
  PUT_SERVICE,
  PUT_SERVICE_FULFILLED,
  PUT_SERVICE_REJECTED,
  DELETE_SERVICE,
  DELETE_SERVICE_FULFILLED,
  DELETE_SERVICE_REJECTED
} from '../actions'

export default function services(state = {
  serviceLoading: false,
  serviceSuccess: false,
  serviceRejected: false,
  serviceData: [],
  servicesLoading: false,
  servicesSuccess: false,
  servicesRejected: false,
  servicesData: [],
  updateServiceLoading: false,
  updateServiceSuccess: false,
  updateServiceRejected: false,
  updateServiceData: [],
  deleteServiceLoading: false,
  deleteServiceSuccess: false,
  deleteServiceRejected: false,
  deleteServiceData: []
}, action) {
  switch (action.type) {
    case POST_SERVICE:
      return {
        ...state,
        serviceLoading: true,
        serviceSuccess: false,
        serviceRejected: false
      }
    case POST_SERVICE_FULFILLED:
      return {
        ...state,
        serviceLoading: false,
        serviceSuccess: true,
        serviceData: action.payload
      }
    case POST_SERVICE_REJECTED:
      return {
        ...state,
        serviceLoading: false,
        serviceRejected: true
      }
    case GET_SERVICES:
      return {
        ...state,
        servicesLoading: true,
        servicesSuccess: false,
        servicesRejected: false
      }
    case GET_SERVICES_FULFILLED:
      return {
        ...state,
        servicesLoading: false,
        servicesSuccess: true,
        servicesData: action.payload
      }
    case GET_SERVICES_REJECTED:
      return {
        ...state,
        servicesLoading: false,
        servicesRejected: true
      }
    case PUT_SERVICE:
      return {
        ...state,
        updateServiceLoading: true,
        updateServiceSuccess: false,
        updateServiceRejected: false
      }
    case PUT_SERVICE_FULFILLED:
      return {
        ...state,
        updateServiceLoading: false,
        updateServiceSuccess: true,
        updateServiceData: action.payload
      }
    case PUT_SERVICE_REJECTED:
      return {
        ...state,
        updateServiceLoading: false,
        updateServiceRejected: true
      }
    case DELETE_SERVICE:
      return {
        ...state,
        deleteServiceLoading: true,
        deleteServiceSuccess: false,
        deleteServiceRejected: false
      }
    case DELETE_SERVICE_FULFILLED:
      return {
        ...state,
        deleteServiceLoading: false,
        deleteServiceSuccess: true,
        deleteServiceData: action.payload
      }
    case DELETE_SERVICE_REJECTED:
      return {
        ...state,
        deleteServiceLoading: false,
        deleteServiceRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
