import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_AMENITY = 'POST_AMENITY'
export const POST_AMENITY_FULFILLED = 'POST_AMENITY_FULFILLED'
export const POST_AMENITY_REJECTED = 'POST_AMENITY_REJECTED'

export const GET_AMENITIES = 'GET_AMENITIES'
export const GET_AMENITIES_FULFILLED = 'GET_AMENITIES_FULFILLED'
export const GET_AMENITIES_REJECTED = 'GET_AMENITIES_REJECTED'

export const GET_AMENITY = 'GET_AMENITY'
export const GET_AMENITY_FULFILLED = 'GET_AMENITY_FULFILLED'
export const GET_AMENITY_REJECTED = 'GET_AMENITY_REJECTED'

export const PUT_AMENITY = 'PUT_AMENITY'
export const PUT_AMENITY_FULFILLED = 'PUT_AMENITY_FULFILLED'
export const PUT_AMENITY_REJECTED = 'PUT_AMENITY_REJECTED'

export const DELETE_AMENITY = 'DELETE_AMENITY'
export const DELETE_AMENITY_FULFILLED = 'DELETE_AMENITY_FULFILLED'
export const DELETE_AMENITY_REJECTED = 'DELETE_AMENITY_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadAmenity = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_AMENITIES,
      payload: {}
    })
    return Axios.get(`${API_URL}/amenities?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_AMENITIES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_AMENITIES_REJECTED,
          payload: error
        })
      })
  }
}

export const loadAmenityById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_AMENITY,
      payload: {}
    })
    return Axios.get(`${API_URL}/amenity/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_AMENITY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_AMENITY_REJECTED,
          payload: error
        })
      })
  }
}

export const submitAmenity = (data) => {
  return dispatch => {
    dispatch({
      type: POST_AMENITY,
      payload: {}
    })
    return Axios.post(`${API_URL}/amenity`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_AMENITY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_AMENITY_REJECTED,
          payload: error
        })
      })
  }
}

export const updateAmenity = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_AMENITY,
      payload: {}
    })
    return Axios.put(`${API_URL}/amenity/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_AMENITY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_AMENITY_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteAmenity = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_AMENITY,
      payload: {}
    })
    return Axios.delete(`${API_URL}/amenity/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_AMENITY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_AMENITY_REJECTED,
          payload: error
        })
      })
  }
}





