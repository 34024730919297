import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Pagination, Tag } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadServiceRequest, deleteServiceRequest, rejectServiceRequest, approveServiceRequest, loadSelectedRowRequest } from '../actions'
import { getParamFromUrl } from '../../../../utilities/helper'
import Papa from 'papaparse'
import { getUser } from '../../../../utilities/token'
import {
  APPROVAL,
  APPROVED,
  CANCELLED,
  REJECTED
} from '../../../../utilities/serviceStatusEnum'
import moment from 'moment';
const { Search } = Input;
const { TabPane } = Tabs;
class ServiceRequests extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,

      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      requestStatus: '0',
      selectedRow: {},
      selectedRowKeys: [],
      downloadLoading: false,
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
    this.timeout = 0;
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if ((this.props.serviceRequestSuccess !== nextProps.serviceRequestSuccess) && nextProps.serviceRequestSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Service Request added successfully.")
      this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)
    }
    if ((this.props.updateServiceRequestSuccess !== nextProps.updateServiceRequestSuccess) && nextProps.updateServiceRequestSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Service Request edited successfully.")
      this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)
    }
    if ((this.props.verifiedServiceRequestSuccess !== nextProps.verifiedServiceRequestSuccess) && nextProps.verifiedServiceRequestSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Service Request verified successfully.")
      this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)
    }
    if ((this.props.deleteServiceRequestSuccess !== nextProps.deleteServiceRequestSuccess) && nextProps.deleteServiceRequestSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Service Request deleted successfully.")
      this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)
    }
    if ((this.props.approveServiceRequestSuccess !== nextProps.approveServiceRequestSuccess) && nextProps.approveServiceRequestSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Service Request approved successfully.")
      this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)
    }
    if ((this.props.serviceRequestsSuccess !== nextProps.serviceRequestsSuccess) && nextProps.serviceRequestsSuccess) {
      this.setState({ tableData: nextProps.serviceRequestsData.data })
    }
  }

  getKeyConvertToStatus(key) {
    let status
    switch (key) {
      case '0':
        status = ''
        break;
      case '1':
        status = APPROVAL
        break;
      case '2':
        status = APPROVED
        break;
      case '3':
        status = CANCELLED
        break;
      case '4':
        status = REJECTED
        break;
      default:
        break;
    }
    return status
  }

  componentDidMount() {
    const { page, limit, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)

    if (getParamFromUrl('modal') === 'true') {
      this.setState({ isEditModalOpen: true })
    }

    window.Echo.channel(`admin-requests-crud.${getUser().id}`)
      .listen('.admin-requests-crud-channel', (response) => {
        const { data } = response
        const index = this.state.tableData.findIndex(item => item.id === data.id)
        if (data.status === 0) {
          this.state.tableData.splice(index, 1) // delete
        } else {
          if (index !== -1) this.state.tableData[index] = data // update
          else {
            this.state.tableData.unshift({ ...data }) // create
            this.state.tableData = this.state.tableData.slice(0, this.state.limit) // re-assigning and limit to 10
          }
        }
        // this.setState({tableData: this.state.tableData})
        // this.setState are not working inside echo channel, 
        // regarding to this. <Table key/> key must be unique to know if there was a changed.
        this.forceUpdate()
      })

  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)

    this.setState({ page, limit })
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteServiceRequest(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        // self.props.onDeleteServiceRequest(id)
        self.props.onDeleteServiceRequest(id, self.getRequestStatus(self.state.requestStatus))
      },
      onCancel() { },
    });
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadServiceRequest(this.getKeyConvertToStatus(this.state.requestStatus), pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  getRequestStatus(e) {
    let type
    switch (e) {
      case '0':
        type = ''
        break;
      case '1':
        type = 'approval'
        break;
      case '2':
        type = 'approved'
        break;
      case '3':
        type = 'cancelled'
        break;
      case '4':
        type = 'rejected'
        break;
      default:
        break;
    }
    return type
  }

  getStatus(status) {
    switch (status) {
      case 1:
        return <Tag color="gold">FOR APPROVAL</Tag>
      case 2:
        return <Tag color="lime">APPROVED</Tag>
      case 3:
        return <Tag color="geekblue">ONGOING</Tag>
      case 4:
        return <Tag color="green">COMPLETED</Tag>
      case 5:
        return <Tag color="magenta">CANCELLED</Tag>
      case 6:
        return <Tag color="red">REJECTED</Tag>
      default:
        break;
    }
  }

  handleRequestStatus(e) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadServiceRequest(this.getKeyConvertToStatus(e), pagination)

    this.setState({ requestStatus: e.toString() })

  }

  handleDownloadSelectedRowToCSV() {
    this.setState({ downloadLoading: true })
    this.props.onLoadSelectedRowRequest(this.state.selectedRowKeys).then(res => {

      const newData = res.payload.map(data => {
        return {
          id: data.id,
          ticketNumber: data.ticketNumber,
          clientCode: data.tenant && data.tenant.clientCode,
          clientName: data.tenant && data.tenant.uoccname,
          title: data.title,
          description: data.description,
          estimatedAmount: data.estimatedAmount,
          status: this.getStatusForCsv(data.status),
          remarks: data.remarks,
          comment: data.comment,
          completedDate: moment(data.completedDate).format('DD/MM/YYYY'),
          createdAt: moment(data.created_at).format('DD/MM/YYYY'),
        }
      })
      var csv = Papa.unparse(newData);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, 'Job Orders Reports.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'Job Orders Reports.csv');
      tempLink.click();

    }).finally(() => {
      this.setState({ downloadLoading: false })
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  getStatusForCsv(status) {
    switch (status) {
      case 1:
        return 'FOR APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      preserveSelectedRowKeys: true
    };
    const dataSource = this.state.tableData

    const columns = [
      {
        title: 'Service Ticket #',
        dataIndex: 'ticketNumber',
        key: 'ticketNumber',
        sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
      },
      {
        title: 'Client Code',
        dataIndex: 'tenant',
        key: 'clientCode',
        sorter: (a, b) => a.tenant.clientCode && a.tenant.clientCode.localeCompare(b.tenant && b.tenant.clientCode),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.clientCode}`}</span>
          )
        }
      },
      {
        title: 'Client Name',
        dataIndex: 'tenant',
        key: 'tenant',
        sorter: (a, b) => a.tenant.uoccname && a.tenant.uoccname.localeCompare(b.tenant && b.tenant.uoccname),
        render: (info, data) => {
          return (
            <span>{data.tenant && `${data.tenant.uoccname}`}</span>
          )
        }
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title && a.title.localeCompare(b.title),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description && a.description.localeCompare(b.description),
      },
      {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
        render: (info, data) => {
          return (
            <span>{moment(info).format('LLL')}</span>
          )
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (item) => {
          return (<span>{this.getStatus(item)}</span>)
        }
      },
      {
        title: 'Actions',
        dataIndex: 'status',
        key: 'status',
        render: (item, data) => {
          switch (item) {
            case 1:
              return (
                <div className='action-btn'>
                  {
                    this.access('orders', 'view') &&
                    <Button type="primary" size={'medium'} icon={<EyeOutlined />} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true, isModalEditEditable: false })} >View</Button>
                  }
                  {
                    this.access('orders', 'delete') &&
                    <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteServiceRequest(data.id)} >Delete</Button>
                  }
                </div>
              )
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              return <div className='action-btn'>
                {
                  this.access('orders', 'view') &&
                  <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true, isModalEditEditable: false })} >View</Button>
                }
              </div>
            default:
              break;
          }
        }
      },
    ];
    return (
      <Layout className='serviceRequests-container'>

        <Layout.Content>
          <div className='serviceRequest-title'>
            <h1>Job Orders</h1>
          </div>
          <Tabs defaultActiveKey="0" type="card" size='small' onChange={e => this.handleRequestStatus(e)}>
            <TabPane tab="All" key="0" />
            <TabPane tab="Approval" key="1" />
            <TabPane tab="Approved" key="2" />
            <TabPane tab="Cancelled" key="3" />
            <TabPane tab="Rejected" key="4" />
          </Tabs>
          <Row>
            <Col md={14} lg={14} sm={24} xs={24}>
              <div className='mt3 mb3'>
                <Button type='primary' onClick={() => this.handleDownloadSelectedRowToCSV()} loading={this.state.downloadLoading}>Download CSV</Button>
              </div>
            </Col>
          </Row>
          <Row className='serviceRequest-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('orders', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Service Ticket, Description, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table
              rowSelection={rowSelection}
              key={(JSON.stringify(this.state.tableData))}
              style={{ width: '100%' }} rowKey='id' loading={this.props.serviceRequestsLoading || this.props.deleteServiceRequestLoading} dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.serviceRequestsData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            requestStatus={this.getRequestStatus(this.state.requestStatus)}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            requestStatus={this.getRequestStatus(this.state.requestStatus)}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    serviceRequestLoading: state.admin.serviceRequest.serviceRequestLoading,
    serviceRequestSuccess: state.admin.serviceRequest.serviceRequestSuccess,
    updateServiceRequestSuccess: state.admin.serviceRequest.updateServiceRequestSuccess,
    deleteServiceRequestSuccess: state.admin.serviceRequest.deleteServiceRequestSuccess,
    serviceRequestsSuccess: state.admin.serviceRequest.serviceRequestsSuccess,
    serviceRequestsData: state.admin.serviceRequest.serviceRequestsData,
    serviceRequestsLoading: state.admin.serviceRequest.serviceRequestsLoading,
    deleteServiceRequestLoading: state.admin.serviceRequest.deleteServiceRequestLoading,
    approveServiceRequestSuccess: state.admin.serviceRequest.approveServiceRequestSuccess,
    verifiedServiceRequestSuccess: state.admin.serviceRequest.verifiedServiceRequestSuccess,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadServiceRequest: (status, pagination) => dispatch(loadServiceRequest(status, pagination)),
    onDeleteServiceRequest: (id) => dispatch(deleteServiceRequest(id)),
    onRejectServiceRequest: (id, status) => dispatch(rejectServiceRequest(id, status)),
    onApproveServiceRequest: (id, status) => dispatch(approveServiceRequest(id, status)),
    onLoadSelectedRowRequest: (selectedKeys) => dispatch(loadSelectedRowRequest(selectedKeys))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ServiceRequests))
