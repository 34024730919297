import {
  GET_TENANT_INFO,
  GET_TENANT_INFO_FULFILLED,
  GET_TENANT_INFO_REJECTED,
  PUT_TENANT_INFO,
  PUT_TENANT_INFO_FULFILLED,
  PUT_TENANT_INFO_REJECTED,
  PUT_UPDATE_PASSWORD,
  PUT_UPDATE_PASSWORD_FULFILLED,
  PUT_UPDATE_PASSWORD_REJECTED
} from '../actions'


export default function settings(state = {
  tenantInfoLoading: false,
  tenantInfoSuccess: false,
  tenantInfoRejected: false,
  updateTenantLoading: false,
  updateTenantSuccess: false,
  updateTenantRejected: false,
  tenantInfoData: {},
  changePasswordLoading: false,
  changePasswordSuccess: false,
  changePasswordRejected: false
}, action) {
  switch (action.type) {
    case GET_TENANT_INFO:
      return {
        ...state,
        tenantInfoLoading: true,
        tenantInfoSuccess: false,
        tenantInfoRejected: false
      }
    case GET_TENANT_INFO_FULFILLED:
      return {
        ...state,
        tenantInfoLoading: false,
        tenantInfoSuccess: true,
        tenantInfoData: action.payload
      }
    case GET_TENANT_INFO_REJECTED:
      return {
        ...state,
        tenantInfoLoading: false,
        tenantInfoRejected: true
      }
    case PUT_TENANT_INFO:
      return {
        ...state,
        updateTenantLoading: true,
        updateTenantSuccess: false,
        updateTenantRejected: false
      }
    case PUT_TENANT_INFO_FULFILLED:
      return {
        ...state,
        updateTenantLoading: false,
        updateTenantSuccess: true,
        updateTenantData: action.payload
      }
    case PUT_TENANT_INFO_REJECTED:
      return {
        ...state,
        updateTenantLoading: false,
        updateTenantRejected: true
      }
    case PUT_UPDATE_PASSWORD:
      return {
        ...state,
        changePasswordLoading: true,
        changePasswordSuccess: false,
        changePasswordRejected: false
      }
    case PUT_UPDATE_PASSWORD_FULFILLED:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordSuccess: true
      }
    case PUT_UPDATE_PASSWORD_REJECTED:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
