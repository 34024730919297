import React from 'react'
import Dropzone from 'react-dropzone'
import './index.scss'
import { ImagePresignedUrl } from '../../../../utilities/renderer'

class UploadComponent extends React.Component {
    constructor(props) {
        super()
        this.state = {
            loading: false,
            base64Image: '',
            value: props.value,
            imageArray: []
        };
        this.getBase64 = this.getBase64.bind(this)
        this.handleDeleteImage = this.handleDeleteImage.bind(this)
    }

    getBase64(file) {
        this.setState({ imageArray: file })
        this.props.getFile && this.props.getFile(file)
    }

    fileToBase64(file) {
        let fileUrl = ''
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            fileUrl = reader.result
        };
        reader.onerror = function (error) { };
        return fileUrl
    }

    handleDeleteImage() {
        this.setState({ base64Image: '' })
        this.props.getImageBase64('')
    }

    render() {
        return (
            <>
                <Dropzone onDrop={acceptedFiles => this.getBase64(acceptedFiles)} maxFiles={1}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {

                                    ((this.state.imageArray.length) ?
                                        <img src={URL.createObjectURL(this.state.imageArray[0])} style={{ height: this.props.height || '100%', width: this.props.width || '100%' }} />
                                        :
                                        (this.state.value) ?
                                            <ImagePresignedUrl src={`${this.state.value}`} style={{ height: this.props.height || '100%', width: this.props.width || '100%', objectFit: 'cover' }} /> :
                                            <div className='dropzone-empty'>Select your 1 (one) image file or drag here.</div>)
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
                {this.state.base64Image !== '' && <a onClick={() => this.handleDeleteImage()}>Delete</a>}
            </>
        );
    }
}

export default UploadComponent;
