import {
  GET_VISITOR,
  GET_VISITOR_FULFILLED,
  GET_VISITOR_REJECTED,
  PUT_VISITOR,
  PUT_VISITOR_FULFILLED,
  PUT_VISITOR_REJECTED,
  POST_VISITOR,
  POST_VISITOR_FULFILLED,
  POST_VISITOR_REJECTED
} from '../actions'

export default function visitor(state = {
  visitorLoading: false,
  visitorSuccess: false,
  visitorRejected: false,
  visitorData: [],
  updateVisitorLoading: false,
  updateVisitorSuccess: false,
  updateVisitorRejected: false,
  addVisitorLoading: false,
  addVisitorSuccess: false,
  addVisitorRejected: false
}, action) {
  switch (action.type) {
    case GET_VISITOR:
      return {
        ...state,
        visitorLoading: true,
        visitorSuccess: false,
        visitorRejected: false
      }
    case GET_VISITOR_FULFILLED:
      return {
        ...state,
        visitorLoading: false,
        visitorSuccess: true,
        visitorData: action.payload
      }
    case GET_VISITOR_REJECTED:
      return {
        ...state,
        visitorLoading: false,
        visitorRejected: true
      }
    case POST_VISITOR:
      return {
        ...state,
        addVisitorLoading: true,
        addVisitorSuccess: false,
        addVisitorRejected: false
      }
    case POST_VISITOR_FULFILLED:
      return {
        ...state,
        addVisitorLoading: false,
        addVisitorSuccess: true,
        addVisitorData: action.payload
      }
    case POST_VISITOR_REJECTED:
      return {
        ...state,
        addVisitorLoading: false,
        addVisitorRejected: true
      }
    case PUT_VISITOR:
      return {
        ...state,
        updateVisitorLoading: true,
        updateVisitorSuccess: false,
        updateVisitorRejected: false
      }
    case PUT_VISITOR_FULFILLED:
      return {
        ...state,
        updateVisitorLoading: false,
        updateVisitorSuccess: true,
        updateVisitorData: action.payload
      }
    case PUT_VISITOR_REJECTED:
      return {
        ...state,
        updateVisitorLoading: false,
        updateVisitorRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
