import {
  POST_INCIDENTREPORT,
  POST_INCIDENTREPORT_FULFILLED,
  POST_INCIDENTREPORT_REJECTED,
  GET_INCIDENTREPORTS,
  GET_INCIDENTREPORTS_FULFILLED,
  GET_INCIDENTREPORTS_REJECTED,
  PUT_INCIDENTREPORT,
  PUT_INCIDENTREPORT_FULFILLED,
  PUT_INCIDENTREPORT_REJECTED,
  DELETE_INCIDENTREPORT,
  DELETE_INCIDENTREPORT_FULFILLED,
  DELETE_INCIDENTREPORT_REJECTED
} from '../actions'

export default function incidentReports(state = {
  incidentReportLoading: false,
  incidentReportSuccess: false,
  incidentReportRejected: false,
  incidentReportData: [],
  incidentReportsLoading: false,
  incidentReportsSuccess: false,
  incidentReportsRejected: false,
  incidentReportsData: [],
  updateIncidentReportLoading: false,
  updateIncidentReportSuccess: false,
  updateIncidentReportRejected: false,
  updateIncidentReportData: [],
  deleteIncidentReportLoading: false,
  deleteIncidentReportSuccess: false,
  deleteIncidentReportRejected: false,
  deleteIncidentReportData: []
}, action) {
  switch (action.type) {
    case POST_INCIDENTREPORT:
      return {
        ...state,
        incidentReportLoading: true,
        incidentReportSuccess: false,
        incidentReportRejected: false
      }
    case POST_INCIDENTREPORT_FULFILLED:
      return {
        ...state,
        incidentReportLoading: false,
        incidentReportSuccess: true,
        incidentReportData: action.payload
      }
    case POST_INCIDENTREPORT_REJECTED:
      return {
        ...state,
        incidentReportLoading: false,
        incidentReportRejected: true
      }
    case GET_INCIDENTREPORTS:
      return {
        ...state,
        incidentReportsLoading: true,
        incidentReportsSuccess: false,
        incidentReportsRejected: false
      }
    case GET_INCIDENTREPORTS_FULFILLED:
      return {
        ...state,
        incidentReportsLoading: false,
        incidentReportsSuccess: true,
        incidentReportsData: action.payload
      }
    case GET_INCIDENTREPORTS_REJECTED:
      return {
        ...state,
        incidentReportsLoading: false,
        incidentReportsRejected: true
      }
    case PUT_INCIDENTREPORT:
      return {
        ...state,
        updateIncidentReportLoading: true,
        updateIncidentReportSuccess: false,
        updateIncidentReportRejected: false
      }
    case PUT_INCIDENTREPORT_FULFILLED:
      return {
        ...state,
        updateIncidentReportLoading: false,
        updateIncidentReportSuccess: true,
        updateIncidentReportData: action.payload
      }
    case PUT_INCIDENTREPORT_REJECTED:
      return {
        ...state,
        updateIncidentReportLoading: false,
        updateIncidentReportRejected: true
      }
    case DELETE_INCIDENTREPORT:
      return {
        ...state,
        deleteIncidentReportLoading: true,
        deleteIncidentReportSuccess: false,
        deleteIncidentReportRejected: false
      }
    case DELETE_INCIDENTREPORT_FULFILLED:
      return {
        ...state,
        deleteIncidentReportLoading: false,
        deleteIncidentReportSuccess: true,
        deleteIncidentReportData: action.payload
      }
    case DELETE_INCIDENTREPORT_REJECTED:
      return {
        ...state,
        deleteIncidentReportLoading: false,
        deleteIncidentReportRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
