import React, { useEffect, useState } from 'react'
import { get } from '../filesController'

const EmbedPresignedUrl = (props) => {
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        async function getData() {
            const fetchedUrl = await get(props.src)
            setUrl(fetchedUrl)
            setLoading(false)
        }
        getData()
    }, [props.src])

    return (
        !loading && <embed src={url} id={props.id} style={props.style} className={props.className} />
    )

}

export default EmbedPresignedUrl