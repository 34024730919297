
import React from 'react'
import { Layout, List, Divider, Row, Col, Collapse, Card } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './index.scss'
import moment from 'moment'
import { loadMemorandums } from '../actions'
import { IframePresignedUrl } from '../../../../utilities/renderer'
const { Panel } = Collapse;

class Memorandums extends React.Component {

    componentDidMount() {
        this.props.onLoadMemorandums()
    }

    handleScrollView(id) {
        var elmnt = document.getElementById(`memorandums-${id}`);
        elmnt.scrollIntoView(true);
        window.scrollBy(0, -140);
    }

    render() {
        return (
            <Layout className='memorandums-container'>
                <Row>
                    <Col md={18} lg={18} sm={24} xs={24}>
                        <List
                            className='memorandums-list'
                            itemLayout="horizontal"
                            dataSource={this.props.memorandumsData}
                            loading={this.props.memorandumsLoading}
                            renderItem={item => (
                                <List.Item id={`memorandums-${item.id}`} >
                                    <Card title={item.title} style={{ width: '100%' }}>
                                        <Row>
                                            <Col md={12} lg={12} xs={24} sm={24}>
                                                <p>{item.description}</p>
                                            </Col>
                                            <Col md={12} lg={12} xs={24} sm={24} align='right'>
                                                <span className='created-date'>{moment(item.date).format('LLL')}</span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={24} lg={24} xs={24} sm={24}>
                                                <span className='attached-file'>Attached Files</span>
                                                <Collapse ghost>
                                                    {
                                                        item.files && item.files.map((data, index) => {
                                                            return (
                                                                <Panel header={data.name} key={index} style={{ width: '100%' }}>
                                                                    <Row style={{ width: '100%' }}>
                                                                        <Col md={24} lg={24} xs={24} sm={24} align='center'>
                                                                            <IframePresignedUrl src={`${data.url}#view=FitH`} style={{ width: '100%' }}
                                                                                scrolling="yes" height={600} frameBorder="0" />
                                                                        </Col>
                                                                    </Row>
                                                                </Panel>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col className='summary' md={6} lg={6} xs={24} sm={24}>
                        <Card title="Memorandum" style={{ margin: '0 10px' }} >
                            <Collapse defaultActiveKey={'1'} bordered={false} ghost>
                                <Panel header="Lists" key="1">
                                    {
                                        this.props.memorandumsData.map((item, index) => {
                                            return (
                                                <div className='item-list' key={index}>
                                                    <h1 onClick={() => this.handleScrollView(item.id)}>{item.title}</h1>
                                                    <Divider />
                                                </div>
                                            )
                                        })
                                    }
                                </Panel>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        memorandumsData: state.client.memorandums.memorandumsData,
        memorandumsLoading: state.client.memorandums.memorandumsLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadMemorandums: () => dispatch(loadMemorandums()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Memorandums))
