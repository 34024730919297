import React from 'react'
import './index.scss'
import { Row, Col, Button, Modal, Card } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../utilities/token'
import PdfBulkUploader from './pdfBulkUploader'
import Upload from './upload'
import { updateSoaMasterFileOfClient, PUT_SOA_INFO_FULFILLED, socketCount } from '../actions'
import Papa from 'papaparse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import {
  LoadingOutlined,
  CheckCircleOutlined
} from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid';
import { upload } from '../../../../utilities/filesController'

class ImportDataFromPromis extends React.Component {
  constructor() {
    super()
    this.state = {
      csvSoaParsedFileArray: [],
      csvWebClientParsedFileArray: [],
      csvSoaFile: undefined,
      csvWebClient: undefined,
      pdfFilesArray: [],
      pdfFileUploadedCount: 0,
      isCsvSoaFileUploadSuccess: false,
      iscsvWebClientUploadSuccess: false,
      isPdfFilesArrayUploadSuccess: false,
      uploadedFilesSuccess: false,
      isLoading: false,
      count: 0,
      totalRows: 0,
      uniqId: ''
    }
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.pdfUploader = this.pdfUploader.bind(this)
  }

  handleUpload(e) {
    upload(e, Math.random().toString(36).substring(20), 'soa/pdf').then(data => {
    }).catch(err => { })
  }

  getFileFromSoaFile(e) {
    const self = this
    Papa.parse(e, {
      header: true,
      complete: function (results) {
        // remove data that does not have urefno
        const removedDataWithoutUrefNo = results.data.filter(obj => Object.keys(obj).includes("urefno")).filter(data => data.urefno)
        // return only urefno and attachment
        const trimmedData = removedDataWithoutUrefNo.map(item => {
          return {
            urefno: item.urefno,
            invdate: item.invdate,
            attachment: item.attachment
          };
        })
        self.setState({ csvSoaParsedFileArray: trimmedData })
      }
    });
    this.setState({ csvSoaFile: e })
  }

  getFileFromWebClient(e) {
    const self = this
    Papa.parse(e, {
      header: true,
      complete: function (results) {
        // remove data that does not have urefno
        const removedDataWithoutUrefNo = results.data.filter(obj => Object.keys(obj).includes("urefno")).filter(data => data.urefno)
        // return only urefno and outhdep
        const trimmedData = removedDataWithoutUrefNo.map(item => {
          return {
            urefno: item.urefno,
            uoccname: item.uoccname,
            uoccpers: item.uoccpers,
            uocctel1: item.uocctel1,
            uocctel2: item.uocctel2,
            ubtname: item.ubtname,
            ubaddln1: item.ubaddln1,
            ubaddln2: item.ubaddln2,
            ubaddln3: item.ubaddln3,
            ubaddln4: item.ubaddln4,
            soaamt: parseFloat(item.uothdep)
          };
        })
        self.setState({ csvWebClientParsedFileArray: trimmedData })
      }
    });
    this.setState({ csvWebClient: e })
  }

  getPdfFileArray(e) {
    this.setState({ pdfFilesArray: e })
  }

  handleUploadFile() {

    this.setState({
      isCsvSoaFileUploadSuccess: false,
      iscsvWebClientUploadSuccess: false,
      isPdfFilesArrayUploadSuccess: false,
      isLoading: true,
    })

    // CSV SOA FILE UPLOADED
    if (this.state.csvSoaFile) {
      upload(this.state.csvSoaFile, this.state.csvSoaFile.name, 'soa/csv').then(data => {
        this.setState({ isCsvSoaFileUploadSuccess: true })
      }).catch(err => {
        return Modal.error({
          title: 'This is an error message in Soa File',
          content: err,
        });
      })
    } else {
      this.setState({ isCsvSoaFileUploadSuccess: true })
    }

    // CSV WEB CLIENT UPLOADED
    if (this.state.csvWebClient) {
      upload(this.state.csvWebClient, this.state.csvWebClient.name, 'soa/csv').then(data => {
        this.setState({ iscsvWebClientUploadSuccess: true })
      }).catch(err => {
        return Modal.error({
          title: 'This is an error message in Soa Web Client',
          content: err,
        });
      })
    } else {
      this.setState({ iscsvWebClientUploadSuccess: true })
    }

    // PDF SOA FILE UPLOADED
    this.pdfUploader(this.state.pdfFilesArray, 'soa/pdf', 0)
  }

  pdfUploader(pdfFilesArray, pathName, index) {
    if (pdfFilesArray.length) {
      const items = pdfFilesArray[index]
      return upload(items, items.name, pathName).then(data => {
        this.setState({ isPdfFilesArrayUploadSuccess: true, pdfFileUploadedCount: index + 1 })
        this.props.onSocketCount(index + 1)
        if ((pdfFilesArray.length - 1) !== index) {
          this.pdfUploader(pdfFilesArray, pathName, index + 1)
        }
      }).catch(err => {
        return Modal.error({
          title: 'This is an error message in Soa Pdf',
          content: err,
        });
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.state.pdfFilesArray !== prevState.pdfFilesArray) || (this.state.pdfFileUploadedCount !== prevState.pdfFileUploadedCount)) {
      const submitData = {
        pdfFiles: this.mergeArrayObjects(this.state.csvWebClientParsedFileArray, this.state.csvSoaParsedFileArray),
        uniqId: this.state.uniqId
      }
      // trigger onupdate when done uploading all the pdf's
      if (this.state.pdfFilesArray.length !== 0 && (this.state.pdfFileUploadedCount === this.state.pdfFilesArray.length)) {
        this.setState({
          isLoading: true
        })
        this.props.onUpdateSoaMasterFileOfClient(submitData).then(res => {
          if (res.type === PUT_SOA_INFO_FULFILLED) {
            if (!this.state.uploadedFilesSuccess) {
              this.setState({ uploadedFilesSuccess: true, isLoading: false })
              Modal.success({
                title: 'Success',
                content: 'You have successfully uploaded all the files.',
                onOk: () => {
                  window.location.reload()
                }
              });
            }
          } else {
            Modal.error({
              title: 'Error',
              content: 'Some of the files may uploaded and some are causing an error.',
              onOk: () => {
                window.location.reload()
              }
            });
          }
        }).finally(() => {
          this.setState({
            // csvSoaParsedFileArray: [],
            // csvWebClientParsedFileArray: [],
            // csvSoaFile: undefined,
            // csvWebClient: undefined,
            pdfFilesArray: [],
            pdfFileUploadedCount: 0,
            isLoading: false
          })
        })
      }
    }
  }

  componentDidMount() {
    const uniqId = `${getUser().id}-${uuidv4()}`
    this.setState({ uniqId })
    window.Echo.channel(`soa-update.${uniqId}`)
      .listen('.soa-update-channel', (response) => {
        const { data } = response
        if (data.count > this.state.count) {
          this.setState({ count: data.count, totalRows: data.total })
        }
        if ((+data.count === +data.total) && +data.count !== 0 && +data.total !== 0) {
          if (!this.state.uploadedFilesSuccess) {
            this.setState({ uploadedFilesSuccess: true, isLoading: false })
            Modal.success({
              title: 'Success',
              content: 'You have successfully uploaded all the files.',
              onOk: () => {
                window.location.reload()
              }
            });
          }

        }
        this.props.onSocketCount(data.count)
      })
  }
  // with this function it will merge two arrays based on their identity
  mergeArrayObjects(arr1, arr2) {
    return arr1.map(webClient => {
      const found = arr2.findIndex(soaFile => soaFile.urefno === webClient.urefno)
      if (found !== -1) {
        webClient.attachment = arr2[found] && arr2[found].attachment
        webClient.invdate = arr2[found] && arr2[found].invdate
      }
      else webClient.attachment = ''
      return webClient
    })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const { isCsvSoaFileUploadSuccess, iscsvWebClientUploadSuccess, isPdfFilesArrayUploadSuccess, isLoading } = this.state
    return (
      <Card loading={this.props.servicesLoading}>
        <Row gutter={32}>
          <Col lg={8} md={8} xs={24} sm={24}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h1>Select or Drag your CSV <b>SOA FILE</b> here. (ex: Soa File.csv)</h1>
              <span> {isCsvSoaFileUploadSuccess ? <b style={{ color: 'green' }}>UPLOADED!!</b> : null} <FontAwesomeIcon icon={faCheckCircle} style={{ color: isCsvSoaFileUploadSuccess && 'green' }} /></span>
            </div>
            <Upload
              getFile={e => this.getFileFromSoaFile(e)}
            />
            <span style={{ fontSize: 10, color: 'red' }}>Single file Only</span>
          </Col>
          <Col lg={8} md={8} xs={24} sm={24}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h1>Select or Drag your CSV <b>WEB CLIENT</b> here. (ex: Webclient.csv)</h1>
              <span> {iscsvWebClientUploadSuccess ? <b style={{ color: 'green' }}>UPLOADED!!</b> : null}   <FontAwesomeIcon icon={faCheckCircle} style={{ color: iscsvWebClientUploadSuccess && 'green' }} />  </span>
            </div>
            <Upload
              getFile={e => this.getFileFromWebClient(e)}
            />
            <span style={{ fontSize: 10, color: 'red' }}>Single file Only</span>
          </Col>
          <Col lg={8} md={8} xs={24} sm={24}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h1>Select or Drag your PDF File for tenant  here. (ex: Pdf soa.pdf)</h1>
              <span> {((this.state.pdfFilesArray.length !== 0 || this.state.isPdfFilesArrayUploadSuccess) && (this.state.pdfFileUploadedCount === this.state.pdfFilesArray.length)) ? <b style={{ color: 'green' }} >UPLOADED!!</b> : <span style={{ color: 'green', fontWeight: 'bold', margin: 10 }}>{this.state.pdfFileUploadedCount} / {this.state.pdfFilesArray.length}</span>}<FontAwesomeIcon icon={faCheckCircle} style={{ color: isPdfFilesArrayUploadSuccess && 'green' }} /></span>
            </div>
            <PdfBulkUploader
              getFile={e => this.getPdfFileArray(e)}
            />
            <span style={{ fontSize: 10, color: 'red' }}>Can be multiple files</span>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} xs={24} sm={24} align='right'>
            {
              this.access('utilities', 'add') &&
              <Button loading={isLoading} type="primary" onClick={() => {
                this.handleUploadFile()
                this.setState({ isLoading: true })
              }}>Upload Files</Button>
            }
          </Col>
        </Row>
        <Modal visible={
          (this.state.isLoading || this.props.soaInfoLoading) && !this.state.uploadedFilesSuccess
          // ((!(+this.state.count === +this.state.totalRows) && !(+this.state.count !== 0 && +this.state.total !== 0)))

        } onOk={() => { }} title={null} footer={null} closable={false} onCancel={null}>
          <span> {isCsvSoaFileUploadSuccess ? <><CheckCircleOutlined /> &nbsp;&nbsp;Soa File Uploaded.</> : <><LoadingOutlined style={{ color: 'green' }} /> &nbsp;&nbsp;Soa File Uploading. <span style={{ color: 'gray', fontSize: 11 }}>(Please wait)</span>  </>}</span>
          <br />
          <span> {iscsvWebClientUploadSuccess ? <><CheckCircleOutlined /> &nbsp;&nbsp;Web Client Uploaded.</> : <> <LoadingOutlined style={{ color: 'green' }} /> &nbsp;&nbsp;Web Client Uploading. <span style={{ color: 'gray', fontSize: 11 }}>(Please wait)</span></>}</span>
          <br />
          <span> {((this.state.pdfFilesArray.length !== 0 || this.state.isPdfFilesArrayUploadSuccess) && (this.state.pdfFileUploadedCount === this.state.pdfFilesArray.length)) ? <><CheckCircleOutlined /> &nbsp;&nbsp;PDF Files Uploaded. {this.state.pdfFileUploadedCount} / {this.state.pdfFilesArray.length}.</>
            : <><LoadingOutlined style={{ color: 'green' }} /> &nbsp;&nbsp;PDF Files Uploading. {this.state.pdfFileUploadedCount} / {this.state.pdfFilesArray.length} <span style={{ color: 'gray', fontSize: 11 }}>(Please wait)</span></>} </span>
          <br />
          <span> {(this.state.isLoading && this.props.soaInfoSuccess) ? <><CheckCircleOutlined /> &nbsp;&nbsp;Data Saved.</> : <> <LoadingOutlined style={{ color: 'green' }} /> &nbsp;&nbsp;Data Saving {this.state.count} / {this.state.totalRows} (<span style={{ fontSize: 11 }}>Will take up to 5 - 10 mins for large rows of file</span>). <span style={{ color: 'gray', fontSize: 11 }}>(Please wait)</span></>}</span>
        </Modal>
      </Card>
    )
  }
}


function mapStateToProps(state) {
  return {
    adminData: state.admin.utilities.adminData,
    soaInfoLoading: state.admin.utilities.soaInfoLoading,
    soaInfoSuccess: state.admin.utilities.soaInfoSuccess
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateSoaMasterFileOfClient: (data) => dispatch(updateSoaMasterFileOfClient(data)),
    onSocketCount: (count) => dispatch(socketCount(count))

  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(ImportDataFromPromis))
