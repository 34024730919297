import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_POSTS = 'GET_POSTS'
export const GET_POSTS_FULFILLED = 'GET_POSTS_FULFILLED'
export const GET_POSTS_REJECTED = 'GET_POSTS_REJECTED'

export const GET_POST_LATEST = 'GET_POST_LATEST'
export const GET_POST_LATEST_FULFILLED = 'GET_POST_LATEST_FULFILLED'
export const GET_POST_LATEST_REJECTED = 'GET_POST_LATEST_REJECTED'

export const POST_COMMENT = 'POST_COMMENT'
export const POST_COMMENT_FULFILLED = 'POST_COMMENT_FULFILLED'
export const POST_COMMENT_REJECTED = 'POST_COMMENT_REJECTED'

export const DELETE_COMMENT = 'DELETE_COMMENT'
export const DELETE_COMMENT_FULFILLED = 'DELETE_COMMENT_FULFILLED'
export const DELETE_COMMENT_REJECTED = 'DELETE_COMMENT_REJECTED'

export const POST_CLICK_ANALYTICS = 'POST_CLICK_ANALYTICS'
export const POST_CLICK_ANALYTICS_FULFILLED = 'POST_CLICK_ANALYTICS_FULFILLED'
export const POST_CLICK_ANALYTICS_REJECTED = 'POST_CLICK_ANALYTICS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadPosts = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_POSTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/posts?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_POSTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_POSTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadPostLatest = (data) => {
  return dispatch => {
    dispatch({
      type: GET_POST_LATEST,
      payload: {}
    })
    return Axios.get(`${API_URL}/post-latest`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_POST_LATEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_POST_LATEST_REJECTED,
          payload: error
        })
      })
  }
}

export const submitComments = (data) => {
  return dispatch => {
    dispatch({
      type: POST_COMMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/comment`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch(loadPosts())
        return dispatch({
          type: POST_COMMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_COMMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteComment = (commentId) => {
  return dispatch => {
    dispatch({
      type: DELETE_COMMENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/comment/${commentId} `, { headers: headers })
      .then(function (response) {
        dispatch(loadPosts())
        return dispatch({
          type: DELETE_COMMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_COMMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPostClickAnalytics = (data) => {
  return dispatch => {
    dispatch({
      type: POST_CLICK_ANALYTICS,
      payload: {}
    })
    return Axios.post(`${API_URL}/post-analytic`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_CLICK_ANALYTICS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_CLICK_ANALYTICS_REJECTED,
          payload: error
        })
      })
  }
}






