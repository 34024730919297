import React from 'react'
import { Row, Col, Button, Modal, Radio, Input, Select, Switch, message, Tabs, Divider, Layout } from 'antd'
import { connect } from 'react-redux'
import { updateAdmin, POST_ADMINS_REJECTED } from '../../../actions'
import _ from 'lodash';
import '../../index.scss'
import { defaultRoles } from '../../../../../../utilities/roles'
const { TabPane } = Tabs;

class Roles extends React.Component {
  constructor() {
    super()
    this.state = {
      roles: []
    }
    this.handleChangeRoles = this.handleChangeRoles.bind(this)
  }

  handleSubmit(values) {
    const submitData = {
      "name": values.name,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "password": values.password,
      "password_confirmation": values.confirm,
      "status": 1,
    }

    this.props.onSubmitAdmin(submitData).then((res) => {
      if (res.type === POST_ADMINS_REJECTED) {
        if (res.payload && res.payload.response && res.payload.response.status === 400) {
          message.error("Email Already Taken")
        }
      }
    })
  }

  mergeDefaultAndExistingRoles() {
    let newDefaultRoles = []
    defaultRoles.map((defRoles, index) => {
      const found = this.props.selectedRow.roles.find(role => (role.title) === defRoles.title)
      if (found) newDefaultRoles.push(found)
    })
    return newDefaultRoles
  }

  componentDidMount() {
    const roles = this.props.selectedRow.roles.length === 0 ? defaultRoles : this.mergeDefaultAndExistingRoles()
    this.setState({ roles: roles })
  }

  handleChangeRoles(value, title, roles) {
    const index = this.state.roles.findIndex(data => data.title === title)
    this.state.roles[index].roles[roles] = value
    this.setState({ roles: this.state.roles })
  }

  handlePageEnable(value, title) {
    const index = this.state.roles.findIndex(data => data.title === title)
    this.state.roles[index].visible = value
    this.setState({ roles: this.state.roles })
  }

  handleSubmitRoles() {
    const submitData = {
      id: this.props.selectedRow.id,
      roles: this.state.roles
    }
    this.props.onUpdateAdmin(submitData)
  }

  render() {
    const self = this
    return (
      <Modal
        title="Roles"
        className="roles-management-modal"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Tabs tabPosition='left' centered={true}>
          {
            this.state.roles.map((data, index) => {
              return (
                <TabPane tab={_.startCase(data.title)} key={index}>
                  <h1>Page Settings <span>{`(${_.startCase(data.title)})`}</span></h1>
                  <h4>Switching this to 'ON' that means you allow this ADMIN to view the {_.startCase(data.title)} Page.</h4>
                  <Switch defaultChecked={data.visible} onChange={(value) => self.handlePageEnable(value, data.title)} />
                  <Divider />
                  <h1>Access Roles</h1>
                  <Row align="bottom">
                    {
                      Object.keys(data.roles).map(function (key, index) {
                        return (
                          <Col span={4} style={{ marginBottom: 100 }} key={index}>
                            <h4>{_.startCase(key)}</h4>
                            <Switch defaultChecked={data.roles[key]} onChange={(value) => self.handleChangeRoles(value, data.title, key)} />
                          </Col>

                        )
                      })
                    }
                  </Row>
                </TabPane>
              )
            })
          }
        </Tabs>
        <Row>
          <Col md={24} lg={24} sm={24} align={'right'}>
            <Button type="primary" onClick={() => this.handleSubmitRoles()} >
              Update Roles
            </Button>
          </Col>
        </Row>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateAdmin: (data) => dispatch(updateAdmin(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Roles))


