import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Pagination } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadLoggedInMonitor } from '../actions'
import ReactTimeAgo from 'react-time-ago'
import Papa from 'papaparse'
import moment from 'moment'
const { Search } = Input;

class LoginLogs extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedRowKeys: [],
      tableData: [],
      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.tenantsSuccess !== nextProps.tenantsSuccess) && nextProps.tenantsSuccess) {
      this.setState({ tableData: nextProps.tenantsData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadLoggedInMonitor(pagination)
  }

  onChangePagination(page) {
    const { limit, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadLoggedInMonitor(pagination)
    this.setState({ page: page })
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadLoggedInMonitor(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }


  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const dataSource = this.state.tableData
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      preserveSelectedRowKeys: true
    };
    const columns = [
      {
        title: 'Last Active',
        dataIndex: 'lastSeen',
        key: 'lastSeen',
        sorter: (a, b) => new Date(b.lastSeen) - new Date(a.lastSeen),
        render: (info, data) => {
          return info && <ReactTimeAgo date={info} locale="en-US" />
        },
      },
      {
        title: 'Client Code',
        dataIndex: 'clientCode',
        key: 'clientCode',
        sorter: (a, b) => a.clientCode && a.clientCode.localeCompare(b.clientCode)
      },
      {
        title: 'Uoccname',
        dataIndex: 'uoccname',
        key: 'uoccname',
        sorter: (a, b) => a.uoccname && a.uoccname.localeCompare(b.uoccname)
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email && a.email.localeCompare(b.email)
      },

    ];
    return (
      <Layout className='tenants-container'>
        <Layout.Content>
          <div className='tenant-title'>
            <h1>Logged In Monitor</h1>
          </div>
          <Row className='tenant-header'>
            <Col md={14} lg={14} sm={24} >
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Client Code, Name, Email, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Row style={{ width: '100%' }}>
              <Table style={{ width: '100%' }}
                //  rowSelection={rowSelection}
                rowKey='id' loading={this.props.tenantsLoading} dataSource={dataSource} columns={columns} pagination={false} />
              <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right">
                  <Pagination defaultCurrent={this.state.page} onChange={e => this.onChangePagination(e)} total={this.props.tenantsData.total} />
                </Col>
              </Row>
            </Row>
          </Row>
        </Layout.Content>
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    tenantLoading: state.admin.tenant.tenantLoading,
    tenantSuccess: state.admin.tenant.tenantSuccess,
    updateTenantSuccess: state.admin.tenant.updateTenantSuccess,
    tenantsSuccess: state.admin.tenant.tenantsSuccess,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadLoggedInMonitor: (pagination) => dispatch(loadLoggedInMonitor(pagination)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(LoginLogs))
