import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Radio } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitServiceRequest } from '../../actions'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      remarks: ''
    }
    this.timeout = 0;
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleSubmit(values) {
    const submitData = {
      "tenantId": values.tenantId,
      "postById": getUser().id,
      "title": values.title,
      "description": values.description,
      "comment": values.comment,
      "status": 1,
      "title": values.title,
      "billOption": values.billOption,
      "description": values.description,
      "estimatedAmount": values.estimatedAmount,
      "remarks": values.remarks
    }
    this.props.onSubmitServinceRequest(submitData, this.props.requestStatus)
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }

  render() {
    // const isReadOnly = this.props.selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    const isReadOnly = false // Cant edit if the status is for approval = 1

    return (
      <Modal
        title="ADD JOB ORDER"
        centered
        visible={this.props.visible}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input readOnly={isReadOnly} />
                </Form.Item>

              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label={`Estimated Amount`}
                  name="estimatedAmount"
                  rules={[{ required: true, message: 'Please input your estimated amount!' }]}
                >
                  <Input type="number" />
                </Form.Item>

                <Form.Item
                  label="Bill Option (Tag as Billable/Non-Billable)"
                  name="billOption"
                  rules={[{ required: true, message: 'Please select a bill option!' }]}
                >
                  <Radio.Group>
                    <Radio value={'Billable'}>Billable</Radio>
                    <Radio value={'Non-Billable'}>Non-Billable</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  label={`Remarks`}
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea onChange={(e) => this.setState({ remarks: e.target.value })} rows={3} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button type="primary" htmlType="submit" loading={this.props.serviceRequestLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    serviceRequestLoading: state.admin.serviceRequest.serviceRequestLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitServinceRequest: (data, status) => dispatch(submitServiceRequest(data, status)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant())
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))

