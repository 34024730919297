import {
  GET_SERVICE,
  GET_SERVICE_FULFILLED,
  GET_SERVICE_REJECTED,
  PUT_SERVICE,
  PUT_SERVICE_FULFILLED,
  PUT_SERVICE_REJECTED,
  POST_SERVICE,
  POST_SERVICE_FULFILLED,
  POST_SERVICE_REJECTED,
  DELETE_SERVICE,
  DELETE_SERVICE_FULFILLED,
  DELETE_SERVICE_REJECTED
} from '../actions'

export default function service(state = {
  serviceLoading: false,
  serviceSuccess: false,
  serviceRejected: false,
  serviceData: {
    data: []
  },
  updateServiceLoading: false,
  updateServiceSuccess: false,
  updateServiceRejected: false,
  addServiceLoading: false,
  addServiceSuccess: false,
  addServiceRejected: false,
  deleteServiceLoading: false,
  deleteServiceSuccess: false,
  deleteServiceRejected: false
}, action) {
  switch (action.type) {
    case GET_SERVICE:
      return {
        ...state,
        serviceLoading: true,
        serviceSuccess: false,
        serviceRejected: false
      }
    case GET_SERVICE_FULFILLED:
      return {
        ...state,
        serviceLoading: false,
        serviceSuccess: true,
        serviceData: action.payload
      }
    case GET_SERVICE_REJECTED:
      return {
        ...state,
        serviceLoading: false,
        serviceRejected: true
      }
    case POST_SERVICE:
      return {
        ...state,
        addServiceLoading: true,
        addServiceSuccess: false,
        addServiceRejected: false
      }
    case POST_SERVICE_FULFILLED:
      return {
        ...state,
        addServiceLoading: false,
        addServiceSuccess: true,
        addServiceData: action.payload
      }
    case POST_SERVICE_REJECTED:
      return {
        ...state,
        addServiceLoading: false,
        addServiceRejected: true
      }
    case PUT_SERVICE:
      return {
        ...state,
        updateServiceLoading: true,
        updateServiceSuccess: false,
        updateServiceRejected: false
      }
    case PUT_SERVICE_FULFILLED:
      return {
        ...state,
        updateServiceLoading: false,
        updateServiceSuccess: true,
        updateServiceData: action.payload
      }
    case PUT_SERVICE_REJECTED:
      return {
        ...state,
        updateServiceLoading: false,
        updateServiceRejected: true
      }

    case DELETE_SERVICE:
      return {
        ...state,
        deleteServiceLoading: true,
        deleteServiceSuccess: false,
        deleteServiceRejected: false
      }
    case DELETE_SERVICE_FULFILLED:
      return {
        ...state,
        deleteServiceLoading: false,
        deleteServiceSuccess: true,
        deleteServiceData: action.payload
      }
    case DELETE_SERVICE_REJECTED:
      return {
        ...state,
        deleteServiceLoading: false,
        deleteServiceRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
