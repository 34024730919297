import Axios from 'axios'
import { headers } from '../../../utilities/token'

export const GET_WEBSITE_SETTINGS = 'GET_WEBSITE_SETTINGS'
export const GET_WEBSITE_SETTINGS_FULFILLED = 'GET_WEBSITE_SETTINGS_FULFILLED'
export const GET_WEBSITE_SETTINGS_REJECTED = 'GET_WEBSITE_SETTINGS_REJECTED'

export const GET_UTILITY_SERVICES = 'GET_UTILITY_SERVICES'
export const GET_UTILITY_SERVICES_FULFILLED = 'GET_UTILITY_SERVICES_FULFILLED'
export const GET_UTILITY_SERVICES_REJECTED = 'GET_UTILITY_SERVICES_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadWebsiteSettings = (data) => {
  return dispatch => {
    dispatch({
      type: GET_WEBSITE_SETTINGS,
      payload: {}
    })
    return Axios.get(`${API_URL}/public-website-settings`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_WEBSITE_SETTINGS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_WEBSITE_SETTINGS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadUtilityServices = (data) => {
  return dispatch => {
    dispatch({
      type: GET_UTILITY_SERVICES,
      payload: {}
    })
    return Axios.get(`${API_URL}/system-parameter-settings`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_UTILITY_SERVICES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_UTILITY_SERVICES_REJECTED,
          payload: error
        })
      })
  }
}
