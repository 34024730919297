import React from 'react'
import '../index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faEnvelope, faHome, faList, faListAlt } from '@fortawesome/free-solid-svg-icons'
import { ImagePresignedUrl } from '../../../../../utilities/renderer'

const MobileSimulator = (props) => {
    return (
        <div className='mobile-simulator'>
            <div className='speaker'>
                <i></i>
            </div>
            <div className='screen'>
                <div className='header' style={{ backgroundColor: props.headerBackgroundColor }}>
                    <div className='level-1'>
                        <div />
                        {/* <img src='https://www.century-properties.com/wp-content/uploads/2021/03/newlogo4-retina.png' /> */}
                        <ImagePresignedUrl src={props.headerLogo} />
                        <div className='menu'>
                            <FontAwesomeIcon icon={faList} />
                        </div>
                    </div>
                    <div className='level-2'>
                        <h1>John Lloyd Dela Cruz</h1>
                        <p>OWNER: UNIT 4504</p>
                    </div>
                </div>

                <div className='footer-navigator' style={{ backgroundColor: props.footerNavigationBackgroundColor }}>
                    <div className='icon'>
                        <FontAwesomeIcon icon={faHome} />
                        <span>Home</span>
                    </div>
                    <div className='icon'>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span>Mail</span>
                    </div>
                    <div className='icon'>
                        <FontAwesomeIcon icon={faListAlt} />
                        <span>Services</span>
                    </div>
                    <div className='icon'>
                        <FontAwesomeIcon icon={faCreditCard} />
                        <span>Billing</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileSimulator
