import Axios from 'axios'
import moment from 'moment'
import { headers } from '../../../../utilities/token'

export const POST_SERVICEREQUEST = 'POST_SERVICEREQUEST'
export const POST_SERVICEREQUEST_FULFILLED = 'POST_SERVICEREQUEST_FULFILLED'
export const POST_SERVICEREQUEST_REJECTED = 'POST_SERVICEREQUEST_REJECTED'

export const GET_SERVICEREQUESTS = 'GET_SERVICEREQUESTS'
export const GET_SERVICEREQUESTS_FULFILLED = 'GET_SERVICEREQUESTS_FULFILLED'
export const GET_SERVICEREQUESTS_REJECTED = 'GET_SERVICEREQUESTS_REJECTED'

export const GET_SERVICEREQUEST = 'GET_SERVICEREQUEST'
export const GET_SERVICEREQUEST_FULFILLED = 'GET_SERVICEREQUEST_FULFILLED'
export const GET_SERVICEREQUEST_REJECTED = 'GET_SERVICEREQUEST_REJECTED'

export const PUT_SERVICEREQUEST = 'PUT_SERVICEREQUEST'
export const PUT_SERVICEREQUEST_FULFILLED = 'PUT_SERVICEREQUEST_FULFILLED'
export const PUT_SERVICEREQUEST_REJECTED = 'PUT_SERVICEREQUEST_REJECTED'

export const DELETE_SERVICEREQUEST = 'DELETE_SERVICEREQUEST'
export const DELETE_SERVICEREQUEST_FULFILLED = 'DELETE_SERVICEREQUEST_FULFILLED'
export const DELETE_SERVICEREQUEST_REJECTED = 'DELETE_SERVICEREQUEST_REJECTED'

export const APPROVE_SERVICEREQUEST = 'APPROVE_SERVICEREQUEST'
export const APPROVE_SERVICEREQUEST_FULFILLED = 'APPROVE_SERVICEREQUEST_FULFILLED'
export const APPROVE_SERVICEREQUEST_REJECTED = 'APPROVE_SERVICEREQUEST_REJECTED'

export const VERIFIED_SERVICEREQUEST = 'VERIFIED_SERVICEREQUEST'
export const VERIFIED_SERVICEREQUEST_FULFILLED = 'VERIFIED_SERVICEREQUEST_FULFILLED'
export const VERIFIED_SERVICEREQUEST_REJECTED = 'VERIFIED_SERVICEREQUEST_REJECTED'

export const GET_SELECTED_KEYS_REQUEST = 'GET_SELECTED_KEYS_REQUEST'
export const GET_SELECTED_KEYS_REQUEST_FULFILLED = 'GET_SELECTED_KEYS_REQUEST_FULFILLED'
export const GET_SELECTED_KEYS_REQUEST_REJECTED = 'GET_SELECTED_KEYS_REQUEST_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadServiceRequest = (status, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_SERVICEREQUESTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/requests?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&status=${status}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_SERVICEREQUESTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_SERVICEREQUESTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadServiceById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_SERVICEREQUEST,
      payload: {}
    })
    return Axios.get(`${API_URL}/request/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SERVICEREQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SERVICEREQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const submitServiceRequest = (data, status) => {
  return dispatch => {
    dispatch({
      type: POST_SERVICEREQUEST,
      payload: {}
    })
    return Axios.post(`${API_URL}/request`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_SERVICEREQUEST_FULFILLED,
          payload: response.data
        })
        dispatch(loadServiceRequest(status))
      })
      .catch(function (error) {
        dispatch({
          type: POST_SERVICEREQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const updateServiceRequest = (data, status) => {
  return dispatch => {
    dispatch({
      type: PUT_SERVICEREQUEST,
      payload: {}
    })
    return Axios.put(`${API_URL}/request/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_SERVICEREQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_SERVICEREQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const approveServiceRequest = (id, status, data) => {
  const submitData = {
    approvalDate: data.approvalDate,
    approvedById: data.approvedById
  }
  return dispatch => {
    dispatch({
      type: APPROVE_SERVICEREQUEST,
      payload: {}
    })
    return Axios.put(`${API_URL}/request/approved/${id}`, { ...submitData }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: APPROVE_SERVICEREQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: APPROVE_SERVICEREQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const verifiedServiceRequest = (id, status, data) => {
  const submitData = {
    verifiedById: data.verifiedById
  }
  return dispatch => {
    dispatch({
      type: VERIFIED_SERVICEREQUEST,
      payload: {}
    })
    return Axios.put(`${API_URL}/request/verified/${id}`, { ...submitData }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: VERIFIED_SERVICEREQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: VERIFIED_SERVICEREQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const rejectServiceRequest = (id, status) => {
  return dispatch => {
    dispatch({
      type: DELETE_SERVICEREQUEST,
      payload: {}
    })
    return Axios.put(`${API_URL}/request/rejected/${id}`, {}, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_SERVICEREQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_SERVICEREQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteServiceRequest = (id, status) => {
  return dispatch => {
    dispatch({
      type: DELETE_SERVICEREQUEST,
      payload: {}
    })
    return Axios.delete(`${API_URL}/request/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_SERVICEREQUEST_FULFILLED,
          payload: response.data
        })
        dispatch(loadServiceRequest(status))
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_SERVICEREQUEST_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSelectedRowRequest = (selectedKeys) => {
  return dispatch => {
    dispatch({
      type: GET_SELECTED_KEYS_REQUEST,
      payload: {}
    })
    return Axios.get(`${API_URL}/requestIds?selectedIds=${selectedKeys.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SELECTED_KEYS_REQUEST_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SELECTED_KEYS_REQUEST_REJECTED,
          payload: error
        })
      })
  }
}





