import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import * as _ from 'lodash'
import './index.scss'
import 'antd/dist/antd.css'
// import Header from './shared/Header'
import { NotFound } from './notFound'
import Login from './login/component'
import Dashboard from './dashboard/components'
import Policies from './policies/components'
import Memorandums from './memorandums/components'
import Gatepass from './gatePass/components'
import Announcements from './announcements/components'
import Settings from './settings/components'
import Services from './services/components'
import Billings from './billings/components'
import Payments from './billings/components/payments'
import DragonPayMessage from './billings/components/dragonpay/message'
import Deposit from './billings/components/deposit'
import Dragonpay from './billings/components/dragonpay/dragonpay'
import AQWire from './billings/components/aqwire/aqwire'
import AQWireMessage from './billings/components/aqwire/success'
import MobileHeader from './shared/Header/mobileHeader'
import Permits from './permits/components'
import Inquiries from './inquiries/components'
import Commendations from './commendations/components'
import Visitors from './visitors/components'
import ServiceRequests from './services/components/orderRequest'
import IncidentReports from './incidents/components'
import Reservations from './reservations/components'
import Notifications from './notifications/components'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Divider, Layout, Menu, Modal, Row, Col, Spin } from 'antd'
import { loadWebsiteSettings, loadUtilityServices } from './actions'
import { loadTenantData } from '../client/settings/actions'
import { loadAdvertisement } from './advertisements/actions'
import Axios from 'axios';
import { getUser } from '../../utilities/token'
import {
  ExclamationCircleOutlined,
  OrderedListOutlined
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faComment,
  faCreditCard,
  faFlag,
  faHandPaper,
  faSignOutAlt,
  faWrench, faBuilding,
  faTicketAlt,
  faCalendarAlt,
  faExclamationCircle,
  faCogs,
  faUsers,
  faPaperclip,
  faEnvelope,
  faTachometerAlt,
  faTasks
} from '@fortawesome/free-solid-svg-icons'
import { Fragment } from 'react'
import ActivityChecker from '../../utilities/activityChecker'
import { ImagePresignedUrl } from '../../utilities/renderer'

const { SubMenu } = Menu;
const REACT_APP_NAME = process.env.REACT_APP_NAME

const { Sider, Content } = Layout
moment.updateLocale(moment.locale(), { invalidDate: 'N/A' })

const axiosError = (error) => {
  if (error && error.response && error.response.status) {
    Modal.info({
      title: 'Oops! Something went wrong.',
      content: `${error && error.response && error.response.data && error.response.data.message}`,
    });
  } else {
    Modal.error({
      title: 'Oops! Something went wrong.',
      content: 'You will be redirected to login page.',
      onOk: () => logoutError(),
    });
  }

};

const logoutError = () => {
  localStorage.removeItem('CLIENT_SESSION_TOKEN');
  window.location.replace('/');
  window.location.reload();
};

let paths = [
  {
    default: true,
    exact: true,
    sidebarMenu: true,
    slug: 'Dashboard',
    header: 'Dashboard',
    route: '/',
    component: Dashboard,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faTachometerAlt} />
  },
  {
    default: true,
    exact: true,
    sidebarMenu: true,
    slug: 'Mail',
    header: 'Announcements and Events',
    route: '/mail',
    component: Announcements,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faEnvelope} />
  },
  {
    default: true,
    sidebarMenu: true,
    exact: true,
    title: 'services',
    slug: 'Services',
    route: '/services',
    component: Services,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faTasks} />
  },
  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'orders',
    slug: 'Job Orders',
    route: '/service-requests',
    component: ServiceRequests,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faBuilding} />
  },
  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'gatepass',
    slug: 'Gate Pass',
    route: '/tickets',
    component: Gatepass,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faTicketAlt} />
  },
  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'reservations',
    slug: 'Reservations',
    route: '/reservations',
    component: Reservations,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faCalendarAlt} />
  },
  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'reports',
    slug: 'Incident Reports',
    route: '/incident-reports',
    component: IncidentReports,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faExclamationCircle} />
  },

  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'permits',
    slug: 'Work Permits',
    route: '/permits',
    component: Permits,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faCogs} />
  },
  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'visitors',
    slug: 'Visitors',
    route: '/visitors',
    component: Visitors,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faUsers} />
  },
  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'inquiries',
    slug: 'Leasing Assistance',
    route: '/inquiries',
    component: Inquiries,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faPaperclip} />
  },
  {
    default: true,
    sidebarMenu: false,
    exact: true,
    title: 'commendations',
    slug: 'Commendations',
    route: '/commendations',
    component: Commendations,
    type: 'subMenu',
    icon: <FontAwesomeIcon icon={faComment} />
  },
  // {
  //   default: true,
  //   exact: true,
  //   sidebarMenu: true,
  //   slug: 'Commendations',
  //   header: 'Commendations And Testimonials',
  //   route: '/comments',
  //   component: Comments,
  //   type: 'menu',
  //   icon: <FontAwesomeIcon icon={faComment} />
  // },
  // {
  //   default: true,
  //   exact: true,
  //   sidebarMenu: true,
  //   slug: 'Services',
  //   header: 'PMO Services',
  //   route: '/services',
  //   component: Services,
  //   type: 'menu',
  //   icon: <FontAwesomeIcon icon={faTasks} />
  // },
  {
    default: true,
    exact: true,
    sidebarMenu: true,
    slug: 'Policies',
    header: 'House Rules & Policies Module',
    route: '/policies',
    component: Policies,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faFlag} />
  },
  {
    default: true,
    exact: true,
    sidebarMenu: true,
    slug: 'Memorandums',
    header: 'Building Circulars and Memorandums',
    route: '/memorandums',
    component: Memorandums,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faHandPaper} />
  },
  {
    default: true,
    exact: true,
    sidebarMenu: true,
    slug: 'Billings',
    header: 'Latest Statement Of Account',
    route: '/billings',
    component: Billings,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faCreditCard} />
  },
  {
    default: true,
    exact: true,
    sidebarMenu: false,
    slug: 'Payments',
    header: 'Payments',
    route: '/billings/payments',
    component: Payments,
  },
  {
    default: true,
    exact: true,
    sidebarMenu: false,
    slug: 'Dragonpay Message',
    header: 'Dragonpay Message',
    route: '/billings/payments/dragonpay/message',
    type: 'open',
    component: DragonPayMessage,
  },
  {
    default: true,
    exact: true,
    sidebarMenu: false,
    slug: 'Deposit',
    header: 'Deposit',
    route: '/billings/payments/deposit',
    component: Deposit,
  },
  {
    default: true,
    exact: true,
    sidebarMenu: false,
    slug: 'AQWire Message',
    header: 'AQWire Message',
    route: '/billings/payments/aqwire/success',
    type: 'open',
    component: AQWireMessage,
  },
  {
    default: true,
    exact: true,
    sidebarMenu: false,
    slug: 'Dragonpay',
    header: 'Dragonpay',
    route: '/billings/payments/dragonpay',
    component: Dragonpay,
  },
  {
    default: true,
    exact: true,
    sidebarMenu: false,
    slug: 'AQWire',
    header: 'AQWire',
    route: '/billings/payments/aqwire',
    component: AQWire,
  },
  {
    default: true,
    exact: true,
    sidebarMenu: true,
    slug: 'Settings',
    header: 'Settings',
    route: '/settings',
    component: Settings,
    type: 'menu',
    icon: <FontAwesomeIcon icon={faWrench} />
  }
]

const MainLayout = (props) => {
  return (
    <Layout {...props} className='app-layout'>
      {props.children}
    </Layout>
  )
}

const hasLocalStorage = () => {
  let strResult
  if (localStorage.getItem('CLIENT_SESSION_TOKEN') !== null) {
    strResult = true
  } else {
    strResult = false
  }
  return strResult
}
class App extends Component {
  constructor() {
    super()
    this.state = {
      isUnauthenticated: false,
      loading: false,
      activeTab: 0
    }
  }

  componentDidMount() {
    const tenantId = getUser() && getUser().id
    this.props.onLoadWebsiteSettings().then((res) => {
      // internal function, this will get the immediate result of public settings in the first call.
      function getValueInWebsiteSettings(name) {
        const found = res.payload.find(data => data.name === name)
        if (found) return found.value
        return
      }

      if (hasLocalStorage() && getValueInWebsiteSettings('isUnderMaintenance') === '1') {
        // if maintenance is true logged out client session
        localStorage.removeItem('CLIENT_SESSION_TOKEN')
        this.props.history.push('/')
        window.location.reload()
      }

    })

    if (hasLocalStorage()) {
      this.props.onLoadTenantData(tenantId).then(tenantData => {
        this.setState({ loading: true })
        let { soaAmount } = tenantData.payload
        soaAmount = +soaAmount
        this.props.onLoadUtilityServices().then((res) => {
          const utilityData = res.payload || []
          // order request
          if (utilityData.find(data => data.type === 'jobOrderRequest')) {
            const allowedAmount = utilityData.find(data => data.type === 'jobOrderRequest').amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'orders').sidebarMenu = utilityData.find(data => data.type === 'jobOrderRequest').status === 1 ? true : false
            }
          }

          // gate pass
          if (utilityData.find(data => data.type === 'gatePass')) {
            const allowedAmount = utilityData.find(data => data.type === 'gatePass').amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'gatepass').sidebarMenu = utilityData.find(data => data.type === 'gatePass').status === 1 ? true : false
            }
          }

          // reservation
          if (utilityData.find(data => data.type === 'reservation')) {
            const allowedAmount = utilityData.find(data => data.type === 'reservation').amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'reservations').sidebarMenu = utilityData.find(data => data.type === 'reservation').status === 1 ? true : false
            }
          }

          // incident report
          if (utilityData.find(data => data.type === 'incident')) {
            const allowedAmount = utilityData.find(data => data.type === 'incident').amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'reports').sidebarMenu = utilityData.find(data => data.type === 'incident').status === 1 ? true : false
            }
          }

          // work permit
          if (utilityData.find(data => data.type === "workPermit")) {
            const allowedAmount = utilityData.find(data => data.type === "workPermit").amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'permits').sidebarMenu = utilityData.find(data => data.type === "workPermit").status === 1 ? true : false
            }
          }

          // visitor pass
          if (utilityData.find(data => data.type === "visitorPass")) {
            const allowedAmount = utilityData.find(data => data.type === "visitorPass").amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'visitors').sidebarMenu = utilityData.find(data => data.type === "visitorPass").status === 1 ? true : false
            }
          }

          // leasing assistance
          if (utilityData.find(data => data.type === "leasingAssistance")) {
            const allowedAmount = utilityData.find(data => data.type === "leasingAssistance").amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'inquiries').sidebarMenu = utilityData.find(data => data.type === "leasingAssistance").status === 1 ? true : false
            }
          }

          // commendations
          if (utilityData.find(data => data.type === "commendation")) {
            const allowedAmount = utilityData.find(data => data.type === "commendation").amount
            if (allowedAmount > soaAmount) {
              paths.find(data => data.title === 'commendations').sidebarMenu = utilityData.find(data => data.type === "commendation").status === 1 ? true : false
            }
          }

          this.forceUpdate()
          this.setState({ loading: false })

        })
      })
      this.props.onLoadAdvertisement()
    }

  }

  componentWillMount() {
    var url_string = window.location.href
    var url = new URL(url_string);
    var path = url.searchParams.get("path");

    Axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (hasLocalStorage()) {
          axiosError(error);
        }
        return Promise.reject(error);
      }
    );

    const currentPathIndex = paths.findIndex(data => data.route === this.props.location.pathname)
    this.setState({ activeTab: currentPathIndex + 1 })

    if (path === 'open') {
      this.setState({ isUnauthenticated: 'open' })
    } else {
      if (hasLocalStorage()) this.setState({ isUnauthenticated: false })
      else this.setState({ isUnauthenticated: true })
    }

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loggedIn !== nextProps.loggedIn && nextProps.loggedIn) {
      window.location.reload()
    }

    window.document.title = REACT_APP_NAME && `${this.capitalizedFirstChar(REACT_APP_NAME)} Client`
  }

  capitalizedFirstChar(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  logout() {
    const self = this
    Modal.confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You will redirected to login page',
      onOk() {
        localStorage.removeItem('CLIENT_SESSION_TOKEN')
        self.props.history.push('/')
        window.location.reload()
      },
      onCancel() {
      },
    });
  }

  getValueInWebsiteSettings(name) {
    const found = this.props.websiteSettingsData.find(data => data.name === name)
    if (found) return found.value
    return
  }

  render() {
    switch (this.state.isUnauthenticated) {
      case true:
        return (
          <MainLayout>
            <Login />
          </MainLayout>
        )
      case false:
        return (
          <Layout className='client-container'>

            <Sider
              className='custom-sider'
              style={{
                // height: '100vh',
                left: 0,
              }}
              breakpoint="lg"
              collapsedWidth="0"
            >
              <div className='sider-header'>
                {this.getValueInWebsiteSettings('websiteLogo') &&
                  <Link to='/'>
                    <ImagePresignedUrl src={`${this.getValueInWebsiteSettings('websiteLogo')}`} />
                  </Link>
                }
                <h1>{this.getValueInWebsiteSettings('websiteName')}</h1>
                <p>YOUR PROPERTY MANAGEMENT PORTAL</p>
              </div>
              <Divider />
              <div className='user-info'>
                <h1>{this.props.tenantInfoData.uoccname}</h1>
                <p>OWNER: {this.props.tenantInfoData.unitNumber}</p>
              </div>
              <Divider />
              <Spin spinning={this.state.loading}>
                <Menu theme="dark" style={{ background: 'transparent' }} onClick={(e) => {
                  this.setState({ activeTab: e.key })
                }} mode="inline" defaultSelectedKeys={[this.state.activeTab ? this.state.activeTab.toString() : '0']}>
                  {
                    paths.filter(data => data.type === 'menu').map((data, i) => {
                      if (!data.sidebarMenu) return
                      return (
                        <Fragment>
                          {
                            i === 2 &&
                            <SubMenu style={{ background: 'transparent', color: 'white' }} key="subMenu" icon={<OrderedListOutlined />} title="PMO Services">
                              {
                                paths.filter(data => data.type === 'subMenu').map((data, i) => {
                                  if (!data.sidebarMenu) return
                                  return (
                                    <Menu.Item key={`subMenu_${(i + 1)}`}>
                                      <Link to={data.route}>
                                        {data.slug}
                                      </Link>
                                    </Menu.Item>
                                  )
                                })
                              }
                            </SubMenu>
                          }
                          <Menu.Item key={`menu_${(i + 1)}`} icon={data.icon}>
                            <Link to={data.route} style={{ marginLeft: 10, marginRight: 10 }}>
                              {data.slug}
                            </Link>
                          </Menu.Item>
                        </Fragment>
                      )
                    })
                  }
                  <Menu.Item icon={<FontAwesomeIcon icon={faSignOutAlt} />}>
                    <a style={{ marginLeft: 10, marginRight: 10 }} onClick={() => this.logout()}>
                      Logout
                    </a>
                  </Menu.Item>
                </Menu>
              </Spin>

            </Sider>
            <Layout className='client-layout'>
              <MobileHeader paths={paths} onChangeMenu={(e) => this.setState({ activeTab: e.key })} />
              <Content className='client-content' >
                <Row>
                  <Col md={24} lg={24} sm={24} xs={24} align='right' className='p3'>
                    <Notifications />
                  </Col>
                </Row>
                <Switch>
                  {/* // this will scroll to top when switch changes. */}
                  {window.scrollTo(0, 0)}
                  {_.map(paths, (path) => (
                    <Route
                      exact={path.exact}
                      path={path.route}
                      // component={path.component}
                      render={(props) =>
                        <path.component {...props} paths={paths} />}
                      key={path.route}
                    />
                  ))}
                  <Route component={NotFound} />
                  <Redirect to='/404' />
                </Switch>
              </Content>
            </Layout>
            <ActivityChecker timoutRedirection='/' sessionName='CLIENT_SESSION_TOKEN' />
          </Layout>
        )

      case 'open': {
        const openPaths = paths.filter(data => data.type === 'open')
        return (
          <Switch>
            {/* // this will scroll to top when switch changes. */}
            {window.scrollTo(0, 0)}
            {_.map(openPaths, (openPath) => (
              <Route
                exact={openPath.exact}
                path={openPath.route}
                render={(props) =>
                  <openPath.component {...props} />}
                key={openPath.route}
              />
            ))}
            <Route component={NotFound} />
            <Redirect to='/404' />
          </Switch>
        )
      }
      default:
        break
    }
  }
}


function mapStateToProps(state) {
  return {
    loggedIn: state.client.login.loggedIn,
    websiteSettingsData: state.client.websiteSettings.websiteSettingsData,
    websiteSettingsLoading: state.client.websiteSettings.websiteSettingsLoading,
    tenantInfoData: state.client.settings.tenantInfoData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadWebsiteSettings: (data) => dispatch(loadWebsiteSettings(data)),
    onLoadTenantData: (data) => dispatch(loadTenantData(data)),
    onLoadAdvertisement: (data) => dispatch(loadAdvertisement(data)),
    onLoadUtilityServices: (data) => dispatch(loadUtilityServices(data)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
