import React, { Fragment, useEffect, useState } from 'react'
import { get } from '../filesController'
import ReactPlayer from 'react-player'
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
const VideoPresignedUrl = (props) => {
    const [url, setUrl] = useState('')
    const [playing, setPlaying] = useState(false)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    useEffect(() => {
        setLoading(true)
        async function getData() {
            const fetchedUrl = await get(props.url)
            setUrl(fetchedUrl)
            setLoading(false)
        }
        getData()
    }, [props.src])

    return (
        (!loading) && <div style={{ marginBottom: 40 }}>
            <ReactPlayer url={url}
                playing={playing}
                width={props.width} height={props.height}
                onEnded={() => setPlaying(false)}
            />
            <div style={{ textAlign: 'center' }}>
                {playing ? (
                    <PauseCircleOutlined style={{ fontSize: 24, color: 'gray' }} onClick={() => setPlaying(false)} />
                ) : (
                    <PlayCircleOutlined style={{ fontSize: 24, color: 'gray' }} onClick={() => setPlaying(true)} />
                )}
            </div>
        </div>
    )

}

export default VideoPresignedUrl