import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_ADVERTISEMENT = 'POST_ADVERTISEMENT'
export const POST_ADVERTISEMENT_FULFILLED = 'POST_ADVERTISEMENT_FULFILLED'
export const POST_ADVERTISEMENT_REJECTED = 'POST_ADVERTISEMENT_REJECTED'

export const GET_ADVERTISEMENTS = 'GET_ADVERTISEMENTS'
export const GET_ADVERTISEMENTS_FULFILLED = 'GET_ADVERTISEMENTS_FULFILLED'
export const GET_ADVERTISEMENTS_REJECTED = 'GET_ADVERTISEMENTS_REJECTED'

export const GET_ADVERTISEMENT = 'GET_ADVERTISEMENT'
export const GET_ADVERTISEMENT_FULFILLED = 'GET_ADVERTISEMENT_FULFILLED'
export const GET_ADVERTISEMENT_REJECTED = 'GET_ADVERTISEMENT_REJECTED'

export const PUT_ADVERTISEMENT = 'PUT_ADVERTISEMENT'
export const PUT_ADVERTISEMENT_FULFILLED = 'PUT_ADVERTISEMENT_FULFILLED'
export const PUT_ADVERTISEMENT_REJECTED = 'PUT_ADVERTISEMENT_REJECTED'

export const DELETE_ADVERTISEMENT = 'DELETE_ADVERTISEMENT'
export const DELETE_ADVERTISEMENT_FULFILLED = 'DELETE_ADVERTISEMENT_FULFILLED'
export const DELETE_ADVERTISEMENT_REJECTED = 'DELETE_ADVERTISEMENT_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadAdvertisement = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_ADVERTISEMENTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/advertisements?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ADVERTISEMENTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ADVERTISEMENTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadAdvertisementById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_ADVERTISEMENT,
      payload: {}
    })
    return Axios.get(`${API_URL}/advertisement/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_ADVERTISEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_ADVERTISEMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitAdvertisement = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ADVERTISEMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/advertisement`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_ADVERTISEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_ADVERTISEMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const updateAdvertisement = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_ADVERTISEMENT,
      payload: {}
    })
    return Axios.put(`${API_URL}/advertisement/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_ADVERTISEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_ADVERTISEMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteAdvertisement = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_ADVERTISEMENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/advertisement/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_ADVERTISEMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_ADVERTISEMENT_REJECTED,
          payload: error
        })
      })
  }
}





