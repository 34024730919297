import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_MEMORANDUMS = 'GET_MEMORANDUMS'
export const GET_MEMORANDUMS_FULFILLED = 'GET_MEMORANDUMS_FULFILLED'
export const GET_MEMORANDUMS_REJECTED = 'GET_MEMORANDUMS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadMemorandums = (data) => {
  return dispatch => {
    dispatch({
      type: GET_MEMORANDUMS,
      payload: {}
    })
    return Axios.get(`${API_URL}/memorandums`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_MEMORANDUMS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_MEMORANDUMS_REJECTED,
          payload: error
        })
      })
  }
}



