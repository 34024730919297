import React, { useEffect, useState } from 'react'
import { Radio, Button, Space, Row, Col, Modal } from 'antd';
import { connect } from 'react-redux'
import { loadTenant, submitPayment } from '../actions'
import { getUser } from '../../../../utilities/token'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

const Payments = (props) => {
    let history = useHistory();
    const [paymentsActive, setPaymentsActive] = useState('deposit')

    useEffect(() => {
        props.onLoadTenant(getUser().id)
    }, [])


    function checkout() {
        if (paymentsActive === 'deposit') {
            Modal.confirm({
                title: 'Direct Deposit',
                icon: <ExclamationCircleOutlined />,
                content: `You chose direct deposit, Continuing this will redirect you to our upload page and require you to upload atleast 1 (one) clean image of your deposit slip.`,
                onOk() {
                    history.push('/billings/payments/deposit')
                },
            });
        } else if (paymentsActive === 'dragonpay') {
            Modal.confirm({
                title: 'DragonPay Payment Gateway',
                icon: <ExclamationCircleOutlined />,
                content: `You chose dragon pay as your payment gateway, Continuing this will redirect you to our Dragonpay page which required you to fill up all the fields needed.`,
                onOk() {
                    history.push('/billings/payments/dragonpay')
                },
            });
        } else if (paymentsActive === 'aqwire') {
            Modal.confirm({
                title: 'AQWIRE Payment Gateway',
                icon: <ExclamationCircleOutlined />,
                content: `You chose AQWire as your payment gateway, Continuing this will redirect you to our AQWire page which required you to fill up all the fields needed.`,
                onOk() {
                    history.push('/billings/payments/aqwire')
                },
            });
        }
    }

    return (
        <div className='billing-payments'>
            <Radio.Group onChange={(e) => setPaymentsActive(e.target.value)} value={paymentsActive} style={{ width: '100%' }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Radio value={'deposit'} className={paymentsActive === 'deposit' ? 'payments-item payments-active' : 'payments-item'}>
                        <Row gutter={32}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <img src={require('../../../../assets/images/deposit.png')} />
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <h1>Direct Deposit</h1>
                                <p>Upload (1) one image of your deposit slip as a proof that you are paid. Our Officers in charge will review your uploaded deposit slip. </p>
                            </Col>
                        </Row>
                    </Radio>
                    <Radio value={'dragonpay'} className={paymentsActive === 'dragonpay' ? 'payments-item payments-active' : 'payments-item'}>
                        <Row gutter={32} >
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <img src={require('../../../../assets/images/dragonpay.png')} />
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <h1>Dragon Pay</h1>
                                <p>Use Dragon Pay's Payment Integration to pay for your monthly bills. </p>
                            </Col>
                        </Row>
                    </Radio>
                    <Radio value={'aqwire'} className={paymentsActive === 'aqwire' ? 'payments-item payments-active' : 'payments-item'}>
                        <Row gutter={32} >
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <img src={require('../../../../assets/images/aqwire.png')} />
                            </Col>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <h1>AQWire</h1>
                                <p>Use International Cards Pay with PH issued Visa/Mastercard. </p>
                            </Col>
                        </Row>
                    </Radio>
                </Space>
            </Radio.Group>
            <div className='row-center mt5'>
                <Button className='primary-btn' onClick={checkout} >CONTINUE TO CHECKOUT</Button>
            </div>

            <Modal title={null} visible={props.paymentLoading} footer={null} closable={false} width={200} >
                <Row>
                    <Col span={24} align='center'>
                        <p>Loading Payment <br /> Please Wait.</p>
                        <LoadingOutlined />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        tenantData: state.client.billings.tenantData,
        paymentLoading: state.client.billings.paymentLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenant: (data) => dispatch(loadTenant(data)),
        onSubmitPayment: (data) => dispatch(submitPayment(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments)
