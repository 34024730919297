import {
  POST_SERVICEREQUEST,
  POST_SERVICEREQUEST_FULFILLED,
  POST_SERVICEREQUEST_REJECTED,
  GET_SERVICEREQUESTS,
  GET_SERVICEREQUESTS_FULFILLED,
  GET_SERVICEREQUESTS_REJECTED,
  PUT_SERVICEREQUEST,
  PUT_SERVICEREQUEST_FULFILLED,
  PUT_SERVICEREQUEST_REJECTED,
  DELETE_SERVICEREQUEST,
  DELETE_SERVICEREQUEST_FULFILLED,
  DELETE_SERVICEREQUEST_REJECTED,
  APPROVE_SERVICEREQUEST,
  APPROVE_SERVICEREQUEST_FULFILLED,
  APPROVE_SERVICEREQUEST_REJECTED,
  VERIFIED_SERVICEREQUEST,
  VERIFIED_SERVICEREQUEST_FULFILLED,
  VERIFIED_SERVICEREQUEST_REJECTED
} from '../actions'

export default function serviceRequests(state = {
  serviceRequestLoading: false,
  serviceRequestSuccess: false,
  serviceRequestRejected: false,
  serviceRequestData: {
    data: []
  },
  serviceRequestsLoading: false,
  serviceRequestsSuccess: false,
  serviceRequestsRejected: false,
  serviceRequestsData: [],
  updateServiceRequestLoading: false,
  updateServiceRequestSuccess: false,
  updateServiceRequestRejected: false,
  updateServiceRequestData: [],
  deleteServiceRequestLoading: false,
  deleteServiceRequestSuccess: false,
  deleteServiceRequestRejected: false,
  deleteServiceRequestData: [],
  approveServiceRequestLoading: false,
  approveServiceRequestSuccess: false,
  approveServiceRequestRejected: false,
  verifiedServiceRequestLoading: false,
  verifiedServiceRequestSuccess: false,
  verifiedServiceRequestRejected: false
}, action) {
  switch (action.type) {
    case POST_SERVICEREQUEST:
      return {
        ...state,
        serviceRequestLoading: true,
        serviceRequestSuccess: false,
        serviceRequestRejected: false
      }
    case POST_SERVICEREQUEST_FULFILLED:
      return {
        ...state,
        serviceRequestLoading: false,
        serviceRequestSuccess: true,
        serviceRequestData: action.payload
      }
    case POST_SERVICEREQUEST_REJECTED:
      return {
        ...state,
        serviceRequestLoading: false,
        serviceRequestRejected: true
      }
    case GET_SERVICEREQUESTS:
      return {
        ...state,
        serviceRequestsLoading: true,
        serviceRequestsSuccess: false,
        serviceRequestsRejected: false
      }
    case GET_SERVICEREQUESTS_FULFILLED:
      action.payload.data.map(data => {
        data.driver = data.employee && `${data.employee.firstName} ${data.employee.middleName} ${data.employee.lastName}`
        // data.status = data.status === 1 ? 'Active' : 'Inactive'
      })
      return {
        ...state,
        serviceRequestsLoading: false,
        serviceRequestsSuccess: true,
        serviceRequestsData: action.payload
      }
    case GET_SERVICEREQUESTS_REJECTED:
      return {
        ...state,
        serviceRequestsLoading: false,
        serviceRequestsRejected: true
      }
    case PUT_SERVICEREQUEST:
      return {
        ...state,
        updateServiceRequestLoading: true,
        updateServiceRequestSuccess: false,
        updateServiceRequestRejected: false
      }
    case PUT_SERVICEREQUEST_FULFILLED:
      return {
        ...state,
        updateServiceRequestLoading: false,
        updateServiceRequestSuccess: true,
        updateServiceRequestData: action.payload
      }
    case PUT_SERVICEREQUEST_REJECTED:
      return {
        ...state,
        updateServiceRequestLoading: false,
        updateServiceRequestRejected: true
      }
    case DELETE_SERVICEREQUEST:
      return {
        ...state,
        deleteServiceRequestLoading: true,
        deleteServiceRequestSuccess: false,
        deleteServiceRequestRejected: false
      }
    case DELETE_SERVICEREQUEST_FULFILLED:
      return {
        ...state,
        deleteServiceRequestLoading: false,
        deleteServiceRequestSuccess: true,
        deleteServiceRequestData: action.payload
      }
    case DELETE_SERVICEREQUEST_REJECTED:
      return {
        ...state,
        deleteServiceRequestLoading: false,
        deleteServiceRequestRejected: true
      }
    case APPROVE_SERVICEREQUEST:
      return {
        ...state,
        approveServiceRequestLoading: true,
        approveServiceRequestSuccess: false,
        approveServiceRequestRejected: false
      }
    case APPROVE_SERVICEREQUEST_FULFILLED:
      return {
        ...state,
        approveServiceRequestLoading: false,
        approveServiceRequestSuccess: true,
        approveServiceRequestData: action.payload
      }
    case APPROVE_SERVICEREQUEST_REJECTED:
      return {
        ...state,
        approveServiceRequestLoading: false,
        approveServiceRequestRejected: true
      }

    case VERIFIED_SERVICEREQUEST:
      return {
        ...state,
        verifiedServiceRequestLoading: true,
        verifiedServiceRequestSuccess: false,
        verifiedServiceRequestRejected: false
      }
    case VERIFIED_SERVICEREQUEST_FULFILLED:
      return {
        ...state,
        verifiedServiceRequestLoading: false,
        verifiedServiceRequestSuccess: true,
        verifiedServiceRequestData: action.payload
      }
    case VERIFIED_SERVICEREQUEST_REJECTED:
      return {
        ...state,
        verifiedServiceRequestLoading: false,
        verifiedServiceRequestRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
