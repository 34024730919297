import React from 'react'
import { Upload, message, Tag } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Dropzone from 'react-dropzone'
import pdfIcon from '../../../../../assets/images/pdfIcon.png'
import '../index.scss'

class UploadComponent extends React.Component {
    constructor(props) {
        super()
        this.state = {
            loading: false,
            base64Image: '',
            value: props.value,
            valueArray: []
        };
        this.getBase64 = this.getBase64.bind(this)
        this.handleDeleteImage = this.handleDeleteImage.bind(this)
    }

    getBase64(file) {
        this.setState({ valueArray: file })
        this.props.getFile && this.props.getFile(file)
    }

    handleDeleteImage() {
        this.setState({ base64Image: '' })
        this.props.getImageBase64('')
    }

    render() {
        return (
            <>
                <Dropzone onDrop={acceptedFiles => this.getBase64(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {this.state.base64Image === '' ?
                                    (this.state.valueArray.length ?
                                        <div className='dropzone-container'>
                                            {
                                                this.state.valueArray.map((data, index) => {
                                                    return (
                                                        <div className='dropzone-list' key={index}>
                                                            <img src={pdfIcon} />
                                                            <span>{data.name}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='dropzone-empty'>Select All PDF file or drag here.</div>) :
                                    <img src={this.state.base64Image} style={{ height: '100%', width: '100%' }} />
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
                {this.state.base64Image !== '' && <a onClick={() => this.handleDeleteImage()}>Delete</a>}
            </>
        );
    }
}

export default UploadComponent;
