import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, Card, DatePicker, Space } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import { updatePermit, loadPermitById } from '../../actions'
import { loadTenant } from '../../../tenants/actions'
import { getParamFromUrl } from '../../../../../utilities/helper'
import moment from 'moment'
const { Option } = Select;
class Edit extends React.Component {
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: [],
      // preloaded data only works when redirected from services and if the modal is open
      preloadedData: {}
    }
  }

  componentWillMount() {
    if ((getParamFromUrl('modal') === 'true') && Object.keys(this.props.selectedRow).length === 0) {
      this.setState({ isEditModalOpen: true })
      this.props.onLoadPermitById(getParamFromUrl('serviceId')).then(res => {
        this.formRef.current.setFieldsValue({
          ...res.payload,
          dateFrom: moment(res.payload.dateFrom),
          dateTo: moment(res.payload.dateTo),
          dateApplied: moment(res.payload.created_at).format('LLL'),
          dateCompleted: moment(res.payload.dateCompleted).format('LLL'),
          approvalDate: moment(res.payload.approvalDate).format('LLL'),
        });
        this.setState({ preloadedData: res.payload })
      })
    }
  }


  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
    this.props.onLoadTenant()
  }

  handleUpdate(values) {
    this.setState({ isLoading: true })
    const selectedRowId = (this.props.selectedRow && this.props.selectedRow.id) || this.state.preloadedData.id
    const submitData = {
      "dateApplied": values.dateApplied,
      "workPermitFor": values.workPermitFor,
      "workArea": values.workArea,
      "dateFrom": values.dateFrom,
      "dateTo": values.dateTo,
      "purpose": values.purpose,
      "contractors": values.contractors.length ? values.contractors : [{ name: '', information: '' }],
      "items": values.items,
      "bondAmount": values.bondAmount,
      "remarks": values.remarks,
      "status": this.getStatusEnum(values.status),

      // APPROVED
      "approvalDate": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),
      "approvedById": (values.status === 'APPROVED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && getUser().id,

      // COMPLETED
      "dateCompleted": (values.status === 'COMPLETED' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date()),

      // // ONGOING
      // "dateApplied": (values.status === 'ONGOING' && (this.props.selectedRow.status !== this.getStatusEnum(values.status))) && moment(new Date())

    }
    this.props.onUpdatePermit({ ...submitData, id: selectedRowId }).then(res => {
      this.setState({ isLoading: false })
    })

  }

  getStatusEnum(status) {
    switch (status) {
      case 'APPROVAL':
        return 1
      case 'APPROVED':
        return 2
      case 'ONGOING':
        return 3
      case 'COMPLETED':
        return 4
      case 'CANCELLED':
        return 5
      case 'REJECTED':
        return 6
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }


  render() {
    const isReadOnly = false // Cant edit if the status is for approval = 1
    const disabled = (!this.access('permits', 'edit') || !this.access('permits', 'approve') || this.props.selectedRow.status !== 1) // (1 !=) not for approval 
    return (
      <Modal
        title="EDIT PERMIT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>

          <Form
            name="basic"
            ref={this.formRef}
            initialValues={{
              ...this.props.selectedRow,
              approvedById: this.props.selectedRow.approver && this.props.selectedRow.approver.name,
              dateFrom: moment(this.props.selectedRow.dateFrom),
              dateTo: moment(this.props.selectedRow.dateTo),
              dateApplied: moment(this.props.selectedRow.created_at).format('LLL'),
              dateCompleted: moment(this.props.selectedRow.dateCompleted).format('LLL'),
              approvalDate: moment(this.props.selectedRow.approvalDate).format('LLL'),
              status: this.getStatusLabel(this.props.selectedRow.status),
              contractors: this.props.selectedRow.contractors || [],
              items: this.props.selectedRow.items || []
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Work Permit For"
                  name="workPermitFor"
                  rules={[{ required: true, message: 'Please input your work permit for!' }]}
                >
                  <Input readOnly={isReadOnly} disabled={disabled} />
                </Form.Item>

                <Form.Item
                  label="Work Area"
                  name="workArea"
                  rules={[{ required: true, message: 'Please input your work area!' }]}
                >
                  <Input readOnly={isReadOnly} disabled={disabled} />
                </Form.Item>
                <Form.Item
                  label="Purpose"
                  name="purpose"
                  rules={[{ required: true, message: 'Please input your purpose!' }]}
                >
                  <Input readOnly={isReadOnly} disabled={disabled} />
                </Form.Item>
                <Form.Item
                  label="Covered From"
                  name="dateFrom"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date from!',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={disabled}
                    readOnly={isReadOnly}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Covered To"
                  name="dateTo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date to!',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={disabled}
                    readOnly={isReadOnly}
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Card title="List of Workers">
                  <Form.List name="contractors">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(field => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Missing Name' }]}
                            >
                              <Input placeholder="Worker Name" readOnly={isReadOnly} disabled={disabled} />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'information']}
                              fieldKey={[field.fieldKey, 'information']}
                              rules={[{ required: true, message: 'Missing Information' }]}
                            >
                              <Input placeholder="Worker Information" readOnly={isReadOnly} disabled={disabled} />
                            </Form.Item>
                            {
                              !disabled &&
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            }
                          </Space>
                        ))}
                        <Form.Item>
                          <Button disabled={disabled} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Worker
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>

                <Card title="List of Items" style={{ marginTop: 30 }}>
                  <Form.List name="items">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields && fields.map(field => (
                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                rules={[{ required: true, message: 'Missing Name' }]}
                              >
                                <Input placeholder="Name" disabled={disabled} />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'quantity']}
                                fieldKey={[field.fieldKey, 'quantity']}
                                rules={[{ required: true, message: 'Missing Quantity' },
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject();
                                    }
                                    if (isNaN(value)) {
                                      return Promise.reject("Quantity to be a number.");
                                    }
                                    if (value.includes('.')) {
                                      return Promise.reject("Quantity is not a whole number");
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                                ]}
                              >
                                <Input type="number" placeholder="Quantity" disabled={disabled} />
                              </Form.Item>
                              {
                                !disabled &&
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              }
                            </Space>
                          ))}
                          <Form.Item>
                            <Button disabled={disabled} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add Item
                            </Button>
                          </Form.Item>
                        </>
                      )
                    }}
                  </Form.List>
                </Card>
              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>

                <Form.Item
                  label="Date Applied"
                  name="dateApplied"
                  rules={[{ required: false, message: 'Please input your date applied!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Date Completed"
                  name="dateCompleted"
                  rules={[{ required: false, message: 'Please input your date completed!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Approved By"
                  name="approvedById"
                  rules={[{ required: false, message: 'Please input your approved by!' }]}
                >
                  <Input readOnly={isReadOnly} disabled />
                </Form.Item>

                <Form.Item
                  label="Bond Amount"
                  name="bondAmount"
                  rules={[{ required: false, message: 'Please input your bond amount!' }]}
                >
                  <Input disabled={disabled} />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                  rules={[{ required: false, message: 'Please input your remarks!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true, message: 'Please input your status!' }]}
                >
                  <Select style={{ width: '100%' }}  >
                    <Option value={'APPROVAL'}>APPROVAL</Option>
                    {
                      this.access('permits', 'approve') &&
                      <Option value={'APPROVED'}>APPROVED</Option>
                    }
                    {
                      this.access('permits', 'ongoing') &&
                      <Option value={'ONGOING'}>ONGOING</Option>
                    }
                    {
                      this.access('permits', 'complete') &&
                      <Option value={'COMPLETED'}>COMPLETED</Option>
                    }
                    {
                      this.access('permits', 'cancel') &&
                      <Option value={'CANCELLED'}>CANCELLED</Option>
                    }
                    {
                      this.access('permits', 'reject') &&
                      <Option value={'REJECTED'}>REJECTED</Option>
                    }
                  </Select>
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit" loading={this.props.updatePermitLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>

          </Form>

        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updatePermitLoading: state.admin.permits.updatePermitLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdatePermit: (data) => dispatch(updatePermit(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onLoadPermitById: (data) => dispatch(loadPermitById(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))