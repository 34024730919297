import React, { useEffect } from 'react'
import { Divider, Card } from 'antd'
import { updateAQWireStatus } from '../../actions'
import { connect } from 'react-redux'
import { FOR_VERIFICATION } from '../../../../../utilities/paymentsStatusEnum'
import '../index.scss'
const SuccessMessage = (props) => {

    function getStatus() {
        const status = url.searchParams.get("outsourceStatus")
        switch (status) {
            case 'S':
                return 'SUCCESSFUL'
            case 'P':
                return 'PENDING'
            case 'R':
                return 'REFUND'
            default:
                return 'PENDING'
        }
    }

    useEffect(() => {
        var url_string = window.location.href
        var url = new URL(url_string);
        const submitData = {
            paymentId: url.searchParams.get("paymentId"),
            status: url.searchParams.get("status"),
            outsourceStatus: url.searchParams.get("outsourceStatus"),
            message: url.searchParams.get("message"),
            digest: url.searchParams.get("digest"),
        }
        props.onUpdateAQWireStatus(submitData)
    }, [])

    var url_string = window.location.href
    var url = new URL(url_string);
    return (
        <div className='aqwire-transaction-complete'>
            <Card loading={props.updateDragonPayStatusLoading}>
                <h1>Transaction Complete</h1>
                <p>You have successfully finished the transaction.</p>
                <Divider />
                <p>Message: {url.searchParams.get("message")}</p>
                <p>Status: <b>{getStatus()}</b></p>
            </Card>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        updateDragonPayStatusLoading: state.client.billings.updateDragonPayStatusLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdateAQWireStatus: (data) => dispatch(updateAQWireStatus(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessMessage)