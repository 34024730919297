import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_MEMORANDUM = 'POST_MEMORANDUM'
export const POST_MEMORANDUM_FULFILLED = 'POST_MEMORANDUM_FULFILLED'
export const POST_MEMORANDUM_REJECTED = 'POST_MEMORANDUM_REJECTED'
export const GET_MEMORANDUMS = 'GET_MEMORANDUMS'
export const GET_MEMORANDUMS_FULFILLED = 'GET_MEMORANDUMS_FULFILLED'
export const GET_MEMORANDUMS_REJECTED = 'GET_MEMORANDUMS_REJECTED'
export const PUT_MEMORANDUM = 'PUT_MEMORANDUM'
export const PUT_MEMORANDUM_FULFILLED = 'PUT_MEMORANDUM_FULFILLED'
export const PUT_MEMORANDUM_REJECTED = 'PUT_MEMORANDUM_REJECTED'
export const DELETE_MEMORANDUM = 'DELETE_MEMORANDUM'
export const DELETE_MEMORANDUM_FULFILLED = 'DELETE_MEMORANDUM_FULFILLED'
export const DELETE_MEMORANDUM_REJECTED = 'DELETE_MEMORANDUM_REJECTED'

export const APPROVE_MEMORANDUM = 'APPROVE_MEMORANDUM'
export const APPROVE_MEMORANDUM_FULFILLED = 'APPROVE_MEMORANDUM_FULFILLED'
export const APPROVE_MEMORANDUM_REJECTED = 'APPROVE_MEMORANDUM_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadMemorandum = () => {
  return dispatch => {
    dispatch({
      type: GET_MEMORANDUMS,
      payload: {}
    })
    return Axios.get(`${API_URL}/memorandums`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_MEMORANDUMS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_MEMORANDUMS_REJECTED,
          payload: error
        })
      })
  }
}

export const submitMemorandum = (data) => {
  return dispatch => {
    dispatch({
      type: POST_MEMORANDUM,
      payload: {}
    })
    return Axios.post(`${API_URL}/memorandum`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_MEMORANDUM_FULFILLED,
          payload: response.data
        })
        dispatch(loadMemorandum())
      })
      .catch(function (error) {
        dispatch({
          type: POST_MEMORANDUM_REJECTED,
          payload: error
        })
      })
  }
}

export const updateMemorandum = (data, status) => {
  return dispatch => {
    dispatch({
      type: PUT_MEMORANDUM,
      payload: {}
    })
    return Axios.put(`${API_URL}/memorandum/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_MEMORANDUM_FULFILLED,
          payload: response.data
        })
        dispatch(loadMemorandum(status))
      })
      .catch(function (error) {
        dispatch({
          type: PUT_MEMORANDUM_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteMemorandum = (id, status) => {
  return dispatch => {
    dispatch({
      type: DELETE_MEMORANDUM,
      payload: {}
    })
    return Axios.delete(`${API_URL}/memorandum/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_MEMORANDUM_FULFILLED,
          payload: response.data
        })
        dispatch(loadMemorandum(status))
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_MEMORANDUM_REJECTED,
          payload: error
        })
      })
  }
}

export const approveMemorandum = (id, status) => {
  return dispatch => {
    dispatch({
      type: APPROVE_MEMORANDUM,
      payload: {}
    })
    return Axios.put(`${API_URL}/memorandum/reserved/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: APPROVE_MEMORANDUM_FULFILLED,
          payload: response.data
        })
        dispatch(loadMemorandum(status))
      })
      .catch(function (error) {
        dispatch({
          type: APPROVE_MEMORANDUM_REJECTED,
          payload: error
        })
      })
  }
}








