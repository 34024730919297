import React, { Component, Fragment } from 'react'
import { Form, Input, Button, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { submitLogin } from '../actions'
import Maintenance from '../../maintenance/components'
import './index.scss'
class Login extends Component {
  handleSubmit(e) {
    this.props.onSubmitLogin(e)
  }

  getValueInWebsiteSettings(name) {
    const found = this.props.websiteSettingsData.find(data => data.name === name)
    if (found) return found.value
    return ''
  }

  render() {
    const { loggingIn, loginRejected } = this.props
    return (
      <Fragment>
        <Row justify='center'>

          <Col lg={12} md={12} sm={24} md={24} className='left-panel'>
            {
              this.getValueInWebsiteSettings('isUnderMaintenance') === '0' ?

                <div className='login-container'>
                  <div className='login-form'>
                    <div className='title-header'>
                      <h1>Log in</h1>
                    </div>
                    {loginRejected &&
                      <div className='login-error-message'>
                        <span>Login Failed: Invalid Credentials</span>
                      </div>}
                    <Form
                      name='basic'
                      initialValues={{ remember: true }}
                      onFinish={(e) => this.handleSubmit(e)}
                    >
                      <h4>Email</h4>
                      <Form.Item
                        name='email'
                        rules={[{ required: true, message: 'Please input your email!' }]}
                      >
                        <Input className='primary-input' />
                      </Form.Item>

                      <h4>Password</h4>
                      <Form.Item
                        name='password'
                        rules={[{ required: true, message: 'Please input your password!' }]}
                      >
                        <Input.Password className='primary-input' />
                      </Form.Item>

                      <Form.Item>
                        <div style={{ display: 'flex' }}>
                          <Button type='primary' style={{ width: '100%', marginTop: 10 }} className='primary-btn' loading={loggingIn} htmlType='submit'>
                            Log in
                          </Button>
                        </div>
                      </Form.Item>

                      <div className='login-reminder'>
                        <p>Your login details are provided to you via your registered email <br /> address at the Property Management Office (PMO)</p>
                        <p>Forgot your password? Contact the PMO for assistance.</p>
                      </div>
                    </Form>
                  </div>
                </div> :
                <Maintenance message={`${this.getValueInWebsiteSettings('maintenanceMessage')}`}
                  loading={this.props.websiteSettingsLoading}
                />
            }
          </Col>
          <Col lg={12} md={12} sm={24} xs={24} className='right-panel'>
            <div className='website-name'>
              <h1>{`${this.getValueInWebsiteSettings('websiteName')}`} </h1>
              <p>YOUR PROPERTY MANAGEMENT PORTAL</p>
            </div>
          </Col>
        </Row>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    loginRejected: state.admin.login.loginRejected,
    loggingIn: state.admin.login.loggingIn,
    websiteSettingsData: state.client.websiteSettings.websiteSettingsData,
    websiteSettingsLoading: state.client.websiteSettings.websiteSettingsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitLogin: (data) => dispatch(submitLogin(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
