import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_FULFILLED = 'GET_NOTIFICATIONS_FULFILLED'
export const GET_NOTIFICATIONS_REJECTED = 'GET_NOTIFICATIONS_REJECTED'

export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT'
export const GET_NOTIFICATIONS_COUNT_FULFILLED = 'GET_NOTIFICATIONS_COUNT_FULFILLED'
export const GET_NOTIFICATIONS_COUNT_REJECTED = 'GET_NOTIFICATIONS_COUNT_REJECTED'

export const PUT_READ_NOTIFICATIONS = 'PUT_READ_NOTIFICATIONS'
export const PUT_READ_NOTIFICATIONS_FULFILLED = 'PUT_READ_NOTIFICATIONS_FULFILLED'
export const PUT_READ_NOTIFICATIONS_REJECTED = 'PUT_READ_NOTIFICATIONS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadNotifications = (tenantId, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/admin-notif/${tenantId}?page=${pagination.page}&limit=${pagination.limit}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_NOTIFICATIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_NOTIFICATIONS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadNotificationsCount = (tenantId) => {
  return dispatch => {
    dispatch({
      type: GET_NOTIFICATIONS_COUNT,
      payload: {}
    })
    return Axios.get(`${API_URL}/admin-notif-count/${tenantId}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_NOTIFICATIONS_COUNT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_NOTIFICATIONS_COUNT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitReadNotificationsIds = (ids) => {
  const submitData = {
    ids
  }
  return dispatch => {
    dispatch({
      type: PUT_READ_NOTIFICATIONS,
      payload: {}
    })
    return Axios.put(`${API_URL}/admin-notif-read-ids`, { ...submitData }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_READ_NOTIFICATIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_READ_NOTIFICATIONS_REJECTED,
          payload: error
        })
      })
  }
}






