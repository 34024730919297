import React from 'react'
import './index.scss'
import { Layout, Tabs } from 'antd'
import UserAccessDefinition from './userAccessDefinition'
import DatabaseSettings from './databaseSettings'
import MobileSettings from './mobileSettings'
const { TabPane } = Tabs;
class Utilities extends React.Component {

  render() {
    return (
      <Layout>
        <Tabs defaultActiveKey="3" size='large' style={{ marginBottom: 32 }}>
          <TabPane tab="User's Access Definition" key="3">
            <UserAccessDefinition />
          </TabPane>
          <TabPane tab="Database" key="5">
            <DatabaseSettings />
          </TabPane>
          <TabPane tab="Mobile Settings" key="6">
            <MobileSettings />
          </TabPane>
        </Tabs>
      </Layout >
    )
  }
}

export default Utilities



