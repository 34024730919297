import {
  GET_SUMMARY_REPORTS,
  GET_SUMMARY_REPORTS_FULFILLED,
  GET_SUMMARY_REPORTS_REJECTED,

  GET_SUMMARY_REQUESTS,
  GET_SUMMARY_REQUESTS_FULFILLED,
  GET_SUMMARY_REQUESTS_REJECTED,

  GET_SUMMARY_RESERVATIONS,
  GET_SUMMARY_RESERVATIONS_FULFILLED,
  GET_SUMMARY_RESERVATIONS_REJECTED,

  GET_SUMMARY_POST_ANALYTICS,
  GET_SUMMARY_POST_ANALYTICS_FULFILLED,
  GET_SUMMARY_POST_ANALYTICS_REJECTED

} from '../actions'

export default function dashboard(state = {
  summaryReportsLoading: false,
  summaryReportsSuccess: false,
  summaryReportsRejected: false,
  summaryReportsData: {},
  summaryRequestsLoading: false,
  summaryRequestsSuccess: false,
  summaryRequestsRejected: false,
  summaryRequestsData: {
    open: 0, close: 0, cancelled: 0, rejected: 0, all: 0
  },
  summaryReservationsLoading: false,
  summaryReservationsSuccess: false,
  summaryReservationsRejected: false,
  summaryReservationsData: [],

  summaryPostAnalyticsLoading: false,
  summaryPostAnalyticsSuccess: false,
  summaryPostAnalyticsRejected: false,
  summaryPostAnalyticsData: []

}, action) {
  switch (action.type) {
    case GET_SUMMARY_REPORTS:
      return {
        ...state,
        summaryReportsLoading: true,
        summaryReportsSuccess: false,
        summaryReportsRejected: false
      }
    case GET_SUMMARY_REPORTS_FULFILLED:
      return {
        ...state,
        summaryReportsLoading: false,
        summaryReportsSuccess: true,
        summaryReportsData: action.payload
      }
    case GET_SUMMARY_REPORTS_REJECTED:
      return {
        ...state,
        summaryReportsLoading: false,
        summaryReportsRejected: true
      }
    case GET_SUMMARY_REQUESTS:
      return {
        ...state,
        summaryRequestsLoading: true,
        summaryRequestsSuccess: false,
        summaryRequestsRejected: false
      }
    case GET_SUMMARY_REQUESTS_FULFILLED:
      return {
        ...state,
        summaryRequestsLoading: false,
        summaryRequestsSuccess: true,
        summaryRequestsData: action.payload
      }
    case GET_SUMMARY_REQUESTS_REJECTED:
      return {
        ...state,
        summaryRequestsLoading: false,
        summaryRequestsRejected: true
      }

    case GET_SUMMARY_RESERVATIONS:
      return {
        ...state,
        summaryReservationsLoading: true,
        summaryReservationsSuccess: false,
        summaryReservationsRejected: false
      }
    case GET_SUMMARY_RESERVATIONS_FULFILLED:
      return {
        ...state,
        summaryReservationsLoading: false,
        summaryReservationsSuccess: true,
        summaryReservationsData: action.payload
      }
    case GET_SUMMARY_RESERVATIONS_REJECTED:
      return {
        ...state,
        summaryReservationsLoading: false,
        summaryReservationsRejected: true
      }

    case GET_SUMMARY_POST_ANALYTICS:
      return {
        ...state,
        summaryPostAnalyticsLoading: true,
        summaryPostAnalyticsSuccess: false,
        summaryPostAnalyticsRejected: false
      }
    case GET_SUMMARY_POST_ANALYTICS_FULFILLED:
      return {
        ...state,
        summaryPostAnalyticsLoading: false,
        summaryPostAnalyticsSuccess: true,
        summaryPostAnalyticsData: action.payload
      }
    case GET_SUMMARY_POST_ANALYTICS_REJECTED:
      return {
        ...state,
        summaryPostAnalyticsLoading: false,
        summaryPostAnalyticsRejected: true
      }
    default:
      return {
        ...state
      }



  }
}
