import {
  POST_TENANT,
  POST_TENANT_FULFILLED,
  POST_TENANT_REJECTED,
  GET_TENANTS,
  GET_TENANTS_FULFILLED,
  GET_TENANTS_REJECTED,
  PUT_TENANT,
  PUT_TENANT_FULFILLED,
  PUT_TENANT_REJECTED,
  DELETE_TENANT,
  DELETE_TENANT_FULFILLED,
  DELETE_TENANT_REJECTED
} from '../actions'

export default function tenants(state = {
  tenantLoading: false,
  tenantSuccess: false,
  tenantRejected: false,
  tenantData: [],
  tenantsLoading: false,
  tenantsSuccess: false,
  tenantsRejected: false,
  tenantsData: {
    data: []
  },
  updateTenantLoading: false,
  updateTenantSuccess: false,
  updateTenantRejected: false,
  updateTenantData: [],
  deleteTenantLoading: false,
  deleteTenantSuccess: false,
  deleteTenantRejected: false,
  deleteTenantData: []
}, action) {
  switch (action.type) {
    case POST_TENANT:
      return {
        ...state,
        tenantLoading: true,
        tenantSuccess: false,
        tenantRejected: false
      }
    case POST_TENANT_FULFILLED:
      return {
        ...state,
        tenantLoading: false,
        tenantSuccess: true,
        tenantData: action.payload
      }
    case POST_TENANT_REJECTED:
      return {
        ...state,
        tenantLoading: false,
        tenantRejected: true
      }
    case GET_TENANTS:
      return {
        ...state,
        tenantsLoading: true,
        tenantsSuccess: false,
        tenantsRejected: false
      }
    case GET_TENANTS_FULFILLED:
      return {
        ...state,
        tenantsLoading: false,
        tenantsSuccess: true,
        tenantsData: action.payload
      }
    case GET_TENANTS_REJECTED:
      return {
        ...state,
        tenantsLoading: false,
        tenantsRejected: true
      }
    case PUT_TENANT:
      return {
        ...state,
        updateTenantLoading: true,
        updateTenantSuccess: false,
        updateTenantRejected: false
      }
    case PUT_TENANT_FULFILLED:
      return {
        ...state,
        updateTenantLoading: false,
        updateTenantSuccess: true,
        updateTenantData: action.payload
      }
    case PUT_TENANT_REJECTED:
      return {
        ...state,
        updateTenantLoading: false,
        updateTenantRejected: true
      }
    case DELETE_TENANT:
      return {
        ...state,
        deleteTenantLoading: true,
        deleteTenantSuccess: false,
        deleteTenantRejected: false
      }
    case DELETE_TENANT_FULFILLED:
      return {
        ...state,
        deleteTenantLoading: false,
        deleteTenantSuccess: true,
        deleteTenantData: action.payload
      }
    case DELETE_TENANT_REJECTED:
      return {
        ...state,
        deleteTenantLoading: false,
        deleteTenantRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
