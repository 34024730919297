
import React from 'react'
import { Row, Col, Layout, Card, Menu, List, Form, Button, Input, Modal, Divider } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { SettingOutlined, PicCenterOutlined, CalendarOutlined, UserOutlined, WarningOutlined, ExceptionOutlined, ApartmentOutlined, UserSwitchOutlined, AuditOutlined } from '@ant-design/icons';
import { loadUtilityServices, GET_UTILITY_SERVICES_FULFILLED } from '../actions'
import moment from 'moment'
import Order from './orderRequest/index'
import Gatepass from '../../gatePass/components/'
import IncidentReports from '../../incidents/components'
import Reservations from '../../reservations/components'
import WorkPermits from '../../permits/components'
import Visitors from '../../visitors/components'
import RentalInquiries from '../../inquiries/components'
import Commendations from '../../commendations/components'
import All from '../../ticketList/components'
import './index.scss'
import { getUser } from '../../../../utilities/token'
const { confirm } = Modal;
const { SubMenu } = Menu;
const { Search } = Input;
const { Header, Footer, Sider, Content } = Layout;

class Services extends React.Component {
    constructor() {
        super()
        this.state = {
            // activeContent: 'all',
            activeContent: 'all',
            servicesShow: false,
            servicesData: []
        }
    }

    componentDidMount() {
        this.props.onLoadUtilityServices().then(res => {
            if (res.type === GET_UTILITY_SERVICES_FULFILLED) {
                const adminDefaultParamsUtil = 20000
                this.setState({ servicesData: res.payload })
                // services will show only if the user soa amount is    
                // greater than or equal to adminDefeaultParams located admin utilities
                // if (userSoaAmount <= adminDefaultParamsUtil) {
                //     this.setState({ servicesShow: true })
                // }
            }
        })

    }


    render() {
        const { servicesShow } = this.state
        return (
            <Layout className='services-container'>

                <Layout.Content>
                    <div className='header-action' style={{ display: 'none' }}>
                        <Row>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'all' })} >
                                    <div className='action-icon-container'>
                                        <AuditOutlined className='action-icon' />
                                    </div>
                                    <span>Services</span>
                                </button>
                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'orders' })} >
                                    <div className='action-icon-container'>
                                        <SettingOutlined className='action-icon' />
                                    </div>
                                    <span>Job Order</span>
                                </button>

                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'gatePass' })} >
                                    <div className='action-icon-container'>
                                        <PicCenterOutlined className='action-icon' />
                                    </div>
                                    <span> Gate Pass</span>
                                </button>
                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'reservation' })} >
                                    <div className='action-icon-container'>
                                        <CalendarOutlined className='action-icon' />
                                    </div>
                                    <span>Reservations</span>
                                </button>
                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'incident' })} >
                                    <div className='action-icon-container'>
                                        <WarningOutlined className='action-icon' />
                                    </div>
                                    <span>Incident Reports</span>
                                </button>
                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'workPermit' })} >
                                    <div className='action-icon-container'>
                                        <ApartmentOutlined className='action-icon' />
                                    </div>
                                    <span>Work Permits</span>
                                </button>
                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'visitorPass' })} >
                                    <div className='action-icon-container'>
                                        <UserSwitchOutlined className='action-icon' />
                                    </div>
                                    <span>Visitors</span>
                                </button>
                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'rentalInquiry' })} >
                                    <div className='action-icon-container'>
                                        <ExceptionOutlined className='action-icon' />
                                    </div>
                                    <span>Leasing Assistance</span>
                                </button>
                            </Col>
                            <Col className='action-col'>
                                <button onClick={() => this.setState({ activeContent: 'commendations' })} >
                                    <div className='action-icon-container'>
                                        <UserOutlined className='action-icon' />
                                    </div>
                                    <span>Commendations <br /> And Testimonials</span>
                                </button>
                            </Col>
                        </Row>
                        <Divider style={{ background: 'black', margin: 10 }} />
                    </div>
                </Layout.Content>
                <Layout.Content>
                    {
                        (() => {
                            switch (this.state.activeContent) {
                                case 'all':
                                    return <DisplayPanel services={this.state.servicesData} type='all' component={<All />} />
                                case 'orders':
                                    return <DisplayPanel services={this.state.servicesData} type='jobOrderRequest' component={<Order />} />
                                case 'gatePass':
                                    return <DisplayPanel services={this.state.servicesData} type='gatePass' component={<Gatepass />} />
                                case 'reservation':
                                    return <DisplayPanel services={this.state.servicesData} type='reservation' component={<Reservations />} />
                                case 'incident':
                                    return <DisplayPanel services={this.state.servicesData} type='incident' component={<IncidentReports />} />
                                case 'workPermit':
                                    return <DisplayPanel services={this.state.servicesData} type='workPermit' component={<WorkPermits />} />
                                case 'visitorPass':
                                    return <DisplayPanel services={this.state.servicesData} type='visitorPass' component={<Visitors />} />
                                case 'rentalInquiry':
                                    return <DisplayPanel services={this.state.servicesData} type='rentalInquiry' component={<RentalInquiries />} />
                                case 'commendations':
                                    return <DisplayPanel services={this.state.servicesData} type='commendations' component={<Commendations />} />
                                default:
                                    break;
                            }
                        })()
                    }
                </Layout.Content>
            </Layout>
        )
    }
}

const DisplayPanel = (props) => {
    const userSoaAmount = getUser().soaAmount
    const dataFound = props.services.find(data => data.type === props.type)
    if (dataFound && dataFound.status === 0) {
        return <FeaturedHasBeenDisabled />
    } else if (userSoaAmount >= (dataFound && dataFound.amount)) {
        return <OutstandingAccountWarning />
    } else {
        // return <FeaturedHasBeenDisabled />
        return props.component
    }
}

const OutstandingAccountWarning = () => {
    return (
        <Layout.Content>
            <h1>Note:</h1>
            <h1 style={{ color: 'red' }}> Please settle your outstanding account at Property Management Office to access this module.</h1>
        </Layout.Content>
    )
}

const FeaturedHasBeenDisabled = () => {
    return (
        <Layout.Content>
            <h1>Note:</h1>
            <h1 style={{ color: 'red' }}> This service has been disabled by the admin.</h1>
        </Layout.Content>
    )
}

function mapStateToProps(state) {
    return {
        utilityServicesData: state.client.services.utilityServicesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadUtilityServices: (data) => dispatch(loadUtilityServices(data))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Services))
