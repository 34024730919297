import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_TENANT_INFO = 'GET_TENANT_INFO'
export const GET_TENANT_INFO_FULFILLED = 'GET_TENANT_INFO_FULFILLED'
export const GET_TENANT_INFO_REJECTED = 'GET_TENANT_INFO_REJECTED'

export const PUT_TENANT_INFO = 'PUT_TENANT_INFO'
export const PUT_TENANT_INFO_FULFILLED = 'PUT_TENANT_INFO_FULFILLED'
export const PUT_TENANT_INFO_REJECTED = 'PUT_TENANT_INFO_REJECTED'

export const PUT_UPDATE_PASSWORD = 'PUT_UPDATE_PASSWORD'
export const PUT_UPDATE_PASSWORD_FULFILLED = 'PUT_UPDATE_PASSWORD_FULFILLED'
export const PUT_UPDATE_PASSWORD_REJECTED = 'PUT_UPDATE_PASSWORD_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadTenantData = (tenantId) => {
  return dispatch => {
    dispatch({
      type: GET_TENANT_INFO,
      payload: {}
    })
    return Axios.get(`${API_URL}/tenant/${tenantId}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_TENANT_INFO_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_TENANT_INFO_REJECTED,
          payload: error
        })
      })
  }
}

export const updateTenant = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_TENANT_INFO,
      payload: {}
    })
    return Axios.put(`${API_URL}/tenant/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_TENANT_INFO_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_TENANT_INFO_REJECTED,
          payload: error
        })
      })
  }
}

export const changePassword = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_UPDATE_PASSWORD,
      payload: {}
    })
    return Axios.put(`${API_URL}/change-password`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_UPDATE_PASSWORD_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_UPDATE_PASSWORD_REJECTED,
          payload: error
        })
      })
  }
}





