import React from 'react'
import { Row, Col, Form, Button, Card, message } from 'antd'
import { connect } from 'react-redux'
import { submitTermsAndConditions, loadTermsAndCondition, POST_TERMS_AND_CONDITIONS_FULFILLED } from '../actions'
import './index.scss'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { upload } from '../../../../utilities/filesController'

class TermsAndConditions extends React.Component {
  constructor() {
    super()
    this.state = {
      fileImage: '',
      isLoading: false,
      editorState: EditorState.createEmpty(),
    }
    this.onEditorStateChange = this.onEditorStateChange.bind(this)
  }

  componentDidMount() {
    this.setState({ fileImage: '' })
    this.props.onLoadTermsAndCondition()
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.termsAndConditionsData !== nextProps.termsAndConditionsData) {
      console.log("termsAndConditionsData", nextProps.termsAndConditionsData)
      const editorState = nextProps.termsAndConditionsData.value ? EditorState.createWithContent(convertFromRaw(JSON.parse(nextProps.termsAndConditionsData.value))) : EditorState.createEmpty()
      this.setState({ editorState })
    }
  }

  handleSubmit(values) {
    const submitData = {
      "title": "Terms And Conditions",
      "content": JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
      "status": 1
    }
    if (this.state.fileImage.length === 0) {
      this.props.onSubmitTermsAndConditions(submitData).then((res => {
        if (res.type === POST_TERMS_AND_CONDITIONS_FULFILLED) {
          message.success('You have successfully updated the Terms and Conditions.');
        } else {
          message.error('Failed to update the Terms and Conditions.');
        }
      }))
    } else {
      this.setState({ isLoading: true })
      upload(this.state.fileImage[0], this.state.fileImage[0].name, 'images').then(data => {
        this.setState({ isLoading: false })
        this.props.onSubmitTermsAndConditions({ ...submitData, image: `images/${this.state.fileImage[0].name}` }).then((res => {
          if (res.type === POST_TERMS_AND_CONDITIONS_FULFILLED) {
            message.success('You have successfully updated the Terms and Conditions.');
          } else {
            message.error('Failed to update the Terms and Conditions.');
          }
        }))
      }).catch(err => {
        this.setState({ isLoading: false })
      })
    }
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  };

  uploadCallback(file) {
    return upload(file, file.name, 'images').then(data => {
      return new Promise(
        (resolve, reject) => { resolve({ data: { link: data.location } }); }
      );
    }).catch(err => { })
  }

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <h1>Terms and Conditions</h1>
        <Card>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          >
            <div style={{ maxWidth: 650 }}>
              <Row>
                <Col md={24} lg={24} sm={24} xs={24}>
                  <Editor
                    editorState={editorState}
                    // wrapperClassName="demo-wrapper"
                    // editorClassName="demo-editor"
                    toolbarClassName="rdw-storybook-toolbar"
                    wrapperClassName="rdw-storybook-wrapper"
                    editorClassName="rdw-storybook-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{ image: { uploadCallback: this.uploadCallback, alt: { present: true, mandatory: false }, } }}
                  /></Col>
              </Row>
              <Row>
                <Col md={24} lg={24} sm={24} style={{ marginTop: 5 }} align={'right'}>
                  <Form.Item >
                    <Button type="primary" htmlType="submit"
                      loading={this.props.postLoading || this.state.isLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    termsAndConditionsData: state.superadmin.termsAndConditions.termsAndConditionsData,
    termsAndConditionRejected: state.superadmin.termsAndConditions.termsAndConditionRejected,
    termsAndConditionSuccess: state.superadmin.termsAndConditions.termsAndConditionSuccess,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitTermsAndConditions: (data) => dispatch(submitTermsAndConditions(data)),
    onLoadTermsAndCondition: (data) => dispatch(loadTermsAndCondition(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions))
