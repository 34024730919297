
import React from 'react'
import { Row, Col, Layout, Table, Menu, Button, Pagination, Dropdown, Modal, Tag, message } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getUser } from '../../../../utilities/token'
import { ExclamationCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import { submitReservation, loadReservation, deleteReservation } from '../actions'
import AddSlide from './slides/add'
import EditSlide from './slides/edit'
import './index.scss'
import ServiceNavLink from '../../serviceNavLink'
import { ImagePresignedUrl } from '../../../../utilities/renderer'
class Reservation extends React.Component {
    constructor() {
        super()
        this.state = {
            isAddSlideOpen: false,
            isEditSlideOpen: false,

            //pagination
            page: 1,
            limit: 10,
            searchTerm: '',

            tableData: []
        }
        this.onReset = this.onReset.bind(this)
        this.addSlideToggle = this.addSlideToggle.bind(this)
        this.editSlideToggle = this.editSlideToggle.bind(this)
        this.handleDeleteReservation = this.handleDeleteReservation.bind(this)
    }
    formRef = React.createRef();

    handleSubmit(data) {
        const submitData = {
            "tenantId": getUser().id,
            "title": data.title,
            "description": data.description,
            "comment": "TEST",
        }
        this.props.onSubmitReservation(submitData)
        this.onReset()

    }

    handleDeleteReservation(id) {
        const self = this
        Modal.confirm({
            title: 'Do you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'You cannot undo this.',
            onOk() {
                self.props.onDeleteReservation(id)
            },
            onCancel() { },
        });
    }

    onReset = () => {
        this.formRef.current.resetFields();
    };

    componentDidMount() {
        const { limit, page, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadReservation(pagination)

        window.Echo.channel(`client-reservations-crud.${getUser().id}`)
            .listen('.client-reservations-crud-channel', (response) => {
                const { data } = response
                const index = this.state.tableData.findIndex(item => item.id === data.id)
                if (data.status === 0) {
                    this.state.tableData.splice(index, 1) // delete
                } else {
                    if (index !== -1) this.state.tableData[index] = data // update
                    else {
                        this.state.tableData.unshift({ ...data }) // create
                        this.state.tableData = this.state.tableData.slice(0, this.state.limit) // re-assigning and limit to 10
                    }
                }
                // this.setState({tableData: this.state.tableData})
                // this.setState are not working inside echo channel, 
                // regarding to this. <Table key/> key must be unique to know if there was a changed.
                this.forceUpdate()
            })

    }

    onChangePagination(page, limit) {
        const { searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadReservation(pagination)
        this.setState({ page, limit })
    }

    componentWillReceiveProps(nextProps) {
        const { limit, page, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        if ((this.props.addReservationSuccess !== nextProps.addReservationSuccess) && nextProps.addReservationSuccess) {
            this.setState({ isAddSlideOpen: false })
            message.success("Reservation added successfully.")
            this.props.onLoadReservation(pagination)
        }
        if ((this.props.updateReservationSuccess !== nextProps.updateReservationSuccess) && nextProps.updateReservationSuccess) {
            this.setState({ isEditSlideOpen: false })
            message.success("Reservation edited successfully.")
            this.props.onLoadReservation(pagination)
        }
        if ((this.props.deleteReservationSuccess !== nextProps.deleteReservationSuccess) && nextProps.deleteReservationSuccess) {
            this.setState({ isEditSlideOpen: false })
            message.success("Reservation deleted successfully.")
            this.props.onLoadReservation(pagination)
        }
        if ((this.props.reservationSuccess !== nextProps.reservationSuccess) && nextProps.reservationSuccess) {
            this.setState({ tableData: nextProps.reservationData.data })
        }
    }

    addSlideToggle() {
        this.setState({ isAddSlideOpen: !this.state.isAddSlideOpen })
    }

    editSlideToggle() {
        this.setState({ isEditSlideOpen: !this.state.isEditSlideOpen })
    }


    getStatus(status) {
        switch (status) {
            case 1:
                return <Tag color="gold">FOR APPROVAL</Tag>
            case 2:
                return <Tag color="lime">APPROVED</Tag>
            case 3:
                return <Tag color="geekblue">ONGOING</Tag>
            case 4:
                return <Tag color="green">COMPLETED</Tag>
            case 5:
                return <Tag color="magenta">CANCELLED</Tag>
            case 6:
                return <Tag color="red">REJECTED</Tag>
            default:
                break;
        }
    }

    render() {
        const dataSource = this.state.tableData

        const columns = [
            {
                title: 'Service Ticket #',
                dataIndex: 'ticketNumber',
                key: 'ticketNumber',
                sorter: (a, b) => a.ticketNumber && a.ticketNumber.localeCompare(b.ticketNumber)
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                sorter: (a, b) => a.title && a.title.localeCompare(b.title)

            },
            {
                title: 'Amenity',
                dataIndex: 'amenity',
                key: 'amenity',
                sorter: (a, b) => a.amenity && a.amenity.title && a.amenity.title.localeCompare(b.amenity && b.amenity.title),

                render: (item) => {
                    return (
                        <span className='init-capital'>{item && (item.title)}</span>
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (item) => {
                    return (<span>{this.getStatus(item)}</span>)
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: '',
                render: (info, data) => {
                    return (
                        <div className='action-dropdown-btn'>
                            <Dropdown overlay={<Menu>
                                <Menu.Item onClick={() => this.setState({ selectedRow: data, isEditSlideOpen: true })}>
                                    <span>View</span>
                                </Menu.Item>
                                {
                                    data.status === 1 ?
                                        <Menu.Item onClick={() => this.handleDeleteReservation(data.id)}  >
                                            <span>Delete</span>
                                        </Menu.Item>
                                        :
                                        <Menu.Item disabled  >
                                            <span style={{ color: 'red' }}>Unable to delete</span>
                                        </Menu.Item>
                                }
                            </Menu>} trigger={['click']}>
                                <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    <EllipsisOutlined />
                                </span>
                            </Dropdown>
                        </div>
                    )
                }
            },
        ];
        return (
            <Layout>
                <Row>
                    <Col md={12} lg={12} xs={24} sm={24}>
                        <h1 style={{ fontSize: 24, marginLeft: 10 }}>RESERVATIONS</h1>
                        <Button className='primary-btn' type='primary' onClick={() => this.setState({ isAddSlideOpen: true })} style={{ marginBottom: 10 }}>+ ADD RESERVATION</Button>
                    </Col>
                </Row>
                <Row gutter={32}>
                    <Col md={18} lg={18} xs={24} sm={24}>
                        <Table columns={columns} dataSource={dataSource} key={(JSON.stringify(this.state.tableData))} loading={this.props.reservationLoading} pagination={false} />
                        <Row style={{ marginTop: 30, width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} align="right">
                                <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)} total={this.props.reservationData.total} />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6} lg={6} xs={24} sm={24}>
                        {this.props.advertisementData && this.props.advertisementData.images &&
                            <div className='banner-image'>
                                <ImagePresignedUrl src={this.props.advertisementData.images[0].url} />
                            </div>
                        }
                        <ServiceNavLink {...this.props} />
                    </Col>
                </Row>
                <AddSlide
                    visible={this.state.isAddSlideOpen}
                    onCancel={this.addSlideToggle}
                />
                <EditSlide
                    visible={this.state.isEditSlideOpen}
                    selectedRow={this.state.selectedRow}
                    onCancel={this.editSlideToggle}
                />
            </Layout>
        )
    }
}

function mapStateToProps(state) {
    return {
        reservationSuccess: state.client.reservations.reservationSuccess,
        addReservationSuccess: state.client.reservations.addReservationSuccess,
        updateReservationSuccess: state.client.reservations.updateReservationSuccess,
        reservationData: state.client.reservations.reservationData,
        reservationLoading: state.client.reservations.reservationLoading,
        deleteReservationSuccess: state.client.reservations.deleteReservationSuccess,
        advertisementData: state.client.advertisements.advertisementData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitReservation: (data) => dispatch(submitReservation(data)),
        onLoadReservation: (pagination) => dispatch(loadReservation(pagination)),
        onDeleteReservation: (id) => dispatch(deleteReservation(id)),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reservation)
)