import React from 'react'
import { Row, Col, Form, Button, Input, Select, DatePicker, Spin, Card, Space } from 'antd'
import { connect } from 'react-redux'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { updateGatePass } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import moment from 'moment'
import SlidingPane from "react-sliding-pane";

class Edit extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false
    }
  }

  handleUpdate(values) {
    const submitData = {
      "tenantId ": getUser().id,
      "bearerName": values.bearerName,
      "dateOfApplication": values.dateOfApplication,
      "dateOfUse": values.dateOfUse,
      "type": this.getTypeEnum(values.type),
      "items": values.items
    }
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id

    this.props.onUpdateGatePass({ ...submitData, id: selectedRowId })
  }

  getTypeEnum(type) {
    switch (type) {
      case 'Delivery':
        return 1
      case 'MoveIn':
        return 2
      case 'MoveOut':
        return 3
      case 'PullOut':
        return 4
      default:
        return 1
    }
  }

  getTypeLabel(status) {
    switch (status) {
      case 1:
        return 'Delivery'
      case 2:
        return 'MoveIn'
      case 3:
        return 'MoveOut'
      case 4:
        return 'PullOut'
      default:
        break;
    }
  }

  getStatusLabel(status) {
    switch (status) {
      case 1:
        return 'APPROVAL'
      case 2:
        return 'APPROVED'
      case 3:
        return 'ONGOING'
      case 4:
        return 'COMPLETED'
      case 5:
        return 'CANCELLED'
      case 6:
        return 'REJECTED'
      default:
        break;
    }
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    const { selectedRow } = this.props
    const disabled = selectedRow && selectedRow.status !== 1 // Cant edit if the status is for approval = 1
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="EDIT GATE PASS"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large" spinning={false}>
          <Form
            name="basic"
            initialValues={{
              ...this.props.selectedRow,
              dateOfApplication: moment(selectedRow && selectedRow.dateOfApplication),
              dateOfUse: moment(selectedRow && selectedRow.dateOfUse),
              approvalDate: moment(selectedRow && selectedRow.approvalDate),
              items: selectedRow && selectedRow.items || [],
              status: this.getStatusLabel(selectedRow && selectedRow.status),
              type: this.getTypeLabel(selectedRow && selectedRow.type),
              approvedBy: selectedRow && selectedRow.approver && selectedRow && selectedRow.approver.name || '',
              approvalDate: selectedRow && selectedRow.approvalDate && moment(selectedRow && selectedRow.approvalDate).format('LLL') || '',
              completedDate: moment(selectedRow && selectedRow.completedDate).format('LLL') || '',
              verifiedBy: selectedRow && selectedRow.verifier && selectedRow && selectedRow.verifier.name || '',
            }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Bearer Name"
                  name="bearerName"
                  rules={[{ required: true, message: 'Please input your bearer name!' }]}
                >
                  <Input disabled={disabled} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date Of Application"
                  name="dateOfApplication"
                  rules={[{ required: true, message: 'Please input your date of application!' }]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  label="Date Of Use"
                  name="dateOfUse"
                  rules={[{ required: true, message: 'Please input your date of date of use!' }]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabled={disabled}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  label="Type"
                  name="type"
                // rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select defaultValue='Delivery' disabled={disabled} className='primary-input-select' >
                    <Select.Option value={'Delivery'}>Delivery</Select.Option>
                    <Select.Option value={'MoveIn'}>Move In</Select.Option>
                    <Select.Option value={'MoveOut'}>Move Out</Select.Option>
                    <Select.Option value={'PullOut'}>Pull Out</Select.Option>
                  </Select>
                </Form.Item>

                <Card title="List of Items">
                  <Form.List name="items">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields && fields.map((field, index) => (
                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                rules={[{ required: true, message: 'Missing Name' }]}
                              >
                                <Input placeholder="Name" className='primary-input' disabled={disabled} />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'quantity']}
                                fieldKey={[field.fieldKey, 'quantity']}
                                rules={[{ required: true, message: 'Missing Quantity' }]}
                              >
                                <Input type="number" placeholder="Quantity" className='primary-input' disabled={disabled} />
                              </Form.Item>
                              {
                                (!disabled && index !== 0) &&
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              }
                            </Space>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={disabled}>
                              Add Item
                            </Button>
                          </Form.Item>
                        </>
                      )
                    }}
                  </Form.List>
                </Card>

              </Col>

              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Approval Date"
                  name="approvalDate"
                  rules={[{ required: false, message: 'Please input your approval date!' }]}
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Approved by"
                  name="approvedBy"
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Verified By"
                  name="verifiedBy"
                >

                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Status"
                  name="status"
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Remarks"
                  name="remarks"
                >
                  <Input.TextArea rows={5} disabled className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Date Completed"
                  name="completedDate"
                >
                  <Input disabled className='primary-input' />
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit" loading={this.props.updateGatePassLoading || this.state.isLoading} disabled={disabled} >
                    Update Gate Pass
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane >
    )
  }
}

function mapStateToProps(state) {
  return {
    updateGatePassLoading: state.client.services.updateGatePassLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateGatePass: (data) => dispatch(updateGatePass(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))

