import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Pagination } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadTenant, deleteTenant, loadSelectedRowTenants } from '../actions'
import ReactTimeAgo from 'react-time-ago'
import Papa from 'papaparse'
import moment from 'moment'
const { Search } = Input;

class Tenants extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      selectedRow: {},
      selectedRowKeys: [],
      tableData: [],
      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if ((this.props.tenantSuccess !== nextProps.tenantSuccess) && nextProps.tenantSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Tenant added successfully.")
      this.props.onLoadTenant(pagination)
    }
    if ((this.props.updateTenantSuccess !== nextProps.updateTenantSuccess) && nextProps.updateTenantSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Tenant edited successfully.")
      this.props.onLoadTenant(pagination)
    }
    if ((this.props.deleteTenantSuccess !== nextProps.deleteTenantSuccess) && nextProps.deleteTenantSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Tenant deleted successfully.")
      this.props.onLoadTenant(pagination)
    }
    if ((this.props.tenantsSuccess !== nextProps.tenantsSuccess) && nextProps.tenantsSuccess) {
      this.setState({ tableData: nextProps.tenantsData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadTenant(pagination)
  }

  onChangePagination(page, limit) {
    const { searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadTenant(pagination)
    this.setState({ page, limit })
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadTenant(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }


  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteTenant(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteTenant(id)
      },
      onCancel() { },
    });
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  handleDownloadSelectedRowToCSV() {
    this.setState({ downloadLoading: true })
    this.props.onLoadSelectedRowTenants(this.state.selectedRowKeys).then(res => {
      console.log("resss", res)
      const newData = res.payload.map(data => {
        return {
          'clientCode': data.clientCode,
          'uoccname': data.uoccname,
          'uoccpers': data.uoccpers,
          'uocctel1': data.uocctel1,
          'uocctel2': data.uocctel2,
          'ubtname': data.ubtname,
          'ubaddln1': data.ubaddln1,
          'ubaddln2': data.ubaddln2,
          'ubaddln3': data.ubaddln3,
          'ubaddln4': data.ubaddln4,
          'name': data.name,
          'email': data.email,
          'lastName': data.lastName,
          'firstName': data.firstName,
          'middleName': data.middleName,
          'phoneNumber': data.phoneNumber,
          'unitNumber': data.unitNumber,
          'address': data.address,
          'socialMediaAccount': data.socialMediaAccount,
          'soaName': data.soaName,
          'soaAmount': data.soaAmount,
          'soaDate': data.soaDate,
          'lastSeen': moment(data.lastSeen).format('LLLL')

        }
      })
      var csv = Papa.unparse(newData);

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var csvURL = null;
      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, 'Tenants.csv');
      }
      else {
        csvURL = window.URL.createObjectURL(csvData);
      }

      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'Tenants.csv');
      tempLink.click();

    }).finally(() => {
      this.setState({ downloadLoading: false })
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  render() {
    console.log("this.props.tenantsLoading || this.props.deleteTenantLoading", this.props.tenantsLoading, this.props.deleteTenantLoading)
    const dataSource = this.state.tableData
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      preserveSelectedRowKeys: true
    };
    const columns = [
      {
        title: 'Last Active',
        dataIndex: 'lastSeen',
        key: 'lastSeen',
        sorter: (a, b) => new Date(b.lastSeen) - new Date(a.lastSeen),
        render: (info, data) => {
          return info && <ReactTimeAgo date={info} locale="en-US" />
        },
      },
      {
        title: 'Client Code',
        dataIndex: 'clientCode',
        key: 'clientCode',
        sorter: (a, b) => a.clientCode && a.clientCode.localeCompare(b.clientCode)
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (info, data) => {
          return (<span>{data.firstName} {data.middleName} {data.lastName}</span>)
        }
      },
      {
        title: 'Uoccname',
        dataIndex: 'uoccname',
        key: 'uoccname',
        sorter: (a, b) => a.uoccname && a.uoccname.localeCompare(b.uoccname)
      },
      {
        title: 'Uoccpers',
        dataIndex: 'uoccpers',
        key: 'uoccpers',
        sorter: (a, b) => a.uoccpers && a.uoccpers.localeCompare(b.uoccpers)
      },
      {
        title: 'Uocctel1',
        dataIndex: 'uocctel1',
        key: 'uocctel1',
        sorter: (a, b) => a.uocctel1 && a.uocctel1.localeCompare(b.uocctel1)
      },

      {
        title: 'Uocctel2',
        dataIndex: 'uocctel2',
        key: 'uocctel2',
        sorter: (a, b) => a.uocctel2 && a.uocctel2.localeCompare(b.uocctel2)
      },
      {
        title: 'Ubtname',
        dataIndex: 'ubtname',
        key: 'ubtname',
        sorter: (a, b) => a.ubtname && a.ubtname.localeCompare(b.ubtname)
      },
      {
        title: 'Ubaddln1',
        dataIndex: 'ubaddln1',
        key: 'ubaddln1',
        sorter: (a, b) => a.ubaddln1 && a.ubaddln1.localeCompare(b.ubaddln1)
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              {
                this.access('tenants', 'view') &&
                <Button type="primary" icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })}>View</Button>
              }
              {
                this.access('tenants', 'delete') &&
                <Button type="primary" icon={< DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteTenant(data.id)} >Delete</Button>
              }
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='tenants-container'>
        <Layout.Content>
          <div className='tenant-title'>
            <h1>Tenants List</h1>
          </div>
          <Row>
            <Col md={14} lg={14} sm={24} xs={24}>
              <div className='mt3 mb3'>
                <Button type='primary' onClick={() => this.handleDownloadSelectedRowToCSV()} loading={this.state.downloadLoading}>Download CSV</Button>
              </div>
            </Col>
          </Row>
          <Row className='tenant-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('tenants', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Name, Email, Phone Number, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Row style={{ width: '100%' }}>
              <Table style={{ width: '100%' }} rowSelection={rowSelection} rowKey='id' loading={this.props.tenantsLoading || this.props.deleteTenantLoading} dataSource={dataSource} columns={columns} pagination={false} />
              <Row style={{ marginTop: 30, width: '100%' }}>
                <Col md={24} lg={24} sm={24} xs={24} align="right">
                  <Pagination defaultCurrent={this.state.page} onChange={(page, limit) => this.onChangePagination(page, limit)}
                    total={this.props.tenantsData.total} />
                </Col>
              </Row>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}


function mapStateToProps(state) {
  return {
    tenantLoading: state.admin.tenant.tenantLoading,
    tenantSuccess: state.admin.tenant.tenantSuccess,
    updateTenantSuccess: state.admin.tenant.updateTenantSuccess,
    deleteTenantSuccess: state.admin.tenant.deleteTenantSuccess,
    tenantsSuccess: state.admin.tenant.tenantsSuccess,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
    deleteTenantLoading: state.admin.tenant.deleteTenantLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadTenant: (pagination) => dispatch(loadTenant(pagination)),
    onDeleteTenant: (id) => dispatch(deleteTenant(id)),
    onLoadSelectedRowTenants: (selectedKeys) => dispatch(loadSelectedRowTenants(selectedKeys))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Tenants))
