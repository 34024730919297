import React from 'react'
import { Row, Col, Form, Button, Input, Spin, Space, DatePicker, Card } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitPermit } from '../../actions'
import moment from 'moment'
import SlidingPane from "react-sliding-pane";

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false
    }
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": getUser().id,
      "dateApplied": values.dateApplied,
      "workPermitFor": values.workPermitFor,
      "workArea": values.workArea,
      "dateFrom": values.dateFrom,
      "dateTo": values.dateTo,
      "purpose": values.purpose,
      "contractors": values.contractors,
      "items": values.items,
    }

    this.props.onSubmitPermit(submitData).then(res => {
      this.setState({ isLoading: false })
    })
  }

  disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  render() {
    return (

      <SlidingPane
        isOpen={this.props.visible}
        title="ADD PERMIT"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            initialValues={{
              contractors: [{ name: '', information: '' }],
              items: [{ name: '', quantity: null }]
            }}
            onFinish={(e) => this.handleSubmit(e)}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Work Permit For"
                  name="workPermitFor"
                  rules={[{ required: true, message: 'Please input your work permit for!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Work Area"
                  name="workArea"
                  rules={[{ required: true, message: 'Please input your work area!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Purpose"
                  name="purpose"
                  rules={[{ required: true, message: 'Please input your purpose!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>
                <Form.Item
                  label="Covered From"
                  name="dateFrom"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date from!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Form.Item
                  label="Covered To"
                  name="dateTo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date to!',
                    },
                  ]}
                >
                  <DatePicker
                    className='primary-input'
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                    disabledDate={this.disabledDate}
                  />
                </Form.Item>
                <Card title="List of Workers">
                  <Form.List name="contractors">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Missing Name' }]}
                            >
                              <Input className='primary-input' placeholder="Worker Name" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'information']}
                              fieldKey={[field.fieldKey, 'information']}
                              rules={[{ required: true, message: 'Missing Information' }]}
                            >
                              <Input className='primary-input' placeholder="Worker Information" />
                            </Form.Item>
                            {
                              index !== 0 &&
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                            }
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" className='primary-btn' onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Worker
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>

                <Card title="List of Items" style={{ marginTop: 30 }}>
                  <Form.List name="items">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields && fields.map((field, index) => (
                            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                rules={[{ required: true, message: 'Missing Name' }]}
                              >
                                <Input placeholder="Name" className='primary-input' />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, 'quantity']}
                                fieldKey={[field.fieldKey, 'quantity']}
                                rules={[{ required: true, message: 'Missing Quantity' }]}
                              >
                                <Input type="number" placeholder="Quantity" className='primary-input' />
                              </Form.Item>
                              {
                                (index !== 0) &&
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                              }
                            </Space>
                          ))}
                          <Form.Item>
                            <Button type="dashed" className='primary-btn' onClick={() => add()} block icon={<PlusOutlined />} >
                              Add Item
                            </Button>
                          </Form.Item>
                        </>
                      )
                    }}
                  </Form.List>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit Work Permit
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.client.permits.postLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitPermit: (data) => dispatch(submitPermit(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
