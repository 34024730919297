import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Spin } from 'antd'
import { connect } from 'react-redux'
import ImageUploader from './imageBulkUploader'
import { getUser } from '../../../../../utilities/token'
import { updateTenant } from '../../actions'
import { upload } from '../../../../../utilities/filesController'

class Edit extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
    }
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `images/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'images').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  handleUpdate(values) {
    const selectedRowId = this.props.selectedRow && this.props.selectedRow.id

    const submitData = {
      clientCode: values.clientCode,
      uoccname: values.uoccname,
      uoccpers: values.uoccpers,
      uocctel1: values.uocctel1,
      uocctel2: values.uocctel2,
      ubtname: values.ubtname,
      ubaddln1: values.ubaddln1,
      ubaddln2: values.ubaddln2,
      ubaddln3: values.ubaddln3,
      ubaddln4: values.ubaddln4,
      lastName: values.lastName,
      firstName: values.firstName,
      middleName: values.middleName,
      email: values.email,
      image: this.state.fileImageList[0] && this.state.fileImageList[0].url,
      phoneNumber: values.phoneNumber,
      password: values.password,
      postById: getUser().id,
      status: 1
    }

    Promise.all([
      this.uploadFileImage()
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onUpdateTenant({ ...submitData, id: selectedRowId })
        this.setState({ isLoading: false })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })

  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }


  render() {
    return (
      <Modal
        title="EDIT TENANT'S INFORMATION"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={false}>

          <Form
            name="basic"
            initialValues={{ ...this.props.selectedRow }}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={12} lg={12} sm={24} xs={24}>
                <Form.Item
                  label="Client Code"
                  name="clientCode"
                  rules={[{ required: false, message: 'Please input your client code!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: false, message: 'Please input your first name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: false, message: 'Please input your last name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Middle Name"
                  name="middleName"
                  rules={[{ required: false, message: 'Please input your middle name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: false, message: 'Please input your email!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[{ required: false, message: 'Please input your phone number!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                >
                  <Input.Password />
                </Form.Item>

              </Col>
              <Col md={12} lg={12} sm={24} xs={24}>

                <Form.Item
                  label="UOCC NAME"
                  name="uoccname"
                  rules={[{ required: true, message: 'Please input your Uocc name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UOCC PERS"
                  name="uoccpers"
                  rules={[{ required: true, message: 'Please input your Uocc pers!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UOCC Tel 1"
                  name="uocctel1"
                  rules={[{ required: true, message: 'Please input your UOCC tel 1!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UOCC Tel 2"
                  name="uocctel2"
                  rules={[{ required: true, message: 'Please input your UOCC tel 2!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBT Name"
                  name="ubtname"
                  rules={[{ required: true, message: 'Please input your Ubt name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln1"
                  name="ubaddln1"
                  rules={[{ required: true, message: 'Please input your Ubadd ln1!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln2"
                  name="ubaddln2"
                  rules={[{ required: true, message: 'Please input your Ubadd ln2!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln3"
                  name="ubaddln3"
                  rules={[{ required: true, message: 'Please input your Ubadd ln3!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="UBADD ln4"
                  name="ubaddln4"
                  rules={[{ required: true, message: 'Please input your Ubadd ln4!' }]}
                >
                  <Input />
                </Form.Item>

                <span style={{ fontWeight: 550 }}>Tenant Image</span>
                <ImageUploader
                  value={this.props.selectedRow}
                  getFile={e => this.getFileImage(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  {
                    this.access('tenants', 'edit') &&
                    <Button style={{ marginTop: 10 }} type="primary" htmlType="submit" loading={this.props.updateTenantLoading || this.state.isLoading}>
                      Submit
                    </Button>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    updateTenantLoading: state.admin.tenant.updateTenantLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateTenant: (data) => dispatch(updateTenant(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Edit))

