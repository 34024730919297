import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_POLICIES = 'GET_POLICIES'
export const GET_POLICIES_FULFILLED = 'GET_POLICIES_FULFILLED'
export const GET_POLICIES_REJECTED = 'GET_POLICIES_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadPolicies = (data) => {
  return dispatch => {
    dispatch({
      type: GET_POLICIES,
      payload: {}
    })
    return Axios.get(`${API_URL}/policies-header`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_POLICIES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_POLICIES_REJECTED,
          payload: error
        })
      })
  }
}



