import { combineReducers } from 'redux'

// Client
import clientReducers from '../components/client/reducers'

// Admin
import adminReducers from '../components/admin/reducers'

// Super Admin
import superAdminReducers from '../components/superAdmin/reducers'

const rootReducer = combineReducers({
  client: clientReducers,
  admin: adminReducers,
  superadmin: superAdminReducers
})

export default rootReducer
