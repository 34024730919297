import {
  GET_GATEPASS,
  GET_GATEPASS_FULFILLED,
  GET_GATEPASS_REJECTED,
  PUT_GATEPASS,
  PUT_GATEPASS_FULFILLED,
  PUT_GATEPASS_REJECTED,
  POST_GATEPASS,
  POST_GATEPASS_FULFILLED,
  POST_GATEPASS_REJECTED,
  DELETE_GATEPASS,
  DELETE_GATEPASS_FULFILLED,
  DELETE_GATEPASS_REJECTED
} from '../actions'

export default function gatePass(state = {
  gatePassLoading: false,
  gatePassSuccess: false,
  gatePassRejected: false,
  gatePassData: [],
  updateGatePassLoading: false,
  updateGatePassSuccess: false,
  updateGatePassRejected: false,
  addGatePassLoading: false,
  addGatePassSuccess: false,
  addGatePassRejected: false,
  deleteGatePassLoading: false,
  deleteGatePassSuccess: false,
  deleteGatePassRejected: false
}, action) {
  switch (action.type) {
    case GET_GATEPASS:
      return {
        ...state,
        gatePassLoading: true,
        gatePassSuccess: false,
        gatePassRejected: false
      }
    case GET_GATEPASS_FULFILLED:
      return {
        ...state,
        gatePassLoading: false,
        gatePassSuccess: true,
        gatePassData: action.payload
      }
    case GET_GATEPASS_REJECTED:
      return {
        ...state,
        gatePassLoading: false,
        gatePassRejected: true
      }
    case POST_GATEPASS:
      return {
        ...state,
        addGatePassLoading: true,
        addGatePassSuccess: false,
        addGatePassRejected: false
      }
    case POST_GATEPASS_FULFILLED:
      return {
        ...state,
        addGatePassLoading: false,
        addGatePassSuccess: true,
        addGatePassData: action.payload
      }
    case POST_GATEPASS_REJECTED:
      return {
        ...state,
        addGatePassLoading: false,
        addGatePassRejected: true
      }
    case PUT_GATEPASS:
      return {
        ...state,
        updateGatePassLoading: true,
        updateGatePassSuccess: false,
        updateGatePassRejected: false
      }
    case PUT_GATEPASS_FULFILLED:
      return {
        ...state,
        updateGatePassLoading: false,
        updateGatePassSuccess: true,
        updateGatePassData: action.payload
      }
    case PUT_GATEPASS_REJECTED:
      return {
        ...state,
        updateGatePassLoading: false,
        updateGatePassRejected: true
      }

    case DELETE_GATEPASS:
      return {
        ...state,
        deleteGatePassLoading: true,
        deleteGatePassSuccess: false,
        deleteGatePassRejected: false
      }
    case DELETE_GATEPASS_FULFILLED:
      return {
        ...state,
        deleteGatePassLoading: false,
        deleteGatePassSuccess: true,
        deleteGatePassData: action.payload
      }
    case DELETE_GATEPASS_REJECTED:
      return {
        ...state,
        deleteGatePassLoading: false,
        deleteGatePassRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
