import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_INCIDENT = 'GET_INCIDENT'
export const GET_INCIDENT_FULFILLED = 'GET_INCIDENT_FULFILLED'
export const GET_INCIDENT_REJECTED = 'GET_INCIDENT_REJECTED'

export const POST_INCIDENT = 'POST_INCIDENT'
export const POST_INCIDENT_FULFILLED = 'POST_INCIDENT_FULFILLED'
export const POST_INCIDENT_REJECTED = 'POST_INCIDENT_REJECTED'

export const PUT_INCIDENT = 'PUT_INCIDENT'
export const PUT_INCIDENT_FULFILLED = 'PUT_INCIDENT_FULFILLED'
export const PUT_INCIDENT_REJECTED = 'PUT_INCIDENT_REJECTED'

export const DELETE_INCIDENT = 'DELETE_INCIDENT'
export const DELETE_INCIDENT_FULFILLED = 'DELETE_INCIDENT_FULFILLED'
export const DELETE_INCIDENT_REJECTED = 'DELETE_INCIDENT_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadIncident = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_INCIDENT,
      payload: {}
    })
    return Axios.get(`${API_URL}/reports?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_INCIDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_INCIDENT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitIncident = (data) => {
  return dispatch => {
    dispatch({
      type: POST_INCIDENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/report`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_INCIDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_INCIDENT_REJECTED,
          payload: error
        })
      })
  }
}

export const updateIncident = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_INCIDENT,
      payload: {}
    })
    return Axios.put(`${API_URL}/report/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_INCIDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_INCIDENT_REJECTED,
          payload: error
        })
      })
  }
}


export const deleteIncident = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_INCIDENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/report/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_INCIDENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_INCIDENT_REJECTED,
          payload: error
        })
      })
  }
}











