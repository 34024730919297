import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_PAYMENT = 'POST_PAYMENT'
export const POST_PAYMENT_FULFILLED = 'POST_PAYMENT_FULFILLED'
export const POST_PAYMENT_REJECTED = 'POST_PAYMENT_REJECTED'

export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_PAYMENTS_FULFILLED = 'GET_PAYMENTS_FULFILLED'
export const GET_PAYMENTS_REJECTED = 'GET_PAYMENTS_REJECTED'

export const GET_PAYMENT_SCHEDULES = 'GET_PAYMENT_SCHEDULES'
export const GET_PAYMENT_SCHEDULES_FULFILLED = 'GET_PAYMENT_SCHEDULES_FULFILLED'
export const GET_PAYMENT_SCHEDULES_REJECTED = 'GET_PAYMENT_SCHEDULES_REJECTED'

export const GET_PAYMENT = 'GET_PAYMENT'
export const GET_PAYMENT_FULFILLED = 'GET_PAYMENT_FULFILLED'
export const GET_PAYMENT_REJECTED = 'GET_PAYMENT_REJECTED'

export const PUT_PAYMENT = 'PUT_PAYMENT'
export const PUT_PAYMENT_FULFILLED = 'PUT_PAYMENT_FULFILLED'
export const PUT_PAYMENT_REJECTED = 'PUT_PAYMENT_REJECTED'

export const DELETE_PAYMENT = 'DELETE_PAYMENT'
export const DELETE_PAYMENT_FULFILLED = 'DELETE_PAYMENT_FULFILLED'
export const DELETE_PAYMENT_REJECTED = 'DELETE_PAYMENT_REJECTED'

export const APPROVE_PAYMENT = 'APPROVE_PAYMENT'
export const APPROVE_PAYMENT_FULFILLED = 'APPROVE_PAYMENT_FULFILLED'
export const APPROVE_PAYMENT_REJECTED = 'APPROVE_PAYMENT_REJECTED'

export const GET_SELECTED_KEYS_TRANSACTIONS = 'GET_SELECTED_KEYS_TRANSACTIONS'
export const GET_SELECTED_KEYS_TRANSACTIONS_FULFILLED = 'GET_SELECTED_KEYS_TRANSACTIONS_FULFILLED'
export const GET_SELECTED_KEYS_TRANSACTIONS_REJECTED = 'GET_SELECTED_KEYS_TRANSACTIONS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadPayment = (status, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_PAYMENTS,
      payload: {}
    })
    return Axios.get(`${API_URL}/payments?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&status=${status}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_PAYMENTS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_PAYMENTS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSelectedRowTransactions = (selectedKeys) => {
  return dispatch => {
    dispatch({
      type: GET_SELECTED_KEYS_TRANSACTIONS,
      payload: {}
    })
    return Axios.get(`${API_URL}/paymentIds?selectedIds=${selectedKeys.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SELECTED_KEYS_TRANSACTIONS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SELECTED_KEYS_TRANSACTIONS_REJECTED,
          payload: error
        })
      })
  }
}


export const loadPaymentById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_PAYMENT,
      payload: {}
    })
    return Axios.get(`${API_URL}/payment/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPayment = (data, status) => {
  return dispatch => {
    dispatch({
      type: POST_PAYMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/payment`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const updatePayment = (data, status) => {
  return dispatch => {
    dispatch({
      type: PUT_PAYMENT,
      payload: {}
    })
    return Axios.put(`${API_URL}/payment/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const deletePayment = (id, status) => {
  return dispatch => {
    dispatch({
      type: DELETE_PAYMENT,
      payload: {}
    })
    return Axios.delete(`${API_URL}/payment/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const approvePayment = (id, status) => {
  return dispatch => {
    dispatch({
      type: APPROVE_PAYMENT,
      payload: {}
    })
    return Axios.put(`${API_URL}/payment/reserved/${id}`, {}, { headers: headers })
      .then(function (response) {
        dispatch({
          type: APPROVE_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: APPROVE_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const loadPaymentSchedules = (id) => {
  return dispatch => {
    dispatch({
      type: GET_PAYMENT_SCHEDULES,
      payload: {}
    })
    return Axios.get(`${API_URL}/get-payment-schedules`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_PAYMENT_SCHEDULES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_PAYMENT_SCHEDULES_REJECTED,
          payload: error
        })
      })
  }
}








