import React from 'react'
import './index.scss'
import { Row, Col, Card, Divider } from 'antd'
import { Line, Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux'
import { loadSummaryReports, loadSummaryRequests, loadSummaryReservations, loadSummaryPostsAnalytics } from '../actions/index'
import moment from 'moment'
import _ from 'lodash'
import {
  UserOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  CopyOutlined
} from '@ant-design/icons'

var randomColor = require('randomcolor')
class Dashboard extends React.Component {
  constructor() {
    super()
  }
  componentDidMount() {
    this.props.onLoadSummaryReports()
    this.props.onLoadSummaryRequests()
    this.props.onLoadSummaryReservations()
    this.props.onLoadSummaryPostsAnalytics()
  }

  getCount(arr) {
    let newArray = []
    arr && arr.map((data) => {
      newArray.push(data.count)
    })
    return newArray
  }

  mapDate() {
    const month = 12
    const x = _.groupBy(this.props.summaryReservationsData, 'year')
    const myData = []
    const newData = Object.entries(x).map(((data, i) => {
      const count = []
      for (let index = 1; index <= month; index++) {
        const isFound = data[1].find(x => +(x.month.replace('0', '')) === index)
        if (isFound) {
          count.push(isFound.count)
        } else {
          count.push(0)
        }

      }
      myData.push({
        label: data[0],
        borderColor: randomColor(),
        fill: false,
        data: count
      })
      return myData
    }))
    return myData
  }

  render() {
    const reservationData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: this.mapDate()
    };

    const postAnalyticsData = {
      labels: this.props.summaryPostAnalyticsData && this.props.summaryPostAnalyticsData.map((data) => (data.post && data.post.title.substring(0, 75))),
      datasets: [
        {
          label: 'Number of Views Per Posts',
          data: this.props.summaryPostAnalyticsData && this.props.summaryPostAnalyticsData.map(data => data.total),
          borderColor: randomColor(),
          fill: false,
        }
      ]
    };


    const { summaryReportsData, summaryRequestsData } = this.props
    const serviceData = {
      labels: [
        'Open',
        'Close', //change to approved  from Close and line 206
        'Ongoing',
        'Completed',
        'Cancelled',
        'Rejected',
        'Deleted'
      ],
      datasets: [{
        data: [
          summaryRequestsData.open,
          summaryRequestsData.close,
          summaryRequestsData.ongoing,
          summaryRequestsData.completed,
          summaryRequestsData.cancelled,
          summaryRequestsData.rejected,
          summaryRequestsData.deleted
        ],
        // data: [10, 20, 30, 40],
        backgroundColor: [
          '#4BC0C0',
          '#72fafc',
          'green',
          'lime',
          '#e03f3f',
          '#a32f2f',
          'red'
        ],
        hoverBackgroundColor: [
          '#4BC0C0',
          '#72fafc',
          'green',
          'lime',
          '#e03f3f',
          '#a32f2f',
          'red'
        ]
      }]
    };

    return (
      <div className='dashboard-container' >
        <Row gutter={12}>
          <Col md={24}>
            <h1 style={{ color: 'gray', marginLeft: 10, marginTop: 10 }}>SUMMARY REPORT</h1>
          </Col>
        </Row>
        <Row gutter={12} style={{ padding: '5px 0px' }}>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Card className='card-content' style={{ backgroundColor: '#2DCC70' }}>
              <Row>
                <Col md={18} lg={18} sm={18} xs={18} className='summary-title'>
                  <h2>{summaryReportsData.posts || 0} </h2>
                  <h1>TOTAL POSTS</h1>
                </Col>
                <Col md={6} lg={6} sm={6} xs={6} className='summary-icon'>
                  < CopyOutlined />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Card className='card-content' style={{ backgroundColor: '#23B7E5' }}>
              <Row>
                <Col md={18} lg={18} sm={18} xs={18} className='summary-title'>
                  <h2>{summaryReportsData.tenants || 0} </h2>
                  <h1>TOTAL TENANTS</h1>
                </Col>
                <Col md={6} lg={6} sm={6} xs={6} className='summary-icon'>
                  < UserOutlined />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Card className='card-content' style={{ backgroundColor: '#F39C11' }}>
              <Row>
                <Col md={18} lg={18} sm={18} xs={18} className='summary-title'>
                  <h2>{summaryReportsData.requests || 0} </h2>
                  <h1>TOTAL REQUESTS</h1>
                </Col>
                <Col md={6} lg={6} sm={6} xs={6} className='summary-icon'>
                  <FormOutlined />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Card className='card-content' style={{ backgroundColor: '#E84C3D' }}>
              <Row>
                <Col md={18} lg={18} sm={18} xs={18} className='summary-title'>
                  <h2>{summaryReportsData.reports || 0} </h2>
                  <h1>TOTAL REPORTS</h1>
                </Col>
                <Col md={6} lg={6} sm={6} xs={6} className='summary-icon'>
                  <ExclamationCircleOutlined />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row gutter={12} >
          <Col md={24}>
            <h1 style={{ color: 'gray', marginLeft: 10, marginTop: 10 }}>GRAPH REPORT</h1>
          </Col>
        </Row>
        <Row gutter={12} style={{ padding: '5px 0px' }}>
          <Col lg={9} md={9} sm={24} xs={24}>
            <Card style={{ height: '100%' }}>
              <h2>Services</h2>
              <div style={{ marginLeft: 20 }}>
                <p>
                  <span>Description: </span><br />
                  <span style={{ color: 'gray', fontSize: 12 }}>Showing the statuses of all services</span><br />
                  <span style={{ color: 'gray', fontSize: 12 }}>Indicated the count per services and its percentage</span>
                </p>
              </div>
              <Doughnut data={serviceData} />
              <Row style={{ width: '100%' }}>
                <Col span={24}>
                  <h1 style={{ fontSize: 12 }}>Open: <span>{`${summaryRequestsData.open}`}</span> <span style={{ fontStyle: 'italic', fontSize: 11, opacity: 8.9 }}>({((summaryRequestsData.open / summaryRequestsData.all) * 100 || 0).toFixed(2)}%)</span></h1>
                  <Divider style={{ margin: 2 }} />
                  <h1 style={{ fontSize: 12 }}>Close: <span>{`${summaryRequestsData.close}`}</span> <span style={{ fontStyle: 'italic', fontSize: 11, opacity: 8.9 }}>({((summaryRequestsData.close / summaryRequestsData.all) * 100 || 0).toFixed(2)}%)</span></h1>
                  <Divider style={{ margin: 2 }} />
                  <h1 style={{ fontSize: 12 }}>Ongoing: <span>{`${summaryRequestsData.ongoing}`}</span> <span style={{ fontStyle: 'italic', fontSize: 11, opacity: 8.9 }}>({((summaryRequestsData.ongoing / summaryRequestsData.all) * 100 || 0).toFixed(2)}%)</span></h1>
                  <Divider style={{ margin: 2 }} />
                  <h1 style={{ fontSize: 12 }}>Completed: <span>{`${summaryRequestsData.completed}`}</span> <span style={{ fontStyle: 'italic', fontSize: 11, opacity: 8.9 }}>({((summaryRequestsData.completed / summaryRequestsData.all) * 100 || 0).toFixed(2)}%)</span></h1>
                  <Divider style={{ margin: 2 }} />
                  <h1 style={{ fontSize: 12 }}>Cancelled: <span>{`${summaryRequestsData.cancelled}`}</span> <span style={{ fontStyle: 'italic', fontSize: 11, opacity: 8.9 }}>({((summaryRequestsData.cancelled / summaryRequestsData.all) * 100 || 0).toFixed(2)}%)</span></h1>
                  <Divider style={{ margin: 2 }} />
                  <h1 style={{ fontSize: 12 }}>Rejected: <span>{`${summaryRequestsData.rejected}`}</span> <span style={{ fontStyle: 'italic', fontSize: 11, opacity: 8.9 }}>({((summaryRequestsData.rejected / summaryRequestsData.all) * 100 || 0).toFixed(2)}%)</span></h1>
                  <Divider style={{ margin: 2 }} />
                  <h1 style={{ fontSize: 12 }}>Deleted: <span>{`${summaryRequestsData.deleted}`}</span> <span style={{ fontStyle: 'italic', fontSize: 11, opacity: 8.9 }}>({((summaryRequestsData.deleted / summaryRequestsData.all) * 100 || 0).toFixed(2)}%)</span></h1>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={15} md={15} sm={24} xs={24}>
            <Card>
              <h2>Reservation </h2>
              <div style={{ marginLeft: 20 }}>
                <p>
                  <span>Description: </span><br />
                  <span style={{ color: 'gray', fontSize: 12 }}>Number of reservation per month in every year seperated by colors.</span><br />
                  <span style={{ color: 'gray', fontSize: 12 }}>Left side numbers will be the total count of reservation <br /> then the bottom "month" will be the month of the reservation.</span>
                </p>
              </div>
              <Line ref="chart" data={reservationData} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Card>
              <h2>Announcement and Events Analytics </h2>
              <div style={{ marginLeft: 20 }}>
                <p>
                  <span>Description: </span><br />
                  <span style={{ color: 'gray', fontSize: 12 }}>Number of clicks(views) per announcements and events (TOP 10)</span><br />
                  <span style={{ color: 'gray', fontSize: 12 }}>Left side numbers will be the total count of clicks <br /> then the bottom "TITLE" of the ANNOUNCEMENT AND EVENTS.</span>
                </p>
              </div>
              <Line ref="chart" data={postAnalyticsData} options={{}} />
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    summaryPostAnalyticsData: state.admin.dashboard.summaryPostAnalyticsData,
    summaryReportsData: state.admin.dashboard.summaryReportsData,
    summaryRequestsData: state.admin.dashboard.summaryRequestsData,
    summaryReservationsData: state.admin.dashboard.summaryReservationsData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadSummaryReports: (data) => dispatch(loadSummaryReports(data)),
    onLoadSummaryRequests: (data) => dispatch(loadSummaryRequests(data)),
    onLoadSummaryReservations: (data) => dispatch(loadSummaryReservations(data)),
    onLoadSummaryPostsAnalytics: (data) => dispatch(loadSummaryPostsAnalytics(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
