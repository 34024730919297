import React, { useState } from 'react'
import { Button, Row, Col, Modal, Card, message, Spin, Checkbox } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { resetDatabase, DELETE_DATABASE_FULFILLED } from '../../actions'
const DatabaseSettings = (props) => {
    const [loading, setLoading] = useState(false)
    const [tables, setTables] = useState([
        'posts',
        'commendations',
        'policies',
        'policiesHeader',
        'comments',
        'memorandums',
        'permits',
        'rentalInquiries',
        'reports',
        'requests',
        'reservations',
        'tickets',
        'visitors',
        'payments',
        'users',
        'admins',
        'websiteSettings',
        'systemParameterSettings',
        'amenities',
        'advertisements',
        'notifications'
    ])

    function handleResetDatabase() {
        Modal.confirm({
            title: 'Do you Want to reset your database?',
            icon: <ExclamationCircleOutlined />,
            content: 'You cannot undo this.',
            onOk() {
                setLoading(true)
                props.onResetDatabase(tables).then((res) => {
                    if (res.type === DELETE_DATABASE_FULFILLED) {
                        logout()
                    } else {
                        message.error('Unable To Reset Database. Please try again later');
                    }
                    setLoading(false)
                })
            },
            onCancel() { },
        });
    }

    function logout() {
        localStorage.removeItem('SUPER_ADMIN_SESSION_TOKEN')
        props.history.push('/super-admin')
        window.location.reload()
    }

    function onChangeCheckbox(values) {
        setTables(values)
    }

    return (
        <Spin spinning={loading}>
            <Card className='admin-database-settings-container' >
                <Row>
                    <Col span={24}>
                        <div className='warning-message'>
                            <h5>Clicking truncate button will reset all your tables and also data will be deleted.</h5>
                            <h5>Make sure you have downloaded or exported your database for back up.</h5>
                            <h5>You cannot undo the process once you confirm.</h5>
                        </div>
                        <div className='warning-message-logout'>
                            <h5>After truncating your tables you will be redirected to logout. </h5>
                            <h5>and can login again with the credentials given by the system administrator.</h5>
                        </div>

                        <div className='warning-message-logout'>
                            <h5>Selected Tables. </h5>
                        </div>
                        <div className='table-selections'>
                            <Checkbox.Group style={{ width: '100%' }}
                                defaultValue={tables}
                                onChange={onChangeCheckbox}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Checkbox value="posts">ANNOUNCEMENTS AND POSTS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="commendations">COMMENDATIONS AND TESTIMONIALS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="policies">POLICIES</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="policiesHeader">POLICIES HEADER</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="comments">COMMENTS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="memorandums">MEMORANDUMS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="permits">PERMITS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="rentalInquiries">LEASING ASSISTANCE</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="reports">INCIDENT REPORTS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="requests">JOB ORDERS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="reservations">RESERVATIONS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="tickets">GATE PASS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="visitors">VISITORS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="payments">PAYMENTS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="users">CLIENTS/TENANTS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="admins">ADMINS (ADMINS, SUPERADMINS)</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="websiteSettings">WEBSITE SETTINGS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="systemParameterSettings">SYSTEM PARAMETER SETTINGS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="amenities">AMENITIES</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="advertisements">ADVERTISEMENTS</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="notifications">NOTIFICATIONS</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </div>
                        <Button onClick={() => handleResetDatabase()} type="text" danger>Reset Database (Truncate Tables)</Button>
                    </Col>
                </Row>
            </Card>
        </Spin>
    )
}


function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onResetDatabase: (tables) => dispatch(resetDatabase(tables)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatabaseSettings))
