import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker } from 'antd'
import { DownloadOutlined, FileAddOutlined, InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { updateAdmin } from '../../../actions'
import moment from 'moment'
const { Search } = Input;
const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false,
      isApprovedDisabled: false,
      isVerifiedDisabled: false
    }
  }



  handleUpdate(values) {
    const submitData = {
      "id": this.props.selectedRow.id,
      "name": values.name,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "password": values.password,
      "password_confirmation": values.confirm,
      "status": 1,
    }

    this.props.onUpdateAdmin(submitData, this.props.requestStatus)
  }

  render() {
    const { editable, selectedRow } = this.props
    const initialValues = {
      "name": selectedRow.name,
      "firstName": selectedRow.firstName,
      "lastName": selectedRow.lastName,
      "email": selectedRow.email
    }

    const isReadOnly = false // Cant edit if the status is for approval = 1

    return (
      <Modal
        title="EDIT ADMIN"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={false}>

          <Form
            name="basic"
            initialValues={initialValues}
            onFinish={(e) => this.handleUpdate(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: false,
                      message: 'Please input your password!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: false,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

              </Col>

            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button type="primary" htmlType="submit" >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdateAdmin: (data) => dispatch(updateAdmin(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
