import {
  GET_COMMENDATION,
  GET_COMMENDATION_FULFILLED,
  GET_COMMENDATION_REJECTED,
  PUT_COMMENDATION,
  PUT_COMMENDATION_FULFILLED,
  PUT_COMMENDATION_REJECTED,
  POST_COMMENDATION,
  POST_COMMENDATION_FULFILLED,
  POST_COMMENDATION_REJECTED,
  DELETE_COMMENDATION,
  DELETE_COMMENDATION_FULFILLED,
  DELETE_COMMENDATION_REJECTED
} from '../actions'

export default function commendation(state = {
  commendationLoading: false,
  commendationSuccess: false,
  commendationRejected: false,
  commendationData: {
    data: []
  },
  updateCommendationLoading: false,
  updateCommendationSuccess: false,
  updateCommendationRejected: false,
  addCommendationLoading: false,
  addCommendationSuccess: false,
  addCommendationRejected: false,
  deleteCommendationLoading: false,
  deleteCommendationSuccess: false,
  deleteCommendationRejected: false
}, action) {
  switch (action.type) {
    case GET_COMMENDATION:
      return {
        ...state,
        commendationLoading: true,
        commendationSuccess: false,
        commendationRejected: false
      }
    case GET_COMMENDATION_FULFILLED:
      return {
        ...state,
        commendationLoading: false,
        commendationSuccess: true,
        commendationData: action.payload
      }
    case GET_COMMENDATION_REJECTED:
      return {
        ...state,
        commendationLoading: false,
        commendationRejected: true
      }
    case POST_COMMENDATION:
      return {
        ...state,
        addCommendationLoading: true,
        addCommendationSuccess: false,
        addCommendationRejected: false
      }
    case POST_COMMENDATION_FULFILLED:
      return {
        ...state,
        addCommendationLoading: false,
        addCommendationSuccess: true,
        addCommendationData: action.payload
      }
    case POST_COMMENDATION_REJECTED:
      return {
        ...state,
        addCommendationLoading: false,
        addCommendationRejected: true
      }
    case PUT_COMMENDATION:
      return {
        ...state,
        updateCommendationLoading: true,
        updateCommendationSuccess: false,
        updateCommendationRejected: false
      }
    case PUT_COMMENDATION_FULFILLED:
      return {
        ...state,
        updateCommendationLoading: false,
        updateCommendationSuccess: true,
        updateCommendationData: action.payload
      }
    case PUT_COMMENDATION_REJECTED:
      return {
        ...state,
        updateCommendationLoading: false,
        updateCommendationRejected: true
      }

    case DELETE_COMMENDATION:
      return {
        ...state,
        deleteCommendationLoading: true,
        deleteCommendationSuccess: false,
        deleteCommendationRejected: false
      }
    case DELETE_COMMENDATION_FULFILLED:
      return {
        ...state,
        deleteCommendationLoading: false,
        deleteCommendationSuccess: true,
        deleteCommendationData: action.payload
      }
    case DELETE_COMMENDATION_REJECTED:
      return {
        ...state,
        deleteCommendationLoading: false,
        deleteCommendationRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
