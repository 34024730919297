import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Pagination } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import AddModal from './modals/add'
import EditModal from './modals/edit'
import { connect } from 'react-redux'
import { loadAdvertisement, deleteAdvertisement, updateAdvertisement } from '../actions'
import { getParamFromUrl } from '../../../../utilities/helper'
import noAvailableImage from '../../../../assets/images/noavailableimage.png'
import { ImagePresignedUrl } from '../../../../utilities/renderer'

const { Search } = Input;
const { TabPane } = Tabs;

class Advertisements extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      reportType: '0',

      //pagination
      page: 1,
      limit: 10,
      searchTerm: '',

      selectedRow: {},
      tableData: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if ((this.props.advertisementSuccess !== nextProps.advertisementSuccess) && nextProps.advertisementSuccess) {
      this.setState({ isAddModalOpen: false })
      message.success("Advertisement added successfully.")
      this.props.onLoadAdvertisement(pagination)
    }
    if ((this.props.updateAdvertisementSuccess !== nextProps.updateAdvertisementSuccess) && nextProps.updateAdvertisementSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Advertisement edited successfully.")
      this.props.onLoadAdvertisement(pagination)
    }
    if ((this.props.deleteAdvertisementSuccess !== nextProps.deleteAdvertisementSuccess) && nextProps.deleteAdvertisementSuccess) {
      this.setState({ isEditModalOpen: false })
      message.success("Advertisement deleted successfully.")
      this.props.onLoadAdvertisement(pagination)
    }
    if ((this.props.advertisementsSuccess !== nextProps.advertisementsSuccess) && nextProps.advertisementsSuccess) {
      this.setState({ tableData: nextProps.advertisementsData.data })
    }
  }

  componentDidMount() {
    const { limit, searchTerm, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }

    this.props.onLoadAdvertisement(pagination)

    if (getParamFromUrl('modal') === 'true') {
      this.setState({ isEditModalOpen: true })
    }
  }

  addModalToggle() {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
  }

  handleDeleteAdvertisement(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeleteAdvertisement(id)
      },
      onCancel() { },
    });
  }

  onChangePagination(page) {
    const { limit, searchTerm } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    this.props.onLoadAdvertisement(pagination)
    this.setState({ page: page })
  }

  handleSearch(searchTerm) {
    const { limit, page } = this.state
    const pagination = {
      page: page,
      limit: limit,
      searchTerm: searchTerm
    }
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      this.props.onLoadAdvertisement(pagination)
    }, 1000);
    this.setState({ searchTerm: searchTerm })
  }

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  render() {
    const dataSource = this.state.tableData

    const columns = [
      {
        title: 'Image',
        dataIndex: 'images',
        key: 'images',
        render: (images) => {
          return (
            <ImagePresignedUrl src={(images && images[0]) ? `${images[0].url}` : noAvailableImage} style={{ height: 100, width: 150, objectFit: 'cover' }} />
          )
        }
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title && a.title.localeCompare(b.title)
      },
      {
        title: 'Url',
        dataIndex: 'url',
        key: 'url',
        sorter: (a, b) => a.url && a.url.localeCompare(b.url)
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: '',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              {
                this.access('advertisements', 'view') &&
                <Button type="primary" icon={<EditOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })} >View</Button>
              }
              {
                this.access('advertisements', 'delete') &&
                <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeleteAdvertisement(data.id)}  >Delete</Button>
              }
            </div>
          )
        }
      },
    ];
    return (
      <Layout className='advertisements-container'>

        <Layout.Content>
          <div className='advertisement-title'>
            <h1>Advertisements</h1>
          </div>
          <Row className='advertisement-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('advertisements', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Name, Email, Phone Number, Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value)} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            <Table style={{ width: '100%' }} rowKey='id'
              loading={this.props.advertisementsLoading || this.props.deleteAdvertisementLoading || this.props.updateAdvertisementLoading}
              dataSource={dataSource} columns={columns} pagination={false} />
            <Row style={{ marginTop: 30, width: '100%' }}>
              <Col md={24} lg={24} sm={24} xs={24} align="right">
                <Pagination defaultCurrent={this.state.page} onChange={e => this.onChangePagination(e)} total={this.props.advertisementsData.total} />
              </Col>
            </Row>
          </Row>
        </Layout.Content>
        {
          this.state.isAddModalOpen &&
          <AddModal
            visible={this.state.isAddModalOpen}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditModalOpen &&
          <EditModal
            visible={this.state.isEditModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    advertisementLoading: state.admin.advertisement.advertisementLoading,
    advertisementSuccess: state.admin.advertisement.advertisementSuccess,
    updateAdvertisementSuccess: state.admin.advertisement.updateAdvertisementSuccess,
    deleteAdvertisementSuccess: state.admin.advertisement.deleteAdvertisementSuccess,
    advertisementsSuccess: state.admin.advertisement.advertisementsSuccess,
    advertisementsData: state.admin.advertisement.advertisementsData,
    advertisementsLoading: state.admin.advertisement.advertisementsLoading,
    deleteAdvertisementLoading: state.admin.advertisement.deleteAdvertisementLoading,
    updateAdvertisementLoading: state.admin.advertisement.updateAdvertisementLoading,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAdvertisement: (pagination) => dispatch(loadAdvertisement(pagination)),
    onDeleteAdvertisement: (id) => dispatch(deleteAdvertisement(id)),
    onUpdateStatus: (data) => dispatch(updateAdvertisement(data)),


  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Advertisements))
