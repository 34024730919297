import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { submitIncidentReport } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import { loadTenant, unLoadTenant } from '../../../tenants/actions'
import ImageUploader from './imageBulkUploader'
import VideoUploader from './videoUploader'
import { upload } from '../../../../../utilities/filesController'

const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      fileImage: undefined,
      fileVideo: undefined,
      fileImageUploaded: false,
      fileVideoUploaded: false,
      fileImageList: [],
      fileVideoList: [],
    }
    this.timeout = 0;
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": values.tenantId,
      "postById": getUser().id,
      "title": values.title,
      "description": values.description,
      "comment": values.comment,
      "images": this.state.fileImageList,
      "video": this.state.fileVideoList,
      "status": 1
    }

    Promise.all([
      this.uploadFileImage(),
      this.uploadFileVideo()
    ]).then((res) => {
      const isAllResultTrue = res.every(data => data === true)
      if (isAllResultTrue) {
        this.props.onSubmitServinceRequest(submitData)
        this.setState({ isLoading: false })
      } else {
        Modal.error({
          title: 'Unable to submit incident report',
          content: 'ERROR',
        });
        this.setState({ isLoading: false })
      }
    })
  }

  getFileImage(e) {
    const list = e.map(data => {
      return { name: data.name, url: `incidentreports/${data.name}` }
    })
    this.setState({ fileImageList: list })
    this.setState({ fileImage: e })
  }

  getFileVideo(e) {
    const list = e.map(data => {
      return { name: data.name, url: `videos/${data.name}` }
    })
    this.setState({ fileVideoList: list })
    this.setState({ fileVideo: e })
  }

  uploadFileImage() {
    return new Promise((resolve, reject) => {
      if (this.state.fileImage) {
        this.state.fileImage.map((items, index) => {
          upload(items, items.name, 'incidentreports').then(data => {
            return resolve(true)
          }).catch(err => {
            return Modal.error({
              title: 'This is an error message for uploading images',
              content: err,
            });
          })
        })
      } else {
        return resolve(true)
      }
    })
  }

  uploadFileVideo() {
    return new Promise((resolve, reject) => {
      if (this.state.fileVideo) {
        upload(this.state.fileVideo[0], this.state.fileVideo[0].name, 'videos').then(data => {
          return resolve(true)
        }).catch(err => {
          return Modal.error({
            title: 'This is an error message in Soa Web Client',
            content: err,
          });
        })
      } else {
        return resolve(true)
      }
    })
  }

  tenantSearch(value) {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      //search function
      const pagination = {
        page: 1,
        limit: 0,
        searchTerm: value
      }
      if (value === '') {
        this.props.onUnloadTenant()
      } else {
        this.props.onLoadTenant(pagination)
      }
    }, 700);
  }

  render() {
    return (
      <Modal
        title="ADD INCIDENT REPORT"
        centered
        visible={this.props.visible}
        onCancel={() => this.props.onCancel()}
        width={1000}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            onFinish={(e) => this.handleSubmit(e)}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    onSearch={(value) => this.tenantSearch(value)}
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname} (${data.clientCode})`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  rules={[{ required: true, message: 'Please input your description!' }]}
                >
                  <Input.TextArea rows={5} />
                </Form.Item>
              </Col>

            </Row>

            <Row gutter={12}>
              <Col lg={12} md={12} xs={24} sm={24}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Upload Maximum of 3 (Three) Images</span>
                </div>
                <ImageUploader
                  value={this.props.selectedRow}
                  getFile={e => this.getFileImage(e)}
                />
                <span style={{ fontSize: 10, color: 'red' }}>Multiple file (at least 3 images)</span>
              </Col>

              <Col lg={12} md={12} xs={24} sm={24}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Upload 1(one) video of incident</span>
                </div>
                <VideoUploader
                  value={this.props.selectedRow}
                  getFile={e => this.getFileVideo(e)}
                />
                <span style={{ fontSize: 10, color: 'red' }}>Single file Only</span>
              </Col>
            </Row>

            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button type="primary" htmlType="submit" loading={this.props.incidentReportLoading || this.state.isLoading}>
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    incidentReportLoading: state.admin.incidentReport.incidentReportLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitServinceRequest: (data) => dispatch(submitIncidentReport(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data)),
    onUnloadTenant: () => dispatch(unLoadTenant())
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}