import {
  GET_TENANT,
  GET_TENANT_FULFILLED,
  GET_TENANT_REJECTED,
  POST_PAYMENT,
  POST_PAYMENT_FULFILLED,
  POST_PAYMENT_REJECTED,
  GET_PAYMENT_TRANSACTION,
  GET_PAYMENT_TRANSACTION_FULFILLED,
  GET_PAYMENT_TRANSACTION_REJECTED,
  PUT_UPDATE_DRAGON_PAY_STATUS,
  PUT_UPDATE_DRAGON_PAY_STATUS_FULFILLED,
  PUT_UPDATE_DRAGON_PAY_STATUS_REJECTED,
  PUT_UPDATE_AQWIRE_STATUS,
  PUT_UPDATE_AQWIRE_STATUS_FULFILLED,
  PUT_UPDATE_AQWIRE_STATUS_REJECTED
} from '../actions'

export default function billings(state = {
  tenantLoading: false,
  tenantSuccess: false,
  tenantRejected: false,
  tenantData: [],
  paymentLoading: false,
  paymentSuccess: false,
  paymentRejected: false,
  paymentTransactionsLoading: false,
  paymentTransactionsSuccess: false,
  paymentTransactionsRejected: false,
  paymentTransactionsData: {
    data: []
  },
  updateDragonPayStatusLoading: false,
  updateDragonPayStatusSuccess: false,
  updateDragonPayStatusRejected: false,
  updateAQWireStatusLoading: false,
  updateAQWireStatusSuccess: false,
  updateAQWireStatusData: {
    data: []
  },
  updateAQWireStatusRejected: false
}, action) {
  switch (action.type) {
    case GET_TENANT:
      return {
        ...state,
        tenantLoading: true,
        tenantSuccess: false,
        tenantRejected: false
      }
    case GET_TENANT_FULFILLED:
      return {
        ...state,
        tenantLoading: false,
        tenantSuccess: true,
        tenantData: action.payload
      }
    case GET_TENANT_REJECTED:
      return {
        ...state,
        tenantLoading: false,
        tenantRejected: true
      }
    case POST_PAYMENT:
      return {
        ...state,
        paymentLoading: true,
        paymentSuccess: false,
        paymentRejected: false
      }
    case POST_PAYMENT_FULFILLED:
      return {
        ...state,
        paymentLoading: false,
        paymentSuccess: true,
      }
    case POST_PAYMENT_REJECTED:
      return {
        ...state,
        paymentLoading: false,
        paymentRejected: true
      }
    case GET_PAYMENT_TRANSACTION:
      return {
        ...state,
        paymentTransactionsLoading: true,
        paymentTransactionsSuccess: false,
        paymentTransactionsRejected: false
      }
    case GET_PAYMENT_TRANSACTION_FULFILLED:
      return {
        ...state,
        paymentTransactionsLoading: false,
        paymentTransactionsSuccess: true,
        paymentTransactionsData: action.payload,
      }
    case GET_PAYMENT_TRANSACTION_REJECTED:
      return {
        ...state,
        paymentTransactionsLoading: false,
        paymentTransactionsRejected: true
      }

    case PUT_UPDATE_DRAGON_PAY_STATUS:
      return {
        ...state,
        updateDragonPayStatusLoading: true,
        updateDragonPayStatusSuccess: false,
        updateDragonPayStatusRejected: false
      }
    case PUT_UPDATE_DRAGON_PAY_STATUS_FULFILLED:
      return {
        ...state,
        updateDragonPayStatusLoading: false,
        updateDragonPayStatusSuccess: true,
        updateDragonPayStatusData: action.payload,
      }
    case PUT_UPDATE_DRAGON_PAY_STATUS_REJECTED:
      return {
        ...state,
        updateDragonPayStatusLoading: false,
        updateDragonPayStatusRejected: true
      }

    case PUT_UPDATE_AQWIRE_STATUS:
      return {
        ...state,
        updateAQWireStatusLoading: true,
        updateAQWireStatusSuccess: false,
        updateAQWireStatusRejected: false
      }
    case PUT_UPDATE_AQWIRE_STATUS_FULFILLED:
      return {
        ...state,
        updateAQWireStatusLoading: false,
        updateAQWireStatusSuccess: true,
        updateAQWireStatusData: action.payload,
      }
    case PUT_UPDATE_AQWIRE_STATUS_REJECTED:
      return {
        ...state,
        updateAQWireStatusLoading: false,
        updateAQWireStatusRejected: true
      }

    default:
      return {
        ...state
      }
  }
}
