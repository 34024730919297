import React from 'react'
import './index.scss'
import { Row, Col, Layout, Table, Button, Input, message, Modal, Tabs, Collapse, Divider } from 'antd'
import { ExclamationCircleOutlined, FileAddOutlined, EditOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import AddModal from './modals/header/add'
import EditModal from './modals/header/edit'

import AddPolicyHeaderModal from './modals/policies/add'
import EditPolicyHeaderModal from './modals/policies/edit'
import { get } from '../../../../utilities/filesController'
import { connect } from 'react-redux'
import { loadPolicy, deletePolicyHeader, deletePolicy, loadPolicyHeader, updatePolicyHeaderSequence } from '../actions'
import arrayMove from 'array-move';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const { Panel } = Collapse;
const { Search } = Input;
const { TabPane } = Tabs;

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class Policies extends React.Component {
  constructor() {
    super()
    this.state = {
      isAddPolicyHeaderModalOpen: false,
      isEditPolicyHeaderModalOpen: false,
      isAddPolicyModalOpen: false,
      isEditPolicyModalOpen: false,
      selectedRow: {},
      dataSource: [],
      currentPage: 1, expandedRows: []
    }
    this.addModalToggle = this.addModalToggle.bind(this)
    this.editModalToggle = this.editModalToggle.bind(this)

    this.addPolicyModalToggle = this.addPolicyModalToggle.bind(this)
    this.editPolicyModalToggle = this.editPolicyModalToggle.bind(this)

  }


  componentWillReceiveProps(nextProps) {
    if ((this.props.policiesHeaderSuccess !== nextProps.policiesHeaderSuccess) && nextProps.policiesHeaderSuccess) {
      this.setState({ isAddPolicyHeaderModalOpen: false })
      // message.success("Policy added successfully.")
    }
    if ((this.props.updatePolicyHeaderSuccess !== nextProps.updatePolicyHeaderSuccess) && nextProps.updatePolicyHeaderSuccess) {
      this.setState({ isEditPolicyHeaderModalOpen: false })
      message.success("Policy edited successfully.")
    }
    if ((this.props.policySuccess !== nextProps.policySuccess) && nextProps.policySuccess) {
      this.setState({ isAddPolicyHeaderModalOpen: false, isAddPolicyModalOpen: false })
      message.success("Policy added successfully.")
    }
    if ((this.props.updatePolicySuccess !== nextProps.updatePolicySuccess) && nextProps.updatePolicySuccess) {
      this.setState({ isEditPolicyHeaderModalOpen: false })
      message.success("Policy edited successfully.")
    }

    if ((this.props.deletePolicySuccess !== nextProps.deletePolicySuccess) && nextProps.deletePolicySuccess) {
      this.setState({ isEditPolicyHeaderModalOpen: false })
      message.success("Policy deleted successfully.")
    }
    if ((this.props.policiesHeaderSuccess !== nextProps.policiesHeaderSuccess) && nextProps.policiesHeaderSuccess) {
      this.setState({ dataSource: nextProps.policiesHeaderData })
    }
  }

  componentDidMount() {
    this.props.onLoadPolicyHeader()
  }

  addModalToggle() {
    this.setState({ isAddPolicyHeaderModalOpen: !this.state.isAddPolicyHeaderModalOpen })
  }

  editModalToggle() {
    this.setState({ isEditPolicyHeaderModalOpen: !this.state.isEditPolicyHeaderModalOpen })
  }


  addPolicyModalToggle() {
    this.setState({ isAddPolicyModalOpen: !this.state.isAddPolicyModalOpen })
  }

  editPolicyModalToggle() {
    this.setState({ isEditPolicyModalOpen: !this.state.isEditPolicyModalOpen })
  }


  handleDeletePolicyHeader(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeletePolicyHeader(id)
      },
      onCancel() { },
    });
  }

  handleDeletePolicy(id) {
    const self = this
    Modal.confirm({
      title: 'Do you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'You cannot undo this.',
      onOk() {
        self.props.onDeletePolicy(id)
      },
      onCancel() { },
    });
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch(searchTerm, searchKeys) {
    // function inside function, this will convert the dot string into actual object
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split('.')
      while (arr.length && (obj = obj[arr.shift()]));
      return obj
    }

    const newArrayObj = this.props.policiesHeaderData
    // if SearchTerm is Empty return original data.
    if (!searchTerm) {
      return this.setState({ dataSource: newArrayObj })
    }
    const distributorArray = []
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach(keys => {
        const rowItemKeys = convertStringDotNotation(keys, { ...rowItem })
        const isFound = (rowItemKeys) && rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase())
        if (isFound) distributorArray.push(rowItem)
      })
    })
    // Removed Duplicate Entry in Array.
    const newArrayElement = distributorArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem)
    })
    this.setState({ dataSource: newArrayElement })
  }

  updateRowSequence(data) {
    const newArray = []
    data.map((item, index) => {
      newArray.push({ id: item.id, sequenceNumber: index + 1 })
    })
    this.props.onUpdatePolicyHeaderSequence({ sequence: newArray })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { dataSource } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      this.updateRowSequence(newData)
      this.setState({ dataSource: newData });
    }
  };

  DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={this.onSortEnd}
      {...props}
    />
  );

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  access(title, role) {
    if (this.props.adminData.roles.length === 0) return false
    const found = this.props.adminData.roles.find(data => data.title === title)
    if (found !== -1) return (found.roles[role]) ? true : false
    else return false
  }

  async urlOpen(pathname) {
    const url = await get(pathname)
    window.open(url);
  }

  render() {
    const dataSource = this.state.dataSource
    const columns = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: 'Header',
        dataIndex: 'header',
        className: 'drag-visible',
        key: 'header',
        sorter: (a, b) => a.header && a.header.localeCompare(b.header)
      },
      {
        title: 'Policies',
        dataIndex: 'policies',
        className: 'policies',
        key: 'policies',
        width: 900,
        render: (item, row) => {
          return (
            <div>
              {/* <Row>
                <Col md={24} lg={24} sm={24} xs={24} align='right'>
                
                </Col>
              </Row> */}
              <Collapse bordered={false} ghost>
                <Panel header={
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h1>List</h1>
                    {
                      this.access('policies', 'edit') &&
                      <Button type="primary" icon={<FileAddOutlined />} size={'medium'} title='Edit' onClick={() => this.setState({ selectedRow: row, isAddPolicyModalOpen: true })}   ></Button>
                    }
                  </div>
                } key="1">
                  {
                    item.map(data => {
                      return (
                        <>
                          <Row>
                            <Col md={12} lg={12} xs={24} sm={24}>
                              <h1>Sub Header: {data.subHeader}</h1>
                              <span>Title: {data.title}</span>
                            </Col>
                            <Col md={9} lg={9} xs={24} sm={24}>
                              <h1>Files</h1>
                              {data.files && data.files.map(data => <><a onClick={() => this.urlOpen(data.url)} >{data.name}</a><br /></>)}
                            </Col>
                            <Col md={3} lg={3} xs={24} sm={24}>
                              <div className='action-btn'>
                                {
                                  this.access('policies', 'edit') &&
                                  <Button type="primary" icon={<EditOutlined />} size={'medium'} title='Edit'
                                    onClick={() => this.setState({ selectedRow: data, isEditPolicyModalOpen: true })}
                                  ></Button>
                                }
                                {
                                  this.access('policies', 'delete') &&
                                  <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger
                                    onClick={() => this.handleDeletePolicy(data.id)}
                                  ></Button>
                                }

                              </div>
                            </Col>
                          </Row>
                          <Divider style={{ margin: 5 }} />
                        </>
                      )
                    })
                  }
                </Panel>
              </Collapse>

            </div>
          )
        }
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        render: (info, data) => {
          return (
            <div className='action-btn'>
              {
                this.access('policies', 'view') &&
                <Button type="primary" icon={<EditOutlined />} size={'medium'} title='Edit' onClick={() => this.setState({ selectedRow: data, isEditPolicyHeaderModalOpen: true })} >View</Button>
              }
              {
                this.access('policies', 'delete') &&
                <Button type="primary" icon={<DeleteOutlined />} size={'medium'} title='Delete' danger onClick={() => this.handleDeletePolicyHeader(data.id)}  >Delete</Button>
              }
            </div>
          )
        }
      },
    ];

    return (

      <Layout className='policies-container'>

        <Layout.Content>
          <div className='policy-title'>
            <h1>House Rules & Policies Module</h1>
          </div>
          <Row className='policy-header'>
            <Col md={14} lg={14} sm={24} >
              <div className='action-btn'>
                {
                  this.access('policies', 'add') &&
                  <Button type="primary" shape="circle" icon={<FileAddOutlined />} size={'large'} title='Add' onClick={() => this.addModalToggle()} />
                }
              </div>
            </Col>
            <Col md={10} lg={10} sm={24} className='input-search'>
              <Search placeholder="Search Header, Sub Header, Title Etc..." className='search' onChange={(e) => this.handleSearch(e.target.value, ['header', 'subHeader', 'description', 'title', 'status'])} style={{ width: 400 }} />
            </Col>
          </Row>
          <Row>
            {/* <Table style={{ width: '100%' }} rowKey='id' loading={this.props.policiesLoading || this.props.deletePolicyLoading} dataSource={dataSource} columns={columns} /> */}
            {/* <Table
              style={{ width: '100%' }}
              columns={columns}
              expandable={{
                expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
                rowExpandable: record => record.name !== 'Not Expandable',
              }}
              dataSource={dataSource}
            /> */}

            <Table
              loading={this.props.policiesLoading || this.props.deletePolicyLoading || this.props.policiesHeaderLoading}
              style={{ width: '100%' }}
              pagination={false}
              dataSource={dataSource}
              columns={columns}
              rowKey={(record) => record.id}
              components={{
                body: {
                  wrapper: this.DraggableContainer,
                  row: this.DraggableBodyRow,
                },
              }}
            />
          </Row>
        </Layout.Content>
        {
          this.state.isAddPolicyHeaderModalOpen &&
          <AddModal
            visible={this.state.isAddPolicyHeaderModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.addModalToggle}
          />
        }
        {
          this.state.isEditPolicyHeaderModalOpen &&
          <EditModal
            visible={this.state.isEditPolicyHeaderModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editModalToggle}
          />
        }

        {
          this.state.isAddPolicyModalOpen &&
          <AddPolicyHeaderModal
            selectedRow={this.state.selectedRow}
            visible={this.state.isAddPolicyModalOpen}
            onCancel={this.addPolicyModalToggle}
          />
        }
        {
          this.state.isEditPolicyModalOpen &&
          <EditPolicyHeaderModal
            visible={this.state.isEditPolicyModalOpen}
            selectedRow={this.state.selectedRow}
            onCancel={this.editPolicyModalToggle}
          />
        }
      </Layout>
    )
  }
}

function mapStateToProps(state) {
  return {
    policyLoading: state.admin.policies.policyLoading,
    policySuccess: state.admin.policies.policySuccess,
    updatePolicySuccess: state.admin.policies.updatePolicySuccess,
    deletePolicySuccess: state.admin.policies.deletePolicySuccess,
    policiesSuccess: state.admin.policies.policiesSuccess,
    policiesData: state.admin.policies.policiesData,
    policiesLoading: state.admin.policies.policiesLoading,
    deletePolicyLoading: state.admin.policies.deletePolicyLoading,
    policiesHeaderData: state.admin.policies.policiesHeaderData,
    policiesHeaderSuccess: state.admin.policies.policiesHeaderSuccess,
    policiesHeaderLoading: state.admin.policies.policiesHeaderLoading,
    updatePolicyHeaderLoading: state.admin.policies.updatePolicyHeaderLoading,
    updatePolicyHeaderSuccess: state.admin.policies.updatePolicyHeaderSuccess,
    adminData: state.admin.utilities.adminData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadPolicy: (data) => dispatch(loadPolicy(data)),
    onLoadPolicyHeader: (data) => dispatch(loadPolicyHeader(data)),
    onDeletePolicy: (id) => dispatch(deletePolicy(id)),
    onDeletePolicyHeader: (id) => dispatch(deletePolicyHeader(id)),
    onUpdatePolicyHeaderSequence: (data) => dispatch(updatePolicyHeaderSequence(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Policies))
