import React, { Component, Fragment } from 'react'
import { Form, Input, Button, Row, Col, Checkbox, Modal } from 'antd'
import { connect } from 'react-redux'
import { submitLogin, loadTermsAndCondition } from '../actions'
import './index.scss'
import TermsAndConditions from './termsAndConditions'
import Maintenance from '../../maintenance/components'

const APP_STORAGE = process.env.REACT_APP_STORAGE
class Login extends Component {
  constructor() {
    super()
    this.state = {
      isAgree: false,
      showTermsAndConditions: false
    }
  }

  componentDidMount() {
    this.props.onLoadTermsAndCondition()
  }

  handleSubmit(e) {
    this.props.onSubmitLogin(e)
  }

  getValueInWebsiteSettings(name) {
    const found = this.props.websiteSettingsData.find(data => data.name === name)
    if (found) return found.value
    return ''
  }

  handleAgree() {
    this.setState({ isAgree: !this.state.isAgree })
  }

  handleViewTermsAndConditions(e) {
    e.preventDefault()
    this.setState({ showTermsAndConditions: !this.state.showTermsAndConditions })
  }

  render() {
    console.log("this.state.isAgree", this.state.isAgree)
    const { loggingIn, loginRejected } = this.props
    return (
      <Fragment>
        <Row justify='center'>
          <Col lg={12} md={12} sm={24} md={24} className='left-panel'>
            {
              this.getValueInWebsiteSettings('isUnderMaintenance') === '0' ?
                <div className='login-container'>
                  <div className='login-form'>
                    <div className='title-header'>
                      <h1>Log in</h1>
                    </div>
                    {loginRejected &&
                      <div className='login-error-message'>
                        <span>Login Failed: Invalid Credentials</span>
                      </div>}
                    <Form
                      name='basic'
                      initialValues={{ remember: true }}
                      onFinish={(e) => this.handleSubmit(e)}
                    >
                      <h4>Client Code</h4>
                      <Form.Item
                        name='clientCode'
                        rules={[{ required: true, message: 'Please input your client code!' }]}
                      >
                        <Input className='primary-input' />
                      </Form.Item>

                      <h4>Password</h4>
                      <Form.Item
                        name='password'
                        rules={[{ required: true, message: 'Please input your password!' }]}
                      >
                        <Input.Password className='primary-input' />
                      </Form.Item>

                      <Checkbox checked={this.state.isAgree} onChange={() => this.handleAgree()}>Check here to indicate that you have read and <br /> agree to the<span onClick={e => this.handleViewTermsAndConditions(e)}><a> Terms and Conditions</a></span></Checkbox>

                      <Form.Item>
                        <div style={{ display: 'flex' }}>
                          <Button type='primary' style={{ width: '100%', marginTop: 10 }} disabled={!this.state.isAgree} className='primary-btn' loading={loggingIn} htmlType='submit'>
                            Log in
                          </Button>
                        </div>
                      </Form.Item>

                      <div className='login-reminder'>
                        <p>Your login details are provided to you via your registered email <br /> address at the Property Management Office (PMO)</p>
                        <p>Forgot your password? Contact the PMO for assistance.</p>
                      </div>
                    </Form>
                  </div>
                </div> :
                <Maintenance message={`${this.getValueInWebsiteSettings('maintenanceMessage')}`}
                  loading={this.props.websiteSettingsLoading}
                />
            }
          </Col>
          <Col lg={12} md={12} sm={24} md={24} className='right-panel' style={{ backgroundImage: `url(${this.getValueInWebsiteSettings('websiteLoginBackgroundImage')})` }}>
            <div className='website-name'>
              <h1>{`${this.getValueInWebsiteSettings('websiteName')}`} </h1>
              <p>YOUR PROPERTY MANAGEMENT PORTAL</p>
            </div>
          </Col>
        </Row>
        <Modal title="Terms and Conditions" visible={this.state.showTermsAndConditions} width={600} onCancel={() => this.setState({ showTermsAndConditions: false })} footer={null}>
          <TermsAndConditions />
        </Modal>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    loginRejected: state.client.login.loginRejected,
    loggingIn: state.client.login.loggingIn,
    websiteSettingsData: state.client.websiteSettings.websiteSettingsData,
    websiteSettingsLoading: state.client.websiteSettings.websiteSettingsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitLogin: (data) => dispatch(submitLogin(data)),
    onLoadTermsAndCondition: () => dispatch(loadTermsAndCondition()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
