import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_SERVICES = 'GET_SERVICES'
export const GET_SERVICES_FULFILLED = 'GET_SERVICES_FULFILLED'
export const GET_SERVICES_REJECTED = 'GET_SERVICES_REJECTED'

export const PUT_SERVICES = 'PUT_SERVICES'
export const PUT_SERVICES_FULFILLED = 'PUT_SERVICES_FULFILLED'
export const PUT_SERVICES_REJECTED = 'PUT_SERVICES_REJECTED'

export const PUT_SOA_INFO = 'PUT_SOA_INFO'
export const PUT_SOA_INFO_FULFILLED = 'PUT_SOA_INFO_FULFILLED'
export const PUT_SOA_INFO_REJECTED = 'PUT_SOA_INFO_REJECTED'

export const GET_ADMINS = 'GET_ADMINS'
export const GET_ADMINS_FULFILLED = 'GET_ADMINS_FULFILLED'
export const GET_ADMINS_REJECTED = 'GET_ADMINS_REJECTED'

export const GET_ADMIN = 'GET_ADMIN'
export const GET_ADMIN_FULFILLED = 'GET_ADMIN_FULFILLED'
export const GET_ADMIN_REJECTED = 'GET_ADMIN_REJECTED'

export const PUT_ADMINS = 'PUT_ADMINS'
export const PUT_ADMINS_FULFILLED = 'PUT_ADMINS_FULFILLED'
export const PUT_ADMINS_REJECTED = 'PUT_ADMINS_REJECTED'

export const POST_ADMINS = 'POST_ADMINS'
export const POST_ADMINS_FULFILLED = 'POST_ADMINS_FULFILLED'
export const POST_ADMINS_REJECTED = 'POST_ADMINS_REJECTED'

export const DELETE_ADMIN = 'DELETE_ADMIN'
export const DELETE_ADMIN_FULFILLED = 'DELETE_ADMIN_FULFILLED'
export const DELETE_ADMIN_REJECTED = 'DELETE_ADMIN_REJECTED'

export const DELETE_DATABASE = 'DELETE_DATABASE'
export const DELETE_DATABASE_FULFILLED = 'DELETE_DATABASE_FULFILLED'
export const DELETE_DATABASE_REJECTED = 'DELETE_DATABASE_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadServices = (data) => {
  return dispatch => {
    dispatch({
      type: GET_SERVICES,
      payload: {}
    })
    return Axios.get(`${API_URL}/system-parameter-settings`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_SERVICES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_SERVICES_REJECTED,
          payload: error
        })
      })
  }
}

export const loadAdmins = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_ADMINS,
      payload: {}
    })
    return Axios.get(`${API_URL}/user-admins?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ADMINS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ADMINS_REJECTED,
          payload: error
        })
      })
  }
}

export const loadAdmin = (id) => {
  return dispatch => {
    dispatch({
      type: GET_ADMIN,
      payload: {}
    })
    return Axios.get(`${API_URL}/user-admin/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_ADMIN_REJECTED,
          payload: error
        })
      })
  }
}

export const submitAdmin = (data) => {
  return dispatch => {
    dispatch({
      type: POST_ADMINS,
      payload: {}
    })
    return Axios.post(`${API_URL}/user-admin`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_ADMINS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_ADMINS_REJECTED,
          payload: error
        })
      })
  }
}

export const updateAdmin = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_ADMINS,
      payload: {}
    })
    return Axios.put(`${API_URL}/user-admin/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_ADMINS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_ADMINS_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteAdmin = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_ADMIN,
      payload: {}
    })
    return Axios.delete(`${API_URL}/user-admin/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_ADMIN_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_ADMIN_REJECTED,
          payload: error
        })
      })
  }
}

export const updateServices = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_SERVICES,
      payload: {}
    })
    return Axios.post(`${API_URL}/system-parameter-setting`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_SERVICES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_SERVICES_REJECTED,
          payload: error
        })
      })
  }
}

export const updateSoaMasterFileOfClient = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_SOA_INFO,
      payload: {}
    })
    return Axios.put(`${API_URL}/tenant-soa-info`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_SOA_INFO_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_SOA_INFO_REJECTED,
          payload: error
        })
      })
  }
}


export const resetDatabase = (tables) => {
  return dispatch => {
    dispatch({
      type: DELETE_DATABASE,
      payload: {}
    })
    return Axios.delete(`${API_URL}/reset-data?tables=${tables.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: DELETE_DATABASE_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_DATABASE_REJECTED,
          payload: error
        })
      })
  }
}








