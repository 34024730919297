import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_TICKET = 'POST_TICKET'
export const POST_TICKET_FULFILLED = 'POST_TICKET_FULFILLED'
export const POST_TICKET_REJECTED = 'POST_TICKET_REJECTED'

export const GET_TICKETS = 'GET_TICKETS'
export const GET_TICKETS_FULFILLED = 'GET_TICKETS_FULFILLED'
export const GET_TICKETS_REJECTED = 'GET_TICKETS_REJECTED'

export const GET_TICKET = 'GET_TICKET'
export const GET_TICKET_FULFILLED = 'GET_TICKET_FULFILLED'
export const GET_TICKET_REJECTED = 'GET_TICKET_REJECTED'

export const PUT_TICKET = 'PUT_TICKET'
export const PUT_TICKET_FULFILLED = 'PUT_TICKET_FULFILLED'
export const PUT_TICKET_REJECTED = 'PUT_TICKET_REJECTED'

export const DELETE_TICKET = 'DELETE_TICKET'
export const DELETE_TICKET_FULFILLED = 'DELETE_TICKET_FULFILLED'
export const DELETE_TICKET_REJECTED = 'DELETE_TICKET_REJECTED'

export const GET_SELECTED_KEYS_GATEPASS = 'GET_SELECTED_KEYS_GATEPASS'
export const GET_SELECTED_KEYS_GATEPASS_FULFILLED = 'GET_SELECTED_KEYS_GATEPASS_FULFILLED'
export const GET_SELECTED_KEYS_GATEPASS_REJECTED = 'GET_SELECTED_KEYS_GATEPASS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadTicket = (status, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_TICKETS,
      payload: {}
    })
    return Axios.get(`${API_URL}/tickets?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&status=${status}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_TICKETS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_TICKETS_REJECTED,
          payload: error
        })
      })
  }
}
export const loadTicketById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_TICKET,
      payload: {}
    })
    return Axios.get(`${API_URL}/ticket/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_TICKET_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_TICKET_REJECTED,
          payload: error
        })
      })
  }
}
export const submitTicket = (data, ticketType) => {
  return dispatch => {
    dispatch({
      type: POST_TICKET,
      payload: {}
    })
    return Axios.post(`${API_URL}/ticket`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_TICKET_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_TICKET_REJECTED,
          payload: error
        })
      })
  }
}

export const updateTicket = (data, ticketType) => {
  return dispatch => {
    dispatch({
      type: PUT_TICKET,
      payload: {}
    })
    return Axios.put(`${API_URL}/ticket/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: PUT_TICKET_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: PUT_TICKET_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteTicket = (id, ticketType) => {
  return dispatch => {
    dispatch({
      type: DELETE_TICKET,
      payload: {}
    })
    return Axios.delete(`${API_URL}/ticket/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_TICKET_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_TICKET_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSelectedRowGatePass = (selectedKeys) => {
  return dispatch => {
    dispatch({
      type: GET_SELECTED_KEYS_GATEPASS,
      payload: {}
    })
    return Axios.get(`${API_URL}/ticketIds?selectedIds=${selectedKeys.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SELECTED_KEYS_GATEPASS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SELECTED_KEYS_GATEPASS_REJECTED,
          payload: error
        })
      })
  }
}




