import {
  POST_COMMENDATION,
  POST_COMMENDATION_FULFILLED,
  POST_COMMENDATION_REJECTED,
  GET_COMMENDATIONS,
  GET_COMMENDATIONS_FULFILLED,
  GET_COMMENDATIONS_REJECTED,
  PUT_COMMENDATION,
  PUT_COMMENDATION_FULFILLED,
  PUT_COMMENDATION_REJECTED,
  DELETE_COMMENDATION,
  DELETE_COMMENDATION_FULFILLED,
  DELETE_COMMENDATION_REJECTED
} from '../actions'

export default function commendations(state = {
  commendationLoading: false,
  commendationSuccess: false,
  commendationRejected: false,
  commendationData: [],
  commendationsLoading: false,
  commendationsSuccess: false,
  commendationsRejected: false,
  commendationsData: [],
  updateCommendationLoading: false,
  updateCommendationSuccess: false,
  updateCommendationRejected: false,
  updateCommendationData: [],
  deleteCommendationLoading: false,
  deleteCommendationSuccess: false,
  deleteCommendationRejected: false,
  deleteCommendationData: []
}, action) {
  switch (action.type) {
    case POST_COMMENDATION:
      return {
        ...state,
        commendationLoading: true,
        commendationSuccess: false,
        commendationRejected: false
      }
    case POST_COMMENDATION_FULFILLED:
      return {
        ...state,
        commendationLoading: false,
        commendationSuccess: true,
        commendationData: action.payload
      }
    case POST_COMMENDATION_REJECTED:
      return {
        ...state,
        commendationLoading: false,
        commendationRejected: true
      }
    case GET_COMMENDATIONS:
      return {
        ...state,
        commendationsLoading: true,
        commendationsSuccess: false,
        commendationsRejected: false
      }
    case GET_COMMENDATIONS_FULFILLED:
      return {
        ...state,
        commendationsLoading: false,
        commendationsSuccess: true,
        commendationsData: action.payload
      }
    case GET_COMMENDATIONS_REJECTED:
      return {
        ...state,
        commendationsLoading: false,
        commendationsRejected: true
      }
    case PUT_COMMENDATION:
      return {
        ...state,
        updateCommendationLoading: true,
        updateCommendationSuccess: false,
        updateCommendationRejected: false
      }
    case PUT_COMMENDATION_FULFILLED:
      return {
        ...state,
        updateCommendationLoading: false,
        updateCommendationSuccess: true,
        updateCommendationData: action.payload
      }
    case PUT_COMMENDATION_REJECTED:
      return {
        ...state,
        updateCommendationLoading: false,
        updateCommendationRejected: true
      }
    case DELETE_COMMENDATION:
      return {
        ...state,
        deleteCommendationLoading: true,
        deleteCommendationSuccess: false,
        deleteCommendationRejected: false
      }
    case DELETE_COMMENDATION_FULFILLED:
      return {
        ...state,
        deleteCommendationLoading: false,
        deleteCommendationSuccess: true,
        deleteCommendationData: action.payload
      }
    case DELETE_COMMENDATION_REJECTED:
      return {
        ...state,
        deleteCommendationLoading: false,
        deleteCommendationRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
