
import React from 'react'
import { Card, Button, Table, Modal } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { CloudDownloadOutlined, RetweetOutlined, EyeOutlined } from '@ant-design/icons';
import { loadTenant } from '../actions'
import { getUser } from '../../../../utilities/token'
import { formatMoney } from '../../../../utilities/helper'
import { get } from '../../../../utilities/filesController'

import './index.scss'
import { Fragment } from 'react';
import EditModal from './modals/edit'
class Bill extends React.Component {
    constructor() {
        super()
        this.state = {
            isEditModalOpen: false,
            selectedRow: {}
        }
        this.editModalToggle = this.editModalToggle.bind(this)
    }

    editModalToggle() {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
    }

    componentDidMount() {
        this.props.onLoadTenant(getUser().id)
    }

    payNow() {
        Modal.confirm({
            title: 'PAYMENT GATEWAY',
            content: 'You will redirect to our payment gateway page once you click OK.',
            onOk: () => this.props.history.push('/billings/payments')
        });
    }

    async downloadPdf(pathname) {
        const url = await get(pathname)
        window.open(url);
    }

    render() {
        const dataSource = [
            {
                title: 'Monthly SOA',
                ...this.props.tenantData
            }
        ]

        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                sorter: (a, b) => a.title && a.title.localeCompare(b.title)

            },
            {
                title: 'Date',
                dataIndex: 'soaDate',
                key: 'soaDate',
                sorter: (a, b) => new Date(b.soaDate) - new Date(a.soaDate),
            },
            {
                title: 'SOA Amount',
                dataIndex: 'soaAmount',
                key: 'soaAmount',
                sorter: (a, b) => a.soaAmount - b.soaAmount,
                render: (item) => {
                    return (
                        <span style={{ fontWeight: 'bold', fontSize: 16, color: '#1890FF' }}>₱{formatMoney(item)}</span>
                    )
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: '',
                render: (info, data) => {
                    return (
                        <div className='action-btn'>
                            <Button type="primary" className='primary-btn' style={{ margin: 4 }} icon={<EyeOutlined />} size={'medium'} title='View' onClick={() => this.setState({ selectedRow: data, isEditModalOpen: true })}>View Pdf</Button>
                            <Button type="primary" className='primary-btn' style={{ margin: 4 }} icon={< CloudDownloadOutlined />} size={'medium'} title='Delete' onClick={() => this.downloadPdf('soa/pdf/' + data.soaName)} >
                                Download
                            </Button>
                            <Button type="primary" className='primary-btn' onClick={() => this.payNow()} style={{ margin: 4 }} icon={< RetweetOutlined />} size={'medium'} title='Delete' >Pay Now</Button>
                        </div>
                    )
                }
            },
        ];
        return (
            <Fragment>
                <Card title={`SOA as of ${this.props.tenantData.soaDate || ''}`}>
                    <Table columns={columns} dataSource={dataSource} rowKey="id" pagination={false} />
                </Card>
                {
                    this.state.isEditModalOpen &&
                    <EditModal
                        visible={this.state.isEditModalOpen}
                        selectedRow={this.state.selectedRow}
                        onCancel={this.editModalToggle}
                    />
                }
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        tenantData: state.client.billings.tenantData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadTenant: (data) => dispatch(loadTenant(data)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bill))
