
import React from 'react'
import { Card, Tag, Table, Pagination, Row, Col, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadPaymentTransaction } from '../actions'
import { formatMoney } from '../../../../utilities/helper'
import './index.scss'
import { Fragment } from 'react';
import EditModal from './modals/edit'
import moment from 'moment'
import { DIRECT_DEPOSIT, DRAGON_PAY, QUICK_WIRE } from '../../../../utilities/paymentsTypeEnum'
import { DELETE, CREATED, FOR_VERIFICATION, VERIFIED, POSTED, REJECTED } from '../../../../utilities/paymentsStatusEnum'

class PaymentTransaction extends React.Component {
    constructor() {
        super()
        this.state = {
            isEditModalOpen: false,
            selectedRow: {},
            //pagination
            page: 1,
            limit: 10,
            searchTerm: '',

            tableData: []
        }
        this.editModalToggle = this.editModalToggle.bind(this)
    }

    editModalToggle() {
        this.setState({ isEditModalOpen: !this.state.isEditModalOpen })
    }

    componentDidMount() {
        const { limit, page, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadPaymentTransaction(pagination)
    }

    onChangePagination(page) {
        const { limit, searchTerm } = this.state
        const pagination = {
            page: page,
            limit: limit,
            searchTerm: searchTerm
        }
        this.props.onLoadPaymentTransaction(pagination)
        this.setState({ page: page })
    }

    getStatus(status) {
        switch (status) {
            case CREATED:
                return <Tag color="blue">CREATED</Tag>
            case FOR_VERIFICATION:
                return <Tag color="gold">FOR VERIFICATION</Tag>
            case VERIFIED:
                return <Tag color="lime">VERIFIED</Tag>
            case POSTED:
                return <Tag color="geekblue">POSTED</Tag>
            case REJECTED:
                return <Tag color="red">REJECTED</Tag>
            default:
                break;
        }
    }

    getOutsourceStatus(status) {
        switch (status) {
            case 'S':
                return <Tag color="lime">SUCCESSFUL</Tag>
            case 'P':
                return <Tag color="gold">PENDING</Tag>
            case 'R':
                return <Tag color="red">REFUND</Tag>
            default:
                return ''
        }
    }

    getType(status) {
        switch (status) {
            case DIRECT_DEPOSIT:
                return <Tag color="#2db7f5">DIRECT DEPOSIT</Tag>
            case DRAGON_PAY:
                return <Tag color="#87d068">DRAGON PAY</Tag>
            case QUICK_WIRE:
                return <Tag color="#108ee9">QUICK WIRE</Tag>
            default:
                break;
        }
    }


    render() {
        const dataSource = this.props.paymentTransactionsData.data

        const columns = [
            {
                title: 'Created Date',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a, b) => new Date(b.created_at) - new Date(a.created_at),
                render: (item) => {
                    return (
                        <span>{moment(item).format('LLL')}</span>
                    )
                }
            },
            {
                title: 'Invoice Date',
                dataIndex: 'invoiceDate',
                key: 'invoiceDate',
                sorter: (a, b) => new Date(b.invoiceDate) - new Date(a.invoiceDate),
                render: (item) => {
                    return (
                        <span>{moment(item).format('LL')}</span>
                    )
                }
            },
            {
                title: 'Transaction Date',
                dataIndex: 'transactionDate',
                key: 'transactionDate',
                sorter: (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate),
                render: (item) => {
                    return (
                        <span>{moment(item).format('LL')}</span>
                    )
                }
            },
            {
                title: 'Soa Amount',
                dataIndex: 'soaAmount',
                key: 'soaAmount',
                sorter: (a, b) => a.soaAmount - b.soaAmount,
                render: (info) => {
                    return (<span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}>₱{formatMoney(info)}</span>)
                }
            },
            {
                title: 'Paid Amount',
                dataIndex: 'amountPaid',
                key: 'amountPaid',
                sorter: (a, b) => a.amountPaid - b.amountPaid,
                render: (info) => {
                    return (<span style={{ color: '#228D57', fontWeight: 'bold', fontSize: 16 }}>₱{formatMoney(info)}</span>)
                }
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: (item) => {
                    return (<span>{this.getType(item)}</span>)
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (item) => {
                    return (<span>{this.getStatus(item)}</span>)
                }
            },
            {
                title: 'Outsource Status (DragonPay & AQWire)',
                dataIndex: 'outsourceStatus',
                key: 'outsourceStatus',
                render: (item) => {
                    return (<span>{this.getOutsourceStatus(item)}</span>)
                }
            },
            {
                title: 'Message',
                dataIndex: 'message',
                key: 'message',
                sorter: (a, b) => a.message && a.message.localeCompare(b.message),
                render: (item) => {
                    return (<span style={{ color: 'red' }}>{item}</span>)
                }
            },
            {
                title: 'Remarks',
                dataIndex: 'remarks',
                key: 'remarks',
                sorter: (a, b) => a.remarks && a.remarks.localeCompare(b.remarks),
                render: (item) => {
                    return (<span style={{ color: 'red' }}>{item}</span>)
                }
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: '',
                render: (info, data) => {
                    console.log("data.returnUrl", data.returnUrl)
                    return (
                        <div className='action-dropdown-btn'>
                            {
                                data.returnUrl &&
                                <div className='row-center mt5'>
                                    <Button className='primary-btn' onClick={() => {
                                        window.open(data.returnUrl, "_blank");
                                    }} >Download Instructions</Button>
                                </div>
                            }

                        </div>
                    )
                }
            },
        ];
        return (
            <Fragment>
                <Card title='Payment Transactions' className='mt5' >
                    <Row>
                        <Table style={{ width: '100%' }} rowKey='id' loading={this.props.paymentTransactionsLoading} dataSource={dataSource} columns={columns} pagination={false} />
                        <Row style={{ marginTop: 30, width: '100%' }}>
                            <Col md={24} lg={24} sm={24} xs={24} align="right">
                                <Pagination defaultCurrent={this.state.page} onChange={e => this.onChangePagination(e)} total={this.props.paymentTransactionsData.total} />
                            </Col>
                        </Row>
                    </Row>
                </Card>
                {
                    this.state.isEditModalOpen &&
                    <EditModal
                        visible={this.state.isEditModalOpen}
                        selectedRow={this.state.selectedRow}
                        onCancel={this.editModalToggle}
                    />
                }
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        paymentTransactionsLoading: state.client.billings.paymentTransactionsLoading,
        paymentTransactionsData: state.client.billings.paymentTransactionsData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadPaymentTransaction: (pagination) => dispatch(loadPaymentTransaction(pagination)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentTransaction))
