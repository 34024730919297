import {
  GET_SERVICES,
  GET_SERVICES_FULFILLED,
  GET_SERVICES_REJECTED,
  PUT_SERVICES,
  PUT_SERVICES_FULFILLED,
  PUT_SERVICES_REJECTED,
  GET_ADMINS,
  GET_ADMINS_FULFILLED,
  GET_ADMINS_REJECTED,
  POST_ADMINS,
  POST_ADMINS_FULFILLED,
  POST_ADMINS_REJECTED,
  PUT_ADMINS,
  PUT_ADMINS_FULFILLED,
  PUT_ADMINS_REJECTED,
  DELETE_ADMIN,
  DELETE_ADMIN_FULFILLED,
  DELETE_ADMIN_REJECTED,
  GET_ADMIN,
  GET_ADMIN_FULFILLED,
  GET_ADMIN_REJECTED

} from '../actions'
export default function services(state = {
  servicesLoading: false,
  servicesSuccess: false,
  servicesRejected: false,
  servicesData: [],
  updateServicesLoading: false,
  updateServicesSuccess: false,
  updateServicesRejected: false,
  adminsLoading: false,
  adminsSuccess: false,
  adminsRejected: false,
  adminsData: {
    data: []
  },

  adminLoading: false,
  adminSuccess: false,
  adminRejected: false,
  adminData: {
    roles: []
  },

  addAdminLoading: false,
  addAdminSuccess: false,
  addAdminRejected: false,

  editAdminLoading: false,
  editAdminSuccess: false,
  editAdminRejected: false,

  deleteAdminLoading: false,
  deleteAdminSuccess: false,
  deleteAdminRejected: false

}, action) {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        servicesLoading: true,
        servicesSuccess: false,
        servicesRejected: false
      }
    case GET_SERVICES_FULFILLED:
      return {
        ...state,
        servicesLoading: false,
        servicesSuccess: true,
        servicesData: action.payload
      }
    case GET_SERVICES_REJECTED:
      return {
        ...state,
        servicesLoading: false,
        servicesRejected: true
      }
    case PUT_SERVICES:
      return {
        ...state,
        updateServicesLoading: true,
        updateServicesSuccess: false,
        updateServicesRejected: false
      }
    case PUT_SERVICES_FULFILLED:
      return {
        ...state,
        updateServicesLoading: false,
        updateServicesSuccess: true,
        updateServicesData: action.payload
      }
    case PUT_SERVICES_REJECTED:
      return {
        ...state,
        updateServicesLoading: false,
        updateServicesRejected: true
      }
    case GET_ADMINS:
      return {
        ...state,
        adminsLoading: true,
        adminsSuccess: false,
        adminsRejected: false
      }
    case GET_ADMINS_FULFILLED:
      action.payload && action.payload.data.map((data, index) => {
        if (!data.roles) action.payload.data[index].roles = []
      })
      return {
        ...state,
        adminsLoading: false,
        adminsSuccess: true,
        adminsData: action.payload
      }
    case GET_ADMINS_REJECTED:
      return {
        ...state,
        adminsLoading: false,
        adminsRejected: true
      }

    case GET_ADMIN:
      return {
        ...state,
        adminLoading: true,
        adminSuccess: false,
        adminRejected: false
      }
    case GET_ADMIN_FULFILLED:
      action.payload.roles = action.payload.roles ? action.payload.roles : []
      return {
        ...state,
        adminLoading: false,
        adminSuccess: true,
        adminData: action.payload
      }
    case GET_ADMIN_REJECTED:
      return {
        ...state,
        adminLoading: false,
        adminRejected: true
      }


    case POST_ADMINS:
      return {
        ...state,
        addAdminLoading: true,
        addAdminSuccess: false,
        addAdminRejected: false
      }
    case POST_ADMINS_FULFILLED:
      return {
        ...state,
        addAdminLoading: false,
        addAdminSuccess: true,
        addAdminData: action.payload
      }
    case POST_ADMINS_REJECTED:
      return {
        ...state,
        addAdminLoading: false,
        addAdminRejected: true
      }
    case PUT_ADMINS:
      return {
        ...state,
        editAdminLoading: true,
        editAdminSuccess: false,
        editAdminRejected: false
      }
    case PUT_ADMINS_FULFILLED:
      return {
        ...state,
        editAdminLoading: false,
        editAdminSuccess: true
      }
    case PUT_ADMINS_REJECTED:
      return {
        ...state,
        editAdminLoading: false,
        editAdminRejected: true
      }
    case DELETE_ADMIN:
      return {
        ...state,
        deleteAdminLoading: true,
        deleteAdminSuccess: false,
        deleteAdminRejected: false
      }
    case DELETE_ADMIN_FULFILLED:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminSuccess: true
      }
    case DELETE_ADMIN_REJECTED:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminRejected: true
      }


    default:
      return {
        ...state
      }
  }
}
