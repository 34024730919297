import React from 'react'
import { Row, Col, Form, Modal, Button, Input, Select, Spin, DatePicker, Card, Space } from 'antd'
import { DownloadOutlined, FileAddOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux'
import { submitService } from '../../actions'
import { getUser } from '../../../../../utilities/token'
import { loadTenant } from '../../../tenants/actions'
import moment from 'moment'
const { Search } = Input;
const { Option } = Select;

class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      base64ImageUrl: '',
      isLoading: false
    }
  }

  componentDidMount() {
    this.setState({ base64ImageUrl: '' })
    this.props.onLoadTenant()
  }

  handleSubmit(values) {
    const submitData = {
      "tenantId": values.tenantId,
      "postById": getUser().id,
      "title": values.title,
      "description": values.description,
      "comment": values.comment,
    }
    const imageUrl = this.state.base64ImageUrl.split(',')[1]
    if (this.state.base64ImageUrl === '') {
      this.props.onSubmitServinceRequest(values)
    } else {
      this.setState({ isLoading: true })
      postData('https://api.imgur.com/3/image', { "image": imageUrl }).then(res => {
        this.setState({ isLoading: false })
        this.props.onSubmitServinceRequest({ ...values, image: res.data.link })
      }).catch((err) => {
        this.setState({ isLoading: false })
      })
    }
  }

  render() {
    const { selectedRow } = this.props
    return (
      <Modal
        title="ADD PERMIT"
        centered
        visible={this.props.visible}
        // onOk={() => setVisible(false)}
        onCancel={() => this.props.onCancel()}
        width={600}
        footer={null}
      >
        <Spin size="large" spinning={this.props.tenantsLoading}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Tenant"
                  name="tenantId"
                  rules={[{ required: true, message: 'Please input your tenant!' }]}
                >
                  <Select style={{ width: '100%' }}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                  >
                    {
                      this.props.tenantsData && this.props.tenantsData.data.map((data, i) => {
                        return (
                          <Option key={i} value={data.id}>{`${data.uoccname}`}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Work Service For"
                  name="workServiceFor"
                  rules={[{ required: true, message: 'Please input your work service for!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Work Area"
                  name="workArea"
                  rules={[{ required: true, message: 'Please input your work area!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Purpose"
                  name="purpose"
                  rules={[{ required: true, message: 'Please input your purpose!' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Covered From"
                  name="dateFrom"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date from!',
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  label="Covered"
                  name="dateTo"
                  rules={[
                    {
                      required: true,
                      message: 'Please input the date to!',
                    },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm:ss"
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Card title="List of Workers">
                  <Form.List name="contractors">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(field => (
                          <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...field}
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Missing Name' }]}
                            >
                              <Input placeholder="Worker Name" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'information']}
                              fieldKey={[field.fieldKey, 'information']}
                              rules={[{ required: true, message: 'Missing Information' }]}
                            >
                              <Input placeholder="Worker Information" />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Worker
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'right'}>
                <Form.Item >
                  <Button style={{ marginTop: 8 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit
                  </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    serviceLoading: state.admin.services.serviceLoading,
    tenantsData: state.admin.tenant.tenantsData,
    tenantsLoading: state.admin.tenant.tenantsLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitServinceRequest: (data) => dispatch(submitService(data)),
    onLoadTenant: (data) => dispatch(loadTenant(data))
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))



async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Authorization': 'Client-ID 6ca8e4184363ab4',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  return response.json();
}