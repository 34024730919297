
import React from 'react'
import { Input } from 'antd'
import { connect } from 'react-redux'
import { getUser } from '../../../../../utilities/token'
import '../index.scss'
import { submitComments } from '../../actions'


class CommentBox extends React.Component {
    constructor() {
        super()
        this.state = {
            value: ''
        }
    }

    handleSubmitComment(data) {
        const submitData = {
            "tenantId": getUser().id,
            "postId": this.props.postId,
            "comment": data.target.value,
        }
        this.props.onEnterData(submitData)
        this.setState({ value: '' })
    }

    render() {
        // const [form] = Form.useForm()
        return (
            <Input className='comment-tb' value={this.state.value} onChange={(e) => this.setState({ value: e.target.value })} disabled={!this.props.enableMessaging} placeholder={this.props.enableMessaging ? 'White a comment' : 'Disabled Comments'} onPressEnter={(e) => {
                this.handleSubmitComment(e)
                this.setState({ value: '' })
            }} />
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        onSubmitComments: (data) => dispatch(submitComments(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommentBox)
