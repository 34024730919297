import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_VISITOR = 'GET_VISITOR'
export const GET_VISITOR_FULFILLED = 'GET_VISITOR_FULFILLED'
export const GET_VISITOR_REJECTED = 'GET_VISITOR_REJECTED'

export const POST_VISITOR = 'POST_VISITOR'
export const POST_VISITOR_FULFILLED = 'POST_VISITOR_FULFILLED'
export const POST_VISITOR_REJECTED = 'POST_VISITOR_REJECTED'

export const PUT_VISITOR = 'PUT_VISITOR'
export const PUT_VISITOR_FULFILLED = 'PUT_VISITOR_FULFILLED'
export const PUT_VISITOR_REJECTED = 'PUT_VISITOR_REJECTED'

export const DELETE_VISITOR = 'DELETE_VISITOR'
export const DELETE_VISITOR_FULFILLED = 'DELETE_VISITOR_FULFILLED'
export const DELETE_VISITOR_REJECTED = 'DELETE_VISITOR_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadVisitor = (data) => {
  return dispatch => {
    dispatch({
      type: GET_VISITOR,
      payload: {}
    })
    return Axios.get(`${API_URL}/visitors`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_VISITOR_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_VISITOR_REJECTED,
          payload: error
        })
      })
  }
}

export const submitVisitor = (data) => {
  return dispatch => {
    dispatch({
      type: POST_VISITOR,
      payload: {}
    })
    return Axios.post(`${API_URL}/visitor`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch(loadVisitor())
        dispatch({
          type: POST_VISITOR_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_VISITOR_REJECTED,
          payload: error
        })
      })
  }
}

export const updateVisitor = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_VISITOR,
      payload: {}
    })
    return Axios.put(`${API_URL}/visitor/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch(loadVisitor())
        return dispatch({
          type: PUT_VISITOR_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_VISITOR_REJECTED,
          payload: error
        })
      })
  }
}


export const deleteVisitor = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_VISITOR,
      payload: {}
    })
    return Axios.delete(`${API_URL}/visitor/${id}`, { headers: headers })
      .then(function (response) {
        dispatch(loadVisitor())
        return dispatch({
          type: DELETE_VISITOR_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: DELETE_VISITOR_REJECTED,
          payload: error
        })
      })
  }
}











