import {
  POST_TICKET,
  POST_TICKET_FULFILLED,
  POST_TICKET_REJECTED,
  GET_TICKETS,
  GET_TICKETS_FULFILLED,
  GET_TICKETS_REJECTED,
  PUT_TICKET,
  PUT_TICKET_FULFILLED,
  PUT_TICKET_REJECTED,
  DELETE_TICKET,
  DELETE_TICKET_FULFILLED,
  DELETE_TICKET_REJECTED
} from '../actions'

export default function tickets(state = {
  ticketLoading: false,
  ticketSuccess: false,
  ticketRejected: false,
  ticketData: [],
  ticketsLoading: false,
  ticketsSuccess: false,
  ticketsRejected: false,
  ticketsData: [],
  updateTicketLoading: false,
  updateTicketSuccess: false,
  updateTicketRejected: false,
  updateTicketData: [],
  deleteTicketLoading: false,
  deleteTicketSuccess: false,
  deleteTicketRejected: false,
  deleteTicketData: []
}, action) {
  switch (action.type) {
    case POST_TICKET:
      return {
        ...state,
        ticketLoading: true,
        ticketSuccess: false,
        ticketRejected: false
      }
    case POST_TICKET_FULFILLED:
      return {
        ...state,
        ticketLoading: false,
        ticketSuccess: true,
        ticketData: action.payload
      }
    case POST_TICKET_REJECTED:
      return {
        ...state,
        ticketLoading: false,
        ticketRejected: true
      }
    case GET_TICKETS:
      return {
        ...state,
        ticketsLoading: true,
        ticketsSuccess: false,
        ticketsRejected: false
      }
    case GET_TICKETS_FULFILLED:
      return {
        ...state,
        ticketsLoading: false,
        ticketsSuccess: true,
        ticketsData: action.payload
      }
    case GET_TICKETS_REJECTED:
      return {
        ...state,
        ticketsLoading: false,
        ticketsRejected: true
      }
    case PUT_TICKET:
      return {
        ...state,
        updateTicketLoading: true,
        updateTicketSuccess: false,
        updateTicketRejected: false
      }
    case PUT_TICKET_FULFILLED:
      return {
        ...state,
        updateTicketLoading: false,
        updateTicketSuccess: true,
        updateTicketData: action.payload
      }
    case PUT_TICKET_REJECTED:
      return {
        ...state,
        updateTicketLoading: false,
        updateTicketRejected: true
      }
    case DELETE_TICKET:
      return {
        ...state,
        deleteTicketLoading: true,
        deleteTicketSuccess: false,
        deleteTicketRejected: false
      }
    case DELETE_TICKET_FULFILLED:
      return {
        ...state,
        deleteTicketLoading: false,
        deleteTicketSuccess: true,
        deleteTicketData: action.payload
      }
    case DELETE_TICKET_REJECTED:
      return {
        ...state,
        deleteTicketLoading: false,
        deleteTicketRejected: true
      }
    default:
      return {
        ...state
      }
  }
}
