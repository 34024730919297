import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const POST_INQUIRY = 'POST_INQUIRY'
export const POST_INQUIRY_FULFILLED = 'POST_INQUIRY_FULFILLED'
export const POST_INQUIRY_REJECTED = 'POST_INQUIRY_REJECTED'

export const GET_INQUIRIES = 'GET_INQUIRIES'
export const GET_INQUIRIES_FULFILLED = 'GET_INQUIRIES_FULFILLED'
export const GET_INQUIRIES_REJECTED = 'GET_INQUIRIES_REJECTED'

export const GET_INQUIRY = 'GET_INQUIRY'
export const GET_INQUIRY_FULFILLED = 'GET_INQUIRY_FULFILLED'
export const GET_INQUIRY_REJECTED = 'GET_INQUIRY_REJECTED'

export const PUT_INQUIRY = 'PUT_INQUIRY'
export const PUT_INQUIRY_FULFILLED = 'PUT_INQUIRY_FULFILLED'
export const PUT_INQUIRY_REJECTED = 'PUT_INQUIRY_REJECTED'

export const DELETE_INQUIRY = 'DELETE_INQUIRY'
export const DELETE_INQUIRY_FULFILLED = 'DELETE_INQUIRY_FULFILLED'
export const DELETE_INQUIRY_REJECTED = 'DELETE_INQUIRY_REJECTED'

export const GET_SELECTED_KEYS_INQUIRY = 'GET_SELECTED_KEYS_INQUIRY'
export const GET_SELECTED_KEYS_INQUIRY_FULFILLED = 'GET_SELECTED_KEYS_INQUIRY_FULFILLED'
export const GET_SELECTED_KEYS_INQUIRY_REJECTED = 'GET_SELECTED_KEYS_INQUIRY_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadInquiry = (status, pagination) => {
  return dispatch => {
    dispatch({
      type: GET_INQUIRIES,
      payload: {}
    })
    return Axios.get(`${API_URL}/inquiries?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}&status=${status}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_INQUIRIES_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_INQUIRIES_REJECTED,
          payload: error
        })
      })
  }
}

export const loadInquiryById = (id) => {
  return dispatch => {
    dispatch({
      type: GET_INQUIRY,
      payload: {}
    })
    return Axios.get(`${API_URL}/inquiry/${id}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_INQUIRY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_INQUIRY_REJECTED,
          payload: error
        })
      })
  }
}


export const submitInquiry = (data) => {
  return dispatch => {
    dispatch({
      type: POST_INQUIRY,
      payload: {}
    })
    return Axios.post(`${API_URL}/inquiry`, { ...data }, { headers: headers })
      .then(function (response) {
        dispatch({
          type: POST_INQUIRY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: POST_INQUIRY_REJECTED,
          payload: error
        })
      })
  }
}

export const updateInquiry = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_INQUIRY,
      payload: {}
    })
    return Axios.put(`${API_URL}/inquiry/${data.id}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_INQUIRY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_INQUIRY_REJECTED,
          payload: error
        })
      })
  }
}

export const deleteInquiry = (id) => {
  return dispatch => {
    dispatch({
      type: DELETE_INQUIRY,
      payload: {}
    })
    return Axios.delete(`${API_URL}/inquiry/${id}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: DELETE_INQUIRY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: DELETE_INQUIRY_REJECTED,
          payload: error
        })
      })
  }
}

export const loadSelectedRowInquiry = (selectedKeys) => {
  return dispatch => {
    dispatch({
      type: GET_SELECTED_KEYS_INQUIRY,
      payload: {}
    })
    return Axios.get(`${API_URL}/inquiryIds?selectedIds=${selectedKeys.toString()}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_SELECTED_KEYS_INQUIRY_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_SELECTED_KEYS_INQUIRY_REJECTED,
          payload: error
        })
      })
  }
}





