import React from 'react'
import { Row, Col, Form, Button, Input, Select, Spin } from 'antd'
import { getUser } from '../../../../../utilities/token'
import { connect } from 'react-redux'
import { submitCommendation } from '../../actions'
import SlidingPane from "react-sliding-pane";
const { Option } = Select;
class Add extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
    }
  }

  handleSubmit(values) {
    this.setState({ isLoading: true })
    const submitData = {
      "tenantId": getUser().id,
      "title": values.title,
      "comment": values.comment,
      "type": values.type,
    }

    this.props.onSubmitCommendation(submitData).then(res => {
      this.setState({ isLoading: false })
    })
  }

  render() {
    return (
      <SlidingPane
        isOpen={this.props.visible}
        title="ADD COMMENDATIONS / TESTIMONIALS"
        subtitle=""
        width={'50%'}
        onRequestClose={() => {
          this.props.onCancel()
        }}
      >
        <Spin size="large"
          spinning={false}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={(e) => this.handleSubmit(e)}
          // onFinishFailed={onFinishFailed}
          >
            <Row gutter={12}>
              <Col md={24} lg={24} sm={24} xs={24}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[{ required: true, message: 'Please input your title!' }]}
                >
                  <Input className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Comment"
                  name="comment"
                  rules={[{ required: true, message: 'Please input your comments!' }]}
                >
                  <Input.TextArea rows={5} className='primary-input' />
                </Form.Item>

                <Form.Item
                  label="Type"
                  name="type"
                  rules={[{ required: true, message: 'Please input your type!' }]}
                >
                  <Select style={{ width: '100%' }} className='primary-input-select' >
                    <Option value={1}>COMMENDATIONS</Option>
                    <Option value={2}>TESTIMONIALS</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} lg={24} sm={24} align={'left'}>
                <Form.Item >
                  <Button className='primary-btn' style={{ marginTop: 50 }} type="primary" htmlType="submit"
                    loading={this.props.postLoading || this.state.isLoading}
                  >
                    Submit Commendation / Testimonial
                </Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </SlidingPane>
    )
  }
}

function mapStateToProps(state) {
  return {
    postLoading: state.client.commendations.postLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitCommendation: (data) => dispatch(submitCommendation(data)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(Add))
