import Axios from 'axios'
import { headers } from '../../../../utilities/token'

export const GET_TENANT = 'GET_TENANT'
export const GET_TENANT_FULFILLED = 'GET_TENANT_FULFILLED'
export const GET_TENANT_REJECTED = 'GET_TENANT_REJECTED'

export const POST_PAYMENT = 'POST_PAYMENT'
export const POST_PAYMENT_FULFILLED = 'POST_PAYMENT_FULFILLED'
export const POST_PAYMENT_REJECTED = 'POST_PAYMENT_REJECTED'

export const GET_PAYMENT_TRANSACTION = 'GET_PAYMENT_TRANSACTION'
export const GET_PAYMENT_TRANSACTION_FULFILLED = 'GET_PAYMENT_TRANSACTION_FULFILLED'
export const GET_PAYMENT_TRANSACTION_REJECTED = 'GET_PAYMENT_TRANSACTION_REJECTED'

export const PUT_UPDATE_DRAGON_PAY_STATUS = 'PUT_UPDATE_DRAGON_PAY_STATUS'
export const PUT_UPDATE_DRAGON_PAY_STATUS_FULFILLED = 'PUT_UPDATE_DRAGON_PAY_STATUS_FULFILLED'
export const PUT_UPDATE_DRAGON_PAY_STATUS_REJECTED = 'PUT_UPDATE_DRAGON_PAY_STATUS_REJECTED'

export const PUT_UPDATE_AQWIRE_STATUS = 'PUT_UPDATE_AQWIRE_STATUS'
export const PUT_UPDATE_AQWIRE_STATUS_FULFILLED = 'PUT_UPDATE_AQWIRE_STATUS_FULFILLED'
export const PUT_UPDATE_AQWIRE_STATUS_REJECTED = 'PUT_UPDATE_AQWIRE_STATUS_REJECTED'

const API_URL = process.env.REACT_APP_API_BASE_URL

export const loadTenant = (tenantId) => {
  return dispatch => {
    dispatch({
      type: GET_TENANT,
      payload: {}
    })
    return Axios.get(`${API_URL}/tenant/${tenantId}`, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: GET_TENANT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: GET_TENANT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPayment = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PAYMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/payment`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPaymentDragonPay = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PAYMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/payment-dragonpay`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const loadPaymentTransaction = (pagination) => {
  return dispatch => {
    dispatch({
      type: GET_PAYMENT_TRANSACTION,
      payload: {}
    })
    return Axios.get(`${API_URL}/payments?page=${pagination.page}&limit=${pagination.limit}&searchTerm=${pagination.searchTerm}`, { headers: headers })
      .then(function (response) {
        dispatch({
          type: GET_PAYMENT_TRANSACTION_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: GET_PAYMENT_TRANSACTION_REJECTED,
          payload: error
        })
      })
  }
}

export const updateDragonPayStatus = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_UPDATE_DRAGON_PAY_STATUS,
      payload: {}
    })
    return Axios.put(`${API_URL}/payment-dragonpay/${data.txnId}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_UPDATE_DRAGON_PAY_STATUS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_UPDATE_DRAGON_PAY_STATUS_REJECTED,
          payload: error
        })
      })
  }
}

export const submitPaymentAQWire = (data) => {
  return dispatch => {
    dispatch({
      type: POST_PAYMENT,
      payload: {}
    })
    return Axios.post(`${API_URL}/payment-aqwire`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: POST_PAYMENT_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: POST_PAYMENT_REJECTED,
          payload: error
        })
      })
  }
}

export const updateAQWireStatus = (data) => {
  return dispatch => {
    dispatch({
      type: PUT_UPDATE_AQWIRE_STATUS,
      payload: {}
    })
    return Axios.put(`${API_URL}/payment-aqwire/${data.paymentId}`, { ...data }, { headers: headers })
      .then(function (response) {
        return dispatch({
          type: PUT_UPDATE_AQWIRE_STATUS_FULFILLED,
          payload: response.data
        })
      })
      .catch(function (error) {
        return dispatch({
          type: PUT_UPDATE_AQWIRE_STATUS_REJECTED,
          payload: error
        })
      })
  }
}

